import React from 'react';

import { ParagraphLinkList } from '../0-atoms';

export type MedicalOffersTeaserListProps = {
  offers: Array<{
    label: string;
    url: string;
    domain?: string;
  }>;
  className?: string;
};

export const MedicalOffersTeaserList: React.FC<
  MedicalOffersTeaserListProps
> = ({ offers, className }) => (
  <ParagraphLinkList
    className={className}
    links={offers.map(({ label, url, domain }) => ({
      label,
      url,
      domain,
    }))}
  />
);
