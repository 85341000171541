import { ParagraphMapFragment } from '@custom/schema';

export const transformParagraphMapCoordinatesToProps = (
  paragraph: ParagraphMapFragment,
) => ({
  latitude: parseFloat(paragraph.latitude!),
  longitude: parseFloat(paragraph.longitude!),
  title: paragraph?.mapTitle,
  small: paragraph.smallVariant,
});
