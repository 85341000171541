import React from 'react';

export type IconFooterSnapChatProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterSnapChat: React.FC<IconFooterSnapChatProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    viewBox="0 0 512 512"
  >
    <path
      fill={fill}
      d="M124.3 96.7c-12.8 28.7-7.7 80-5.7 116-13.8 7.7-31.6-5.8-41.6-5.8-10.5 0-22.9 6.9-24.9 17.1-1.4 7.4 1.9 18.1 25.6 27.5 9.2 3.6 31 7.9 36.1 19.8 7.1 16.7-36.5 93.9-104.9 105.2-5.4.9-9.2 5.7-8.9 11.1 1.2 20.8 47.8 28.9 68.5 32.1 2.1 2.9 3.8 14.9 6.5 24.1 1.2 4.1 4.4 9 12.4 9 10.5 0 28-8.1 58.4-3.1 29.8 5 57.9 47.3 111.7 47.3 50 0 79.9-42.5 108.6-47.3 16.6-2.8 30.9-1.9 46.8 1.2 11 2.2 20.8 3.3 24-7.4 2.8-9.3 4.4-21.2 6.5-24 20.5-3.2 67.3-11.3 68.5-32.1.3-5.4-3.5-10.2-8.9-11.1-67.3-11.1-112.2-88.1-104.9-105.2 5-11.9 26.7-16.1 36.1-19.8 17.4-6.8 26.1-15.3 25.9-25-.2-12.5-15.3-19.9-26.3-19.9-11.2 0-27.4 13.3-40.5 6.1 2-36.2 7.1-87.4-5.7-116-24.2-54.1-78-81.5-131.9-81.5-53.5 0-106.9 27-131.4 81.7z"
    />
  </svg>
);
