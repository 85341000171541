import {
  AnyOperationId,
  OperationResult,
  OperationVariables,
} from '@custom/schema';
import { useQuery, UseQueryOptions } from 'react-query';

export async function drupalExecutor<OperationId extends AnyOperationId>(
  id: OperationId,
  variables?: OperationVariables<OperationId>,
) {
  const url = new URL(
    `${window.location.protocol}//${window.location.host}/graphql/website-build`,
  );
  const isMutation = id.includes('Mutation:');

  const requestInit = (
    isMutation
      ? {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            queryId: id,
            variables: variables || {},
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      : {
          credentials: 'include',
        }
  ) satisfies RequestInit;

  if (!isMutation) {
    url.searchParams.set('queryId', id);
    url.searchParams.set('variables', JSON.stringify(variables || {}));
  }

  try {
    const { data, errors } = await (await fetch(url, requestInit)).json();
    if (errors) {
      console.error('GraphQL error:', errors);
      if (!data) {
        throw errors;
      }
    }
    return data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw new Error(`Fetch error: ${error}`);
  }
}

export function useOperation<TOperationId extends AnyOperationId>(
  id: TOperationId,
  variables: OperationVariables<TOperationId>,
  options: UseQueryOptions = {},
) {
  return useQuery<OperationResult<TOperationId>>(
    [id, variables],
    async () => {
      return drupalExecutor(id, variables);
    },
    options,
  );
}
