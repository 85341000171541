import React from 'react';

export type IconFaxSmallProps = {
  className?: string;
};

export const IconFaxSmall: React.FC<IconFaxSmallProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 297 297"
  >
    <g>
      <path
        d="M271.425,64.845h-7.26V9.9c0-5.468-4.433-9.9-9.9-9.9h-130.68c-5.467,0-9.9,4.433-9.9,9.9v54.944h-12.87V58.41
c0-5.468-4.432-9.9-9.899-9.9H25.574c-5.466,0-9.899,4.433-9.899,9.9v196.02c0,5.468,4.434,9.9,9.899,9.9h22.771v6.434
c0,14.467,12.435,26.235,27.72,26.235h62.37c15.285,0,27.72-12.435,27.72-27.72v-21.285h105.271c5.467,0,9.9-4.433,9.9-9.9V74.745
C281.325,69.277,276.892,64.845,271.425,64.845z M133.484,19.8h110.88v45.045h-110.88V19.8z M35.475,68.311h45.54v176.22h-45.54
V68.311z M146.355,269.28c0,4.367-3.552,7.92-7.92,7.92h-62.37c-4.292,0-7.92-2.947-7.92-6.436v-6.434h22.771
c5.468,0,9.899-4.433,9.899-9.9v-6.435h45.54V269.28z M261.525,228.195H100.814V84.645h160.711V228.195z"
      />
      <path
        d="M221.595,133.65h16.335c5.468,0,9.899-4.434,9.899-9.9s-4.432-9.9-9.899-9.9h-16.335c-5.467,0-9.9,4.434-9.9,9.9
S216.128,133.65,221.595,133.65z"
      />
      <path
        d="M172.59,133.65h16.335c5.468,0,9.899-4.434,9.899-9.9s-4.432-9.9-9.899-9.9H172.59c-5.467,0-9.9,4.434-9.9,9.9
S167.123,133.65,172.59,133.65z"
      />
      <path
        d="M123.585,133.65h16.335c5.468,0,9.899-4.434,9.899-9.9s-4.432-9.9-9.899-9.9h-16.335c-5.467,0-9.9,4.434-9.9,9.9
S118.118,133.65,123.585,133.65z"
      />
      <path
        d="M221.595,166.32h16.335c5.468,0,9.899-4.433,9.899-9.9c0-5.467-4.432-9.9-9.899-9.9h-16.335c-5.467,0-9.9,4.434-9.9,9.9
C211.694,161.888,216.128,166.32,221.595,166.32z"
      />
      <path
        d="M172.59,166.32h16.335c5.468,0,9.899-4.433,9.899-9.9c0-5.467-4.432-9.9-9.899-9.9H172.59c-5.467,0-9.9,4.434-9.9,9.9
C162.689,161.888,167.123,166.32,172.59,166.32z"
      />
      <path
        d="M123.585,166.32h16.335c5.468,0,9.899-4.433,9.899-9.9c0-5.467-4.432-9.9-9.899-9.9h-16.335c-5.467,0-9.9,4.434-9.9,9.9
C113.685,161.888,118.118,166.32,123.585,166.32z"
      />
      <path
        d="M221.595,198.99h16.335c5.468,0,9.899-4.433,9.899-9.9c0-5.467-4.432-9.899-9.899-9.899h-16.335
c-5.467,0-9.9,4.433-9.9,9.899C211.694,194.558,216.128,198.99,221.595,198.99z"
      />
      <path
        d="M172.59,198.99h16.335c5.468,0,9.899-4.433,9.899-9.9c0-5.467-4.432-9.899-9.899-9.899H172.59
c-5.467,0-9.9,4.433-9.9,9.899C162.689,194.558,167.123,198.99,172.59,198.99z"
      />
      <path
        d="M123.585,198.99h16.335c5.468,0,9.899-4.433,9.899-9.9c0-5.467-4.432-9.899-9.899-9.899h-16.335
c-5.467,0-9.9,4.433-9.9,9.899C113.685,194.558,118.118,198.99,123.585,198.99z"
      />
      <path
        d="M221.595,32.175h-65.34c-5.467,0-9.9,4.433-9.9,9.9c0,5.467,4.434,9.899,9.9,9.899h65.34c5.468,0,9.9-4.433,9.9-9.899
C231.495,36.607,227.063,32.175,221.595,32.175z"
      />
    </g>
  </svg>
);
