import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { useScript } from '../../hooks';

export type ParagraphStorifyMeProps = {
  widgetId: string;
  className?: string;
};

export const ParagraphStorifyMe: React.FC<ParagraphStorifyMeProps> = (
  props,
) => {
  const scriptStatus = useScript(
    'https://cdn.storifyme.com/static/web-components/storifyme-elements.min.js',
  );

  const settings = useStaticQuery(graphql`
    query SettingsStorifyMe {
      settingsStorifyMe {
        accountId
        environment
      }
    }
  `);

  if (scriptStatus !== 'ready') {
    return <></>;
  }

  return (
    <div className={'StorifyMe-Widget'}>
      <storifyme-collection
        // @ts-ignore
        account={settings.settingsStorifyMe.accountId}
        widget={props.widgetId}
        env={settings.settingsStorifyMe.environment}
      ></storifyme-collection>
    </div>
  );
};

export default ParagraphStorifyMe;
