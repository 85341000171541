import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphImageGridProps = {
  imageLeft: string | ReactNode;
  imageRight: string | ReactNode;
  className?: string;
};

export const ParagraphImageGrid: React.FC<ParagraphImageGridProps> = ({
  imageLeft,
  imageRight,
  className,
}) => (
  <FadeUp yGap={20} className={classNames('ImageGrid', className)}>
    <div className="ImageGrid--inner">
      <div className="ImageGrid--img ImageGrid--img-small">
        {typeof imageLeft === 'string' ? (
          <img
            src={imageLeft}
            className="ArticleFigure--img"
            alt=""
            loading="lazy"
          />
        ) : (
          imageLeft
        )}
      </div>
      <div className="ImageGrid--img ImageGrid--img-large">
        {typeof imageRight === 'string' ? (
          <img
            src={imageRight}
            className="ArticleFigure--img"
            alt=""
            loading="lazy"
          />
        ) : (
          imageRight
        )}
      </div>
    </div>
  </FadeUp>
);

export default ParagraphImageGrid;
