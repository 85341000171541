import React from 'react';

export type IconFooterYoutubeProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterYoutube: React.FC<IconFooterYoutubeProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="28.89"
    height="21.917"
    viewBox="0 0 28.89 21.917"
  >
    <path
      fill={fill}
      d="M28.591 4.732a7.319 7.319 0 00-1.146-3.088 3.824 3.824 0 00-2.889-1.3c-4.035-.3-10.112-.349-10.112-.349S8.367.045 4.332.344a4.025 4.025 0 00-2.889 1.3A6.655 6.655 0 00.3 4.732 49.83 49.83 0 000 9.813v2.341a48.23 48.23 0 00.3 5.031 7.319 7.319 0 001.146 3.088 4.786 4.786 0 003.188 1.345c2.291.249 9.813.3 9.813.3s6.077 0 10.112-.349a4.025 4.025 0 002.889-1.3 7.319 7.319 0 001.146-3.088 49.511 49.511 0 00.3-5.031V9.813a49.83 49.83 0 00-.3-5.081"
    />
    <path fill="#f6f6f6" d="M10.958 5.927v10.809l9.464-5.28z" />
  </svg>
);
