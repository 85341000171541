import classNames from 'classnames';
import { useReducedMotion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';
import { HeartClick } from '../0-atoms';

export type HeaderHeroHeartProps = {
  className?: string;
};

export const HeaderHeroHeart: React.FC<HeaderHeroHeartProps> = ({
  className,
}) => {
  const { Link, useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const { domain } = useDomain();
  const container = useRef<HTMLDivElement>(null);

  const heartText = t('Support one of our heart projects for paraplegics');

  const generateDonationPageLink = (fullUrl: string, lang: string) => {
    const translations = new Map([
      ['en', ['donations', 'here']],
      ['de', ['spenden', 'hier']],
      ['fr', ['dons', 'ici']],
      ['it', ['donazioni', 'qui']],
      ['default', ['donations', 'here']],
    ]);
    const translatedUrl = translations.has(lang)
      ? translations.get(lang)
      : translations.get('default');
    return `<a rel="noindex noopener" href="${fullUrl}/${lang}/${
      translatedUrl![0]
    }"/>${translatedUrl![1]}</a>`;
  };

  const donationPage = generateDonationPageLink(domain.fullUrl, locale);

  let heartLinkTranslated = '';
  switch (locale) {
    case 'en':
      heartLinkTranslated = `${heartText} ${donationPage}.`;
      break;
    case 'de':
      heartLinkTranslated = `Unterstützen Sie ${donationPage} ${heartText}`;
      break;
    case 'fr':
      heartLinkTranslated = `Soutenez ${donationPage} ${heartText}`;
      break;
    case 'it':
      heartLinkTranslated = `Sostenete ${donationPage} ${heartText}`;
      break;
    default:
      break;
  }

  const reduceMotion = useReducedMotion();

  useEffect(() => {
    if (!container.current || reduceMotion) {
      return;
    }

    let vmin100: number;
    let width: number;
    let height: number;

    const heartsElement =
      container.current.querySelectorAll<HTMLDivElement>('.HeartClick--heart');
    const boxElement =
      container.current.querySelector<HTMLDivElement>('.HeartClick--box');

    let isOpen: boolean | Heart = false;
    let isNext: boolean | Heart = false;
    const boxSize = 400;
    const boxCushion = 20;

    type Heart = {
      element: HTMLDivElement;
      isStarted: boolean;
      isOpen: boolean;
      isVisible: boolean;
      changesVisibility: boolean;
      visibilityAnimationDuration: number;
      size: number;
      xCenter: number;
      yCenter: number;
      x: number;
      y: number;
      velocity: number;
      visibleFromY: number;
      visibleToY: number;
      timeToY: number;
    };

    let hearts: Array<Heart> = [];

    const randomNumber = (min: number, max: number, roundToInteger = true) => {
      const number = Math.random() * (max - min) + min;
      return roundToInteger ? Math.round(number) : number;
    };

    const onClickOutside = (e: MouseEvent) => {
      if (
        // @ts-ignore
        !e.target.classList.contains('HeartClick--heart') &&
        // @ts-ignore
        !e.target.parentElement.classList.contains('HeartClick--box--text')
      ) {
        close();
      }
    };

    const onMouseEnter = (e: MouseEvent) => {
      // @ts-ignore
      const heartIndex = e.currentTarget.getAttribute('data-heart-index');
      const heart = hearts[parseInt(heartIndex)];

      if (heart.isVisible) {
        heart.isStarted = false;
        heart.element.classList.add('is-wobbling');

        setTimeout(() => {
          heart.element.classList.remove('is-wobbling');
        }, 1550);
      }
    };

    const onMouseLeave = (e: MouseEvent) => {
      // @ts-ignore
      const heartIndex = e.currentTarget.getAttribute('data-heart-index');
      const heart = hearts[parseInt(heartIndex)];

      if (!heart.isOpen) {
        heart.isStarted = true;
      }
    };

    const onHeartClick = (e: MouseEvent) => {
      // @ts-ignore
      const heartIndex = e.currentTarget.getAttribute('data-heart-index');
      const heart = hearts[parseInt(heartIndex)];

      if (heart.isVisible) {
        heart.isStarted = false;

        if (isOpen) {
          isNext = heart;
        } else {
          open(heart);
        }
      }
    };

    const open = (heart: Heart) => {
      close();
      isOpen = heart;
      heart.isOpen = true;

      let leftPosition = heart.xCenter - boxSize / 2;
      let rightPosition;
      let widthBox = boxSize;

      if (leftPosition < boxCushion) {
        leftPosition = boxCushion;
      }
      if (leftPosition + boxSize + boxCushion > width) {
        rightPosition = width - boxCushion;
        leftPosition = rightPosition - boxSize;
        if (leftPosition < boxCushion) {
          leftPosition = boxCushion;
        }
        widthBox = rightPosition - leftPosition;
      }

      boxElement!.style.left = `${leftPosition}px`;
      boxElement!.style.right = rightPosition ? `${rightPosition}px` : 'auto';
      boxElement!.style.top = `${heart.yCenter}px`;
      boxElement!.style.width = `${widthBox}px`;
      boxElement!.classList.add('is-there');

      heart.element.classList.remove('is-visible');
      setTimeout(() => {
        boxElement!.classList.add('is-active');
        document.addEventListener('click', onClickOutside);
      }, 500);
    };

    const close = () => {
      document.removeEventListener('click', onClickOutside);

      if (isOpen && typeof isOpen !== 'boolean') {
        const heart = isOpen;

        boxElement!.classList.remove('is-active');
        setTimeout(() => {
          heart.element.classList.add('is-visible');

          isOpen = false;
          heart.isOpen = false;
          heart.isStarted = true;
          if (isNext && typeof isNext !== 'boolean') {
            open(isNext);
            isNext = false;
          }
        }, 500);
      }
    };

    const onResize = () => {
      close();

      width = container.current!.offsetWidth;
      height = container.current!.offsetHeight;

      vmin100 = width <= height ? width : height;
    };

    const bindUiActions = () => {
      window.addEventListener('resize', onResize);
      document
        .querySelectorAll<HTMLDivElement>('.HeartClick--heart')
        .forEach((heart) => {
          heart.addEventListener('mouseenter', onMouseEnter);
          heart.addEventListener('mouseleave', onMouseLeave);
          heart.addEventListener('click', onHeartClick);
        });
    };

    const unbindUiActions = () => {
      window.removeEventListener('resize', onResize);
      document
        .querySelectorAll<HTMLDivElement>('.HeartClick--heart')
        .forEach((heart) => {
          heart.removeEventListener('mouseenter', onMouseEnter);
          heart.removeEventListener('mouseleave', onMouseLeave);
          heart.removeEventListener('click', onHeartClick);
        });
    };

    const setProperties = (
      heart: Pick<
        Heart,
        | 'element'
        | 'isStarted'
        | 'isOpen'
        | 'isVisible'
        | 'changesVisibility'
        | 'visibilityAnimationDuration'
      >,
      isInitial = false,
    ): Heart => {
      const size = randomNumber((vmin100 / 40) * 2, (vmin100 / 40) * 5);
      const xCenter = randomNumber(0, width);
      const yCenter = isInitial
        ? randomNumber((height / 4) * 1.5, (height / 10) * 9)
        : randomNumber((height / 4) * 3, (height / 10) * 9);

      return {
        ...heart,
        size,
        xCenter,
        yCenter,
        x: xCenter - size / 2,
        y: yCenter - size / 2,
        velocity: randomNumber(0.5, 2, false),
        visibleFromY: yCenter - size / 2,
        visibleToY: randomNumber(height / 10, height / 4),
        timeToY: 0,
      };
    };

    const calc = () => {
      hearts = hearts.map((heart) => {
        if (!heart.isStarted) {
          return heart;
        }
        // Movement
        if (heart.yCenter > heart.visibleToY) {
          heart.yCenter -= heart.velocity;
          heart.y = heart.yCenter - heart.size / 2;
        } else {
          heart = setProperties(heart);
        }

        // Visibility
        if (heart.yCenter <= heart.visibleFromY) {
          heart.timeToY = Math.round(
            ((heart.yCenter - heart.visibleToY) / heart.velocity) * (1000 / 60),
          );
          if (heart.timeToY <= heart.visibilityAnimationDuration) {
            if (heart.isVisible) {
              heart.isVisible = false;
              heart.changesVisibility = true;
            }
          } else {
            if (!heart.isVisible) {
              heart.isVisible = true;
              heart.changesVisibility = true;
            }
          }
        }

        return heart;
      });
    };

    const draw = () => {
      hearts.forEach((heart) => {
        if (!heart.isStarted) return;
        // Movement
        const x = heart.x;
        const y = heart.y;
        heart.element.style.width = `${heart.size}px`;
        heart.element.style.height = `${heart.size}px`;
        heart.element.style.transform = `translate(${x}px, ${y}px)`;
        heart.element.style.willChange = 'transform';
        // Visibility
        if (heart.changesVisibility) {
          heart.changesVisibility = false;
          if (heart.isVisible) {
            heart.element.classList.add('is-visible');
          } else {
            heart.element.classList.remove('is-visible');
          }
        }
      });
    };

    const startAnimation = () => {
      const update = () => {
        calc();
        draw();
        requestAnimationFrame(update);
      };

      // Schedule the first frame.
      setTimeout(() => {
        onResize();
        requestAnimationFrame(update);
      }, 1000);

      hearts.forEach((heart, i) => {
        setTimeout(() => {
          heart.isStarted = true;
        }, 1000 + i * 500);
      });
    };

    let heartIndex = 0;
    for (const heartElement of heartsElement) {
      const heart = setProperties(
        {
          element: heartElement,
          isStarted: false,
          isOpen: false,
          isVisible: false,
          changesVisibility: false,
          visibilityAnimationDuration: 1000,
        },
        true,
      );

      heartElement.setAttribute('data-heart-index', `${heartIndex}`);
      hearts.push(heart);
      heartIndex++;
    }

    bindUiActions();
    startAnimation();

    return unbindUiActions;
  }, []);

  return (
    <div className={classNames('HeartClick', className)} ref={container}>
      <HeartClick />
      <HeartClick />
      <HeartClick />
      <HeartClick />
      <HeartClick />
      <HeartClick />
      <div className="HeartClick--text">
        <div className="HeartClick--staticText">
          <div className="richtext">
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  'Oops, this page does not exist.<br />Would you like to help paraplegics?<br/>Click on a heart.',
                ),
              }}
            />
          </div>
        </div>
        <div className="HeartClick--interactiveText">
          <div className="richtext">
            <Link to="/">{t('Click here for the homepage.')}</Link>
          </div>
        </div>
      </div>
      <div className="HeartClick--box">
        <div className="HeartClick--box--text">
          <p
            dangerouslySetInnerHTML={{
              __html: heartLinkTranslated,
            }}
          />
        </div>
      </div>
    </div>
  );
};
