import loadable from '@loadable/component';

export * from './AccordionItem';
export * from './ActionBar';
export * from './BlogRelated';
export * from './Breadcrumbs';
export * from './CompaniesView';
export * from './CompaniesParaworkView';
export * from './Content';
export * from './CTAContactUsButton';
export * from './CTADonateButton';
export * from './CTAMemberButton';
export * from './CTAOfferButton';
export * from './CTARoomsButton';
export * from './CTAMediaRequestButton';
export * from './DoctorsView';
export * from './EventsTeaserList';
export * from './EmployerBranding';
export * from './FastNavigationMenu';
export * from './FlyoutMenu';
export * from './Footer';
export * from './FooterBanner';
export * from './FooterNewsletter';
export * from './FooterOrganization';
export * from './FundingBanner';
export * from './FundingWidget';
export * from './Header';
export * from './HeaderFlyoutOrganisation';
export * from './HeaderHero';
export * from './HeaderHeroHeart';
export * from './HeaderSticky';
export * from './HeroButton';
export * from './LanguageSwitcher';
export * from './Logo';
export * from './MainMenu';
export * from './MedicalOffersView';
export * from './NewsroomView';
export * from './NewsroomTeaser';
export * from './MembershipWidget';
export * from './MetaNavigationMenu';
export * from './MobileLanguageSwitcher';
export * from './MobileMainMenu';
export * from './MobileMenu';
export * from './MobileMetaNavigationMenu';
export * from './MobileNavigation';
export * from './Overlay';
export * from './Overlays';
export * from './PageActions';
export * from './Pager';
export * from './HistoryView';

// @todo loadable should be replaced with suspense.
export const ParagraphAccordion = loadable(
  () => import('./ParagraphAccordion'),
);
export const ParagraphEventModule = loadable(
  () => import('./ParagraphEventModule'),
);
export const ParagraphFullWidthGallery = loadable(
  () => import('./ParagraphFullWidthGallery'),
);
export const ParagraphGallery = loadable(() => import('./ParagraphGallery'));
export * from './ParagraphHeaderSlider';
export type {
  ParagraphHeaderSliderItem,
  ParagraphHeaderSliderProps,
} from './ParagraphHeaderSlider';
export const ParagraphOrthotecGallery = loadable(
  () => import('./ParagraphOrthotecGallery'),
);
export const ParagraphSection = loadable(() => import('./ParagraphSection'));
export const ParagraphViewsCompanies = loadable(
  () => import('./ParagraphViewsCompanies'),
);
export const ParagraphViewsCompaniesParawork = loadable(
  () => import('./ParagraphViewsCompaniesParawork'),
);
export const ParagraphViewsMedicalOffers = loadable(
  () => import('./ParagraphViewsMedicalOffers'),
);
export const ParagraphViewsNewsroom = loadable(
  () => import('./ParagraphViewsNewsroom'),
);
export const ParagraphViewsNewsroomTeaser = loadable(
  () => import('./ParagraphViewsNewsroomTeaser'),
);
export * from './RaisenowPaymentWidget';
export * from './SearchForm';
export * from './SearchResultsView';
export * from './Share';
export * from './SubpageHeaderHero';
export * from './DoctorHeaderHero';
export * from './ZSMForm';
