import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Doctor } from '../../types';
import { DoctorTeaser } from '../0-atoms';
import { FadeUp } from './FadeIn';

export type DoctorTeaserListProps = {
  doctors: Doctor[];
  title?: string;
  className?: string;
  hasBackground?: boolean;
};

export const DoctorTeaserList: React.FC<DoctorTeaserListProps> = ({
  doctors,
  className,
  hasBackground,
}) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return hasMounted ? (
    <div
      className={classNames(
        'PersonTeaserList PersonTeaserList--grid',
        {
          'has-background': hasBackground,
        },
        className,
      )}
    >
      <FadeUp yGap={20}>
        <ul className="PersonTeaserList--list PersonTeaserList--list-grid">
          {doctors.map((doctor) => (
            <li key={`doctor-${doctor.drupalId}`}>
              <DoctorTeaser {...doctor} />
            </li>
          ))}
        </ul>
      </FadeUp>
    </div>
  ) : null;
};
