import { BlogPaginationQuery } from '@custom/schema';

import { Locale } from '../../i18n';
import { isTruthy } from './isTruthy';

export const getPreviousAndNextBlogPost = (
  id: string | undefined,
  locale: Locale,
  data: BlogPaginationQuery,
) => {
  let previous;
  let next;

  if (!id) {
    return { previous, next };
  }

  const blogs = data.allBlogEntries
    .filter(isTruthy)
    .filter((blog) => blog.langcode === locale);
  const actualElement = blogs.find((entry) => entry.id === id);

  if (actualElement) {
    const elementIndex = blogs.indexOf(actualElement);
    if (elementIndex > 0) {
      previous = blogs[elementIndex - 1];
    } else {
      previous = blogs[blogs.length - 1];
    }
    if (elementIndex < blogs.length - 1) {
      next = blogs[elementIndex + 1];
    } else {
      next = blogs[0];
    }
  }
  return { previous, next };
};
