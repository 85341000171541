import React from 'react';

export type IconKindMeldenProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconKindMelden: React.FC<IconKindMeldenProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    viewBox="0 0 119.8 119.8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M107 71.3c-2.6-5.7-7.7-11.2-13.9-13.9 3.5-2.6 5.8-6.8 5.8-11.4 0-7.8-6.4-14.2-14.2-14.2S70.5 38.2 70.5 46c0 4.8 2.5 9.1 6.2 11.7-2.7 1.3-4.9 3-6.5 4.7.8-1.5 1.3-3.3 1.3-5.1 0-6-4.9-10.9-10.9-10.9s-10.9 4.9-10.9 10.9c0 3 1.2 5.7 3.2 7.7l-.6.3c-1.6-2.2-4.4-5.3-8.5-7.4 3.6-2.6 6-6.8 6-11.6 0-7.8-6.4-14.2-14.2-14.2s-14.2 6.4-14.2 14.2c0 4.4 2.1 8.4 5.3 11C20.5 60 15.5 65.5 13 71.2c-1.9 4.4-2.6 9.4-2 14.5l.2 2.4 33.3-.2 64.3.2.2-2.3c.7-5.1 0-10.2-2-14.5zM36.4 61.1c5.6.7 9.4 4.6 11.4 7.2-.5.4-.9.9-1.4 1.4-3.1 3.5-4.8 7.9-4.9 12.6l-25.4.3c-.1-3.3.5-6.5 1.8-9.3 3-6.8 10.3-13.2 18.5-12.2zm65.5 12.1c1.3 2.8 1.9 6 1.8 9.3l-24.3-.1c-.3-4.8-2.1-9.3-5.4-12.8-.5-.5-1-1.1-1.6-1.5 1.9-2.5 5.7-6.2 11.1-6.9 8.1-1 15.5 5.4 18.4 12zm-41.2-4.4c3.4.1 6.8 1.7 9.3 4.5 2.4 2.6 3.8 5.7 4 9.1l-27.1-.1c0-3.3 1.2-6.4 3.5-8.9 2.5-2.9 6.5-4.7 10.3-4.6zM75.9 46c0-4.9 4-8.9 8.9-8.9s8.9 4 8.9 8.9-4 8.9-8.9 8.9-8.9-4-8.9-8.9zm-15.3 5.8c3 0 5.5 2.5 5.5 5.5 0 3.1-2.4 5.5-5.5 5.5s-5.5-2.4-5.5-5.5 2.4-5.5 5.5-5.5zm-25-14.5c4.9 0 8.9 4 8.9 8.9s-4 8.9-8.9 8.9-8.9-4-8.9-8.9 4-8.9 8.9-8.9z"
      fill={fill}
    />
  </svg>
);
