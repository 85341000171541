import classNames from 'classnames';
import React from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphKeyValueTableProps = {
  rows: {
    key: string;
    value: string;
  }[];
  className?: string;
};

export const ParagraphKeyValueTable: React.FC<ParagraphKeyValueTableProps> = ({
  rows,
  className,
}) => (
  <FadeUp yGap={20} className={classNames('KeyValueTable', className)}>
    <ul className="KeyValueTable--list">
      {rows.map(({ key, value }, index) => (
        <li key={`${key}-${index}`} className="KeyValueTable--item">
          <div
            className="KeyValueTable--item--term"
            dangerouslySetInnerHTML={{ __html: key }}
          />
          <div
            className="KeyValueTable--item--definition"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </li>
      ))}
    </ul>
  </FadeUp>
);

export default ParagraphKeyValueTable;
