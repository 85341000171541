import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import {
  formatLinkForSegments,
  useRaisenowTrackingWithBuildUrl,
} from '../../utils';

export type ContentBoxProps = {
  title?: string;
  description?: string;
  links: {
    label?: string;
    url: string;
    target?: string;
  }[];
  image?: string | ReactNode;
  icon?: string | ReactNode;
  isLight?: boolean;
  isSmall?: boolean;
  className?: string;
};

// Format link with optional tracking.
const formatLink = (link: string) => {
  const linkParts = formatLinkForSegments(link);
  // we don't want to try and format external links.
  if (!linkParts) return link;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useRaisenowTrackingWithBuildUrl(linkParts, {}, {}, true);
};

const ContentBoxWrapper: React.FC<
  PropsWithChildren<{
    links: ContentBoxProps['links'];
    className: string;
  }>
> = ({ links, className, children }) => {
  const { Link } = useFrameworkDependencies();

  return links.length === 1 ? (
    <Link
      to={formatLink(links[0].url)}
      target={links[0].target}
      className={className}
    >
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
};

export const ContentBox: React.FC<ContentBoxProps> = ({
  title = '',
  description = '',
  links,
  image,
  icon,
  isLight = false,
  isSmall = false,
  className,
}) => {
  const { t } = useTranslation();
  const { Link } = useFrameworkDependencies();

  return (
    <ContentBoxWrapper
      links={links}
      className={classNames('ContentBox', className, {
        light: isLight,
        small: isSmall,
      })}
    >
      <div className="ContentBox--body">
        <div className="ContentBox--body--inner">
          <div className="ContentBox--textWrapper">
            <h3
              className="ContentBox--title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="ContentBox--text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          {links && links.length > 0 && (
            <div className="ContentBox--body--footer">
              <div className="ContentBox--links">
                {links.length === 1 ? (
                  <span className="ContentBox--footerLink">
                    {links[0].label || t('Learn more')}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.423"
                      height="15.667"
                      viewBox="0 0 19.423 15.667"
                    >
                      <g
                        id="Group_53"
                        data-name="Group 53"
                        transform="translate(-206.077 -27.22)"
                      >
                        <g
                          id="Group_2"
                          data-name="Group 2"
                          transform="translate(209.077 34.53)"
                        >
                          <path
                            id="Path_5"
                            data-name="Path 5"
                            d="M353.961,296.046h-18.5a.527.527,0,0,1,0-1.046h18.5a.527.527,0,0,1,0,1.046Z"
                            transform="translate(-338 -295)"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Path_6"
                          data-name="Path 6"
                          d="M455.523,230.668a.559.559,0,0,1-.37-.135.423.423,0,0,1,0-.652l8-7.047-8-7.047a.423.423,0,0,1,0-.652.574.574,0,0,1,.739,0l8.367,7.373a.423.423,0,0,1,0,.652l-8.367,7.373A.559.559,0,0,1,455.523,230.668Z"
                          transform="translate(-238.913 -187.781)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                ) : (
                  links.map(({ target, url, label }, index) => (
                    <Link
                      key={`link-${index}`}
                      to={formatLink(url)}
                      target={target}
                      className="ContentBox--footerLink"
                      dangerouslySetInnerHTML={{
                        __html: label || t('Learn more'),
                      }}
                    />
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="ContentBox--imgWrapper">
        <>
          {typeof image === 'string' ? (
            <div
              className="ContentBox--cover"
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : (
            image
          )}
          {typeof icon === 'string' ? (
            <img
              src={icon}
              className="ContentBox--img"
              alt={title}
              loading="lazy"
            />
          ) : (
            icon
          )}
        </>
      </div>
    </ContentBoxWrapper>
  );
};
