import { useLocation } from '@reach/router';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type FormSelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label?: string;
  options: {
    label?: string;
    value?: string;
  }[];
  emptyOption?: {
    label?: string;
    value: string;
  };
};

export const FormSelect: React.FC<FormSelectProps> = ({
  id,
  label,
  options,
  emptyOption = {
    value: '',
  },
  ...props
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  // Default value from url does not trigger a re-rendering
  // so use a hook to update the component if a corresponding
  // query string exists.
  const [, setValue] = useState('');
  const parsedQueryString = queryString.parse(search);
  let defaultValue = props.defaultValue;
  if (props.name && props.name in parsedQueryString) {
    defaultValue = parsedQueryString[props.name] as string;
  }
  useEffect(() => {
    setValue(defaultValue as string);
  }, [defaultValue, search]);

  return (
    <div className="FormRow">
      <div className="BasicInput select">
        {label && (
          <label className="FormLabel" htmlFor={id}>
            {label}
          </label>
        )}
        <div className="FormField">
          <div className="Select">
            <select
              value={defaultValue}
              id={id}
              name={props.name}
              onChange={props.onChange}
            >
              <option value={emptyOption.value}>
                {emptyOption.label || t('--Show all--')}
              </option>
              {options.map((option, index) => (
                <option key={`options-${index}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
