import { format } from 'date-fns';

import { Locale, locales } from '../../i18n';

export const formatLocalizedDate =
  (locale: Locale) =>
  (date: Date, dateFormat: string = 'PPP') =>
    format(date, dateFormat, {
      locale: locales[locale].dateLocale,
    });
