import { ContentKitchenSinkFragment } from '@custom/schema';
import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionProvider } from '../../hooks';
import { Icon } from '../../types';
import { isTruthy } from '../../utils/isTruthy';
import { FadeUp } from '../1-molecules/FadeIn';
import { AccordionItem } from './AccordionItem';

export type ParagraphAccordionProps = {
  items?: Array<
    | {
        type: 'item' | 'link' | 'download';
        title?: string;
        subtitle?: string;
        url?: string;
        domain?: string;
        target?: string;
        image?: string | ReactNode;
        content?: ContentKitchenSinkFragment['paragraphs'] | [ReactElement];
        linkLabel?: string;
        hasLogoImage?: boolean;
      }
    | undefined
  >;
  isCollapsible?: boolean;
  className?: string;
};

export const ParagraphAccordion: React.FC<ParagraphAccordionProps> = ({
  items,
  isCollapsible = true,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <FadeUp yGap={20} className={classNames('Accordion', className)}>
      <AccordionProvider>
        <ul className="Accordion--list" role="tablist">
          {items?.filter(isTruthy).map(({ type, target, ...item }, index) => {
            let icon, iconExpanded;
            switch (type) {
              case 'link':
                icon = Icon.BoltRight;
                iconExpanded = Icon.BoltRightWhite;
                break;
              case 'download':
                icon = Icon.Download;
                iconExpanded = Icon.DownloadWhite;
                break;
            }

            return (
              <AccordionItem
                key={`item-${index}`}
                {...item}
                id={`item-${index}`}
                isCollapsible={isCollapsible}
                icon={icon}
                iconExpanded={iconExpanded}
                target={type === 'download' ? '_blank' : target}
                buttonLabel={t('Toggle')}
              />
            );
          })}
        </ul>
      </AccordionProvider>
    </FadeUp>
  );
};

export default ParagraphAccordion;
