import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePager } from '../../hooks';
import { FadeUp } from '../1-molecules/FadeIn';

export type PagerProps = {
  itemsCount?: number;
  itemsPerPage?: number;
  displayPages?: number;
  className?: string;
};

export const Pager: React.FC<PagerProps> = ({
  itemsCount,
  itemsPerPage = 10,
  displayPages = 6,
  className,
}) => {
  const { t } = useTranslation();
  const {
    setItemsCount,
    setItemsPerPage,
    setDisplayPages,
    pages,
    hasPreviousPage,
    hasNextPage,
    currentPage,
    setCurrentPage,
    previousPage,
    nextPage,
  } = usePager();

  useEffect(() => {
    if (itemsCount) {
      setItemsCount(itemsCount);
    }

    if (itemsPerPage) {
      setItemsPerPage(itemsPerPage);
    }

    if (displayPages) {
      setDisplayPages(displayPages);
    }
  }, [
    itemsCount,
    itemsPerPage,
    displayPages,
    setItemsCount,
    setItemsPerPage,
    setDisplayPages,
  ]);

  return itemsCount && itemsCount > itemsPerPage ? (
    <FadeUp yGap={20} className={classNames('Pagination', className)}>
      <div className="Pagination--inner">
        <a
          className={classNames(
            'Pagination--switcher Pagination--switcher-prev',
            {
              'is-disabled': !hasPreviousPage,
            },
          )}
          href="/"
          rel="prev"
          onClick={(event) => {
            event.preventDefault();
            previousPage();
          }}
        >
          <span className="visuallyhidden">{t('Previous page')}</span>
          <i className="ico ico-arrow-left" />
        </a>
        <ul className="Pagination--list">
          {pages.map((page) => (
            <li key={page}>
              <a
                href="/"
                className={classNames('Pagination--link', {
                  'is-active': page === currentPage,
                })}
                onClick={(event) => {
                  event.preventDefault();
                  setCurrentPage(page);
                }}
              >
                <span className="visuallyhidden">{t('Page')}</span>
                {page}
              </a>
            </li>
          ))}
        </ul>
        <a
          className={classNames(
            'Pagination--switcher Pagination--switcher-next',
            {
              'is-disabled': !hasNextPage,
            },
          )}
          href="/"
          rel="next"
          onClick={(event) => {
            event.preventDefault();
            nextPage();
          }}
        >
          <span className="visuallyhidden">{t('Next page')}</span>
          <i className="ico ico-arrow-right" />
        </a>
      </div>
    </FadeUp>
  ) : null;
};
