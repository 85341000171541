import React, { PropsWithChildren, useContext, useState } from 'react';

export type DoctorsFiltersContext = {
  field?: string;
  doctorFunction?: string;
  glossary?: string;
  setField: (field: string) => void;
  setDoctorFunction: (doctorFunction: string) => void;
  setGlossary: (glossary: string) => void;
};
const DoctorsFiltersContext = React.createContext<
  DoctorsFiltersContext | undefined
>(undefined);

export const DoctorsFiltersProvider: React.FC<
  PropsWithChildren<{
    field?: string;
    doctorFunction?: string;
    glossary?: string;
  }>
> = ({
  field: initialField,
  doctorFunction: initialDoctorFunction,
  glossary: initialGlossary,
  children,
}) => {
  const [field, setField] = useState(initialField);
  const [doctorFunction, setDoctorFunction] = useState(initialDoctorFunction);
  const [glossary, setGlossary] = useState(initialGlossary);

  return (
    <DoctorsFiltersContext.Provider
      value={{
        field,
        doctorFunction,
        glossary,
        setField: (field: string) => setField(field),
        setDoctorFunction: (doctorFunction: string) =>
          setDoctorFunction(doctorFunction),
        setGlossary: (glossary: string) => setGlossary(glossary),
      }}
    >
      {children}
    </DoctorsFiltersContext.Provider>
  );
};

export const useDoctorsFilters = () => {
  const context = useContext(DoctorsFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useDoctorsFilters must be used within a DoctorsFiltersProvider',
    );
  }
  return context;
};
