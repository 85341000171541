import React from 'react';

export type PageScrollToTopProps = {
  label: string;
};

export const PageScrollToTop: React.FC<PageScrollToTopProps> = ({ label }) => (
  <a
    className="PageActions--button PageActions--button-backToTop"
    href="#"
    onClick={(event) => {
      event.preventDefault();
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }}
  >
    {label}
    <span className="PageActions--button--icon">
      <i className="ico ico-bolt-up" />
    </span>
  </a>
);
