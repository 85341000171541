import { useLocation } from '@reach/router';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useCompaniesFilters } from '../../hooks';
import { FormVerticalSelect } from '../0-atoms';
import { FadeUp } from './FadeIn';

export type CompaniesFilterFormProps = {
  categories?: {
    id: string;
    label: string;
  }[];
  className?: string;
};

export const CompaniesFilterForm: React.FC<CompaniesFilterFormProps> = ({
  categories,
  className,
}) => {
  const glossaries = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const { t } = useTranslation();
  const { navigate } = useFrameworkDependencies();
  const location = useLocation();
  const {
    category,
    glossary,
    searchTerm,
    setCategory,
    setGlossary,
    setSearchTerm,
  } = useCompaniesFilters();

  const updateUrl = (query?: {
    category?: string;
    glossary?: string;
    searchTerm?: string;
  }) =>
    navigate(
      `${location.pathname}?${queryString.stringify(
        {
          category,
          glossary,
          searchTerm,
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      )}`,
    );

  const updateSearchTerm = debounce((searchTerm: string) => {
    setSearchTerm(searchTerm);
    updateUrl({
      searchTerm,
    });
  }, 500);

  return (
    <FadeUp yGap={20}>
      <form className={className}>
        <div className="ArticleTitle">
          <h3 className="ArticleTitle--title h3">
            {t('Search company by name, industry or place')}
          </h3>
        </div>
        <div className="FilterInline">
          <strong className="FilterInline--title" />
          <div className="FormRow">
            <div className="VerticalInput">
              <div className="InputGroup">
                <div className="InputGroup--row">
                  <div className="VerticalInput text --l">
                    <label className="FormLabel" htmlFor="u1">
                      {t('Company name, place or industry')}
                    </label>
                    <div className="FormField">
                      <input
                        type="text"
                        id="u1"
                        name="search"
                        defaultValue={searchTerm}
                        onChange={(event) => {
                          updateSearchTerm(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="VerticalInput button">
                    <button
                      className="Button block small"
                      type="submit"
                      onClick={(event) => {
                        event.preventDefault();
                        updateUrl();
                      }}
                    >
                      <span className="Button--text">{t('Search')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ArticleTitle">
          <h3 className="ArticleTitle--title h3">
            {t('All companies from A-Z')}
          </h3>
        </div>
        <div className="FilterInline">
          <strong className="FilterInline--title" />
          <FormVerticalSelect
            label={t('All companies from A-Z')}
            id="u2"
            name="glossary"
            defaultValue={glossary || ''}
            options={glossaries.map((glossary) => ({
              value: glossary,
              label: glossary,
            }))}
            onChange={(event) => {
              setGlossary(event.target.value);
              updateUrl({
                glossary: event.target.value,
              });
            }}
          />
          {categories && (
            <FormVerticalSelect
              label={t('Companies by industry')}
              id="u3"
              name="category"
              defaultValue={category || ''}
              options={sortBy(categories, 'label').map(({ id, label }) => ({
                value: id,
                label,
              }))}
              onChange={(event) => {
                setCategory(event.target.value);
                updateUrl({
                  category: event.target.value,
                });
              }}
            />
          )}
        </div>
      </form>
    </FadeUp>
  );
};
