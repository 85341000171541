import { ParagraphCompetencesAccordionFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { isPublished } from '../../utils';
import { isTruthy } from '../../utils/isTruthy';
import { transformImageFragmentToProps } from './MediaImage';

export const transformParagraphSectionToProps = (
  paragraph: ParagraphCompetencesAccordionFragment,
) => {
  const paragraphImage = paragraph?.image;

  const image = paragraphImage ? (
    <Image
      {...transformImageFragmentToProps(paragraphImage, [
        'section_background_image',
      ])}
      fit
    />
  ) : undefined;

  return {
    title: paragraph.title,
    subtitle: paragraph?.leadHtml,
    image,
    content: paragraph?.content?.filter(isPublished).filter(isTruthy),
  };
};
