import { useQuery } from 'react-query';

import { Locale } from '../../i18n';
import { buildUrl } from '../utils';

export type EntityUrlsQueryData = {
  'delete-form'?: string;
  'edit-form'?: string;
  'drupal:content-translation-overview'?: string;
};

export const useEntityUrlsQuery = ({
  language,
  path,
  nodeId,
}: {
  language: Locale;
  path?: string;
  nodeId?: string;
}) => {
  const isQueryEnabled: boolean =
    typeof window !== 'undefined' &&
    document.cookie.match(/has_backend_credentials=1/i) !== null &&
    (path !== undefined || nodeId !== undefined);

  const backendUrl = process.env.GATSBY_BACKEND_BASE_URL;
  const url: string = buildUrl(
    [backendUrl, language, 'rest/entity_links'],
    nodeId
      ? {
          entity_type: 'node',
          entity_id: nodeId,
        }
      : {
          path,
        },
  );

  const entityUrls = useQuery<EntityUrlsQueryData>(
    ['entity-urls', language, nodeId],
    async () =>
      (
        await fetch(url, {
          credentials: 'include',
          headers: { Accept: 'application/json' },
        })
      ).json(),
    {
      cacheTime: 60 * 60 * 24 * 1000, //1 day
      staleTime: 60 * 60 * 24 * 1000, //1 day
      retry: false,
      enabled: isQueryEnabled,
    },
  );

  if (!isQueryEnabled) {
    return {
      isLoading: false,
      data: undefined,
    };
  } else {
    return entityUrls;
  }
};
