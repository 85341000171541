import classNames from 'classnames';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { Icon } from '../../types';

export type CTAButtonProps = {
  url: string;
  label: string;
  icon?: Icon;
  className?: string;
};

export const CTAButton: React.FC<CTAButtonProps> = ({
  url,
  label,
  icon,
  className,
}) => {
  const { Link } = useFrameworkDependencies();

  return (
    <Link className={classNames('CtaButton', className)} to={url}>
      {icon && (
        <span className="CtaButton--icon CtaButton--icon-before CtaButton--icon-inline">
          <i className={classNames('ico', icon)} />
        </span>
      )}
      <span className="CtaButton--text">{label}</span>
    </Link>
  );
};
