import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-scroll-parallax';

import { useFrameworkDependencies } from '../../dependencies';
import { useSetMediaQuery } from '../../utils';
import { ConditionalWrapper } from '../../utils/conditionalContainer';
import { FadeUp } from '../1-molecules/FadeIn';
import { IconKey, IconSvg } from './IconSvg';

export type ParagraphStorytelling = {
  title: string;
  tag?: string;
  text?: string;
  media?: string | ReactNode;
  mediaAlignment: string;
  linkUrl?: string;
  linkLabel?: string;
  className?: string;
};

type TextBox = {
  title: string;
  tag?: string;
  text?: string;
  linkUrl?: string;
  linkLabel?: string;
  className?: string;
};

const TextBox: React.FC<TextBox> = ({
  title,
  tag,
  text,
  linkUrl,
  linkLabel,
  className,
}) => {
  const { Link } = useFrameworkDependencies();
  const { t } = useTranslation();
  return (
    <div className={classNames('ContentTeaser textTeaser', className)}>
      <ConditionalWrapper
        condition={!!linkUrl}
        wrapper={(children: any) => (
          <Link to={linkUrl || ''} title={title}>
            {children}
          </Link>
        )}
      >
        <div className={classNames('ContentTeaser--inner')}>
          <div className="ContentTeaser--inner-last">
            <div className="ContentTeaser--content">
              {tag && title && <div className={classNames(tag)}>{title}</div>}
              {text && (
                <div
                  className="ContentTeaser--text"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}
            </div>
            {linkUrl && (
              <div className="ContentTeaser--bar">
                <div className="ContentTeaser--buttonContainer">
                  <div className="ContentTeaser--button">
                    {linkLabel || t('Read more')}
                    <IconSvg
                      position={'inline'}
                      wrapClass={'Teaser--link--icon'}
                      icon={'ico-arrow-right' as IconKey}
                      transition
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

export const ParagraphStorytelling: React.FC<ParagraphStorytelling> = ({
  title = '',
  tag = 'h3',
  text = '',
  media,
  mediaAlignment = 'left',
  linkUrl = '',
  linkLabel = '',
  className,
}) => {
  const element = tag ? tag.replace(/^<|>$/g, '') : 'h3';
  const alignLeft = mediaAlignment === 'left';
  const alignRight = mediaAlignment === 'right';
  const isParallaxDisabled = useSetMediaQuery() === 'mobile';
  const textBox = (
    <TextBox
      title={title}
      tag={element}
      linkUrl={linkUrl}
      linkLabel={linkLabel}
      text={text}
    />
  );

  return (
    <FadeUp yGap={20}>
      <section
        className={classNames('Storytelling', className, {
          imgLeft: alignLeft,
          imgRight: alignRight,
        })}
      >
        {alignLeft && (
          <>
            <div className="Storytelling--imgWrapper">
              <Parallax
                speed={-10}
                translateY={[10, -10]}
                disabled={isParallaxDisabled}
              >
                {media && (
                  <>
                    {typeof media === 'string' ? (
                      <img
                        src={media}
                        className="Storytelling--img"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      media
                    )}
                  </>
                )}
              </Parallax>
            </div>

            <div className="Storytelling--boxWrapper">
              <Parallax
                speed={10}
                translateY={[-10, 10]}
                disabled={isParallaxDisabled}
              >
                <div className="Storytelling--box">{textBox}</div>
              </Parallax>
            </div>
          </>
        )}

        {alignRight && (
          <>
            <div className="Storytelling--boxWrapper">
              <Parallax
                speed={-10}
                translateY={[-10, 10]}
                disabled={isParallaxDisabled}
              >
                <div className="Storytelling--box">{textBox}</div>
              </Parallax>
            </div>

            <div className="Storytelling--imgWrapper">
              <Parallax
                speed={10}
                translateY={[10, -10]}
                disabled={isParallaxDisabled}
              >
                {media && (
                  <>
                    {typeof media === 'string' ? (
                      <img
                        src={media}
                        className="Storytelling--img"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      media
                    )}
                  </>
                )}
              </Parallax>
            </div>
          </>
        )}
      </section>
    </FadeUp>
  );
};

export default ParagraphStorytelling;
