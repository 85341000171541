import React from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphEmbeddedMediaProps = {
  url: string;
  className?: string;
};

const convertEmbeddedUrl = (url: string) => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551
  // NOTE: Will also add back get params if any have been found.
  const regex =
    /(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/;
  const matches = regex.exec(url);

  if (!matches) {
    return null;
  }

  // extract get params so we can add them back if required
  const getParams = url.indexOf('?') > -1 ? url.split('?').pop() : false;

  if (matches[3] && matches[3].indexOf('youtu') > -1) {
    const youtubeUrl = `https://www.youtube.com/embed/${matches[6]}`;
    return getParams ? `${youtubeUrl}?${getParams}` : youtubeUrl;
  } else if (matches[3] && matches[3].indexOf('vimeo') > -1) {
    const vimeoUrl = `//player.vimeo.com/video/${matches[6]}`;
    return getParams ? `${vimeoUrl}?${getParams}` : vimeoUrl;
  }

  return null;
};

export const ParagraphEmbeddedMedia: React.FC<ParagraphEmbeddedMediaProps> = ({
  url,
  className,
}) => {
  const iframeUrl = convertEmbeddedUrl(url);
  const wrapperClass = className || 'EmbeddedMedia';

  return (
    <FadeUp yGap={20} className={wrapperClass}>
      <div className="EmbeddedMedia--inner">
        <iframe allowFullScreen frameBorder="0" src={iframeUrl || url} />
      </div>
    </FadeUp>
  );
};

export default ParagraphEmbeddedMedia;
