import queryString from 'query-string';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { CompaniesParaworkFiltersProvider, PagerProvider } from '../../hooks';
import { CompaniesParaworkView } from '../2-organisms';

export type ParagraphViewsCompaniesParaworkProps = {
  className?: string;
};

export const ParagraphViewsCompaniesParawork: React.FC<
  ParagraphViewsCompaniesParaworkProps
> = ({ className }) => {
  const { useLocation } = useFrameworkDependencies();
  const { search } = useLocation();
  const parsedSearch = queryString.parse(search) as {
    category?: string;
    glossary?: string;
    searchTerm?: string;
    jobType?: string;
  };
  const { category, glossary, searchTerm, jobType } = parsedSearch;
  // @todo simplify the filters by using the ones
  //   from ParagraphListingCompanySearch instead of
  //   a standalone query.
  return (
    <CompaniesParaworkFiltersProvider
      category={category}
      glossary={glossary}
      searchTerm={searchTerm}
      jobType={jobType}
    >
      <PagerProvider>
        <CompaniesParaworkView className={className} />
      </PagerProvider>
    </CompaniesParaworkFiltersProvider>
  );
};

export default ParagraphViewsCompaniesParawork;
