import { ContentKitchenSinkFragment } from '@custom/schema';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Event } from '../../types';
import { ParagraphAccordion } from './ParagraphAccordion';

export type EventsTeaserListProps = {
  events: Array<Event>;
  noEventsText: string;
};

export const EventsTeaserList: React.FC<EventsTeaserListProps> = ({
  events,
  noEventsText,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {events.length ? (
        <ParagraphAccordion
          items={events.map(
            ({ title, lead, info, map, image, url, domain }) => {
              const content: ContentKitchenSinkFragment['paragraphs'] = [];

              if (lead) {
                content.push({
                  __typename: 'ParagraphCopyText',
                  textHtml: lead,
                });
              }

              if (info) {
                content.push({
                  __typename: 'ParagraphTable',
                  rows: info,
                });
              }

              if (map && map.latitude && map.longitude) {
                content.push({
                  __typename: 'ParagraphMap',
                  latitude: `${map.latitude}`,
                  longitude: `${map.longitude}`,
                  mapTitle: map.title,
                  smallVariant: map.small,
                });
              }

              return {
                __typename: 'ParagraphAccordionLinkItem',
                type: 'link',
                title,
                image,
                url,
                domain,
                linkLabel: t('Read more'),
                content,
              };
            },
          )}
        />
      ) : (
        <p className="ArticleText">{noEventsText}</p>
      )}
    </>
  );
};
