import React, { PropsWithChildren, useContext, useState } from 'react';

export type AccordionContext = {
  toggleAccordion: (id: string) => void;
  openAccordion: (id: string) => void;
  closeAccordion: () => void;
  activeAccordion?: string;
};

const AccordionContext = React.createContext<AccordionContext>(undefined!);

export const AccordionProvider: React.FC<
  PropsWithChildren<{
    active?: string;
  }>
> = ({ active: initialActive, children }) => {
  const [active, setActive] = useState<string | undefined>(initialActive);

  const openAccordion = (id: string) => {
    setActive(id);
  };

  const closeAccordion = () => {
    setActive(undefined);
  };

  const toggleAccordion = (id: string) => {
    setActive(active === id ? undefined : id);
  };

  return (
    <AccordionContext.Provider
      value={{
        toggleAccordion,
        openAccordion,
        closeAccordion,
        activeAccordion: active,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

export const useAccordion = () => {
  const context = useContext(AccordionContext);
  if (context === undefined) {
    throw new Error('useAccordion must be used within a AccordionProvider');
  }
  return context;
};
