import { Locale as DateLocale } from 'date-fns';
import { de, enGB, fr, it } from 'date-fns/locale';

export type Locale = 'en' | 'de' | 'fr' | 'it';
export type LocaleObject = {
  code: Locale;
  flag: string;
  name: string;
  hrefLang: string;
  dateLocale: DateLocale;
};
export type Locales = Record<Locale, LocaleObject>;

export const locales: Locales = {
  en: {
    code: 'en',
    flag: '🇺🇸',
    name: 'English',
    hrefLang: 'en-EN',
    dateLocale: enGB,
  },
  de: {
    code: 'de',
    flag: '🇩🇪',
    name: 'Deutsch',
    hrefLang: 'de-DE',
    dateLocale: de,
  },
  fr: {
    code: 'fr',
    flag: '🇫🇷',
    name: 'Français',
    hrefLang: 'fr-FR',
    dateLocale: fr,
  },
  it: {
    code: 'it',
    flag: '🇮🇹',
    name: 'Italiano',
    hrefLang: 'it-IT',
    dateLocale: it,
  },
};
