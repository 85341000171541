import { ParagraphItemListFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { getImageSrc, isPublished } from '../../utils';
import { getImageStyleUrl } from './MediaImage';

export const transformParagraphContentBoxListToProps = (
  paragraph: ParagraphItemListFragment,
) => ({
  hasBackground: paragraph?.hasBackground,
  boxes:
    paragraph?.items?.filter(isPublished).map((box) => {
      const paragraphImage = box?.backgroundImage;
      const image = paragraphImage ? (
        <div
          className="ContentBox--cover"
          style={{
            backgroundImage: `url(${getImageSrc(
              paragraphImage,
              getImageStyleUrl(paragraphImage, 'person_teaser') || undefined,
            )})`,
          }}
        />
      ) : undefined;

      const paragraphIcon = box?.icon;
      const icon = paragraphIcon ? (
        <Image
          alt=""
          src={getImageSrc(
            paragraphIcon,
            getImageStyleUrl(paragraphIcon, 'person_teaser') || undefined,
          )}
          filemime={paragraphIcon?.mimeType}
          className="ContentBox--img"
          loading="lazy"
        />
      ) : undefined;

      return {
        title: box?.title,
        description: box?.descriptionHtml,
        links:
          box?.links?.filter(isPublished).map((link) => ({
            url: link?.url || '',
            label: link?.title,
            target: link?.options?.attributes?.target,
          })) || [],
        image,
        icon,
      };
    }) || [],
});
