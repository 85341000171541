import React from 'react';

export type AccordionButtonProps = {
  id: string;
  label: string;
  isCollapsible?: boolean;
  toggleAccordion?: () => void;
};

export const AccordionButton: React.FC<AccordionButtonProps> = ({
  id,
  label,
  isCollapsible = true,
  toggleAccordion,
}) => (
  <button
    type="button"
    className="AccordionItem--button AccordionItem--button-toggle"
    aria-controls={`AccordionItem--content-${id}`}
    aria-expanded="false"
    aria-hidden="true"
    onClick={() => isCollapsible && toggleAccordion && toggleAccordion()}
  >
    <span className="visuallyhidden">{label}</span>
    <i className="AccordionItem--toggleIcon" />
  </button>
);
