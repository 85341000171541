export const isNotSupportedMime = (mime?: string | null, src?: string) => {
  if (!mime || !src) {
    return true;
  }
  return (
    ['image/gif', 'image/svg+xml', 'image/svg'].includes(mime) ||
    src.match(/\.gif|\.svg/i)
  );
};

export const getImageSrc = (
  image?: { mimeType?: string | null; url?: string },
  src?: string,
) => {
  if (!image) {
    return;
  }

  return isNotSupportedMime(image?.mimeType, image?.url) ? image?.url : src;
};
