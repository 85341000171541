import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { i18n, Locale, Locales, locales } from '../../i18n';
import { formatLocalizedDate } from '../utils';

export type LocalizationContext = {
  defaultLocale: Locale;
  locale: Locale;
  locales: Locales;
  formatDate: (date: Date, dateFormat?: string) => string;
};

export const LocalizationContext = React.createContext<LocalizationContext>(
  undefined!,
);

export const LocalizationProvider: React.FC<
  PropsWithChildren<{
    locale?: Locale;
    defaultLocale: Locale;
  }>
> = ({ locale, defaultLocale, children }) => {
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (locale && language !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale, language]);

  return (
    <LocalizationContext.Provider
      value={{
        defaultLocale,
        locale: locale || defaultLocale,
        locales,
        formatDate: formatLocalizedDate(locale || defaultLocale),
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  const context = useContext(LocalizationContext);
  if (context === undefined) {
    throw new Error(
      'useLocalization must be used within a LocalizationProvider',
    );
  }
  return context;
};
