import classNames from 'classnames';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ZSMFormInputs } from './ZSMForm';

export type ParagraphEventModuleProps = {
  id: string;
  title?: string;
  description?: string;
  prices: Array<{
    label: string;
    amount: number;
  }>;
  value?: number;
  className?: string;
};

export const ParagraphEventModule: React.FC<ParagraphEventModuleProps> = ({
  id,
  title,
  description,
  prices,
  value: initialValue,
  className,
}) => {
  const { t } = useTranslation();
  const {
    register,
    setValue: setFormValue,
    clearErrors,
  } = useFormContext<ZSMFormInputs>();
  const [value, setValue] = useState<number | undefined>(initialValue);

  return (
    <div className={classNames('ArticleText', className)}>
      {title && <h3 className="ArticleTitle--title h3">{title}</h3>}
      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
      {prices.map(({ label, amount }, index) => (
        <div key={`prices-${index}`} className="OptionInput">
          <input
            ref={() => register(`selectedModules.${id}`)}
            id={`selected-module-${id}-${index}`}
            checked={value === amount}
            type="radio"
            value={amount}
            onChange={() => {
              setValue(amount);
              setFormValue(`selectedModules.${id}`, {
                id,
                title: title || '',
                label,
                amount,
              });
              if (amount !== undefined) {
                clearErrors('selectedModules');
              }
            }}
            onClick={() => {
              if (value === amount) {
                setValue(undefined);
                setFormValue(`selectedModules.${id}`, undefined);
              }
            }}
          />
          <label htmlFor={`selected-module-${id}-${index}`}>
            <strong>{label}</strong>
            <br />
            {t('CHF')} {amount}
          </label>
        </div>
      ))}
    </div>
  );
};

export default ParagraphEventModule;
