import classNames from 'classnames';
import React, { ReactNode } from 'react';

export type SubpageHeaderHeroProps = {
  title?: string;
  image?: string | ReactNode;
  heroButton?: ReactNode;
  className?: string;
  pageLevel?: string;
};

export const SubpageHeaderHero: React.FC<SubpageHeaderHeroProps> = ({
  title,
  image,
  heroButton,
  className,
  pageLevel,
}) => {
  return (
    <div
      className={classNames('SubpageHeader', className, {
        'SubpageHeader-img': !!image,
        'third-level': pageLevel == 'three',
        'second-level': pageLevel == 'two',
      })}
    >
      <div className="SubpageHeader--body">
        {image && (
          <div className="SubpageHeader--background is-initialized">
            {typeof image === 'string' ? (
              <img
                className="SubpageHeader--background--img"
                alt={title}
                title={title}
                src={image}
              />
            ) : (
              image
            )}
          </div>
        )}
        {title && (
          <div className="SubpageHeader--content">
            <h1
              className="SubpageHeader--title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        )}
        <div className="SubpageHeader--footer">
          {heroButton && (
            <div className="SubpageHeader--cta SubpageHeader--cta-donate">
              {heroButton}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
