import React from 'react';

export type IconFooterTikTokProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterTikTok: React.FC<IconFooterTikTokProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    viewBox="0 0 512 512"
  >
    <path
      fill={fill}
      d="M464.7 112.5c-53.7 0-97.5-43.7-97.5-97.5 0-8.3-6.7-15-15-15h-80.3c-8.3 0-15 6.7-15 15v329.4c0 31.6-25.7 57.3-57.3 57.3s-57.3-25.7-57.3-57.3c0-31.6 25.7-57.3 57.3-57.3 8.3 0 15-6.7 15-15v-80.3c0-8.3-6.7-15-15-15C107.2 176.7 32 251.9 32 344.4S107.2 512 199.6 512s167.6-75.2 167.6-167.6V198.6c29.9 15.9 63.1 24.2 97.5 24.2 8.3 0 15-6.7 15-15v-80.3c0-8.3-6.7-15-15-15z"
    />
  </svg>
);
