import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

type Media = 'mobile' | 'tablet' | 'desktop';

export function useSetMediaQuery(): Media {
  const [mediaQuery, setMediaQuery] = useState<Media>('mobile');

  useEffect(() => {
    const SetMediaQuery = () => {
      if (window.matchMedia('(max-width: 750px)').matches) {
        return 'mobile';
      }
      if (
        window.matchMedia('(min-width: 751px) and (max-width: 1023px)').matches
      ) {
        return 'tablet';
      }
      return 'desktop';
    };
    setMediaQuery(SetMediaQuery());
    const handler = debounce(() => setMediaQuery(SetMediaQuery()));
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [setMediaQuery]);
  return mediaQuery;
}
