import { RelatedBlogPostFragment } from '@custom/schema';
import { fromUnixTime } from 'date-fns';

import { Locale } from '../../../i18n';
import { formatLocalizedDate } from '../../utils';
import { getImageStyleUrl } from './MediaImage';

export const transformRelatedBlogPostFragmentToRelatedBlogPost = (
  relatedBlogPost: RelatedBlogPostFragment,
) => ({
  id: relatedBlogPost?.id || '0',
  title: relatedBlogPost?.title || 'Missing title',
  image: getImageStyleUrl(relatedBlogPost?.teaserImage, 'header_image'),
  date: relatedBlogPost?.created
    ? formatLocalizedDate((relatedBlogPost.langcode as Locale) || 'en')(
        fromUnixTime(relatedBlogPost.created),
      )
    : 'N/A',
  url: relatedBlogPost?.path || '',
  domain: relatedBlogPost?.domainAccess?.[0]?.id,
});
