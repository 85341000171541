import React, { PropsWithChildren, useContext, useState } from 'react';

export type CompaniesParaworkFiltersContext = {
  category?: string;
  glossary?: string;
  searchTerm?: string;
  jobType?: string;
  setCategory: (category: string) => void;
  setGlossary: (glossary: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setJobType: (jobType: string) => void;
};
const CompaniesParaworkFiltersContext = React.createContext<
  CompaniesParaworkFiltersContext | undefined
>(undefined);

export const CompaniesParaworkFiltersProvider: React.FC<
  PropsWithChildren<{
    category?: string;
    glossary?: string;
    searchTerm?: string;
    jobType?: string;
  }>
> = ({
  category: initialCategory,
  glossary: initialGlossary,
  searchTerm: initialSearchTerm,
  jobType: initalJobType,
  children,
}) => {
  const [category, setCategory] = useState(initialCategory);
  const [glossary, setGlossary] = useState(initialGlossary);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [jobType, setJobType] = useState(initalJobType);

  return (
    <CompaniesParaworkFiltersContext.Provider
      value={{
        category,
        glossary,
        searchTerm,
        jobType,
        setCategory: (category: string) => setCategory(category),
        setGlossary: (glossary: string) => setGlossary(glossary),
        setSearchTerm: (searchTerm: string) => setSearchTerm(searchTerm),
        setJobType: (jobType: string) => setJobType(jobType),
      }}
    >
      {children}
    </CompaniesParaworkFiltersContext.Provider>
  );
};

export const useCompaniesParaworkFilters = () => {
  const context = useContext(CompaniesParaworkFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useCompaniesParaworkFilters must be used within a CompaniesParaworkFiltersProvider',
    );
  }
  return context;
};
