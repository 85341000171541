import { ContentThirdLevelFragment } from '@custom/schema';
import React from 'react';

import { HeroButton } from '../../components/2-organisms';
import { ThirdLevelPageProps } from '../../components/3-layouts';

export const transformThirdLevelPageFragmentToThirdLevelPageProps = (
  page?: ContentThirdLevelFragment,
): ThirdLevelPageProps => {
  if (!page) {
    return {};
  }

  const heroButton = page.heroButtonType ? (
    <HeroButton type={page.heroButtonType} url={page.heroButtonLink?.url} />
  ) : undefined;

  return {
    title: page.title,
    heroButton,
    hideFundingBanner: page.hideFundingBanner,
    hideCtasHeader: page.hideCallToActionInHeader,
  };
};
