import React from 'react';

import RaiseNowWidget from './RaiseNowWidget';

export type ParagraphRaisenowNotificationFormProps = {
  id?: string;
  type: string;
  requestType?: string;
  successUrl?: string;
  backUrl?: string;
  className?: string;
};

export const ParagraphRaisenowNotificationForm: React.FC<
  ParagraphRaisenowNotificationFormProps
> = ({ id, type, requestType, successUrl, backUrl, className }) => (
  <RaiseNowWidget
    id={`form-${id}`}
    className={className}
    type={type}
    requestType={requestType}
    thankYouUrl={successUrl}
    backUrl={backUrl}
  />
);

export default ParagraphRaisenowNotificationForm;
