import { Locale } from '../../i18n';
import { useFrameworkDependencies } from '../dependencies';
import { isTruthy } from '../utils/isTruthy';
import { useNewsTypesTermsQuery } from './useNewsTypesTermsQuery';

export const useNewsTypesTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const newsTerms = useNewsTypesTermsQuery();

  return (
    newsTerms.fetchAllTermNewsType
      ?.filter(isTruthy)
      .filter((term) => term.locale === (locale || currentLocale)) || []
  );
};
