import React from 'react';

export type IconCardPaymentProps = {
  className?: string;
  fill?: string | '#fff';
  position?: 'left' | 'center';
};

export const IconCardPayment: React.FC<IconCardPaymentProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <path
        fill={fill}
        d="m85.12,36.24h-10.99c-.73-4.88-2.73-16.34-5.75-19.36-2.63-2.64-11.15-5.22-21.2-8.09-1.98-.56-3.68-1.05-4.89-1.43-4.36-1.73-8.44-4.06-12.14-6.95-.56-.44-1.31-.53-1.96-.26-9.46,4.01-15.62,13.24-15.68,23.52,0,.7.37,1.35.97,1.7,5.74,3.33,8.05,7.14,10.28,10.82,1.07,1.75,2.17,3.57,3.62,5.17-.25.76-.38,1.53-.38,2.27v28.99c0,4.07,3.31,7.38,7.38,7.38h50.74c4.07,0,7.38-3.31,7.38-7.38v-28.99c0-4.07-3.31-7.38-7.38-7.38Zm-28.81,0c-2.27-1.2-5.27-3.03-6.8-5.03.61-.44,1.37-.78,2.04-1.08l.05-.02c.43-.19.84-.37,1.21-.58,1.2.46,2.44.87,3.68,1.22l2.2,5.49h-2.38Zm6.57,0l-3.13-7.84c-.23-.57-.72-1-1.32-1.16-1.76-.46-3.51-1.04-5.19-1.73-.78-.32-1.7-.09-2.24.56-.19.13-.69.35-1.03.5-1.38.61-3.28,1.45-4.51,3.27-.38.56-.44,1.29-.16,1.9,2.25,4.95,10.35,8.68,13.72,10.05,2.86,1.17,8.12,5.71,8.67,8.48.12.6-.05.83-.24,1.01-1.86,1.82-6.29,1.91-11.99-1.99-3.98-2.91-8.64-4.91-13.49-5.78-.11-.02-.23-.03-.35-.03-.03,0-.07,0-.1,0-3.47.19-6.85-.95-9.52-3.17-.03-.03-.07-.05-.1-.08-2.05-1.5-3.33-3.61-4.82-6.06-2.12-3.5-4.76-7.86-10.66-11.56.44-7.91,5.13-14.95,12.26-18.41,3.75,2.8,7.87,5.09,12.22,6.81.04.02.08.03.13.05,1.24.39,2.95.88,4.92,1.44l.16.05c6.07,1.73,17.36,4.95,19.5,7.09,1.61,1.61,3.49,9.71,4.56,16.61h-7.3Zm-31.99,8.1c3.14,2.15,6.81,3.2,10.59,3.04,4.2.79,8.25,2.55,11.73,5.09,3.63,2.49,7.6,3.92,10.88,3.92,2.46,0,4.51-.78,6.08-2.32,1.2-1.18,1.68-2.79,1.33-4.54-.67-3.42-4.49-7.15-7.66-9.39h21.28c1.92,0,3.49,1.57,3.49,3.49v17.71H30.89v-16.99Zm.28,24.38v-3.49h57.32v3.49H31.17Zm57.44,3.89h0c0,1.93-1.57,3.5-3.49,3.5h-50.74c-1.92,0-3.49-1.57-3.49-3.49h0s57.72,0,57.72,0Z"
      />
    ) : (
      <path
        fill={fill}
        d="m72.62,36.24h-10.99c-.73-4.88-2.73-16.34-5.75-19.36-2.63-2.64-11.15-5.22-21.2-8.09-1.98-.56-3.68-1.05-4.89-1.43-4.36-1.73-8.44-4.06-12.14-6.95-.56-.44-1.31-.53-1.96-.26C6.22,4.16.06,13.4,0,23.68c0,.7.37,1.35.97,1.7,5.74,3.33,8.05,7.14,10.28,10.82,1.07,1.75,2.17,3.57,3.62,5.17-.25.76-.38,1.53-.38,2.27v28.99c0,4.07,3.31,7.38,7.38,7.38h50.74c4.07,0,7.38-3.31,7.38-7.38v-28.99c0-4.07-3.31-7.38-7.38-7.38Zm-28.81,0c-2.27-1.2-5.27-3.03-6.8-5.03.61-.44,1.37-.78,2.04-1.08l.05-.02c.43-.19.84-.37,1.21-.58,1.2.46,2.44.87,3.68,1.22l2.2,5.49h-2.38Zm6.57,0l-3.13-7.84c-.23-.57-.72-1-1.32-1.16-1.76-.46-3.51-1.04-5.19-1.73-.78-.32-1.7-.09-2.24.56-.19.13-.69.35-1.03.5-1.38.61-3.28,1.45-4.51,3.27-.38.56-.44,1.29-.16,1.9,2.25,4.95,10.35,8.68,13.72,10.05,2.86,1.17,8.12,5.71,8.67,8.48.12.6-.05.83-.24,1.01-1.86,1.82-6.29,1.91-11.99-1.99-3.98-2.91-8.64-4.91-13.49-5.78-.11-.02-.23-.03-.35-.03-.03,0-.07,0-.1,0-3.47.19-6.85-.95-9.52-3.17-.03-.03-.07-.05-.1-.08-2.05-1.5-3.33-3.61-4.82-6.06-2.12-3.5-4.76-7.86-10.66-11.56.44-7.91,5.13-14.95,12.26-18.41,3.75,2.8,7.87,5.09,12.22,6.81.04.02.08.03.13.05,1.24.39,2.95.88,4.92,1.44l.16.05c6.07,1.73,17.36,4.95,19.5,7.09,1.61,1.61,3.49,9.71,4.56,16.61h-7.3Zm-31.99,8.1c3.14,2.15,6.81,3.2,10.59,3.04,4.2.79,8.25,2.55,11.73,5.09,3.63,2.49,7.6,3.92,10.88,3.92,2.46,0,4.51-.78,6.08-2.32,1.2-1.18,1.68-2.79,1.33-4.54-.67-3.42-4.49-7.15-7.66-9.39h21.28c1.92,0,3.49,1.57,3.49,3.49v17.71H18.39v-16.99Zm.28,24.38v-3.49h57.32v3.49H18.67Zm57.44,3.89h0c0,1.93-1.57,3.5-3.49,3.5H21.88c-1.92,0-3.49-1.57-3.49-3.49h0s57.72,0,57.72,0Z"
      />
    )}
  </svg>
);
