import queryString from 'query-string';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { MedicalOffersFiltersProvider } from '../../hooks';
import { MedicalOffersView } from '../2-organisms';

export type ParagraphViewsMedicalOffersProps = {
  className?: string;
};

export const ParagraphViewsMedicalOffers: React.FC<
  ParagraphViewsMedicalOffersProps
> = ({ className }) => {
  const { useLocation } = useFrameworkDependencies();
  const { search } = useLocation();
  const parsedSearch = queryString.parse(search) as {
    domain?: string;
    glossary?: string;
  };
  const { domain, glossary } = parsedSearch;
  // @todo simplify the filters by using the ones
  //   from ParagraphListingMedicalOfferSearch instead of
  //   a standalone query.
  return (
    <MedicalOffersFiltersProvider domain={domain} glossary={glossary}>
      <MedicalOffersView className={className} />
    </MedicalOffersFiltersProvider>
  );
};

export default ParagraphViewsMedicalOffers;
