import classNames from 'classnames';
import React from 'react';

import {
  IconAddUser,
  IconArrowRight,
  IconBecomeMember,
  IconBoltRight,
  IconCalendar,
  IconCardPayment,
  IconCheckmark,
  IconCompanyMember,
  IconCouple,
  IconDeskWheelChair,
  IconDoctor,
  IconDownload,
  IconEditUser,
  IconEmailSmall,
  IconFacebook,
  IconFaxSmall,
  IconFooterBlog,
  IconFooterFacebook,
  IconFooterInstagram,
  IconFooterLinkedIn,
  IconFooterPinterest,
  IconFooterSnapChat,
  IconFooterTikTok,
  IconFooterX,
  IconFooterXing,
  IconFooterYoutube,
  IconGift,
  IconHeart,
  IconHeartSmall,
  IconInstagram,
  IconKindMelden,
  IconLock,
  IconNews,
  IconScrollArrow,
  IconSignPost,
  IconTelephoneSmall,
  IconWheelChair,
  IconYoutube,
} from './icons';

const IconSet = (
  icon: string,
  fill: string,
  iconPosition: 'left' | 'center',
) => {
  switch (icon) {
    case 'ico-add-user':
      return <IconAddUser fill={fill} position={iconPosition} />;
    case 'ico-arrow-right':
      // color is hardcoded in IconArrowRight.tsx
      // check side effects before changing that.
      return <IconArrowRight fill={fill} />;
    case 'ico-become-member':
      return <IconBecomeMember fill={fill} position={iconPosition} />;
    case 'ico-bolt-right':
      // no color set in IconBoltRight.tsx
      // check side effects before changing that.
      return <IconBoltRight fill={fill} />;
    case 'ico-calendar':
      return <IconCalendar fill={fill} position={iconPosition} />;
    case 'ico-card-payment':
      return <IconCardPayment fill={fill} position={iconPosition} />;
    case 'ico-couple':
      return <IconCouple fill={fill} position={iconPosition} />;
    case 'ico-checkmark':
      return <IconCheckmark fill={fill} position={iconPosition} />;
    case 'ico-company-member':
      return <IconCompanyMember fill={fill} position={iconPosition} />;
    case 'ico-doctor':
      return <IconDoctor fill={fill} position={iconPosition} />;
    case 'ico-desk-wheelchair':
      return <IconDeskWheelChair fill={fill} position={iconPosition} />;
    case 'ico-download':
      // color is hardcoded in IconArrowRight.tsx
      // check side effects before changing that.
      return <IconDownload fill={fill} />;
    case 'ico-edit-user':
      return <IconEditUser fill={fill} position={iconPosition} />;
    case 'ico-email-small':
      return <IconEmailSmall />;
    case 'ico-facebook':
      return <IconFacebook fill={fill} />;
    case 'ico-fax-small':
      return <IconFaxSmall />;
    case 'ico-gift':
      return <IconGift fill={fill} position={iconPosition} />;
    case 'ico-heart':
    case 'ico-donate':
      return <IconHeart fill={fill} position={iconPosition} />;
    case 'ico-heart-small':
      return <IconHeartSmall fill={fill} />;
    case 'ico-instagram':
      return <IconInstagram fill={fill} />;
    case 'ico-kind-melden':
      return <IconKindMelden fill={fill} />;
    case 'ico-lock':
      return <IconLock />;
    case 'ico-news':
      return <IconNews fill={fill} position={iconPosition} />;
    case 'ico-signpost':
      return <IconSignPost fill={fill} position={iconPosition} />;
    case 'ico-telephone-small':
      // color is hardcoded in IconArrowRight.tsx
      // check side effects before changing that.
      return <IconTelephoneSmall />;
    case 'ico-wheel-chair':
      return <IconWheelChair fill={fill} position={iconPosition} />;
    case 'ico-youtube':
      return <IconYoutube fill={fill} />;
    case 'ico-scroll-arrow':
      return <IconScrollArrow />;
    // For BC reasons we need to support the old icon names
    // that is used for FooterSocialMedia.
    case 'icon ico-footer-blog':
    case 'ico-footer-blog':
      return <IconFooterBlog fill={fill} />;
    case 'icon ico-footer-facebook':
    case 'ico-footer-facebook':
      return <IconFooterFacebook fill={fill} />;
    case 'icon ico-footer-instagram':
    case 'ico-footer-instagram':
      return <IconFooterInstagram fill={fill} />;
    case 'icon ico-footer-linkedin':
    case 'ico-footer-linkedin':
      return <IconFooterLinkedIn fill={fill} />;
    case 'icon ico-footer-pinterest':
    case 'ico-footer-pinterest':
      return <IconFooterPinterest fill={fill} />;
    case 'icon ico-footer-snapchat':
    case 'ico-footer-snapchat':
      return <IconFooterSnapChat fill={fill} />;
    case 'icon ico-footer-tiktok':
    case 'ico-footer-tiktok':
      return <IconFooterTikTok fill={fill} />;
    case 'icon ico-footer-x':
    case 'icon ico-footer-twitter':
    case 'ico-footer-x':
    case 'ico-footer-twitter':
      return <IconFooterX fill={fill} />;
    case 'icon ico-footer-xing':
    case 'ico-footer-xing':
      return <IconFooterXing fill={fill} />;
    case 'icon ico-footer-youtube':
    case 'ico-footer-youtube':
      return <IconFooterYoutube fill={fill} />;
  }
};

type IconSet = typeof IconSet;
export type IconKey = keyof IconSet;

export type IconSvgProps = {
  position?: 'before' | 'after' | 'inline' | 'inline fill-all';
  icon?: IconKey;
  wrapClass?: string;
  transition?: boolean;
  fill?: string;
  iconPosition?: 'left' | 'center';
};

export const IconSvg: React.FC<IconSvgProps> = ({
  position,
  icon = 'ico-bolt-right',
  wrapClass,
  transition,
  fill = '#fff',
  iconPosition = 'center',
}) => (
  <span className={classNames(wrapClass)}>
    {icon && (
      <i
        className={classNames(
          'IconSvg',
          `${icon}-svg`,
          transition ? 'transition' : '',
          position,
          fill,
        )}
      >
        {IconSet(icon, fill, iconPosition)}
      </i>
    )}
  </span>
);
