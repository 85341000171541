export const APP_CONFIG = {
  // @todo candidate for removal after instant preview.
  isPreview: Boolean(process.env.GATSBY_PREVIEW),
  previewRefetchInterval: 1000,
  netlifyUrl: process.env.NETLIFY_BASE_URL || 'https://www.paraplegie.ch',
  raisenowWidget: {
    test:
      process.env.GATSBY_RAISENOW_TEST_MODE ||
      process.env.STORYBOOK_RAISENOW_TEST_MODE,
    accountUuid:
      process.env.GATSBY_RAISENOW_ACCOUNT_UUID ||
      process.env.STORYBOOK_RAISENOW_ACCOUNT_UUID,
    engagementUrl:
      process.env.GATSBY_RAISENOW_ENGAGEMENT_URL ||
      process.env.STORYBOOK_RAISENOW_ENGAGEMENT_URL,
  },
  google: {
    gtm_enabled: process.env.GATSBY_ACTIVE_ENV === 'production',
    gtm_code: 'GTM-KQGSR6H',
    maps_key:
      process.env.GATSBY_GOOGLE_MAPS_KEY ||
      process.env.STORYBOOK_GOOGLE_MAPS_KEY,
  },
  hotjar: {
    id: process.env.HOTJAR_ID || 'HOTJAR_ID',
    sv: process.env.HOTJAR_SV || 'HOTJAR_SV',
  },
  activeCampaign: {
    enabled: Boolean(
      process.env.GATSBY_ACTIVE_CAMPAIGN_ENABLED ||
        process.env.STORYBOOK_ACTIVE_CAMPAIGN_ENABLED,
    ),
  },
  drupalUrl:
    process.env.DRUPAL_BASE_URL ||
    process.env.STORYBOOK_DRUPAL_URL ||
    'http://127.0.0.1:8888',
  drupalBackendUrl:
    process.env.DRUPAL_BASE_URL ||
    process.env.STORYBOOK_DRUPAL_BACKEND_URL ||
    'http://127.0.0.1:8888',
  donatePagePath: {
    en: '/en/support-us/donations',
    de: '/de/uns-unterstuetzen/spenden',
    fr: '/fr/nous-soutenir/faire-un-don',
    it: '/it/come-sostenerci/donazioni',
  },
  memberPagePath: {
    en: '/en/support-us/become-member',
    de: '/de/uns-unterstuetzen/mitglied-werden',
    fr: '/fr/nous-soutenir/devenir-membre',
    it: '/it/sosteneteci/diventare-membro',
  },
  groupPagePath: {
    en: '/en/about-us/organisation/overview-swiss-paraplegic-group',
    de: '/de/ueber-uns/organisation/organisationen-schweizer-paraplegiker-gruppe',
    fr: '/fr/portrait/organisation/apercu-groupe-suisse-pour-paraplegiques',
    it: '/it/chi-siamo/organizzazione/panoramica-gruppo-svizzero-paraplegici',
  },
  memberServicePagePath: {
    en: '/en/member-service',
    de: '/de/goenner-services',
    fr: '/fr/service-bienfaiteurs',
    it: '/it/servizio-sostenitori',
  },
  orthotecPagePath: {
    en: '/orthotec/de',
    de: '/orthotec/de',
    fr: '/orthotec/fr',
    it: '/orthotec/it',
  },
};

export type AppConfig = typeof APP_CONFIG;
