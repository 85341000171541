import range from 'lodash/range';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

export type PagerContext = {
  currentPage: number;
  firstPage: number;
  lastPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  hasHiddenPreviousPages: boolean;
  hasHiddenNextPages: boolean;
  pages: Array<number>;
  setItemsCount: (itemsCount: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  setDisplayPages: (displayPages: number) => void;
  setCurrentPage: (currentPage: number) => void;
  previousPage: () => void;
  nextPage: () => void;
};
const PagerContext = React.createContext<PagerContext | undefined>(undefined);

export const PagerProvider: React.FC<
  PropsWithChildren<{
    itemsCount?: number;
    itemsPerPage?: number;
    displayPages?: number;
    currentPage?: number;
  }>
> = ({
  itemsCount: initialItemsCount,
  itemsPerPage: initialItemsPerPage = 10,
  displayPages: initialDisplayPages = 6,
  currentPage: initialCurrentPage = 1,
  children,
}) => {
  const firstPage = 1;
  const guessedLastPage = Math.max(firstPage, initialCurrentPage);
  const [itemsCount, setItemsCount] = useState(initialItemsCount);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [displayPages, setDisplayPages] = useState(initialDisplayPages);
  const [currentPage, setCurrent] = useState(initialCurrentPage);
  const [lastPage, setLastPage] = useState(guessedLastPage);
  const [pages, setPages] = useState<Array<number>>([]);

  useEffect(() => {
    if (!itemsCount) {
      return;
    }
    const totalPages = Math.ceil(itemsCount / itemsPerPage);
    const firstPageToDisplay = Math.max(
      1,
      Math.min(
        totalPages - displayPages + 1,
        currentPage - Math.floor((displayPages - 1) / 2),
      ),
    );
    const lastPageToDisplay = Math.min(
      totalPages,
      Math.max(displayPages, currentPage + Math.floor(displayPages / 2)),
    );
    setLastPage(totalPages);
    setPages(range(firstPageToDisplay, lastPageToDisplay + 1));
  }, [itemsCount, itemsPerPage, displayPages, currentPage]);

  return (
    <PagerContext.Provider
      value={{
        currentPage,
        firstPage,
        lastPage,
        hasPreviousPage: currentPage > 1,
        hasNextPage: currentPage < lastPage,
        hasHiddenPreviousPages: pages[0] > firstPage,
        hasHiddenNextPages: pages[pages.length - 1] < lastPage,
        pages,
        setItemsCount: (itemsCount: number) => setItemsCount(itemsCount),
        setItemsPerPage: (itemsPerPage: number) =>
          setItemsPerPage(itemsPerPage),
        setDisplayPages: (displayPages: number) =>
          setDisplayPages(displayPages),
        setCurrentPage: (currentPage: number) => setCurrent(currentPage),
        previousPage: () =>
          currentPage - 1 >= firstPage && setCurrent(currentPage - 1),
        nextPage: () =>
          currentPage + 1 <= lastPage && setCurrent(currentPage + 1),
      }}
    >
      {children}
    </PagerContext.Provider>
  );
};

export const usePager = () => {
  const context = useContext(PagerContext);
  if (context === undefined) {
    throw new Error('usePager must be used within a PagerProvider');
  }
  return context;
};
