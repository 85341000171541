import { ParagraphTeaserNewsroomFragment } from '@custom/schema';

export const transformParagraphViewsNewsroomTeaserToProps = (
  paragraph: ParagraphTeaserNewsroomFragment,
) => {
  const maxItems = parseInt(paragraph?.maxItems || '4');

  return {
    title: paragraph.pTitle,
    domain: paragraph.domain,
    contentTypes: paragraph.contentTypes as string[],
    linkText: paragraph.linkText,
    itemsPerPage: maxItems < 4 ? 4 : maxItems,
  };
};
