import React from 'react';

export type IconBecomeMemberProps = {
  className?: string;
  fill?: string | '#fff';
  position?: 'left' | 'center';
};

export const IconBecomeMember: React.FC<IconBecomeMemberProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    data-postion={position}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <>
        <path
          fill={fill}
          d="m75.89,78.17v-10.29h10.32c.47,0,.94-.21,1.28-.57.34-.36.53-.83.52-1.32-.02-1-.81-1.8-1.81-1.82h-10.3v-10.32c-.02-.98-.85-1.8-1.85-1.8-1.04.02-1.84.82-1.86,1.83v10.29h-10.32c-1,.02-1.82.87-1.8,1.89.02,1,.82,1.81,1.82,1.82h10.29v10.32c.02.98.85,1.8,1.85,1.8,1.04-.02,1.84-.82,1.86-1.83Z"
        />
        <path
          fill={fill}
          d="m64.84,19.36c0-10.67-8.68-19.36-19.36-19.36s-19.36,8.68-19.36,19.36,8.68,19.36,19.36,19.36c10.66-.01,19.35-8.7,19.36-19.36Zm-19.36,15.65c-8.63,0-15.65-7.02-15.65-15.65s7.02-15.65,15.65-15.65c8.62.01,15.63,7.03,15.64,15.64v.11c-.06,8.57-7.08,15.53-15.64,15.53Z"
        />
        <path
          fill={fill}
          d="m57.97,51.01c.16-.47.12-.97-.09-1.42-.22-.44-.6-.78-1.07-.93-2.82-.98-5.74-1.48-8.71-1.48h-4.6c-14.6.02-26.49,11.91-26.51,26.51,0,1.02.83,1.85,1.86,1.85,1.02,0,1.85-.83,1.85-1.86.02-12.56,10.24-22.78,22.8-22.8h4.57c2.58,0,5.1.43,7.51,1.27.52.17,1.02.14,1.46-.08.44-.22.78-.6.94-1.07Z"
        />
      </>
    ) : (
      <>
        <path
          fill={fill}
          d="m58.91,78.17v-10.29h10.32c.47,0,.94-.21,1.28-.57.34-.36.53-.83.52-1.32-.02-1-.81-1.8-1.81-1.82h-10.3v-10.32c-.02-.98-.85-1.8-1.85-1.8-1.04.02-1.84.82-1.86,1.83v10.29h-10.32c-1,.02-1.82.87-1.8,1.89.02,1,.82,1.81,1.82,1.82h10.29v10.32c.02.98.85,1.8,1.85,1.8,1.04-.02,1.84-.82,1.86-1.83Z"
        />
        <path
          fill={fill}
          d="m47.85,19.36C47.85,8.68,39.16,0,28.49,0S9.13,8.68,9.13,19.36s8.68,19.36,19.36,19.36c10.66-.01,19.35-8.7,19.36-19.36Zm-19.36,15.65c-8.63,0-15.65-7.02-15.65-15.65S19.86,3.71,28.49,3.71c8.62.01,15.63,7.03,15.64,15.64v.11c-.06,8.57-7.08,15.53-15.64,15.53Z"
        />
        <path
          fill={fill}
          d="m40.98,51.01c.16-.47.12-.97-.09-1.42-.22-.44-.6-.78-1.07-.93-2.82-.98-5.74-1.48-8.71-1.48h-4.6C11.91,47.2.02,59.09,0,73.69,0,74.71.83,75.55,1.86,75.55c1.02,0,1.85-.83,1.85-1.86.02-12.56,10.24-22.78,22.8-22.8h4.57c2.58,0,5.1.43,7.51,1.27.52.17,1.02.14,1.46-.08.44-.22.78-.6.94-1.07Z"
        />
      </>
    )}
  </svg>
);
