import loadable from '@loadable/component';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import { FadeUp } from '../1-molecules/FadeIn';

const IframeResizer = loadable(() => import('iframe-resizer-react'));

export type ParagraphIframeProps = {
  url?: string;
  height?: number;
  className?: string;
};

export const ParagraphIframe: React.FC<ParagraphIframeProps> = ({
  url,
  height,
  className,
}) => {
  // Escada is not responsive, so we fallback to a non-responsive tablet version
  // on those pages.
  const escadaUrlRegEx = new RegExp('(http://|https://)(escada.)(.+)$', 'i');
  const urlMatch = url?.match(escadaUrlRegEx);
  const [iFrameUrl, setIFrameUrl] = useState(url);
  const ref = useRef<HTMLDivElement>(null);
  const isFirstMessage = useRef(true);

  useEffect(() => {
    setIFrameUrl(url);
  }, [url]);

  return (
    <FadeUp yGap={20}>
      <div ref={ref}>
        {urlMatch ? (
          <Helmet>
            <meta name="viewport" content="width=768" />
          </Helmet>
        ) : null}

        {height && (
          <iframe
            className={className}
            src={url}
            style={{ width: '1px', minWidth: '100%', height: `${height}px` }}
            title="Embedded iframe"
          />
        )}

        {!height && (
          <IframeResizer
            src={iFrameUrl}
            checkOrigin={false}
            onMessage={(data) => {
              if (data.message === 'iframe_loaded') {
                if (isFirstMessage.current) {
                  isFirstMessage.current = false;
                } else {
                  ref.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }
            }}
            style={{ width: '1px', minWidth: '100%' }}
            title="Embedded iframe"
          />
        )}
      </div>
    </FadeUp>
  );
};

export default ParagraphIframe;
