import { ParagraphBlockTeaserListFragment } from '@custom/schema';
import classNames from 'classnames';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { isPublished } from '../../utils';
import { transformImageFragmentToProps } from './MediaImage';

export const transformParagraphBlockTeaserListToProps = (
  paragraph: ParagraphBlockTeaserListFragment,
) => ({
  id: paragraph.id,
  threeInARow: paragraph.threeInARow,
  hasBackground: paragraph?.hasBackground,
  teasers:
    paragraph?.teasers?.filter(isPublished).map((teaser) => {
      const teaserImage = teaser?.image;

      const image = teaserImage ? (
        <Image
          className={classNames({
            'BlockTeaser--img': !paragraph.threeInARow,
          })}
          {...transformImageFragmentToProps(teaserImage, [
            'small',
            'medium',
            'large',
          ])}
          size="medium"
        />
      ) : undefined;

      return {
        url: teaser?.link?.url || '#',
        target: teaser?.link?.options?.attributes?.target,
        title: teaser?.teaserTitle,
        subtitle: teaser?.subTitle,
        description: teaser?.descriptionHtml,
        additionalPriceInfo: teaser?.priceAdditionalInfo,
        linkLabel: teaser?.link?.title,
        image,
      };
    }) || [],
});
