import { ParagraphInfoBoxListFragment } from '@custom/schema';

import { isPublished } from '../../utils';

export const transformParagraphInfoBoxListToProps = (
  paragraph: ParagraphInfoBoxListFragment,
) => ({
  boxes:
    paragraph?.items?.filter(isPublished).map((box) => {
      return {
        title: box?.boxTitle,
        text: box?.textHtml,
        // @ts-ignore
        iconName: box?.iconClass,
      };
    }) || [],
});
