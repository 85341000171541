import { useLocation } from '@reach/router';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type FormTabsProps = {
  id: string;
  defaultValue: string;
  name: string;
  options: {
    label?: string;
    value?: string;
  }[];
  allOption?: {
    label?: string;
    value: string;
  };
  showAllOption?: boolean;
  onClick: (type: string) => void;
};

export const FormTabs: React.FC<FormTabsProps> = ({
  name,
  options,
  allOption = {
    value: '',
  },
  showAllOption = true,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [value, setValue] = useState('');
  let defaultValue = props.defaultValue;
  const parsedQueryString = queryString.parse(search);
  if (name && name in parsedQueryString) {
    defaultValue = parsedQueryString[name] as string;
  }
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, search]);

  return (
    <ul id="tabs" role="tablist" className="FormTabs">
      {showAllOption && (
        <li
          className="FormTabs--Tab"
          key={0}
          role="tab"
          aria-selected={value === allOption.value}
          onClick={() => onClick(allOption.value)}
        >
          {allOption.label || t('All')}
        </li>
      )}
      {options.map((option, index) => (
        <li
          className="FormTabs--Tab"
          key={index + 1}
          role="tab"
          aria-selected={value === option.value}
          onClick={() => option.value && onClick(option.value)}
        >
          {option.label}
        </li>
      ))}
    </ul>
  );
};
