import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { useFrameworkDependencies } from '../../dependencies';

export type ImageTeaserProps = {
  url?: string;
  title?: string;
  target?: string;
  text?: string;
  image?: string | ReactNode;
  isLarge?: boolean;
  className?: string;
};

export const ImageTeaser: React.FC<ImageTeaserProps> = ({
  title,
  url,
  target,
  text,
  image,
  isLarge = false,
  className,
}) => {
  const { Link } = useFrameworkDependencies();
  return (
    <Link
      to={url || ''}
      title={title}
      target={target}
      className={classNames('ContentTeaser withImage', className, {
        large: isLarge,
      })}
    >
      {image && (
        <div className="ContentTeaser--background cover">
          {typeof image === 'string' ? (
            <img
              src={image}
              className="ContentTeaser--backgroundImg"
              alt={title}
              loading="lazy"
            />
          ) : (
            image
          )}
        </div>
      )}
      <div className="ContentTeaser--inner">
        <div className="ContentTeaser--bar">
          {!!text && (
            <div className="ContentTeaser--textBar">
              {text.replace(/<\/?[^>]+(>|$)/g, '')}
            </div>
          )}
          <div className="ContentTeaser--buttonContainer">
            <div className="ContentTeaser--button">{title}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};
