import { ParagraphLogoBarFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { getImageSrc, isPublished } from '../../utils';
import { getImageStyleUrl, transformImageFragmentToProps } from './MediaImage';

export const transformParagraphLogoBarToProps = (
  paragraph: ParagraphLogoBarFragment,
) => ({
  logos: paragraph?.logos?.filter(isPublished).map((logo) => {
    const paragraphImage = logo?.logo;

    const image = paragraphImage ? (
      <Image
        {...transformImageFragmentToProps(paragraphImage, [
          'logo_small',
          'logo_big',
        ])}
        filemime={paragraphImage?.mimeType}
        src={getImageSrc(
          paragraphImage,
          `${getImageStyleUrl(
            paragraphImage,
            'logo_small',
          )} 1x, ${getImageStyleUrl(paragraphImage, 'logo_big')} 2x`,
        )}
        className="LogBar--logo"
      />
    ) : undefined;

    return {
      title: logo?.title,
      image,
      url: logo?.link?.url,
      target: logo?.link?.options?.attributes?.target,
    };
  }),
});
