import classNames from 'classnames';
import React from 'react';

export type ParagraphRowProps = {
  rowTitle: string;
  rowContent: string;
  className?: string;
};

export const ParagraphRow: React.FC<ParagraphRowProps> = ({
  rowTitle,
  rowContent,
  className,
}) => {
  return (
    <div className={classNames(className, 'Row')}>
      <div className={classNames('Row-Item', 'Row--Title')}>
        <strong>{rowTitle}</strong>
      </div>
      <div className={classNames('Row-Item', 'Row--Content')}>{rowContent}</div>
    </div>
  );
};

export default ParagraphRow;
