import React from 'react';

export type IconEditUserProps = {
  className?: string;
  fill?: string | '#fff';
  position?: 'left' | 'center';
};

export const IconEditUser: React.FC<IconEditUserProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <>
        <polygon
          fill={fill}
          points="34.61 43.8 34.61 43.8 34.61 43.8 34.61 43.8"
        />
        <path
          fill={fill}
          d="m90.23,4.53l-2.31-2.3c-1.51-1.44-3.5-2.23-5.61-2.23s-4.1.8-5.63,2.25l-14.04,14.04H20.39c-4.36.01-7.92,3.57-7.93,7.94v47.84c.01,4.36,3.57,7.92,7.94,7.93h47.84c4.36-.01,7.92-3.57,7.93-7.94V29.8l14.06-14.06c3.09-3.09,3.09-8.12,0-11.21Zm-17.97,67.53c0,2.22-1.81,4.03-4.03,4.03H20.39c-2.22,0-4.03-1.81-4.03-4.03V24.22c0-2.22,1.81-4.03,4.03-4.03h38.35l-23.92,23.94c-.2.19-.31.34-.39.51l-9.07,18.37c-.23.47-.26.99-.09,1.49s.52.89.99,1.12c.48.24,1.06.26,1.56.07l19.8-7.57c.26-.09.49-.24.67-.44l.16-.15,23.81-23.82v38.35Zm-35.51-23.32l6.76,6.76-12.42,4.73,5.65-11.5Zm10.25,4.72l-8.01-8.01L74.9,9.54l8.01,8.01-35.92,35.92ZM87.48,12.99l-1.79,1.79-8.01-8.01,1.79-1.79c1.59-1.47,4.14-1.45,5.68-.03l2.33,2.33c1.56,1.57,1.56,4.13,0,5.71Z"
        />
      </>
    ) : (
      <>
        <polygon
          fill={fill}
          points="22.16 43.8 22.16 43.8 22.16 43.8 22.16 43.8"
        />
        <path
          fill={fill}
          d="m77.77,4.53l-2.31-2.3c-1.51-1.44-3.5-2.23-5.61-2.23s-4.1.8-5.63,2.25l-14.04,14.04H7.93C3.57,16.3.01,19.86,0,24.23v47.84c.01,4.36,3.57,7.92,7.94,7.93h47.84c4.36-.01,7.92-3.57,7.93-7.94V29.8l14.06-14.06c3.09-3.09,3.09-8.12,0-11.21Zm-17.97,67.53c0,2.22-1.81,4.03-4.03,4.03H7.93c-2.22,0-4.03-1.81-4.03-4.03V24.22c0-2.22,1.81-4.03,4.03-4.03h38.35l-23.92,23.94c-.2.19-.31.34-.39.51l-9.07,18.37c-.23.47-.26.99-.09,1.49s.52.89.99,1.12c.48.24,1.06.26,1.56.07l19.8-7.57c.26-.09.49-.24.67-.44l.16-.15,23.81-23.82v38.35Zm-35.51-23.32l6.76,6.76-12.42,4.73,5.65-11.5Zm10.25,4.72l-8.01-8.01L62.45,9.54l8.01,8.01-35.92,35.92ZM75.02,12.99l-1.79,1.79-8.01-8.01,1.79-1.79c1.59-1.47,4.14-1.45,5.68-.03l2.33,2.33c1.56,1.57,1.56,4.13,0,5.71Z"
        />
      </>
    )}
  </svg>
);
