import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { FadeUp } from '../1-molecules/FadeIn';
import { IconKey, IconSvg } from './IconSvg';

export type InfoBoxProps = {
  title?: string;
  text?: string;
  iconName?: string;
  className?: string;
};

const InfoBoxWrapper: React.FC<
  PropsWithChildren<{
    className: string;
  }>
> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export const InfoBox: React.FC<InfoBoxProps> = ({
  title = '',
  text = '',
  iconName = '',
  className,
}) => {
  return (
    <FadeUp yGap={20} className={'InfoBox--fadeIn'}>
      <InfoBoxWrapper className={classNames('InfoBox', className, {})}>
        <div className="InfoBox--body">
          <div className="InfoBox--body--inner">
            {iconName && (
              <IconSvg
                fill={'#0070AF'}
                wrapClass={'InfoBox--pack--icon'}
                icon={`ico-${iconName}` as IconKey}
                iconPosition={'left'}
              />
            )}
            <div className="InfoBox--textWrapper">
              <h3
                className="InfoBox--title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <div
                className="InfoBox--text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          </div>
        </div>
      </InfoBoxWrapper>
    </FadeUp>
  );
};
