import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphOrthotecKeyValueTableProps = {
  brand?: string;
  modification?: string;
  price?: string;
  firstCirculation?: string;
  year?: string;
  km?: string;
  color?: string;
  inside?: string;
  capacity?: string;
  ps?: string;
  mfk?: string;
  priceNew?: string;
  additionalEquipment?: string;
  options?: string;
  contact?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  email?: string;
  published?: string;
  className?: string;
};

export const ParagraphOrthotecKeyValueTable: React.FC<
  ParagraphOrthotecKeyValueTableProps
> = ({
  brand,
  modification,
  price,
  firstCirculation,
  year,
  km,
  color,
  inside,
  capacity,
  ps,
  mfk,
  priceNew,
  additionalEquipment,
  options,
  contact,
  phone,
  mobile,
  fax,
  email,
  published,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <FadeUp yGap={20} className={classNames('KeyValueTable', className)}>
      <ul className="KeyValueTable--list">
        {brand && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Brand')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: brand }}
            />
          </li>
        )}
        {modification && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">
              {t('Modification')}
            </strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: modification }}
            />
          </li>
        )}
        {price && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Price')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{
                __html: t('{{price}} CHF', { price }),
              }}
            />
          </li>
        )}
        {firstCirculation && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">
              {t('1st Circulation')}
            </strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: firstCirculation }}
            />
          </li>
        )}
        {year && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Year')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: year }}
            />
          </li>
        )}
        {km && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('KM')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: t('{{km}} km', { km }) }}
            />
          </li>
        )}
        {color && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Color')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: color }}
            />
          </li>
        )}
        {inside && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Inside')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: inside }}
            />
          </li>
        )}
        {capacity && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">
              {t('Capacity')}
            </strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{
                __html: t('{{capacity}} cm<sup>3</sup>', { capacity }),
              }}
            />
          </li>
        )}
        {ps && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('PS')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: ps }}
            />
          </li>
        )}
        {mfk && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('MFK')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: mfk }}
            />
          </li>
        )}
        {priceNew && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">
              {t('Price new')}
            </strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{
                __html: t('{{price}} CHF', { price: priceNew }),
              }}
            />
          </li>
        )}
        {additionalEquipment && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">
              {t('Additional equipment')}
            </strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: additionalEquipment }}
            />
          </li>
        )}
        {options && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">
              {t('Options')}
            </strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: options }}
            />
          </li>
        )}
        {contact && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">
              {t('Contact')}
            </strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: contact }}
            />
          </li>
        )}
        {phone && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Phone')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: phone }}
            />
          </li>
        )}
        {mobile && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Mobile')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: mobile }}
            />
          </li>
        )}
        {fax && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Fax')}</strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: fax }}
            />
          </li>
        )}
        {email && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">{t('Email')}</strong>
            <p className="KeyValueTable--item--definition">
              <a href={`mailto: ${email}`}>{email}</a>
            </p>
          </li>
        )}
        {published && (
          <li className="KeyValueTable--item">
            <strong className="KeyValueTable--item--term">
              {t('Published')}
            </strong>
            <p
              className="KeyValueTable--item--definition"
              dangerouslySetInnerHTML={{ __html: published }}
            />
          </li>
        )}
      </ul>
    </FadeUp>
  );
};

export default ParagraphOrthotecKeyValueTable;
