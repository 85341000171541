import classNames from 'classnames';
import React from 'react';

import { SearchResult as SearchResultType } from '../../types';
import { SearchResult } from '../0-atoms';

export type SearchResultListProps = {
  results: SearchResultType[];
  className?: string;
};

export const SearchResultList: React.FC<SearchResultListProps> = ({
  results,
  className,
}) => (
  <div className={classNames('SearchResultList', className)}>
    <ul className="SearchResultList--item">
      {results.map(({ title, excerpt, url }, index) => (
        <li key={`result-${index}`} className="SearchResultList--item">
          <SearchResult title={title} excerpt={excerpt} url={url} />
        </li>
      ))}
    </ul>
  </div>
);
