//add props and documentation

import React, { useEffect } from 'react';

type MasonryLinkProps = {
  path: string;
  className?: string;
  /**
   * The base size of the row (ideally 4px) The number of rows is then calculated based on the height of the content.
   */
  gridRowSize: number;
  /**
   * The vertical gap between the items in the grid
   */
  gridItemGap: number;
  children?: React.ReactNode;
  fixedHeight?: number;
};

/**
 *
 * A responsive component that can be used to create a masonry grid item. It will automatically calculate the number of rows based on the height of the content.
 * Required conditions for this to work perfectly:
 * - The parent must be a grid with `grid-auto-rows` set to the `gridRowSize` prop. otherwise, it will behave normally according to the other styles.
 * - The element's contents and the `gridItemGap` should be of a `gridRowSize` base.
 */
export const MasonryLink: React.FC<MasonryLinkProps> = ({
  path,
  className,
  gridRowSize,
  gridItemGap,
  fixedHeight,
  children,
}) => {
  const ref = React.useRef<HTMLAnchorElement>(null);

  const calculateRowSpan = () => {
    if (ref.current) {
      const rowHeight = gridRowSize;

      const rowSpan = Math.ceil(
        ((fixedHeight ??
          ref.current?.querySelector('.content')?.getBoundingClientRect()
            ?.height ??
          0) +
          gridItemGap) /
          rowHeight,
      );

      ref.current.style.gridRow = 'span ' + rowSpan;
    }
  };

  useEffect(() => {
    calculateRowSpan();

    typeof window !== 'undefined' &&
      window.addEventListener('resize', calculateRowSpan);

    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', calculateRowSpan);
    };
  }, [ref.current]);

  return (
    <a className={className} href={path} ref={ref}>
      <div className="content">{children}</div>
    </a>
  );
};
