import React from 'react';

import {
  CTAContactUsButton,
  CTADonateButton,
  CTAMediaRequestButton,
  CTAMemberButton,
  CTAOfferButton,
  CTARoomsButton,
} from '../2-organisms';

export type HeroButtonProps = {
  type?: string;
  url?: string;
};

export const HeroButton: React.FC<HeroButtonProps> = ({ type, url }) => {
  switch (type) {
    case 'become_member':
      return <CTAMemberButton />;
    case 'donate':
      return <CTADonateButton url={url} />;
    case 'rooms':
      return <CTARoomsButton url={url || ''} />;
    case 'offer':
      return <CTAOfferButton url={url || ''} />;
    case 'contact_us':
      return <CTAContactUsButton url={url || ''} />;
    case 'media_request':
      return <CTAMediaRequestButton />;
    default:
    case 'none':
      return null;
  }
};
