import classNames from 'classnames';
import { decode } from 'he';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';

export type ParagraphHeaderSliderImageProps = {
  image?: string | ReactNode;
  title?: string;
  titleTag?: string;
  description?: string;
  url?: string;
  target?: string;
  className?: string;
};

export const ParagraphHeaderSliderImage: React.FC<
  ParagraphHeaderSliderImageProps
> = ({ image, title, titleTag, description, url, target, className }) => {
  const { t } = useTranslation();
  const { Link } = useFrameworkDependencies();

  return (
    <div className={classNames('HeaderSliderSlide', className)}>
      <div className="HeaderSliderSlide--background is-initialized">
        {typeof image === 'string' ? (
          <img
            src={image}
            className="HeaderSliderSlide--img"
            alt={title}
            style={{
              objectFit: 'cover',
              objectPosition: '50% 50%',
            }}
            loading="eager"
          />
        ) : (
          image
        )}
      </div>
      <div className="HeaderSliderSlide--content">
        {title &&
          React.createElement(
            titleTag ? titleTag : 'h2',
            {
              className: 'HeaderSliderSlide--title',
            },
            decode(title),
          )}
        {description && (
          <div
            className="HeaderSliderSlide--paragraph"
            dangerouslySetInnerHTML={{
              __html: decode(description.replace(/(^<p>|<\/p>$)/g, '')),
            }}
          />
        )}
        {url && (
          <Link className="HeaderSliderSlide--cta" to={url} target={target}>
            {t('Learn more')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ParagraphHeaderSliderImage;
