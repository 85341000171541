import classNames from 'classnames';
import React, { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { useFrameworkDependencies } from '../../dependencies';
import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphLogoBarProps = {
  logos?: {
    title?: string;
    image?: string | ReactNode;
    url?: string;
    target?: string;
  }[];
  className?: string;
};

export const ParagraphLogoBar: React.FC<ParagraphLogoBarProps> = ({
  logos,
}) => {
  const swiperInstance = useRef<SwiperClass>();
  const [isPreviousActive, setIsPreviousActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(false);
  const { Link } = useFrameworkDependencies();
  const { t } = useTranslation();

  return (
    <FadeUp yGap={20}>
      <div className="LogoBar" data-slides={logos?.length}>
        <div className="LogoBar--header">
          <div className="LogoBar--buttonContainer">
            <a
              className={classNames(
                'LogoBar--articleLink LogoBar--articleLink-prev',
                {
                  'is-disabled': !isPreviousActive,
                },
              )}
              href=""
              rel="prev"
              onClick={(event) => {
                event.preventDefault();
                swiperInstance.current?.slidePrev();
              }}
            >
              <span className="visuallyhidden">{t('Previous')}</span>
              <i className="ico ico-bolt-left" />
            </a>
            <a
              className={classNames(
                'LogoBar--articleLink LogoBar--articleLink-next',
                {
                  'is-disabled': !isNextActive,
                },
              )}
              href=""
              rel="next"
              onClick={(event) => {
                event.preventDefault();
                swiperInstance.current?.slideNext();
              }}
            >
              <span className="visuallyhidden">{t('Next')}</span>
              <i className="ico ico-bolt-right" />
            </a>
          </div>
        </div>
        <Swiper
          className="LogoBar--list"
          wrapperTag="ul"
          breakpoints={{
            320: {
              slidesPerView: 2.5,
              spaceBetween: 12,
            },
            1024: {
              slidesPerView: 4,
              initialSlide: 1,
              spaceBetween: 16,
            },
          }}
          onSwiper={(instance) => {
            swiperInstance.current = instance;
            setIsPreviousActive(false);
            setIsNextActive(
              instance.slides.length >
                (typeof instance.params.slidesPerView === 'number'
                  ? instance.params.slidesPerView || 2
                  : 2),
            );
          }}
          onSlideChange={(instance) => {
            setIsPreviousActive(instance.realIndex > 0);
            setIsNextActive(
              instance.slides.length >
                instance.realIndex +
                  (typeof instance.params.slidesPerView === 'number'
                    ? instance.params.slidesPerView || 4
                    : 4),
            );
          }}
          updateOnWindowResize={typeof ResizeObserver !== undefined}
        >
          {logos &&
            logos.map(({ title, image, url, target }, index) => {
              const logo =
                typeof image === 'string' ? (
                  <img
                    src={image}
                    className="LogoBar--logo"
                    alt={title}
                    loading="lazy"
                  />
                ) : (
                  image
                );
              return (
                <SwiperSlide
                  key={`logo-${index}`}
                  tag="li"
                  className="relative logobar-slide-item"
                >
                  {url ? (
                    <Link to={url} target={target}>
                      {logo}
                    </Link>
                  ) : (
                    logo
                  )}
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </FadeUp>
  );
};

export default ParagraphLogoBar;
