import React, { PropsWithChildren, useContext, useState } from 'react';

export type SearchResultsFiltersContext = {
  domain?: string;
  search?: string;
  setDomain: (domain: string) => void;
  setSearch: (search: string) => void;
};
const SearchResultsFiltersContext = React.createContext<
  SearchResultsFiltersContext | undefined
>(undefined);

export const SearchResultsFiltersProvider: React.FC<
  PropsWithChildren<{
    domain?: string;
    search?: string;
  }>
> = ({ domain: initialDomain, search: initialSearch, children }) => {
  const [domain, setDomain] = useState(initialDomain);
  const [search, setSearch] = useState(initialSearch);

  return (
    <SearchResultsFiltersContext.Provider
      value={{
        domain,
        search,
        setDomain: (domain: string) => setDomain(domain),
        setSearch: (search: string) => setSearch(search),
      }}
    >
      {children}
    </SearchResultsFiltersContext.Provider>
  );
};

export const useSearchResultsFilters = () => {
  const context = useContext(SearchResultsFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useSearchResultsFilters must be used within a SearchResultsFiltersProvider',
    );
  }
  return context;
};
