import React from 'react';

export type IconDownloadProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconDownload: React.FC<IconDownloadProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 28 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#333333" data-name="Layer 2">
      <path
        d="M27 22a1 1 0 00-1 1v3a3 3 0 01-3 3H5a3 3 0 01-3-3v-3a1 1 0 10-2 0v3a5 5 0 005 5h18a5 5 0 005-5v-3a1 1 0 00-1-1z"
        data-name="Pfad 47"
      />
      <path
        d="M14 0a1 1 0 00-1 1v17.86l-4.36-3.63a1.001 1.001 0 00-1.28 1.54l6 5a1 1 0 00.15.09l.08.06A1 1 0 0014 22a1 1 0 00.36-.07l.08-.06a1 1 0 00.15-.09l6-5a1.001 1.001 0 10-1.28-1.54L15 18.86V1a1 1 0 00-1-1z"
        data-name="Pfad 48"
      />
    </g>
  </svg>
);
