import classNames from 'classnames';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useEventsFilters, useMonthNames } from '../../hooks';
import { FormSelect } from '../0-atoms';
import { FadeUp } from './FadeIn';

export type EventsFilterFormProps = {
  organisations: {
    id: string;
    label: string;
  }[];
  categories: {
    id: string;
    label: string;
  }[];
  redirectToAll?: boolean;
  className?: string;
};

export const EventsFilterForm: React.FC<EventsFilterFormProps> = ({
  organisations,
  categories,
  redirectToAll,
  className,
}) => {
  const { useLocalization } = useFrameworkDependencies();
  const months = useMonthNames();
  const { t } = useTranslation();
  const { locale } = useLocalization();
  const { navigate } = useFrameworkDependencies();
  const {
    organisation,
    category,
    month,
    period,
    setOrganisation,
    setCategory,
    setMonth,
    setPeriod,
  } = useEventsFilters();

  const updateUrl = (query?: {
    organisation?: string;
    category?: string;
    month?: string;
    period?: string;
  }) =>
    navigate(
      `${redirectToAll ? `/${locale}/events/all` : ''}?${queryString.stringify(
        {
          organisation,
          category,
          month,
          period,
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      )}`,
    );

  return (
    <div className={classNames('FilterBox', className)}>
      <FadeUp yGap={20} className="FilterBox--inner">
        <form action="" className="Form" method="GET">
          <FormSelect
            label={t('Organisation')}
            id="u1"
            name="organisation"
            defaultValue={organisation || undefined}
            options={organisations.map(({ id, label }) => ({
              value: id,
              label,
            }))}
            onChange={(event) => {
              setOrganisation(event.target.value);
              updateUrl({
                organisation: event.target.value,
              });
            }}
          />
          <FormSelect
            label={t('Month')}
            id="u2"
            name="month"
            defaultValue={month || undefined}
            options={months.map(({ id, label }) => ({
              value: id,
              label,
            }))}
            onChange={(event) => {
              setMonth(event.target.value);
              updateUrl({
                month: event.target.value,
              });
            }}
          />
          <FormSelect
            label={t('Category')}
            id="u3"
            name="category"
            defaultValue={category || undefined}
            options={categories.map(({ id, label }) => ({
              value: id,
              label,
            }))}
            onChange={(event) => {
              setCategory(event.target.value);
              updateUrl({
                category: event.target.value,
              });
            }}
          />
          <FormSelect
            label={t('Show')}
            id="u4"
            name="period"
            defaultValue={period || undefined}
            emptyOption={{
              value: 'all',
            }}
            options={[
              {
                value: '1',
                label: t('Future events'),
              },
              {
                value: '2',
                label: t('Past events'),
              },
            ]}
            onChange={(event) => {
              setPeriod(event.target.value);
              updateUrl({
                period: event.target.value,
              });
            }}
          />
        </form>
      </FadeUp>
    </div>
  );
};
