import React from 'react';

export type PrintButtonProps = {
  label: string;
};

export const PrintButton: React.FC<PrintButtonProps> = ({ label }) => (
  <button
    className="PageActions--button"
    onClick={() => {
      window.print(); // eslint-disable-line no-undef
    }}
    type="button"
  >
    {label}
    <span className="PageActions--button--icon">
      <i className="ico ico-footer-print" />
    </span>
  </button>
);
