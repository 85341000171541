import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export type HistoryNavProps = {
  years: Array<number>;
  className?: string;
};

export const HistoryNav: React.FC<HistoryNavProps> = ({ years, className }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') {
      return;
    }
    document.querySelectorAll('[data-history]').forEach((target) => {
      const interSecObs = new IntersectionObserver((entries) => {
        const intersectingEntries = entries.filter(
          (entry) => entry.isIntersecting,
        );

        if (intersectingEntries.length) {
          document
            .querySelectorAll('[data-history-nav]')
            .forEach((anchorNav) => anchorNav.classList.remove('is-active'));
          intersectingEntries.forEach((entry) => {
            document
              .querySelector(
                `[data-history-nav='${entry.target.getAttribute(
                  'data-history',
                )}']`,
              )
              ?.classList.add('is-active');
          });
        }
      });
      interSecObs.observe(target);
    });
  }, []);

  return (
    <div
      className={classNames(
        'AnchorNav no-scroll no-topscroll no-bottomscroll',
        className,
      )}
    >
      <div className="AnchorNav--inner">
        <button
          aria-hidden="true"
          className="AnchorNav--scrollBtn AnchorNav--scrollBtn-up"
          type="button"
        >
          <span className="visuallyhidden">{t('Previous years')}</span>
        </button>
        <div className="AnchorNav--scroll">
          <ul className="AnchorNav--years">
            {years.map((year) => (
              <li key={`year-${year}`}>
                <a
                  className="AnchorNav--link"
                  data-history-nav={year}
                  href={`#${year}`}
                  onClick={(event) => {
                    event.preventDefault();
                    document
                      .querySelector(`[data-history='${year}']`)
                      ?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'start',
                      });
                  }}
                >
                  <span className="visuallyhidden">{t('Year')}</span>
                  {year}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <button
          aria-hidden="true"
          className="AnchorNav--scrollBtn AnchorNav--scrollBtn-down"
          type="button"
        >
          <span className="visuallyhidden">{t('Next years')}</span>
        </button>
      </div>
    </div>
  );
};
