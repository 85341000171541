import React from 'react';

export type IconCheckmarkProps = {
  className?: string;
  fill?: string | '#FFF';
  position?: 'left' | 'center';
};

export const IconCheckmark: React.FC<IconCheckmarkProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <g fill={fill}>
        <path d="m46.08,50.61c.35.44.85.7,1.4.73l.36-.06c.64-.06,1.21-.43,1.52-.99.69-1.23,17.38-30.76,47.62-44.88.88-.46,1.26-1.6.81-2.55h0c-.45-.97-1.57-1.41-2.55-.99-24.63,11.47-40.46,32.69-46.12,41.28l-1.77,2.69-14.78-18.4c-.75-.62-1.83-.64-2.6-.04-.71.59-.9,1.67-.4,2.51l16.53,20.69Z" />
        <path d="m87.03,39.98c0-3.67-.5-7.3-1.5-10.82l-3.5,2.2c2.16,8.7,1.02,17.77-3.25,25.72-4.56,8.48-12.16,14.68-21.38,17.45-3.37,1.01-6.85,1.52-10.37,1.52h-.01c-19.9,0-36.06-16.18-36.06-36.06,0-19.87,16.18-36.04,36.05-36.04.03,0,.05,0,.07,0,5.43,0,10.82,1.25,15.69,3.63l3.86-2.43C60.67,1.78,53.96,0,47.03,0,24.96,0,7.02,17.95,7.02,40.01c0,10.68,4.17,20.73,11.72,28.28,7.55,7.55,17.6,11.71,28.28,11.71h0c22.04-.04,39.98-18,40-40.02Z" />
      </g>
    ) : (
      <g fill={fill}>
        <path d="m39.06,50.61c.35.44.85.7,1.4.73l.36-.06c.64-.06,1.21-.43,1.52-.99.69-1.23,17.38-30.76,47.62-44.88.88-.46,1.26-1.6.81-2.55h0c-.45-.97-1.57-1.41-2.55-.99-24.63,11.47-40.46,32.69-46.12,41.28l-1.77,2.69-14.78-18.4c-.75-.62-1.83-.64-2.6-.04-.71.59-.9,1.67-.4,2.51l16.53,20.69Z" />
        <path d="m80.01,39.98c0-3.67-.5-7.3-1.5-10.82l-3.5,2.2c2.16,8.7,1.02,17.77-3.25,25.72-4.56,8.48-12.16,14.68-21.38,17.45-3.37,1.01-6.85,1.52-10.37,1.52h-.01c-19.9,0-36.06-16.18-36.06-36.06,0-19.87,16.18-36.04,36.05-36.04.03,0,.05,0,.07,0,5.43,0,10.82,1.25,15.69,3.63l3.86-2.43C53.64,1.78,46.93,0,40.01,0,17.94,0,0,17.95,0,40.01c0,10.68,4.17,20.73,11.72,28.28,7.55,7.55,17.6,11.71,28.28,11.71h0c22.04-.04,39.98-18,40-40.02Z" />
      </g>
    )}
  </svg>
);
