import { ParagraphImageFragment } from '@custom/schema';
import React from 'react';

import { Image, ImageProps } from '../../components/0-atoms';
import { transformImageFragmentToProps } from './MediaImage';

export const transformParagraphImageToProps = (
  paragraph: ParagraphImageFragment,
) => {
  const paragraphImage = paragraph?.image;
  const image = paragraphImage ? (
    <Image
      {...transformImageFragmentToProps(paragraphImage, [
        'small',
        'medium',
        'large',
      ])}
      size={paragraph.imageSize as ImageProps['size']}
      className="ArticleFigure--img"
      fit
      loading="lazy"
    />
  ) : undefined;

  return {
    image,
    title: paragraph?.image?.title,
    description: paragraph.descriptionHtml,
  };
};
