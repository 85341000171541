import React, { PropsWithChildren, useContext, useState } from 'react';

export type MedicalOffersFiltersContext = {
  domain?: string;
  glossary?: string;
  setDomain: (domain: string) => void;
  setGlossary: (glossary: string) => void;
};
const MedicalOffersFiltersContext = React.createContext<
  MedicalOffersFiltersContext | undefined
>(undefined);

export const MedicalOffersFiltersProvider: React.FC<
  PropsWithChildren<{
    domain?: string;
    glossary?: string;
  }>
> = ({ domain: initialDomain, glossary: initialGlossary, children }) => {
  const [domain, setDomain] = useState(initialDomain);
  const [glossary, setGlossary] = useState(initialGlossary);

  return (
    <MedicalOffersFiltersContext.Provider
      value={{
        domain,
        glossary,
        setDomain: (domain: string) => setDomain(domain),
        setGlossary: (glossary: string) => setGlossary(glossary),
      }}
    >
      {children}
    </MedicalOffersFiltersContext.Provider>
  );
};

export const useMedicalOffersFilters = () => {
  const context = useContext(MedicalOffersFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useMedicalOffersFilters must be used within a MedicalOffersFiltersProvider',
    );
  }
  return context;
};
