import React, { PropsWithChildren, useContext, useState } from 'react';

export type EventsFiltersContext = {
  organisation?: string;
  month?: string;
  category?: string;
  period?: string;
  setOrganisation: (organisation: string) => void;
  setMonth: (month: string) => void;
  setCategory: (category: string) => void;
  setPeriod: (period: string) => void;
};
const EventsFiltersContext = React.createContext<
  EventsFiltersContext | undefined
>(undefined);

export const EventsFiltersProvider: React.FC<
  PropsWithChildren<{
    organisation?: string;
    month?: string;
    category?: string;
    period?: string;
  }>
> = ({
  organisation: initialOrganisation,
  month: initialMonth,
  category: initialCategory,
  period: initialPeriod,
  children,
}) => {
  const [organisation, setOrganisation] = useState(initialOrganisation);
  const [month, setMonth] = useState(initialMonth);
  const [category, setCategory] = useState(initialCategory);
  const [period, setPeriod] = useState(initialPeriod);

  return (
    <EventsFiltersContext.Provider
      value={{
        organisation,
        month,
        category,
        period,
        setOrganisation: (organisation: string) =>
          setOrganisation(organisation),
        setMonth: (month: string) => setMonth(month),
        setCategory: (category: string) => setCategory(category),
        setPeriod: (period: string) => setPeriod(period),
      }}
    >
      {children}
    </EventsFiltersContext.Provider>
  );
};

export const useEventsFilters = () => {
  const context = useContext(EventsFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useEventsFilters must be used within a EventsFiltersProvider',
    );
  }
  return context;
};
