import classNames from 'classnames';
import React, { ReactNode } from 'react';

export type DoctorHeaderHeroProps = {
  pretitle: string | undefined;
  title: string;
  subtitle: string;
  image: string | ReactNode | undefined;
  className?: string;
};

export const DoctorHeaderHero: React.FC<DoctorHeaderHeroProps> = ({
  pretitle,
  title,
  subtitle,
  image,
  className,
}) => {
  return (
    <div
      className={classNames(
        'DoctorHeader',
        'SubpageHeader first-level',
        className,
        {
          'SubpageHeader-img': !!image,
        },
      )}
    >
      <div className="SubpageHeader--body">
        {image && (
          <div className="SubpageHeader--background is-initialized">
            {typeof image === 'string' ? (
              <img
                className="SubpageHeader--background--img"
                alt={title}
                title={title}
                src={image}
              />
            ) : (
              image
            )}
          </div>
        )}
        {title && (
          <div className="SubpageHeader--content">
            {pretitle && (
              <div className="SubpageHeader--pretitle">{pretitle}</div>
            )}
            <h1 className="SubpageHeader--title">{title}</h1>
            <div className="SubpageHeader--subtitle">{subtitle}</div>
          </div>
        )}
      </div>
    </div>
  );
};
