import React from 'react';

export type IconFooterXingProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterXing: React.FC<IconFooterXingProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    viewBox="0 0 512 512"
  >
    <path
      fill={fill}
      d="M319 512L205.4 305.8 377.6 0h105.2L310.6 305.8 423.1 512H319zM163.4 106.7H58.1l59.1 101.2-87.9 154.8h104.8L222 207.6l-58.6-100.9z"
    />
  </svg>
);
