import React from 'react';

export type IconArrowRightProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconArrowRight: React.FC<IconArrowRightProps> = ({
  className,
}) => (
  <svg
    className={className}
    viewBox="0 0 19.424 15.668"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g data-name="Group 53">
      <g data-name="Group 2">
        <path
          d="M18.962 8.357H.462a.527.527 0 010-1.046h18.5a.527.527 0 010 1.046z"
          fill="#333333"
          data-name="Path 5"
        />
      </g>
      <path
        d="M10.534 15.668a.559.559 0 01-.37-.135.423.423 0 010-.652l8-7.047-8-7.047a.423.423 0 010-.652.574.574 0 01.739 0l8.367 7.373a.423.423 0 010 .652l-8.367 7.373a.559.559 0 01-.369.135z"
        fill="#333333"
        data-name="Path 6"
      />
    </g>
  </svg>
);
