import React from 'react';

export type IconBoltRightProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconBoltRight: React.FC<IconBoltRightProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 22 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.025 6.976c0 .6.4 1 1 1h17.9l-3.6 4.4c-.4.4-.3 1.1.1 1.4.4.4 1.1.3 1.4-.1l5-6 .1-.1.1-.1v-.5c0-.1 0-.2-.1-.4l-.1-.1c0-.1-.1-.1-.1-.1l-5-6c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l3.6 4.3h-17.8c-.6 0-1 .4-1 1z" />
  </svg>
);
