import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { ConditionalWrapper } from '../../utils/conditionalContainer';
import { IconKey, IconSvg } from './IconSvg';

export type TeaserProps = {
  url?: string;
  title?: string;
  target?: string;
  text?: string;
  category?: string | ReactNode;
  linkLabel?: string;
  isLarge?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  iconName?: string;
};

export const Teaser: React.FC<TeaserProps> = ({
  title,
  url,
  target,
  text,
  category,
  linkLabel,
  isLarge = false,
  onClick,
  className,
  iconName,
}) => {
  const { Link } = useFrameworkDependencies();
  const { t } = useTranslation();
  return (
    <div
      className={classNames('ContentTeaser textTeaser', className, {
        large: isLarge,
      })}
    >
      <ConditionalWrapper
        condition={!!url}
        wrapper={(children: any) => (
          <Link to={url || ''} title={title} target={target} onClick={onClick}>
            {children}
          </Link>
        )}
      >
        <div
          className={classNames('ContentTeaser--inner', {
            'ContentTeaser--grid': iconName,
          })}
        >
          {iconName && (
            <div className="ContentTeaser--inner-first">
              <IconSvg
                position={'inline fill-all'}
                wrapClass={'Teaser--pack--icon'}
                iconPosition={'center'}
                icon={`ico-${iconName}` as IconKey}
              />
            </div>
          )}
          <div className="ContentTeaser--inner-last">
            <div className="ContentTeaser--content">
              {!!text && (
                <div
                  className="ContentTeaser--title h3"
                  // Make sure the text is not surrounded by '<p>' and '</p>'.
                  dangerouslySetInnerHTML={{
                    __html: text.replace(/(^<p>|<\/p>$)/g, ''),
                  }}
                />
              )}
              {!!category && (
                <strong className="ContentTeaser--subtitle">{category}</strong>
              )}
            </div>
            {url && (
              <div className="ContentTeaser--bar">
                <div className="ContentTeaser--buttonContainer">
                  <div className="ContentTeaser--button">
                    {linkLabel || t('Read more')}
                    <IconSvg
                      position={'inline'}
                      wrapClass={'Teaser--link--icon'}
                      icon={`ico-arrow-right` as IconKey}
                      transition
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};
