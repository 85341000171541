import React from 'react';

import { NewsroomFiltersProvider } from '../../hooks';
import { NewsroomTeaserView } from './NewsroomTeaserView';

export type ParagraphViewsNewsroomTeaserProps = {
  className?: string;
  itemsPerPage?: number;
  highlightPerPage?: number;
  title?: string;
  contentTypes?: string[];
  domain?: string;
  linkText?: string;
};

export const ParagraphViewsNewsroomTeaser: React.FC<
  ParagraphViewsNewsroomTeaserProps
> = ({
  className,
  itemsPerPage = 4,
  highlightPerPage = 1,
  title = '',
  contentTypes = [],
  domain = '',
  linkText = '',
}) => {
  return (
    // org and type are not used in this component, but are required by the provider.
    <NewsroomFiltersProvider organisation={''} type={''}>
      <NewsroomTeaserView
        className={className}
        itemsPerPage={itemsPerPage}
        highlightPerPage={highlightPerPage}
        title={title}
        linkText={linkText}
        organisation={domain}
        type={contentTypes}
      />
    </NewsroomFiltersProvider>
  );
};

export default ParagraphViewsNewsroomTeaser;
