import { ContentEventFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { EventInfoItem, EventPageProps } from '../../components/3-layouts';
import { getImageSrc, isPublished } from '../../utils';
import { getImageStyleUrl } from './MediaImage';

export const transformEventFragmentToEvent = (
  event: ContentEventFragment,
): EventPageProps => {
  const headerImage = event?.headerImage;

  const image = headerImage ? (
    <Image
      className="SubpageHeader--background--img"
      sizes="(max-width: 750px) 50vw, 100vw"
      filemime={headerImage?.mimeType}
      src={getImageSrc(
        headerImage,
        `${getImageStyleUrl(headerImage, 'header_slider')} 2000w, 
        ${getImageStyleUrl(
          headerImage,
          'header_slider_image_responsive_',
        )} 750w`,
      )}
      alt={headerImage?.alt || event?.title}
      fit
      loading="eager"
    />
  ) : undefined;

  const fieldMapCoordinates = event?.map;

  const map =
    fieldMapCoordinates?.latitude && fieldMapCoordinates.longitude
      ? {
          latitude: parseFloat(fieldMapCoordinates.latitude),
          longitude: parseFloat(fieldMapCoordinates.longitude),
          title: fieldMapCoordinates.mapTitle,
          text: fieldMapCoordinates?.textHtml,
          small: fieldMapCoordinates.smallVariant,
        }
      : undefined;

  return {
    title: event?.title || 'Missing title',
    lead: event?.leadHtml,
    image,
    info: event?.info?.rows
      ?.filter(isPublished)
      ?.map((row) => ({
        key: row?.key || '',
        value: row?.value || '',
      }))
      .filter((row): row is EventInfoItem => Boolean(row)),
    map,
    hideFundingBanner: event?.hideFundingBanner,
  };
};
