export enum Icon {
  Ad = 'ico-ad',
  AdminDelete = 'ico-admin-delete',
  AdminEdit = 'ico-admin-edit',
  AdminTranslate = 'ico-admin-translate',
  Amex = 'ico-amex',
  ArrowDownWhite = 'ico-arrow-down-white',
  ArrowDown = 'ico-arrow-down',
  ArrowLeftWhite = 'ico-arrow-left-white',
  ArrowLeft = 'ico-arrow-left',
  ArrowRightWhite = 'ico-arrow-right-white',
  ArrowRight = 'ico-arrow-right',
  ArrowUpWhite = 'ico-arrow-up-white',
  ArrowUp = 'ico-arrow-up',
  BannerHeart = 'ico-banner-heart',
  BannerProfile = 'ico-banner-profile',
  BedBrown = 'ico-bed-brown',
  BedLarge = 'ico-bed-large',
  BedWhite = 'ico-bed-white',
  BoltLeftRed = 'ico-bolt-left-red',
  BoltLeftWhite = 'ico-bolt-left-white',
  BoltLeft = 'ico-bolt-left',
  BoltRightRed = 'ico-bolt-right-red',
  BoltRightWhite = 'ico-bolt-right-white',
  BoltRight = 'ico-bolt-right',
  BoltUpWhite = 'ico-bolt-up-white',
  BoltUp = 'ico-bolt-up',
  Calendar = 'ico-calendar',
  CartRemove = 'ico-cart-remove',
  CartWhite = 'ico-cart-white',
  Cart = 'ico-cart',
  Close = 'ico-close',
  Copytolist = 'ico-copytolist',
  DocumentBrown = 'ico-document-brown',
  DocumentWhite = 'ico-document-white',
  DonateRed = 'ico-donate-red',
  DonateWhite = 'ico-donate-white',
  Donate = 'ico-donate',
  DownloadWhite = 'ico-download-white',
  Download = 'ico-download',
  Edit = 'ico-edit',
  FooterBlog = 'ico-footer-blog',
  FooterFacebook = 'ico-footer-facebook',
  FooterFlickr = 'ico-footer-flickr',
  FooterGoogle = 'ico-footer-google',
  FooterInstagram = 'ico-footer-instagram',
  FooterLinkedin = 'ico-footer-linkedin',
  FooterPinterest = 'ico-footer-pinterest',
  FooterPrint = 'ico-footer-print',
  FooterShare = 'ico-footer-share',
  FooterSnapchat = 'ico-footer-snapchat',
  FooterTripadvisor = 'ico-footer-tripadvisor',
  FooterTwitter = 'ico-footer-twitter',
  FooterVimeo = 'ico-footer-vimeo',
  FooterWhatsapp = 'ico-footer-whatsapp',
  FooterXing = 'ico-footer-xing',
  FooterYoutube = 'ico-footer-youtube',
  GalleryCollapse = 'ico-gallery-collapse',
  GalleryExpand = 'ico-gallery-expand',
  GroupMail = 'ico-group-mail',
  GroupPhone = 'ico-group-phone',
  Heart = 'ico-heart',
  Info = 'ico-info',
  InpageSearch = 'ico-inpage-search',
  LoadMore = 'ico-load-more',
  Logout = 'ico-logout',
  MailSmall = 'ico-mail-small',
  Mastercard = 'ico-mastercard',
  MembershipRed = 'ico-membership-red',
  MembershipWhite = 'ico-membership-white',
  MobileNavigationHome = 'ico-mobile-navigation-home',
  MobileNavigation = 'ico-mobile-navigation',
  Movetoanotherlist = 'ico-movetoanotherlist',
  NavigationMicWhite = 'ico-navigation-mic-white',
  NavigationMic = 'ico-navigation-mic',
  NavigationSearchWhite = 'ico-navigation-search-white',
  NavigationSearch = 'ico-navigation-search',
  Paypal = 'ico-paypal',
  PfCard = 'ico-pf-card',
  PfEfinance = 'ico-pf-efinance',
  PhoneSmall = 'ico-phone-small',
  Phone = 'ico-phone',
  Postfinance = 'ico-postfinance',
  ProfileRed = 'ico-profile-red',
  ProfileWhiteSmall = 'ico-profile-white-small',
  ProfileRedSmall = 'ico-profile-red-small',
  Profile = 'ico-profile',
  SocialEmail = 'ico-social-email',
  SocialFacebook = 'ico-social-facebook',
  SocialInstagram = 'ico-social-instagram',
  SocialTwitter = 'ico-social-twitter',
  Twint = 'ico-twint',
  Visa = 'ico-visa',
}
