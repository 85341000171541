import React from 'react';

export type IconCoupleProps = {
  className?: string;
  fill?: string | '#FFF';
  position?: 'left' | 'center';
};

export const IconCouple: React.FC<IconCoupleProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <>
        <path
          fill={fill}
          d="m104.62,78.02v-3.34c0-9.78-11.59-14.67-22.36-15.06h-.37s-5.37-6.99-5.37-6.99l1.31-.2c10.1-1.54,17.96-6.57,21.57-13.8.3-.6.27-1.31-.08-1.89-1.97-3.28-5.85-10.16-6.45-12.74-3.23-13.69-11.24-21.54-21.97-21.54-3.08,0-6.09.72-8.77,2.08-.97.49-1.36,1.68-.87,2.66.24.47.65.82,1.15.98.5.16,1.04.12,1.51-.11,2.13-1.08,4.53-1.65,6.92-1.65,12.5,0,16.88,12.94,18.19,18.5.52,2.23,2.63,6.55,6.09,12.48l.24.41-.26.4c-2.57,4.03-9.14,10.79-24.19,10.79-3.51-.07-6.98-.63-10.33-1.66l-.11-.03c-.49-.12-1.02-.04-1.48.23-.45.27-.77.7-.9,1.21-.13.51-.05,1.04.22,1.5.26.45.68.76,1.16.88,3.73,1.14,7.56,1.75,11.42,1.82l.89-.02,7.58,9.84c.37.48.95.77,1.56.77,8.21,0,19.76,3.45,19.76,11.13v3.35c0,1.09.89,1.97,1.97,1.97,1.09,0,1.97-.89,1.97-1.98Z"
        />
        <path
          fill={fill}
          d="m72.56,80h.4s.38-.16.38-.16c.72-.31,1.19-1.02,1.2-1.81v-3.34c0-10.02-7.57-17.02-18.42-17.02h-3.97l-4.11-5.98.43-.46c3.61-3.83,6.06-9.32,6.55-14.68l.04-.4.34-.2c1.65-.98,2.6-2.81,2.43-4.66v-6.76c0-1.41,0-4.02-2.81-4.96l-.53-.18v-4.88c0-4.63-1.2-8.25-3.57-10.75C48.54,1.26,45.13,0,40.77,0c-4.09.13-8.12.96-11.95,2.47l-.25.05c-6.42.29-11.46,5.56-11.47,11.99v5.49l-.36.23c-1.93,1.24-2.99,2.77-2.99,4.31v6.68c0,2.48,2.06,4.17,3.82,4.86l.43.17.05.46c.64,5.51,3.7,11.35,7.99,15.22l.5.45-3.53,5.26h-3.95c-10.83,0-18.69,7.16-18.69,17.02v3.35c0,1.08.89,1.97,1.98,1.97s1.97-.88,1.98-1.97v-3.35c0-7.69,6.06-13.07,14.74-13.07h3.73l6.16,13.87c.32.71,1.02,1.18,1.8,1.18.52,0,1.04-.22,1.39-.58l5.29-5.29,5.3,5.3c.37.37.87.57,1.39.57.09,0,.17,0,.32-.02h.09c.59-.12,1.13-.55,1.4-1.15l6.17-13.88h4.01c8.52,0,14.46,5.37,14.46,13.07v3.35c0,1.09.89,1.97,1.97,1.97ZM21.88,34.57c0-1.09-.89-1.97-1.98-1.97-.78,0-1.73-.54-2.06-1.03l-.14-.2v-6.73l.18-.22c.54-.64,1.22-1.12,2.04-1.44.66-.3,1.12-1.01,1.12-1.79v-6.68c0-4.44,3.62-8.05,8.05-8.06.27,0,.52-.05.76-.15,3.5-1.42,7.18-2.21,10.95-2.35,6.55.01,9.71,3.47,9.71,10.56v6.69c0,1.09.89,1.98,1.98,1.98.2,0,.42,0,.6,0l.74.02.02.74c0,.18,0,.4,0,.6v6.68c0,.33-.03.6-.1.81l-.16.53-.56.02c-1.07.04-1.9.91-1.9,1.97,0,10.18-7.59,18.08-14.11,18.08s-15.16-8.34-15.16-18.08Zm9.53,36.68l-2.9-6.52,5.79,3.62-2.9,2.9Zm12.09,0l-2.9-2.9,5.79-3.62-2.9,6.52Zm-6.04-5.58l-10.61-6.63,2.94-4.39.61.31c4.64,2.32,9.57,2.15,13.93-.4l.63-.37,3.27,4.75-10.77,6.73Z"
        />
      </>
    ) : (
      <>
        <path
          fill={fill}
          d="m104.23,78.02v-3.34c0-9.78-11.59-14.67-22.36-15.06h-.37s-5.37-6.99-5.37-6.99l1.31-.2c10.1-1.54,17.96-6.57,21.57-13.8.3-.6.27-1.31-.08-1.89-1.97-3.28-5.85-10.16-6.45-12.74-3.23-13.69-11.24-21.54-21.97-21.54-3.08,0-6.09.72-8.77,2.08-.97.49-1.36,1.68-.87,2.66.24.47.65.82,1.15.98.5.16,1.04.12,1.51-.11,2.13-1.08,4.53-1.65,6.92-1.65,12.5,0,16.88,12.94,18.19,18.5.52,2.23,2.63,6.55,6.09,12.48l.24.41-.26.4c-2.57,4.03-9.14,10.79-24.19,10.79-3.51-.07-6.98-.63-10.33-1.66l-.11-.03c-.49-.12-1.02-.04-1.48.23-.45.27-.77.7-.9,1.21-.13.51-.05,1.04.22,1.5.26.45.68.76,1.16.88,3.73,1.14,7.56,1.75,11.42,1.82l.89-.02,7.58,9.84c.37.48.95.77,1.56.77,8.21,0,19.76,3.45,19.76,11.13v3.35c0,1.09.89,1.97,1.97,1.97,1.09,0,1.97-.89,1.97-1.98Z"
        />
        <path
          fill={fill}
          d="m72.17,80h.4s.38-.16.38-.16c.72-.31,1.19-1.02,1.2-1.81v-3.34c0-10.02-7.57-17.02-18.42-17.02h-3.97l-4.11-5.98.43-.46c3.61-3.83,6.06-9.32,6.55-14.68l.04-.4.34-.2c1.65-.98,2.6-2.81,2.43-4.66v-6.76c0-1.41,0-4.02-2.81-4.96l-.53-.18v-4.88c0-4.63-1.2-8.25-3.57-10.75C48.16,1.26,44.75,0,40.38,0c-4.09.13-8.12.96-11.95,2.47l-.25.05c-6.42.29-11.46,5.56-11.47,11.99v5.49l-.36.23c-1.93,1.24-2.99,2.77-2.99,4.31v6.68c0,2.48,2.06,4.17,3.82,4.86l.43.17.05.46c.64,5.51,3.7,11.35,7.99,15.22l.5.45-3.53,5.26h-3.95C7.86,57.66,0,64.82,0,74.68v3.35c0,1.08.89,1.97,1.98,1.97s1.97-.88,1.98-1.97v-3.35c0-7.69,6.06-13.07,14.74-13.07h3.73l6.16,13.87c.32.71,1.02,1.18,1.8,1.18.52,0,1.04-.22,1.39-.58l5.29-5.29,5.3,5.3c.37.37.87.57,1.39.57.09,0,.17,0,.32-.02h.09c.59-.12,1.13-.55,1.4-1.15l6.17-13.88h4.01c8.52,0,14.46,5.37,14.46,13.07v3.35c0,1.09.89,1.97,1.97,1.97ZM21.5,34.57c0-1.09-.89-1.97-1.98-1.97-.78,0-1.73-.54-2.06-1.03l-.14-.2v-6.73l.18-.22c.54-.64,1.22-1.12,2.04-1.44.66-.3,1.12-1.01,1.12-1.79v-6.68c0-4.44,3.62-8.05,8.05-8.06.27,0,.52-.05.76-.15,3.5-1.42,7.18-2.21,10.95-2.35,6.55.01,9.71,3.47,9.71,10.56v6.69c0,1.09.89,1.98,1.98,1.98.2,0,.42,0,.6,0l.74.02.02.74c0,.18,0,.4,0,.6v6.68c0,.33-.03.6-.1.81l-.16.53-.56.02c-1.07.04-1.9.91-1.9,1.97,0,10.18-7.59,18.08-14.11,18.08s-15.16-8.34-15.16-18.08Zm9.53,36.68l-2.9-6.52,5.79,3.62-2.9,2.9Zm12.09,0l-2.9-2.9,5.79-3.62-2.9,6.52Zm-6.04-5.58l-10.61-6.63,2.94-4.39.61.31c4.64,2.32,9.57,2.15,13.93-.4l.63-.37,3.27,4.75-10.77,6.73Z"
        />
      </>
    )}
  </svg>
);
