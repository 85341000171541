import classNames from 'classnames';
import React from 'react';

import { formatType } from '../../utils';
import { FadeUp } from '../1-molecules/FadeIn';
import { IconKey, IconSvg } from './IconSvg';

export type ParagraphDownloadListProps = {
  downloads: {
    url?: string;
    label?: string;
    type?: string;
    size?: string;
  }[];
  className?: string;
};

export const ParagraphDownloadList: React.FC<ParagraphDownloadListProps> = ({
  downloads,
  className,
}) => (
  <FadeUp yGap={20} className={classNames('LinkList', className)}>
    {downloads && (
      <ul className="LinkList--list">
        {downloads.map(({ url, label, type, size }, index) => (
          <li key={`download-${index}`}>
            <a
              className="LinkList--link"
              href={url}
              target="_blank"
              rel="noreferrer"
            >
              {label} ({formatType(type || 'application/octet-stream')}, {size})
              <IconSvg
                position={'after'}
                wrapClass={'LinkList--link--icon'}
                icon={`ico-download` as IconKey}
                fill="theme"
              />
            </a>
          </li>
        ))}
      </ul>
    )}
  </FadeUp>
);

export default ParagraphDownloadList;
