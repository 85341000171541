import {
  ContentKitchenSinkFragment,
  JobSearchFiltersQuery,
  JobSearchQuery,
  JobSearchSubscribeMutation,
  OperationExecutorsProvider,
} from '@custom/schema';
import { JobListing } from '@custom/ui/routes/JobListing';
import classNames from 'classnames';
import React, { ReactElement } from 'react';

import {
  transformParagraphAccordionToProps,
  transformParagraphAnchorTagToProps,
  transformParagraphBlockTeaserListToProps,
  transformParagraphCallToActionToProps,
  transformParagraphContentBoxListToProps,
  transformParagraphConversionWidgetToProps,
  transformParagraphCopyTextToProps,
  transformParagraphCustomHtmlToProps,
  transformParagraphCustomMarkupToProps,
  transformParagraphDonationWidgetToProps,
  transformParagraphDownloadListToProps,
  transformParagraphEmbeddedMediaToProps,
  transformParagraphEventModuleToProps,
  transformParagraphFloatingQuoteToProps,
  transformParagraphFromLibraryToProps,
  transformParagraphFullWidthGalleryToProps,
  transformParagraphGalleryToProps,
  transformParagraphIframeToProps,
  transformParagraphImageGridToProps,
  transformParagraphImageToProps,
  transformParagraphInfoBoxListToProps,
  transformParagraphKeyValueTableToProps,
  transformParagraphLinkListToProps,
  transformParagraphLogoBarToProps,
  transformParagraphMapCoordinatesToProps,
  transformParagraphOrthotecGalleryToProps,
  transformParagraphOrthotecKeyValueTableToProps,
  transformParagraphPersonTeaserToProps,
  transformParagraphRaisenowNotificationFormToProps,
  transformParagraphRowToProps,
  transformParagraphSectionToProps,
  transformParagraphStorifyMeToProps,
  transformParagraphStorytellingToProps,
  transformParagraphTeasersToProps,
  transformParagraphTestimonialImageToProps,
  transformParagraphTitleToProps,
  transformParagraphTwoColumnTextImgToProps,
  transformParagraphTwoColumnTextToProps,
  transformParagraphViewsNewsroomTeaserToProps,
  transformParagraphWebformToProps,
} from '../../fragments/mappers';
import { isPublished } from '../../utils';
import { drupalExecutor } from '../../utils/operation';
import {
  ParagraphAnchorTag,
  ParagraphArticleFigure,
  ParagraphArticleTitle,
  ParagraphCallToAction,
  ParagraphCopyText,
  ParagraphCustomHtml,
  ParagraphDownloadList,
  ParagraphEmbeddedMedia,
  ParagraphFloatingQuote,
  ParagraphIframe,
  ParagraphImageGrid,
  ParagraphKeyValueTable,
  ParagraphLinkList,
  ParagraphLogoBar,
  ParagraphMapCoordinates,
  ParagraphMayorisPlaceholder,
  ParagraphOrthotecKeyValueTable,
  ParagraphPersonTeaser,
  ParagraphRaisenowNotificationForm,
  ParagraphRow,
  ParagraphStorytelling,
  ParagraphTwoColumnText,
  ParagraphTwoColumnTextImg,
  ParagraphWebform,
} from '../0-atoms';
import {
  ParagraphBlockTeaserList,
  ParagraphContentBoxList,
  ParagraphConversionWidget,
  ParagraphDonationWidget,
  ParagraphFloatingBox,
  ParagraphFromLibrary,
  ParagraphInfoBoxList,
  ParagraphStorifyMe,
  ParagraphTeaserGrid,
} from '../1-molecules';
import {
  ParagraphAccordion,
  ParagraphEventModule,
  ParagraphFullWidthGallery,
  ParagraphGallery,
  ParagraphOrthotecGallery,
  ParagraphSection,
} from './index';
import ParagraphViewsCompanies from './ParagraphViewsCompanies';
import ParagraphViewsCompaniesParawork from './ParagraphViewsCompaniesParawork';
import ParagraphViewsDoctor from './ParagraphViewsDoctor';
import ParagraphViewsMedicalOffers from './ParagraphViewsMedicalOffers';
import ParagraphViewsNewsroom from './ParagraphViewsNewsroom';
import ParagraphViewsNewsroomTeaser from './ParagraphViewsNewsroomTeaser';

export type ContentProps = {
  content?: ContentKitchenSinkFragment['paragraphs'] | [ReactElement];
  className?: string;
};

function isParagraph(
  paragraph: any,
): paragraph is Exclude<
  ContentKitchenSinkFragment['paragraphs'],
  undefined
>[number] {
  return typeof paragraph === 'object' && paragraph !== null;
}

export const Content: React.FC<ContentProps> = ({ content, className }) => {
  return (
    <>
      {content &&
        content.filter(isPublished).map((paragraph, index) => {
          if (React.isValidElement(paragraph) || !paragraph) {
            return paragraph;
          }
          if (!isParagraph(paragraph)) {
            return null;
          }
          switch (paragraph.__typename) {
            case 'ParagraphCopyText':
              return (
                <ParagraphCopyText
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphCopyTextToProps(paragraph)}
                />
              );
            case 'ParagraphMayorisPlaceholder':
              return (
                <ParagraphMayorisPlaceholder
                  key={`paragraph-${index}`}
                  text={paragraph.text}
                />
              );
            case 'ParagraphCustomMarkup':
              return (
                <ParagraphCopyText
                  key={`paragraph-${index}`}
                  className={className}
                  isCustomMarkupBlock={true}
                  {...transformParagraphCustomMarkupToProps(paragraph)}
                />
              );
            case 'ParagraphImage':
              return (
                <ParagraphArticleFigure
                  key={`paragraph-${index}`}
                  className={classNames(className, paragraph.imageSize)}
                  {...transformParagraphImageToProps(paragraph)}
                />
              );
            case 'ParagraphAnchorTag':
              return (
                <ParagraphAnchorTag
                  key={`paragraph-${index}`}
                  {...transformParagraphAnchorTagToProps(paragraph)}
                />
              );
            case 'ParagraphTitle':
              return (
                <ParagraphArticleTitle
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphTitleToProps(paragraph)}
                />
              );
            case 'ParagraphFloatingQuote':
              return (
                <ParagraphFloatingQuote
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphFloatingQuoteToProps(paragraph)}
                />
              );
            case 'ParagraphCallToAction':
              return (
                <ParagraphCallToAction
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphCallToActionToProps(paragraph)}
                />
              );
            case 'ParagraphLinkList':
              return (
                <ParagraphLinkList
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphLinkListToProps(paragraph)}
                />
              );
            case 'ParagraphTeasers':
              return (
                <ParagraphTeaserGrid
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphTeasersToProps(paragraph)}
                />
              );
            case 'ParagraphAccordion':
              return (
                <ParagraphAccordion
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphAccordionToProps(paragraph)}
                />
              );
            case 'ParagraphBlockTeaserList':
              return (
                <ParagraphBlockTeaserList
                  key={`paragraph-${index}`}
                  {...transformParagraphBlockTeaserListToProps(paragraph)}
                />
              );
            case 'ParagraphItemList':
              return (
                <ParagraphContentBoxList
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphContentBoxListToProps(paragraph)}
                />
              );
            case 'ParagraphDownloadList':
              return (
                <ParagraphDownloadList
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphDownloadListToProps(paragraph)}
                />
              );
            case 'ParagraphVideo':
              return (
                <ParagraphEmbeddedMedia
                  key={`paragraph-${index}`}
                  className={classNames(className, 'EmbeddedMedia')}
                  {...transformParagraphEmbeddedMediaToProps(paragraph)}
                />
              );
            case 'ParagraphTestimonialImage': {
              const { children, ...props } =
                transformParagraphTestimonialImageToProps(paragraph);
              return (
                <ParagraphFloatingBox
                  key={`paragraph-${index}`}
                  className={className}
                  {...props}
                >
                  {children}
                </ParagraphFloatingBox>
              );
            }
            case 'ParagraphGallery':
              return (
                <ParagraphGallery
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphGalleryToProps(paragraph)}
                />
              );
            case 'ParagraphImageGrid':
              return (
                <ParagraphImageGrid
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphImageGridToProps(paragraph)}
                />
              );
            case 'ParagraphFromLibrary':
              return (
                <ParagraphFromLibrary
                  key={`paragraph-${index}`}
                  className={className}
                  langcode={paragraph.reusableParagraph?.langcode}
                  {...transformParagraphFromLibraryToProps(paragraph)}
                />
              );
            case 'ParagraphTable': {
              return (
                <ParagraphKeyValueTable
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphKeyValueTableToProps(paragraph)}
                />
              );
            }
            case 'ParagraphTeamList':
              return (
                <ParagraphPersonTeaser
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphPersonTeaserToProps(paragraph)}
                />
              );
            case 'ParagraphIframe':
              return (
                <ParagraphIframe
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphIframeToProps(paragraph)}
                />
              );
            case 'ParagraphCustomHtml':
              return (
                <ParagraphCustomHtml
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphCustomHtmlToProps(paragraph)}
                />
              );
            case 'ParagraphLogoBar':
              return (
                <ParagraphLogoBar
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphLogoBarToProps(paragraph)}
                />
              );
            case 'ParagraphTestimonialGallery':
              return (
                <ParagraphFullWidthGallery
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphFullWidthGalleryToProps(paragraph)}
                />
              );
            case 'ParagraphOrthotecGallery':
              return (
                <ParagraphOrthotecGallery
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphOrthotecGalleryToProps(paragraph)}
                />
              );
            case 'ParagraphRaiseNowForm':
              return (
                <ParagraphRaisenowNotificationForm
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphRaisenowNotificationFormToProps(
                    paragraph,
                  )}
                />
              );
            case 'ParagraphMap':
              return (
                <ParagraphMapCoordinates
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphMapCoordinatesToProps(paragraph)}
                >
                  {paragraph?.textHtml && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: paragraph?.textHtml,
                      }}
                    />
                  )}
                </ParagraphMapCoordinates>
              );
            case 'ParagraphWebform':
              return (
                <ParagraphWebform
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphWebformToProps(paragraph)}
                />
              );
            case 'ParagraphListingCompanySearch':
              return (
                <ParagraphViewsCompanies
                  key={`paragraph-${index}`}
                  className={className}
                />
              );
            case 'ParagraphListingCompanyParaworkSearch':
              return (
                <ParagraphViewsCompaniesParawork
                  key={`paragraph-${index}`}
                  className={className}
                />
              );
            case 'ParagraphListingDoctorSearch':
              return (
                <ParagraphViewsDoctor
                  key={`paragraph-${index}`}
                  className={className}
                />
              );
            case 'ParagraphListingMedicalOfferSearch':
              return (
                <ParagraphViewsMedicalOffers
                  key={`paragraph-${index}`}
                  className={className}
                />
              );
            case 'ParagraphListingNewsroom':
              return (
                <ParagraphViewsNewsroom
                  key={`paragraph-${index}`}
                  className={className}
                />
              );
            case 'ParagraphTeaserNewsroom':
              return (
                <ParagraphViewsNewsroomTeaser
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphViewsNewsroomTeaserToProps(paragraph)}
                />
              );
            case 'ParagraphCompetencesAccordion':
              return (
                <ParagraphSection
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphSectionToProps(paragraph)}
                />
              );
            case 'ParagraphEventModule':
              return (
                <ParagraphEventModule
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphEventModuleToProps(paragraph)}
                />
              );
            case 'ParagraphConversionWidget':
              return (
                <ParagraphConversionWidget
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphConversionWidgetToProps(paragraph)}
                />
              );
            case 'ParagraphDonationWidget':
              return (
                <ParagraphDonationWidget
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphDonationWidgetToProps(paragraph)}
                />
              );
            case 'ParagraphOrthotecValueTable':
              return (
                <ParagraphOrthotecKeyValueTable
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphOrthotecKeyValueTableToProps(paragraph)}
                />
              );
            case 'ParagraphStorifyMe':
              return (
                <ParagraphStorifyMe
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphStorifyMeToProps(paragraph)}
                />
              );
            case 'ParagraphStorytelling':
              return (
                <ParagraphStorytelling
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphStorytellingToProps(paragraph)}
                />
              );
            case 'ParagraphTwoColumnText':
              return (
                <ParagraphTwoColumnText
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphTwoColumnTextToProps(paragraph)}
                />
              );
            case 'ParagraphTwoColumnTextImage':
              return (
                <ParagraphTwoColumnTextImg
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphTwoColumnTextImgToProps(paragraph)}
                />
              );
            case 'ParagraphInfoBoxList':
              return (
                <ParagraphInfoBoxList
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphInfoBoxListToProps(paragraph)}
                />
              );
            case 'ParagraphRow':
              return (
                <ParagraphRow
                  key={`paragraph-${index}`}
                  className={className}
                  {...transformParagraphRowToProps(paragraph)}
                />
              );
            case 'ParagraphJobs':
              return (
                <OperationExecutorsProvider
                  executors={[
                    {
                      id: JobSearchQuery,
                      executor: drupalExecutor,
                    },
                    {
                      id: JobSearchFiltersQuery,
                      executor: drupalExecutor,
                    },
                    {
                      id: JobSearchSubscribeMutation,
                      executor: drupalExecutor,
                    },
                  ]}
                >
                  <JobListing
                    key={`paragraph-${index}`}
                    preselectedFilters={paragraph.preselectedFilters}
                    unpublished={paragraph.unpublished}
                  />
                </OperationExecutorsProvider>
              );
            default:
              return (
                <div
                  key={`not-implemented-${index}`}
                  className="visuallyhidden"
                >{`${paragraph.__typename} is not implemented yet.`}</div>
              );
          }
        })}
    </>
  );
};

export default Content;
