import { Locale, locales } from '../../i18n';
import { DOMAINS } from '../constants';
import { Domain } from '../types';
import { buildUrl } from './buildUrl';
import { isInternalUrl } from './isInternalUrl';
import { isRelativeUrl } from './isRelativeUrl';

const localeKeys = Object.keys(locales);
const domainKeys = Object.values(DOMAINS)
  .map((domain) => domain.pathPrefix?.replace(/^\//, ''))
  .filter((key) => key && key.length > 0);

export const getLocalizedUrl = ({
  to,
  locale,
  domain,
  hash,
}: {
  to: string;
  locale?: Locale;
  domain?: Domain;
  hash?: string;
}) => {
  const segments = to.split('/').filter((seg) => seg.length > 0);

  // If there are no segments or its an internal path, we pass it through without gatsby link handling.
  if (isInternalUrl(to) || !isRelativeUrl(to)) {
    return { url: to, gatsby: false };
  }

  // Split the path into potential language and domain prefixes.
  let path: string[];
  let domainPrefix: string | undefined;
  let localePrefix: string | undefined;

  if (domainKeys.includes(segments[0]) && localeKeys.includes(segments[1])) {
    [domainPrefix, localePrefix, ...path] = segments;
  } else if (localeKeys.includes(segments[0])) {
    [localePrefix, ...path] = segments;
  } else {
    path = segments;
  }

  if (
    (path.length === 0 || path[0] !== 'node') &&
    !localePrefix &&
    !domainPrefix
  ) {
    domainPrefix = domain?.pathPrefix?.replace(/^\//, '');
    localePrefix = locale;
  }

  let homePagePath = DOMAINS['paraplegie_ch'].homepagePath;
  if (domain) {
    homePagePath = domain.homepagePath;
  }
  const homePageSegment = homePagePath.replace('/', '');

  return {
    // Build the url and replace languages if explicitly required.
    url: [
      buildUrl([
        '/',
        domainPrefix,
        localePrefix,
        ...path.filter((seg) => seg !== homePageSegment),
      ]),
      hash,
    ].join(''),
    // If the path has no locale prefix, it's not a reliable
    // one and should not be enhanced by Gatsby.
    gatsby: !!localePrefix || path.length === 0,
  };
};
