import { ParagraphTeamListFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { getImageSrc, isPublished } from '../../utils';
import { isTruthy } from '../../utils/isTruthy';
import { getImageStyleUrl, transformImageFragmentToProps } from './MediaImage';

export const transformParagraphPersonTeaserToProps = (
  paragraph: ParagraphTeamListFragment,
) => ({
  title: paragraph.teamTitle,
  tag: paragraph?.headingLevel || 'h3',
  hasBackground: paragraph?.hasBackground,
  persons:
    paragraph?.teamList?.filter(isPublished).map((person) => {
      const paragraphImage = person?.image;
      const imageStyle = 'person_teaser';

      const image = paragraphImage ? (
        <Image
          {...transformImageFragmentToProps(paragraphImage, [imageStyle])}
          src={getImageSrc(
            paragraphImage,
            `${getImageStyleUrl(paragraphImage, imageStyle)}`,
          )}
          fit
        />
      ) : undefined;

      return {
        name: person?.name,
        job: person?.job,
        email: person?.email,
        telephone: person?.telephone,
        fax: person?.fax,
        image,
        content:
          person?.__typename === 'ParagraphTeamListItemExtended'
            ? person?.teamListItemContent?.filter(isPublished).filter(isTruthy)
            : undefined,
      };
    }) || [],
});
