import React from 'react';

/**
 * This component should be as pure as possible:
 * - minimum styles
 * - no effects
 * - no wrappers
 */
export const ParagraphMayorisPlaceholder: React.FC<{
  text?: string;
}> = ({ text }) => <div className="ArticleText">{text}</div>;

export default ParagraphMayorisPlaceholder;
