import React from 'react';

export type IconFooterFacebookProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterFacebook: React.FC<IconFooterFacebookProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="11.406"
    height="24.741"
    viewBox="0 0 11.406 24.741"
  >
    <path
      fill={fill}
      d="M10.947 12.347H7.564v12.39H2.438v-12.39H0V7.991h2.438V5.172C2.438 3.156 3.396 0 7.609 0l3.8.016v4.228H8.651a1.043 1.043 0 00-1.087 1.187v2.564h3.831z"
    />
  </svg>
);
