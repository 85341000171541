import {
  ContentFirstLevelFragment,
  ParagraphHomeSliderItemFragment,
  ParagraphSliderFragment,
  ParagraphSliderImageFragment,
  ParagraphTeasersFragment,
} from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { HeroButton } from '../../components/2-organisms';
import { FirstLevelPageProps } from '../../components/3-layouts';
import { getImageSrc } from '../../utils';
import { getFundingWidgetPropsFromFragment } from '../../utils/fundingWidget';
import { getImageStyleUrl } from './MediaImage';
import { transformParagraphHeaderSliderToProps } from './ParagraphSlider';
import { transformParagraphTeasersToProps } from './ParagraphTeasers';

const getTeaserPropFromFragment = (page: ContentFirstLevelFragment) =>
  page?.teaserGrid
    ? transformParagraphTeasersToProps(
        page?.teaserGrid as ParagraphTeasersFragment,
      )
    : undefined;

function isHeaderSlider(
  slider: ContentFirstLevelFragment['headerSlider'],
): slider is ParagraphSliderFragment {
  return slider?.['__typename'] === 'ParagraphSlider';
}

const getSliderPropFromFragment = (page: ContentFirstLevelFragment) =>
  isHeaderSlider(page?.headerSlider) && page?.headerSlider?.items
    ? {
        ...transformParagraphHeaderSliderToProps(page?.headerSlider),
        heroButton: (
          <HeroButton
            type={page.heroButtonType}
            url={page.heroButtonLink?.url}
          />
        ),
      }
    : undefined;

const getFundingWidgetPropsFromPage = (page: ContentFirstLevelFragment) => {
  if (!page?.donationPaymentPage) {
    return undefined;
  }
  return getFundingWidgetPropsFromFragment(page.donationPaymentPage);
};

function isImageSlide(
  slide?: ParagraphSliderImageFragment | ParagraphHomeSliderItemFragment | null,
): slide is ParagraphSliderImageFragment {
  return slide?.__typename === 'ParagraphSliderImage';
}

const getFirstSliderImageFromFragment = (page: ContentFirstLevelFragment) => {
  if (isHeaderSlider(page?.headerSlider)) {
    const slide = page?.headerSlider?.items?.[0];
    return isImageSlide(slide) ? slide : undefined;
  }
  return undefined;
};

export const transformFirstLevelPageFragmentToFirstLevelPageProps = (
  page: ContentFirstLevelFragment,
): FirstLevelPageProps => {
  if (!page) {
    return {};
  }
  const teaser = getTeaserPropFromFragment(page);
  const slider = getSliderPropFromFragment(page);
  const fundingWidget = getFundingWidgetPropsFromPage(page);
  const firstImageSlide = getFirstSliderImageFromFragment(page);
  const firstSliderImage = firstImageSlide?.image;
  const title = firstImageSlide?.imageTitle;

  const desktopWideStyleUrl = getImageStyleUrl(
    firstSliderImage,
    'desktop_wide',
  );
  const desktopStdStyleUrl = getImageStyleUrl(
    firstSliderImage,
    'desktop_standard',
  );
  const isCropped = desktopWideStyleUrl !== null || desktopStdStyleUrl !== null;

  const image = firstSliderImage ? (
    <Image
      alt={firstSliderImage?.alt || title}
      filemime={firstSliderImage?.mimeType}
      src={
        isCropped
          ? getImageSrc(
              firstSliderImage,
              `${desktopWideStyleUrl} 2000w,
            ${getImageStyleUrl(firstSliderImage, 'desktop_standard')} 1440w,
            ${getImageStyleUrl(firstSliderImage, 'mobile')} 750w`,
            )
          : getImageSrc(
              firstSliderImage,
              page.hideFundingBanner
                ? `${getImageStyleUrl(firstSliderImage, 'header_image')} 2000w, 
                 ${getImageStyleUrl(
                   firstSliderImage,
                   'header_slider_image_responsive_',
                 )} 750w`
                : `${getImageStyleUrl(firstSliderImage, 'header_image')} 2000w, 
                 ${getImageStyleUrl(
                   firstSliderImage,
                   'header_slider_image_1280_x_540_crop',
                 )} 750w`,
            )
      }
      className="HeaderHero--background--img"
      sizes="(max-width: 750px) 50vw, 100vw"
      fit
      loading="eager"
    />
  ) : undefined;

  return {
    title,
    description: isHeaderSlider(page.headerSlider)
      ? page.headerSlider?.items?.[0]?.descriptionHtml
      : undefined,
    image,
    slider,
    teaser,
    fundingWidget,
    hideFundingBanner: page.hideFundingBanner,
    showMembershipWidget: page.showMembershipBox,
    showGiftMembershipWidget: page.showGiftMembershipBox,
    showDonationWidget: page.showDonationWidget,
    hideCtasHeader: page.hideCallToActionInHeader,
  };
};
