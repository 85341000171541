import { ContentKitchenSinkFragment } from '@custom/schema';
import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode, useState } from 'react';

import { Content } from '../2-organisms';
import { IconKey, IconSvg } from './IconSvg';

export type PersonTeaserProps = PropsWithChildren<{
  name?: string;
  image?: string | ReactNode;
  content?: ContentKitchenSinkFragment['paragraphs'];
  expandableLabel?: string;
  className?: string;
  link?: string;
}>;

export const PersonTeaser: React.FC<PersonTeaserProps> = ({
  name,
  image,
  content,
  expandableLabel = 'More',
  className,
  children,
  link,
}) => {
  const [isActive, setIsActive] = useState(false);
  const hasLink = Boolean(link);

  return (
    <div
      className={classNames('PersonTeaser', className, {
        'is-opened': isActive,
        'has-expandButton': content?.length,
      })}
    >
      <div className="PersonTeaser--inner">
        {image && hasLink && (
          <a href={link}>
            <div className="PersonTeaser--imgWrapper">
              {typeof image === 'string' ? (
                <img src={image} alt={name} loading="lazy" />
              ) : (
                image
              )}
            </div>
          </a>
        )}
        {image && !hasLink && (
          <div className="PersonTeaser--imgWrapper">
            {typeof image === 'string' ? (
              <img src={image} alt={name} loading="lazy" />
            ) : (
              image
            )}
          </div>
        )}
        <div className="PersonTeaser--body">
          <div
            className="PersonTeaser--body--inner"
            onClick={() => (content?.length ? setIsActive(!isActive) : null)}
          >
            {link && (
              <a href={link} className="PersonTeaser--link">
                <h3 className="PersonTeaser--title">
                  {name}
                  <IconSvg
                    position={'inline'}
                    wrapClass={'section--icon'}
                    icon={`ico-bolt-right` as IconKey}
                  />
                </h3>
              </a>
            )}
            {!link && <h3 className="PersonTeaser--title">{name}</h3>}
            <div className="PersonTeaser--text">{children}</div>
          </div>
          {content?.length ? (
            <div className="PersonTeaser--expandButton--wrapper">
              <button
                className={classNames('PersonTeaser--expandButton', {
                  'is-opened': isActive,
                })}
                aria-expanded="false"
                aria-haspopup="true"
                type="button"
                onClick={() => setIsActive(!isActive)}
              >
                <span className="visuallyhidden">{expandableLabel}</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {content?.length ? (
        <div
          className={classNames('PersonTeaser--expanded', {
            'is-opened': isActive,
          })}
        >
          <Content content={content} className={className} />
        </div>
      ) : null}
    </div>
  );
};
