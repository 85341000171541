import { useTranslation } from 'react-i18next';

export const useMonthNames = () => {
  const { t } = useTranslation();
  return [
    {
      id: '01',
      label: t('January'),
    },
    {
      id: '02',
      label: t('February'),
    },
    {
      id: '03',
      label: t('March'),
    },
    {
      id: '04',
      label: t('April'),
    },
    {
      id: '05',
      label: t('May'),
    },
    {
      id: '06',
      label: t('June'),
    },
    {
      id: '07',
      label: t('July'),
    },
    {
      id: '08',
      label: t('August'),
    },
    {
      id: '09',
      label: t('September'),
    },
    {
      id: '10',
      label: t('October'),
    },
    {
      id: '11',
      label: t('November'),
    },
    {
      id: '12',
      label: t('December'),
    },
  ];
};
