import React from 'react';

export type IconHeartSmallProps = {
  className?: string;
  fill?: string | '#fff';
};

export const IconHeartSmall: React.FC<IconHeartSmallProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32.47 28.537"
  >
    <path
      d="M129.08,2.873a8.467,8.467,0,0,0-13.432.944c-.134.187-.259.374-.377.56-.117-.185-.243-.373-.377-.56a8.467,8.467,0,0,0-13.433-.944,9.909,9.909,0,0,0-2.426,6.618c0,2.727,1.058,5.244,3.33,7.92a71.912,71.912,0,0,0,8.119,7.514c1.217,1.027,2.475,2.089,3.812,3.248a1.486,1.486,0,0,0,1.947,0c1.337-1.159,2.595-2.221,3.812-3.247,2.115-1.785,3.941-3.326,5.526-4.822,3.077-2.9,5.924-6.2,5.924-10.614A9.909,9.909,0,0,0,129.08,2.873Zm-5.538,15.07c-1.525,1.439-3.322,2.956-5.4,4.712-.926.781-1.875,1.583-2.868,2.434-.993-.851-1.942-1.653-2.868-2.434-6.482-5.471-10.394-8.773-10.394-13.163,0-3.716,2.531-6.519,5.888-6.519a5.469,5.469,0,0,1,4.533,2.509,10,10,0,0,1,1.409,2.792,1.486,1.486,0,0,0,2.865,0c.015-.053,1.519-5.3,5.942-5.3,3.357,0,5.888,2.8,5.888,6.519C128.534,12.184,127.134,14.554,123.542,17.943Z"
      transform="translate(-99.036)"
      fill={fill}
    />
  </svg>
);
