import React from 'react';

export type IconTelephoneSmallProps = {
  className?: string;
};

export const IconTelephoneSmall: React.FC<IconTelephoneSmallProps> = ({
  className,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 24"
  >
    <g transform="translate(-4)">
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(9.5 6)"
        fill="#159687"
      />
      <path
        d="M8.57,16h6.86a1.12,1.12,0,0,0,1.07-1.16v-.32a2.3,2.3,0,0,0-1.08-2,6.94,6.94,0,0,0-3.42-1,6.94,6.94,0,0,0-3.42,1,2.3,2.3,0,0,0-1.08,2v.32A1.12,1.12,0,0,0,8.57,16ZM11.5,4h1a.5.5,0,0,0,0-1h-1a.5.5,0,0,0,0,1Z"
        fill="#159687"
      />
      <path
        d="M18,0H6A2,2,0,0,0,4,2V22a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V2A2,2,0,0,0,18,0ZM13.5,22h-3a.5.5,0,0,1,0-1h3a.5.5,0,0,1,0,1ZM18,19H6V2H18Z"
        fill="#159687"
      />
      <circle
        cx="0.5"
        cy="0.5"
        r="0.5"
        transform="translate(16 3)"
        fill="#159687"
      />
    </g>
  </svg>
);
