import classNames from 'classnames';
import DangerouslySetHtmlContent from 'dangerously-set-html-content';
import React, { useEffect, useState } from 'react';

import { ConditionalWrapper } from '../../utils/conditionalContainer';
import { processWysiwyg } from '../../utils/processWysiwyg';
import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphCopyTextProps = {
  text?: string;
  className?: string;
  hasBackground?: boolean;
  children?: React.ReactNode;
  isCustomMarkupBlock?: boolean;
};

export const ParagraphCopyText: React.FC<ParagraphCopyTextProps> = ({
  text = '',
  className,
  children,
  hasBackground,
  isCustomMarkupBlock,
}) => {
  const [isFrontend, setIsFrontend] = useState(false);
  useEffect(() => {
    setIsFrontend(true);
  }, []);

  const needsSpamProtection = isCustomMarkupBlock && text?.includes('<form');

  if (needsSpamProtection && !isFrontend) {
    // No server side rendering.
    return null;
  }

  return text ? (
    <ConditionalWrapper
      condition={hasBackground}
      wrapper={(children: any) => (
        <div className="ArticleText has-background">
          <FadeUp yGap={20}>
            <div className="hasBackground--wrapper">{children}</div>
          </FadeUp>
        </div>
      )}
    >
      <FadeUp yGap={20}>
        {needsSpamProtection ? (
          // For the spam protected forms, we use dangerously-set-html-content
          // as it can execute script tags on the frontend (unlike React's
          // dangerouslySetInnerHTML).
          <DangerouslySetHtmlContent
            className={classNames('use-formatted-links', className, {
              ArticleText: !hasBackground,
            })}
            html={processWysiwyg(text)}
          />
        ) : (
          // For the rest, we use the standard element. Because
          // dangerously-set-html-content does not support SSR.
          <div
            className={classNames('use-formatted-links', className, {
              ArticleText: !hasBackground,
            })}
            dangerouslySetInnerHTML={{ __html: processWysiwyg(text) }}
          />
        )}
      </FadeUp>
    </ConditionalWrapper>
  ) : (
    <ConditionalWrapper
      condition={hasBackground}
      wrapper={(children: any) => (
        <div className="ArticleText has-background">
          <FadeUp yGap={20}>{children}</FadeUp>
        </div>
      )}
    >
      <FadeUp yGap={20}>
        <div
          className={classNames(className, {
            ArticleText: !hasBackground,
          })}
        >
          {children}
        </div>
      </FadeUp>
    </ConditionalWrapper>
  );
};

export default ParagraphCopyText;
