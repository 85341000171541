import React from 'react';

export type IconFooterInstagramProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterInstagram: React.FC<IconFooterInstagramProps> = ({
  className,
}) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    viewBox="0 0 28 28"
    version="1.1"
  >
    <g transform="matrix(1.23844,0,0,1.23844,0,0)">
      <g>
        <path d="M15.544,0L7.065,0C3.189,0.001 0.001,3.189 0,7.065L0,15.544C0.001,19.42 3.189,22.608 7.065,22.609L15.544,22.609C19.42,22.608 22.608,19.42 22.609,15.544L22.609,7.065C22.608,3.189 19.42,0.001 15.544,-0ZM20.49,15.544C20.487,18.256 18.256,20.487 15.544,20.49L7.065,20.49C4.353,20.487 2.123,18.256 2.12,15.544L2.12,7.065C2.123,4.353 4.353,2.123 7.065,2.12L15.544,2.12C18.256,2.123 20.487,4.353 20.49,7.065L20.49,15.544Z" />
      </g>
    </g>
    <g transform="matrix(1.23844,0,0,1.23844,0,0)">
      <g>
        <path d="M11.304,5.652C8.203,5.652 5.652,8.203 5.652,11.304C5.652,14.405 8.203,16.956 11.304,16.956C14.405,16.956 16.956,14.405 16.956,11.304C16.955,8.204 14.404,5.653 11.304,5.652ZM11.304,14.837C9.366,14.837 7.771,13.242 7.771,11.304C7.771,9.366 9.366,7.771 11.304,7.771C13.242,7.771 14.837,9.366 14.837,11.304C14.834,13.241 13.241,14.834 11.304,14.837Z" />
      </g>
    </g>
    <g transform="matrix(1.23844,0,0,1.23844,20.5929,5.54204)">
      <g>
        <circle cx="0.753" cy="0.753" r="0.753" />
      </g>
    </g>
  </svg>
);
