import classNames from 'classnames';
import { decode } from 'he';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { IconKey, IconSvg } from '../0-atoms';

export type BlockTeaserProps = {
  url: string;
  target?: string;
  title?: string;
  subtitle?: string;
  description?: string | ReactNode;
  additionalPriceInfo?: string;
  linkLabel?: string;
  threeInARow?: boolean;
  side?: string;
  image?: string | ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
};

const formatTitle = (title?: string, subtitle?: string) => {
  const parts = [
    title ? decode(title) : undefined,
    subtitle ? `<em>${decode(subtitle)}</em>` : undefined,
  ];
  return parts.filter((p) => Boolean(p)).join('<br />');
};

export const BlockTeaser: React.FC<BlockTeaserProps> = ({
  url,
  target,
  title = '',
  subtitle = '',
  description = '',
  additionalPriceInfo,
  linkLabel,
  threeInARow = false,
  side,
  image,
  onClick,
  className,
}) => {
  const { Link } = useFrameworkDependencies();
  const { t } = useTranslation();
  return (
    <Link
      to={url}
      title={linkLabel}
      target={target}
      onClick={onClick}
      className={classNames(className, {
        MembershipTeaser: threeInARow,
        BlockTeaser: !threeInARow,
        imgFirst: side === 'right',
        noImg: !image,
      })}
    >
      {threeInARow ? (
        <div>
          {image && (
            <div className="MembershipTeaser--image">
              {typeof image === 'string' ? (
                <img alt={title} title={title} src={image} loading="lazy" />
              ) : (
                image
              )}
            </div>
          )}
          <div className="MembershipTeaser--content">
            <div className="MembershipTeaser--pricerow">
              {subtitle && (
                <span
                  className="MembershipTeaser--pricerow--price"
                  dangerouslySetInnerHTML={{
                    __html: decode(subtitle),
                  }}
                />
              )}
              {additionalPriceInfo && (
                <span
                  className="MembershipTeaser--pricerow--info"
                  dangerouslySetInnerHTML={{ __html: additionalPriceInfo }}
                />
              )}
            </div>
            <div className="MembershipTeaser--text">
              <h3 className="MembershipTeaser--title">{title}</h3>
              {typeof description === 'string' ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              ) : (
                <div>{description}</div>
              )}
            </div>
          </div>
          <div className="MembershipTeaser--footer">
            <span className="Button icon-after icon-absolute white MembershipTeaser--cta">
              <span className="Button--text">{linkLabel || t('More')}</span>
              <i className="absolute after ico ico-bolt-right-red" />
            </span>
          </div>
        </div>
      ) : (
        <div className="BlockTeaser--contentWrapper">
          <div className="BlockTeaser--content">
            <div className="BlockTeaser--body">
              <h3
                className="BlockTeaser--title"
                dangerouslySetInnerHTML={{
                  __html: formatTitle(title, subtitle),
                }}
              />
              {typeof description === 'string' ? (
                <div
                  className="BlockTeaser--paragraph"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ) : (
                <div className="BlockTeaser--paragraph">{description}</div>
              )}
              {url && (
                <div className="BlockTeaser--button">
                  {linkLabel || t('More')}
                  <IconSvg
                    position={'inline'}
                    wrapClass={'Teaser--link--icon'}
                    icon={`ico-arrow-right` as IconKey}
                    transition
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {image && !threeInARow && (
        <div className="BlockTeaser--imgWrapper">
          <div className="BlockTeaser--imgWrapper--inner">
            {typeof image === 'string' ? (
              <img
                className="BlockTeaser--img"
                alt={title}
                title={title}
                src={image}
                loading="lazy"
              />
            ) : (
              image
            )}
          </div>
        </div>
      )}
    </Link>
  );
};
