import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import {
  usePager,
  useSearchResultsFilters,
  useSearchResultsQuery,
} from '../../hooks';
import { Loader } from '../0-atoms';
import { SearchResultForm, SearchResultList } from '../1-molecules';
import { Pager } from './Pager';

export type SearchResultsViewProps = {
  itemsPerPage?: number;
  className?: string;
};

export const SearchResultsView: React.FC<SearchResultsViewProps> = ({
  itemsPerPage = 10,
  className,
}) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const { domain, search } = useSearchResultsFilters();
  const { currentPage } = usePager();

  const { data: searchResults, isLoading } = useSearchResultsQuery({
    page: currentPage,
    language: locale,
    domain,
    search,
  });

  return (
    <div
      className={classNames(className, {
        'Pane is-loading relative': isLoading,
      })}
    >
      {isLoading && <Loader />}
      <SearchResultForm
        title={
          search
            ? t('Search for <strong>{{term}}</strong>', { term: search })
            : t('Search')
        }
        resultsCount={searchResults?.pager.total_items}
      />

      {search && !!searchResults?.rows?.length && (
        <SearchResultList
          results={searchResults.rows.map(
            ({ title_1, search_api_excerpt, url }) => ({
              title: title_1,
              excerpt: search_api_excerpt,
              url,
            }),
          )}
        />
      )}
      {searchResults && searchResults.pager.total_items > itemsPerPage ? (
        <Pager
          itemsCount={searchResults.pager.total_items}
          itemsPerPage={itemsPerPage}
        />
      ) : null}
    </div>
  );
};
