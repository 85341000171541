import { ContentDoctorFragment, ParagraphRow } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { Doctor as DoctorType } from '../../types';
import { getImageSrc } from '../../utils';
import { isTruthy } from '../../utils/isTruthy';
import { getImageStyleUrl } from './MediaImage';

export const transformDoctorFragmentToDoctor = (
  doctor: ContentDoctorFragment,
): DoctorType => {
  const bannerImage = doctor?.bannerImage;

  const desktopWideStyleUrl = getImageStyleUrl(bannerImage, 'desktop_wide');
  const desktopStdStyleUrl = getImageStyleUrl(bannerImage, 'desktop_standard');
  const isCropped = desktopWideStyleUrl !== null || desktopStdStyleUrl !== null;

  const image = bannerImage ? (
    <Image
      className="HeaderHero--background--img"
      sizes="(max-width: 750px) 50vw, 100vw"
      filemime={bannerImage?.mimeType}
      src={
        isCropped
          ? getImageSrc(
              bannerImage,
              `${desktopWideStyleUrl} 2000w,
            ${getImageStyleUrl(bannerImage, 'desktop_standard')} 1440w,
            ${getImageStyleUrl(bannerImage, 'mobile')} 750w`,
            )
          : getImageSrc(
              bannerImage,
              `${getImageStyleUrl(bannerImage, 'header_slider')} 2000w,
            ${getImageStyleUrl(
              bannerImage,
              'header_slider_image_responsive_',
            )} 750w`,
            )
      }
      alt={bannerImage?.alt || doctor.title}
      fit
      loading="eager"
    />
  ) : undefined;

  return {
    id: doctor.id,
    drupalId: doctor.id,
    path: doctor.path,
    firstName: doctor.firstName,
    lastName: doctor.lastName,
    job: doctor.job,
    additionalInformation: doctor.additionalInformation,
    specialistDepartment: doctor?.specialistDepartment || '',
    specialistTeaserTitle: doctor?.specialistTeaserTitle,
    specialistLongTitle: doctor?.specialistLongTitle,
    treatmentAreas: doctor?.treatmentAreas,
    email: doctor.email,
    fax: doctor.fax,
    telephone: doctor.telephone,
    doctorAdditionalFields: doctor?.additionalFields
      ?.filter(isTruthy)
      .map((field) => {
        return {
          id: field?.id || '0',
          name: field?.title || '',
        };
      }),
    field: doctor?.mainField
      ? {
          id: doctor?.mainField?.id || '0',
          name: doctor?.mainField?.title || '',
        }
      : undefined,
    doctorFunction: doctor?.doctorFunction
      ? {
          id: doctor?.doctorFunction?.id || '0',
          title: doctor?.doctorFunction?.title || '',
        }
      : undefined,
    teaserImage: getImageStyleUrl(doctor.teaserImage, 'person_teaser'),
    bannerImage: image,
    professionalCareer: (doctor.professionalCareer as ParagraphRow[]) || [],
    trainingEducation: (doctor.trainingEducation as ParagraphRow[]) || [],
    memberships: doctor.memberships.filter(isTruthy),
    publications: doctor.publications.filter(isTruthy),
  };
};
