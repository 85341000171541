import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalization } from '../../hooks';
import { IconKey, IconSvg } from '../0-atoms';
import { FadeUp } from '../1-molecules/FadeIn';

export type FundingWidgetProps = {
  amounts: number[];
  paymentUrl: string;
  className?: string;
  displayMode?: string;
  inHeader?: boolean;
};

export const FundingWidget: React.FC<FundingWidgetProps> = ({
  amounts,
  paymentUrl,
  className,
  displayMode,
  inHeader = false,
}) => {
  const { t } = useTranslation();
  const [selectedAmount, setSelectedAmount] = useState<
    'custom' | number | undefined
  >();
  const [customAmount, setCustomAmount] = useState<string | undefined>('');
  const [isVisible, setIsVisible] = useState(false);
  const widgetDisplayMode = displayMode || 'compact';
  const dataLayerWidgetDisplayMode = displayMode
    ? displayMode
    : inHeader
    ? 'header'
    : 'compact';
  const randomKey = Math.random().toString(36).substring(7);
  const { locale } = useLocalization();
  const formFund = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Send a dataLayer push when the component becomes visible on the screen.
    if (isVisible) {
      const dataLayerOnView = {
        event: 'conversionwidget_view',
        goal: 'funding',
        format: dataLayerWidgetDisplayMode,
        lang: locale, // Replace 'en' with your current page language.
      };

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerOnView);
    }
  }, [dataLayerWidgetDisplayMode, isVisible, locale]);

  useEffect(() => {
    if (!formFund.current) {
      return;
    }
    // Create an Intersection Observer to track component visibility.
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { rootMargin: '0px' }, // You can adjust the root margin if needed.
    );

    // Start observing the component's root element.
    observer.observe(formFund.current);

    // Cleanup the observer when the component unmounts.
    return () => observer.disconnect();
  }, []);

  // Validate that the input to only contains numbers.
  const validateNumbersOnly = (e: React.ChangeEvent<HTMLInputElement>) => {
    const originalValue = e.target.value;
    const value = originalValue.replace(/\D/g, '');
    setCustomAmount(value);

    // @todo: Add a visual error message.
    if (originalValue !== value) {
      // console.log('Only numbers allowed');
    }
  };

  return (
    <div
      className={classNames(
        'FundingWidget',
        'FormCard--wrapper',
        `FormCard--wrapper__${widgetDisplayMode}`,
      )}
      ref={formFund}
    >
      <FadeUp yGap={20}>
        <form
          action={paymentUrl}
          className={classNames(
            'FormCard',
            className,
            `FormCard__${widgetDisplayMode}`,
          )}
          onSubmit={() => {
            const dataLayerOnSubmit = {
              event: 'conversionwidget_submit',
              goal: 'funding',
              format: dataLayerWidgetDisplayMode,
              lang: locale,
              amount: selectedAmount,
            };
            // Send the selected amount to the dataLayer before submitting the form.
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayerOnSubmit);
          }}
          method="GET"
        >
          <div className="FormCard--body">
            <div className="FormCard--header">
              {widgetDisplayMode === 'compact' && (
                <>
                  <h2
                    className="FormCard--title"
                    dangerouslySetInnerHTML={{
                      __html: t('Each <em>Donation</em> counts'),
                    }}
                  />
                  <p className="FormCard--note">
                    {t(
                      'No matter the amount, you make an important contribution to paraplegics.',
                    )}
                  </p>
                </>
              )}
              {widgetDisplayMode === 'full_width' && (
                <>
                  <h2 className="ArticleTitle--title h3">
                    {t('Each Donation counts')}
                  </h2>
                  <h3
                    className="h1"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'Donations for people with spinal cord injuries',
                      ),
                    }}
                  />
                  <p>
                    {t(
                      'No matter the amount, you make an important contribution to paraplegics.',
                    )}
                  </p>
                </>
              )}
            </div>
            <div className="FormCard--actions">
              <p className="visuallyhidden">{t('Choose donation amount')}</p>
              <ul className="FormCard--list">
                {amounts &&
                  amounts.map((value, index) => (
                    <li
                      key={value}
                      className={classNames(
                        'FormCard--list--option',
                        selectedAmount === value
                          ? 'FormCard--list--option__selected'
                          : '',
                      )}
                    >
                      <div className="FormCard--option">
                        <input
                          className="FormCard--option--input"
                          id={`amount-${index}-${randomKey}`}
                          name="amountu1"
                          type="radio"
                          value={value}
                          checked={selectedAmount === value}
                          onChange={() => setSelectedAmount(value)}
                        />
                        <label
                          className="FormCard--option--label"
                          htmlFor={`amount-${index}-${randomKey}`}
                        >
                          <div className="FormCard--option--label--details">
                            <div className="FormCard--option--amount">
                              <span className="FormCard--option--amount--currency">
                                {t('CHF')}
                              </span>
                              <span className="FormCard--option--amount--value">
                                {value}
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </li>
                  ))}
                <li
                  className={classNames(
                    'FormCard--list--option',
                    selectedAmount === 'custom'
                      ? 'FormCard--list--option__selected'
                      : '',
                  )}
                >
                  <div className="FormCard--option">
                    <input
                      className="FormCard--option--input"
                      id={`amount-custom-${randomKey}`}
                      name="amountu_custom"
                      type="radio"
                      value="custom"
                      checked={selectedAmount === 'custom'}
                      onChange={() => setSelectedAmount('custom')}
                    />
                    <label
                      className="FormCard--option--label"
                      htmlFor={`amount-custom-${randomKey}`}
                    >
                      <span className="visuallyhidden">
                        {t('Custom amount')}
                      </span>
                      <div className="FormCard--option--label--details FormCard--option--label--details--input">
                        <div className="FormCard--option--amount">
                          <span className="FormCard--option--amount--currency">
                            {t('CHF')}
                          </span>
                          <input
                            className="FormCard--option--amount--input"
                            id={`amount-custom-input-${randomKey}`}
                            name="amount_custom"
                            type="text"
                            placeholder="..."
                            value={customAmount}
                            maxLength={5}
                            onChange={(e) => validateNumbersOnly(e)}
                            onFocus={() => setSelectedAmount('custom')}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </li>
                <li className="FormCard--list--submit">
                  <div className="FormCard--submit">
                    <button
                      className={classNames(
                        'FormCard--button',
                        'FormCard--button-submit',
                      )}
                      type="submit"
                    >
                      <IconSvg
                        position={'inline'}
                        wrapClass={'icon--before'}
                        icon={`ico-heart-small` as IconKey}
                      />
                      <span className="FormCard--button-submit--text">
                        {t('Donate now - short')}
                      </span>
                    </button>
                  </div>
                </li>
                {widgetDisplayMode === 'full_width' && (
                  <li className="FormCard--list--secure">
                    <div className="FormCard--secure--wrapper">
                      <div className="FormCard--secure">
                        <IconSvg
                          wrapClass={'icon--before'}
                          icon={`ico-lock` as IconKey}
                        />
                        <p>{t('Your transaction is secure')}</p>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </form>
      </FadeUp>
    </div>
  );
};
