import { ParagraphTableFragment } from '@custom/schema';

import { isPublished } from '../../utils';

export const transformParagraphKeyValueTableToProps = (
  paragraph: ParagraphTableFragment,
) => ({
  rows:
    paragraph?.rows?.filter(isPublished).map((row) => ({
      key: row?.key || '',
      value: row?.value || '',
    })) || [],
});
