import classNames from 'classnames';
import React from 'react';

export type HeartClickProps = {
  className?: string;
};

export const HeartClick: React.FC<HeartClickProps> = ({ className }) => (
  <div className={classNames('HeartClick--heart', className)}>
    <img src="/img/svgs/heart.svg" alt="HeartClick" />
  </div>
);
