import React from 'react';

export type IconEmailSmallProps = {
  className?: string;
};

export const IconEmailSmall: React.FC<IconEmailSmallProps> = ({
  className,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 12.173"
  >
    <g transform="translate(-2 -123)">
      <path
        d="M49.78,193.112a1.51,1.51,0,0,1-1.095.474h-.019a1.5,1.5,0,0,1-1.1-.5L42,187.7v8.314c0,.364.188.574.3.574H55.192c.11,0,.3-.207.3-.574v-8.129Z"
        transform="translate(-38.745 -62.67)"
        fill="none"
      />
      <path
        d="M78.6,168l.019.022a.251.251,0,0,0,.191.091A.257.257,0,0,0,79,168.03l.019-.022.022-.019,5.754-5.261-5.936.009L73.1,162.7Z"
        transform="translate(-68.869 -38.455)"
        fill="none"
      />
      <path
        d="M16.438,123.016,10,123.031,3.556,123h0A1.7,1.7,0,0,0,2,124.829v8.515a1.7,1.7,0,0,0,1.553,1.829H16.447A1.7,1.7,0,0,0,18,133.344v-8.515A1.68,1.68,0,0,0,16.438,123.016Zm-.505,1.255-5.754,5.261-.022.019-.019.022a.257.257,0,0,1-.195.085.247.247,0,0,1-.191-.091l-.019-.022-5.5-5.3,5.763.038Zm.515,9.647H3.553c-.11,0-.3-.207-.3-.574V125.03l5.572,5.384a1.513,1.513,0,0,0,1.1.5H9.95a1.5,1.5,0,0,0,1.092-.474l5.71-5.227v8.129C16.745,133.711,16.557,133.918,16.447,133.918Z"
        fill="#159687"
      />
    </g>
  </svg>
);
