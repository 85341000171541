import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageScrollToTop, PrintButton } from '../0-atoms';
import { Share } from './Share';

export type PageActionsProps = {
  scrollToTop?: boolean;
};

export const PageActions: React.FC<PageActionsProps> = ({
  scrollToTop = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className="PageActions">
      <ul className="PageActions--list">
        {scrollToTop && (
          <li className="pull-left">
            <PageScrollToTop label={t('To top')} />
          </li>
        )}
        <li>
          <PrintButton label={t('Print')} />
        </li>
        <li>
          <Share />
        </li>
      </ul>
    </div>
  );
};
