import classNames from 'classnames';
import React from 'react';

import { assetUrl } from '../../utils/assets';

export type VideoProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  url: string;
  image?: string | React.ReactNode;
  imageTitle?: string;
  videoClassName?: string;
  imageWrapperClassName?: string;
  imageClassName?: string;
  autoplay?: boolean;
  loop?: boolean;
  showControls?: boolean;
  fullWidth?: boolean;
  preload?: 'auto' | 'metadata' | 'none';
  loading?: 'eager' | 'lazy';
};

export const Video: React.FC<VideoProps> = ({
  url = '',
  image,
  imageTitle = '',
  videoClassName = 'Video--player',
  imageWrapperClassName = 'Video--image--wrapper',
  imageClassName = 'Video--image',
  autoplay = true,
  loop = true,
  showControls = false,
  fullWidth = false,
  preload = 'auto',
  loading = 'lazy',
}) => {
  return (
    <video
      className={classNames(videoClassName, {
        'Video--player--fullWidth': fullWidth,
      })}
      loop={loop}
      muted={autoplay}
      playsInline={autoplay}
      autoPlay={autoplay}
      controls={showControls}
      preload={preload}
    >
      <source src={assetUrl(url)} type="video/mp4" />
      {image && (
        <div className={imageWrapperClassName}>
          {typeof image === 'string' ? (
            <img
              src={assetUrl(image)}
              className={imageClassName}
              alt={imageTitle}
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
              loading={loading}
            />
          ) : (
            image
          )}
        </div>
      )}
    </video>
  );
};
