const protectedUrls = [
  '/sirmed/de/dokumente-und-informationen',
  '/sirmed/de/unterrichtsunterlagen',
  '/spz/de/80-stunden-schmerzkurs-referate',
  '/spz/de/medizinisches-angebot/ambulant-akut-reha/schmerzmedizin/fortbildung-kongresse/80-stunden',
  '/spz/de/invasive-procedures-motion-20th-anniversary-center-pain-medicine-nottwil-lectures',
  '/spz/de/update-schmerzmedizin-referate',
  '/spz/de/80-stunden-schmerzkurs-referate-2020',
  '/spz/de/interdisziplinaere-multimodale-schmerzmedizin-im-dialog-referate',
  '/spz/de/medizinisches-angebot/schmerzmedizin/fortbildung-kongresse/dreilaendertagung-achd-2021',
];

export const isProtectedUrl = (url: string) => protectedUrls.includes(url);
