import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useScript } from '../../hooks';
import { Loader } from '../0-atoms';
import { FadeUp } from '../1-molecules/FadeIn';

export type ModuleProps = {
  id: string;
  title: string;
  label: string;
  amount: number;
};

export type RaisenowPaymentWidgetProps = {
  paymentProcessed?: boolean;
  total?: number;
  customer?: {
    salutation: string;
    title?: string;
    name: string;
    surname: string;
    email: string;
    company: string;
    street: string;
    streetNumber: string;
    poBox: string;
    postcode: string;
    town: string;
    country: string;
    acceptTerms: boolean;
  };
  selectedModules?: Array<ModuleProps>;
  thankYouUrl?: string;
  onEditBooking?: () => void;
  className?: string;
};

export const RaisenowPaymentWidget: React.FC<RaisenowPaymentWidgetProps> = ({
  paymentProcessed,
  total,
  customer,
  selectedModules,
  thankYouUrl,
  onEditBooking,
  className,
}) => {
  const isTestMode = APP_CONFIG.raisenowWidget.test === '1';
  const scriptStatus = useScript(
    'https://tamaro.raisenow.com/sps-schmerzmedizin/latest/widget.js',
  );
  const widget = useRef<HTMLDivElement>(null);
  const widgetId = 'raisenow-widget';
  const { navigate, useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentValid, setPaymentValid] = useState(false);

  // If the payment fails, total and modules are retrieved from the RaiseNow transaction.
  const [storedTotal, setStoredTotal] = useState(total);
  // We need a common interface between the transaction message and the selected modules.
  // Use a list of strings.
  const [storedModules, setStoredModules] = useState(['']);

  useEffect(() => {
    if (scriptStatus !== 'ready') {
      return;
    }

    // Prevent Gatsby scrollRestoration to interfere here.
    window.scrollTo(0, 0);

    window.rnw.tamaro.events['paymentComplete'].subscribe(function (
      event: any,
    ) {
      if (
        event.data.api.transactionInfo.epayment_status === 'success' &&
        thankYouUrl
      ) {
        let state = {};
        if (window.rnw?.tamaro?.instance) {
          const transactionInfo = window.rnw.tamaro.instance.transactionInfo;
          state = {
            transactionInfo,
          };
        }
        // Redirect to thank you page.
        setIsLoading(true);
        setPaymentValid(true);
        navigate(thankYouUrl, {
          state,
          replace: true,
        });
      } else {
        setStoredTotal(event.data.api.transactionInfo.amount);
        const transactionCustomerMessage =
          event.data.api.transactionInfo.stored_customer_message;
        // This is a hack, because RaiseNow doesn't store '\n' in the transaction message.
        // We could improve by introducing another RaiseNow field or use a local storage.
        setStoredModules(
          transactionCustomerMessage
            .split(' CHF')
            .filter((module: string) => module)
            .map((module: string) => module + ' CHF'),
        );
      }
    });

    setIsLoading(false);

    // https://support.raisenow.com/hc/en-us/articles/360011968978-Adding-Custom-Fields-and-hidden-parameters
    const paymentFormPrefill: any = {
      stored_customer_accept_terms: customer?.acceptTerms,
      stored_customer_salutation: customer?.salutation,
      stored_customer_title: customer?.title,
      stored_customer_firstname: customer?.name,
      stored_customer_lastname: customer?.surname,
      stored_customer_email: customer?.email,
      stored_customer_company: customer?.company,
      stored_customer_street: customer?.street,
      stored_customer_street_number: customer?.streetNumber,
      stored_customer_pobox: customer?.poBox,
      stored_customer_zip_code: customer?.postcode,
      stored_customer_city: customer?.town,
      stored_customer_country: customer?.country,
    };
    paymentFormPrefill.stored_customer_message = selectedModules
      ? selectedModules
          .map(
            (selectedModule) =>
              `${selectedModule.title}: ${selectedModule.label} - ${
                selectedModule.amount
              } ${t('CHF')}\n`,
          )
          .reduce((accumulator, currentValue) =>
            accumulator.concat(currentValue),
          )
      : '';
    setStoredModules(paymentFormPrefill.stored_customer_message.split('\n'));

    const tamaroOptions = {
      // https://support.raisenow.com/hc/de/articles/360017178638-Tamaro-in-einem-Bezahl-Checkout-einsetzen
      paymentWidgetBlocks: [
        'payment_payment_methods',
        // This is not working as expected.
        // Overriding with custom css.
        // {
        //   if: 'showPaymentAddress() == true',
        //   then: ['payment_address'],
        // },
      ],
      paymentFormPrefill: paymentFormPrefill,
      showFields: {
        stored_customer_birthdate: false,
        stored_customer_email_permission: false,
        stored_customer_donation_receipt: false,
        stored_customer_message: false,
        stored_customer_accept_terms: false, // doesn't work, use custom css.
      },
      currencies: ['chf'],
      amounts: [total],
      paymentValidations: {},
    };

    window.rnw.tamaro
      .runWidget(`#${widgetId}`, {
        language: locale,
        test_mode: isTestMode,
        debug: isTestMode,
        ...tamaroOptions,
      })
      .catch((params: any) => {
        if (console && console.error) {
          console.error('Error while trying to initialize RaiseNow widget');
          console.error(params);
        }
      });

    // Do not destroy the Tamaro widget.
    // It will be reused in case of a booking edit.
    return () => {};
    // Exhaustive dependencies causes redirect issues.
    // @see https://amazeelabs.slack.com/archives/C02ELKC0LC9/p1694608909075829
    // @see https://react.dev/reference/react/useEffect#caveats
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptStatus]);

  return (
    <FadeUp
      yGap={20}
      className={classNames(className, 'RaiseNowPaymentWidget')}
    >
      <Helmet>
        <link
          type="text/css"
          rel="stylesheet"
          href="/css/raisenow-payment-widget--tamaro.css"
        />
      </Helmet>
      {isLoading ? (
        <div className="ArticleText">
          <Loader />
        </div>
      ) : (
        <>
          <div className={classNames(!storedTotal ? 'is-hidden' : '')}>
            <div className="ArticleText">
              <h3 className="ArticleTitle--title h3">
                {t('Your course selection')}
              </h3>
              <ul>
                {storedModules
                  .filter((module) => module)
                  .map((module, index) => (
                    <li key={index} className="h4 RelatedArticles--title">
                      {module}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="ArticleText">
              <h3 className="ArticleTitle--title h3">
                {t('Total cost')} = {t('CHF')} {storedTotal}
              </h3>
            </div>
          </div>
          {!paymentProcessed && (
            <>
              <div className="ArticleText">
                <button
                  className="Button icon-after block"
                  type="submit"
                  onClick={() => onEditBooking && onEditBooking()}
                >
                  <span className="Button--text">{t('Edit your booking')}</span>
                  <i className="absolute after ico-bolt-right-white icon" />
                </button>
              </div>
            </>
          )}
          <div
            className={classNames(
              'ArticleText',
              paymentProcessed && paymentValid ? 'is-hidden' : '',
            )}
          >
            <div id={widgetId} ref={widget} className="dds-widget-container" />
          </div>
        </>
      )}
    </FadeUp>
  );
};
