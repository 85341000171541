import { ParagraphFromLibraryFragment } from '@custom/schema';

import { isPublished } from '../../utils';

export const transformParagraphFromLibraryToProps = (
  paragraph: ParagraphFromLibraryFragment,
) => ({
  content: isPublished(paragraph?.reusableParagraph?.paragraph)
    ? paragraph?.reusableParagraph?.paragraph
    : undefined,
});
