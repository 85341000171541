import { ListingCompanySearchQuery } from '@custom/schema';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getUrlFromStyles } from '../../fragments/mappers';
import {
  useCompaniesFilters,
  useIndustryTerms,
  useLocalization,
  usePager,
} from '../../hooks';
import { isTruthy } from '../../utils/isTruthy';
import { useOperation } from '../../utils/operation';
import { Loader } from '../0-atoms';
import { CompaniesFilterForm, CompanyTeaserList } from '../1-molecules';
import { Pager } from './Pager';

export type CompaniesViewProps = {
  itemsPerPage?: number;
  className?: string;
};

export const CompaniesView: React.FC<CompaniesViewProps> = ({
  itemsPerPage = 10,
  className,
}) => {
  const { t } = useTranslation();
  const { locale: currentLocale } = useLocalization();
  const industryTerms = useIndustryTerms(currentLocale);
  const { category, glossary, searchTerm } = useCompaniesFilters();
  const { currentPage } = usePager();
  const { data, isLoading } = useOperation(ListingCompanySearchQuery, {
    offset: (currentPage - 1) * itemsPerPage,
    limit: itemsPerPage,
    glossary: glossary,
    text: searchTerm,
    industry: category ? [category] : [],
    language: currentLocale,
  });

  const companies = data?.listingCompanySearch?.items || [];
  const total = data?.listingCompanySearch?.total || 0;

  const industryTermsMapped = industryTerms
    ? industryTerms.map(({ id, title }) => ({
        id: id.toString(),
        label: title || '',
      }))
    : undefined;

  return (
    <div
      className={classNames(className, {
        'Pane is-loading relative': isLoading,
      })}
    >
      {isLoading && <Loader />}
      {<CompaniesFilterForm categories={industryTermsMapped} />}

      {companies && companies?.length ? (
        Object.entries(
          companies.reduce((groupedCompanies, company) => {
            if (!company) {
              return groupedCompanies;
            }
            const group = company.title.charAt(0).toUpperCase();
            if (!groupedCompanies[group]) {
              groupedCompanies[group] = [company];
            } else {
              groupedCompanies[group].push(company);
            }
            return groupedCompanies;
          }, {} as Record<string, ListingCompanySearchQuery['listingCompanySearch']['items']>),
        ).map(([group, groupedCompanies]) => (
          <CompanyTeaserList
            key={`companies-${group}`}
            title={group}
            companies={groupedCompanies.filter(isTruthy).map((company) => ({
              url: company.path,
              name: company.title,
              place: company.place,
              plz: company.zip,
              details: company.details,
              address: company.streetAndNumber,
              email: company.email,
              telephone: company.telephone,
              website: company.websiteLink?.url,
              logo: company.logo?.styles
                ? getUrlFromStyles(
                    company?.logo?.styles.filter(isTruthy),
                    'thumbnail',
                  )
                : undefined,
              hasContent: company.hasContent,
            }))}
          />
        ))
      ) : (
        <p className="ArticleText">
          {t('There are no companies that match your filters.')}
        </p>
      )}
      {total > itemsPerPage ? (
        <Pager itemsCount={total} itemsPerPage={itemsPerPage} />
      ) : null}
    </div>
  );
};
