import React from 'react';
import { useTranslation } from 'react-i18next';

import { getImageStyleUrl } from '../../fragments/mappers';
import { Doctor } from '../../types';
import { IconKey, IconSvg } from './IconSvg';
import { PersonTeaser } from './PersonTeaser';

export type DoctorTeaserProps = Doctor & {
  className?: string;
};

export const DoctorTeaser: React.FC<DoctorTeaserProps> = ({
  path,
  firstName,
  lastName,
  job,
  additionalInformation,
  specialistDepartment,
  specialistTeaserTitle,
  additionalFields,
  doctorFunction,
  mainField,
  email,
  telephone,
  fax,
  teaserImage,
  className,
}) => {
  const { t } = useTranslation();
  const personTelLink = 'tel:' + telephone;
  const newClassName = 'PersonTeaser--card';
  const clsName = className ? className + ` ${newClassName}` : newClassName;
  // @ts-ignore
  const teaserImageUrl = getImageStyleUrl(teaserImage, 'person_teaser');

  return (
    <PersonTeaser
      className={clsName}
      expandableLabel={t('Show CV')}
      image={teaserImageUrl}
      name={[job, firstName, lastName].filter((p) => Boolean(p)).join(' ')}
      link={path}
    >
      <span className="line1 line--name-department">
        {doctorFunction?.title} {mainField?.title} {specialistDepartment}
        <br />
      </span>
      <span className="line2 line--specialist">
        {specialistTeaserTitle}{' '}
        {additionalFields &&
          additionalFields.map(function (field, index: number) {
            return (
              <>
                {' '}
                {index !== 0 && '&'} {field.title}
              </>
            );
          })}
        {(additionalFields?.length !== 0 || specialistTeaserTitle) && <br />}
      </span>
      {additionalInformation && (
        <span className="line3 line--additional">
          {additionalInformation}
          <br />
        </span>
      )}
      <div className="section section--contact">
        {email && (
          <span className="line4 line--email">
            <IconSvg
              position={'inline'}
              wrapClass={'section--icon'}
              icon={`ico-email-small` as IconKey}
            />
            <a href={`mailto:${email}`}>{email}</a>
            <br />
          </span>
        )}
        {fax && (
          <span className="line5 line--fax">
            <IconSvg
              position={'inline'}
              wrapClass={'section--icon'}
              icon={`ico-fax-small` as IconKey}
            />
            {fax}
            <br />
          </span>
        )}
        {telephone && (
          <span className="line6 line--telephone">
            <IconSvg
              position={'inline'}
              wrapClass={'section--icon'}
              icon={`ico-telephone-small` as IconKey}
            />
            <a href={personTelLink}>{telephone}</a>
            <br />
          </span>
        )}
      </div>
    </PersonTeaser>
  );
};
