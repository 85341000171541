import { Locale, MenuItemFragment } from '@custom/schema';

import { MenuId } from '../constants';
import { useFrameworkDependencies } from '../dependencies';
import { MenuItem } from '../types';
import { isTruthy } from '../utils/isTruthy';
import { useSiteMenusQuery } from './useSiteMenusQuery';

function extractItemsByLocale<
  TInput extends Array<{ langcode: Locale }>,
  TKey extends TInput[number]['langcode'],
>(input: TInput, langcode: TKey): TInput[number] | undefined {
  return input.filter((item) => item.langcode === langcode).pop();
}

// @todo process maxDepth
export const useMenu = (id: MenuId): MenuItem[] => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();

  const result = useSiteMenusQuery();
  let menuTranslations;
  switch (id) {
    case 'fast-navigation-menu':
      menuTranslations = result?.menuFastNavigation;
      break;
    case 'footer-menu':
      menuTranslations = result?.menuFooter;
      break;
    case 'footer-quicklinks-activecom':
      menuTranslations = result?.menuFooterQuickLinksActiveCom;
      break;
    case 'footer-quicklinks-gv':
      menuTranslations = result?.menuFooterQuickLinksGonnerVereinigung;
      break;
    case 'footer-quicklinks-orthotec':
      menuTranslations = result?.menuFooterQuickLinksOrthotec;
      break;
    case 'footer-quicklinks-orthotecsports':
      menuTranslations = result?.menuFooterQuickLinksOrthotecSports;
      break;
    case 'footer-quicklinks-parahelp':
      menuTranslations = result?.menuFooterQuickLinksParahelp;
      break;
    case 'footer-quicklinks-sirmed':
      menuTranslations = result?.menuFooterQuickLinksSirmed;
      break;
    case 'footer-quicklinks-spf':
      menuTranslations = result?.menuFooterQuickLinksSpf;
      break;
    case 'footer-quicklinks-sportmedi':
      menuTranslations = result?.menuFooterQuickLinksSportMedizin;
      break;
    case 'footer-quicklinks-sps':
      menuTranslations = result?.menuFooterQuickLinksSps;
      break;
    case 'footer-quicklinks-spz':
      menuTranslations = result?.menuFooterQuickLinksSpz;
      break;
    case 'footer-social-menu':
      menuTranslations = result?.menuSocial;
      break;
    case 'footer-social-menu-activecom':
      menuTranslations = result?.menuSocialActiveCom;
      break;
    case 'footer-social-menu-orthotec':
      menuTranslations = result?.menuSocialOrthotec;
      break;
    case 'footer-social-menu-parahelp':
      menuTranslations = result?.menuSocialParahelp;
      break;
    case 'footer-social-menu-sirmed':
      menuTranslations = result?.menuSocialSirmed;
      break;
    case 'footer-social-menu-spf':
      menuTranslations = result?.menuSocialSpf;
      break;
    case 'footer-social-menu-sps':
      menuTranslations = result?.menuSocialSps;
      break;
    case 'footer-social-menu-spz':
      menuTranslations = result?.menuSocialSpz;
      break;
    case 'home-menu':
      menuTranslations = result?.menuHome;
      break;
    case 'main':
      menuTranslations = result?.menuMain;
      break;
    case 'meta-navigation':
      menuTranslations = result?.menuMetaNavigation;
      break;
    case 'meta-navigation-orthotecsports':
      menuTranslations = result?.menuMetaNavigationOrthotecSports;
      break;
    case 'sidebar-ac':
      menuTranslations = result?.menuSidebarActiveCommunication;
      break;
    case 'sidebar-gv':
      menuTranslations = result?.menuSidebarGonnerVereinigung;
      break;
    case 'sidebar-orthotec':
      menuTranslations = result?.menuSidebarOrthotec;
      break;
    case 'sidebar-orthotecsports':
      menuTranslations = result?.menuSidebarOrthotecSports;
      break;
    case 'sidebar-parahelp':
      menuTranslations = result?.menuSidebarParahelp;
      break;
    case 'sidebar-sirmed':
      menuTranslations = result?.menuSidebarSirmed;
      break;
    case 'sidebar-spf':
      menuTranslations = result?.menuSidebarSpf;
      break;
    case 'sidebar-sportmedizin':
      menuTranslations = result?.menuSidebarSportMedizin;
      break;
    case 'sidebar-sps':
      menuTranslations = result?.menuSidebarSps;
      break;
    case 'sidebar-spz':
      menuTranslations = result?.menuSidebarSpz;
      break;
  }

  const menuItems =
    extractItemsByLocale(menuTranslations?.filter(isTruthy) || [], locale)
      ?.items || [];
  return buildMenuTree(menuItems);
};

export const buildMenuTree = (
  menu: Array<MenuItemFragment> = [],
  parent: string = '',
): Array<MenuItem> =>
  menu
    .filter((item) => item.parent === parent)
    .map((item) => ({
      id: item.id,
      title: item.label,
      url: item.url,
      attributes: {
        title: item.titleAttribute,
        class: item.classAttribute,
        id: item.idAttribute,
        ariaControls: item.ariaControlsAttribute,
        ariaExpanded: item.ariaExpandedAttribute === 'true',
        ariaHasPopup: item.ariaHasPopupAttribute === 'true',
        accessKey: item.accessKeyAttribute,
        target: item.targetAttribute,
        enabled: item.isEnabled,
      },
      childItems: buildMenuTree(menu, item.id),
    }));
