import { DOMAINS } from '../constants';
import { Domain } from '../types';

export const getDomainUrl = ({
  absoluteUrl,
  domain,
}: {
  absoluteUrl: string;
  domain?: Domain;
}) => {
  const urlObject = new URL(absoluteUrl);
  if (domain?.pathPrefix && domain?.pathPrefix !== '') {
    return `${urlObject.origin}${domain.pathPrefix}${urlObject.pathname}`;
    // Fallback based on the query string if the domain path prefix is not set.
  } else if (typeof window !== 'undefined') {
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split('/');
    if (pathParts.length > 1) {
      const domainKeys = Object.values(DOMAINS)
        .map((domain) => domain.pathPrefix?.replace(/^\//, ''))
        .filter((key) => key && key.length > 0);
      if (domainKeys.includes(pathParts[1])) {
        return `${urlObject.origin}/${pathParts[1]}${urlObject.pathname}`;
      }
    }
  }
  return absoluteUrl;
};
