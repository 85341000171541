import React from 'react';

export type IconNewsProps = {
  className?: string;
  fill?: string | '#FFF';
  position?: 'left' | 'center';
};

export const IconNews: React.FC<IconNewsProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <>
        <path
          fill={fill}
          d="m83.66,80c7.03-.02,12.76-5.76,12.78-12.8v-34.56h-17.42v3.82h13.6v30.73c-.01,4.94-4.03,8.97-8.96,8.98-.49,0-.99-.05-1.54-.14l-.14-.04-1.36-.45c-.86-.28-1.67-.7-2.41-1.24l-.11-.08c-.17-.13-.34-.28-.51-.44l-.09-.08c-1.53-1.46-2.5-3.38-2.75-5.44-.04-.36-.07-.71-.07-1.07V0H8.56v67.22c.02,7.03,5.76,12.76,12.8,12.78h60.58v-.06l1.3.04c.14.01.28.02.42.02Zm-62.3-3.82c-4.94-.01-8.96-4.04-8.98-8.97V3.82h58.48v63.35c-.01,2.75.79,5.29,2.32,7.42l1.13,1.58H21.36Z"
        />
        <rect fill={fill} x="23.11" y="18.63" width="29.94" height="3.82" />
        <rect fill={fill} x="23.11" y="32.6" width="15.97" height="3.82" />
      </>
    ) : (
      <>
        <path
          fill={fill}
          d="m75.1,80c7.03-.02,12.76-5.76,12.78-12.8v-34.56h-17.42v3.82h13.6v30.73c-.01,4.94-4.03,8.97-8.96,8.98-.49,0-.99-.05-1.54-.14l-.14-.04-1.36-.45c-.86-.28-1.67-.7-2.41-1.24l-.11-.08c-.17-.13-.34-.28-.51-.44l-.09-.08c-1.53-1.46-2.5-3.38-2.75-5.44-.04-.36-.07-.71-.07-1.07V0H0v67.22c.02,7.03,5.76,12.76,12.8,12.78h60.58v-.06l1.3.04c.14.01.28.02.42.02Zm-62.3-3.82c-4.94-.01-8.96-4.04-8.98-8.97V3.82h58.48v63.35c-.01,2.75.79,5.29,2.32,7.42l1.13,1.58H12.8Z"
        />
        <rect fill={fill} x="14.55" y="18.63" width="29.94" height="3.82" />
        <rect fill={fill} x="14.55" y="32.6" width="15.97" height="3.82" />
      </>
    )}
  </svg>
);
