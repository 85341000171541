import loadable from '@loadable/component';
import classNames from 'classnames';
import type { Props } from 'google-map-react';
import React from 'react';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { FadeUp } from '../1-molecules/FadeIn';
import { MapMarker } from './MapMarker';

const GoogleMapReact = loadable(() => import('google-map-react'));

export type ParagraphMapCoordinatesProps = Props & {
  latitude?: number;
  longitude?: number;
  inline?: boolean;
  small?: boolean;
  title?: string;
  className?: string;
};

const defaultCenter = {
  lat: 47.1408131,
  lng: 8.1289623,
};

export const ParagraphMapCoordinates: React.FC<
  ParagraphMapCoordinatesProps
> = ({
  latitude,
  longitude,
  title,
  inline = false,
  small = false,
  className,
  children,
  ...props
}) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const coords =
    latitude && longitude
      ? {
          lat: latitude,
          lng: longitude,
        }
      : defaultCenter;

  return (
    <FadeUp
      yGap={20}
      className={classNames('InlineMap', className, {
        small,
        'absolute inline': inline,
      })}
    >
      <div className="InlineMap--inner">
        <div className="InlineMap--map">
          <GoogleMapReact
            defaultCenter={coords}
            defaultZoom={12}
            bootstrapURLKeys={{
              key: APP_CONFIG.google.maps_key || '',
              libraries: ['places'],
              language: locale,
            }}
            {...props}
          >
            <MapMarker lat={coords.lat} lng={coords.lng} title={title || ''}>
              {children}
            </MapMarker>
          </GoogleMapReact>
        </div>
      </div>
    </FadeUp>
  );
};

export default ParagraphMapCoordinates;
