import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';

export const Share: React.FC = () => {
  const { useLocation } = useFrameworkDependencies();
  const { domain } = useDomain();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  const urlToShare = `${domain.formattedHost}${pathname}`;
  const socialLinks = [
    {
      type: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`,
      title: 'Facebook',
      target: '_blank',
    },
    {
      type: 'twitter',
      url: `https://twitter.com/intent/tweet?url=${urlToShare}`,
      title: 'Twitter',
      target: '_blank',
    },
    {
      type: 'email',
      url: `mailto:?subject=${t(domain.title)}&body=${urlToShare}`,
      title: t('Mail'),
    },
  ];

  return (
    <div className="Flyout">
      <button
        className="Flyout--trigger PageActions--button"
        type="button"
        onClick={() => setIsVisible(true)}
        aria-haspopup
      >
        {t('Share')}
        <span className="PageActions--button--icon">
          <i className="ico ico-footer-share" />
        </span>
      </button>
      <div
        className={classNames('Flyout--overlay', {
          'is-visible': isVisible,
        })}
        onClick={() => setIsVisible(false)}
      />
      <div
        className={classNames('Flyout--container ShareFlyout', {
          'is-visible': isVisible,
        })}
      >
        <div className="Flyout--container--arrow" />
        <div className="Flyout--content">
          <ul className="Flyout--list">
            {socialLinks.map((item) => (
              <li key={item.title} className="Flyout--item">
                <a
                  className="Flyout--item--link"
                  href={item.url || ''}
                  target={item.target || '_self'}
                  onClick={() => setIsVisible(false)}
                >
                  <span className="Flyout--item--icon">
                    <i className={`ico-social-${item.type} icon`} />
                  </span>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
