export type ConditionalWrapperProps = {
  condition?: boolean;
  children?: any;
  wrapper?: JSX.Element | any | null | undefined;
};

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);
