import React, { PropsWithChildren, useContext } from 'react';

import { Locale, locales } from '../../i18n';

const PageTranslationsContext = React.createContext<
  Partial<Map<Locale, string>>
>({});

export const PageTranslationsProvider: React.FC<
  PropsWithChildren<{
    path?: string;
    language: string;
    localizations?: Array<{
      path: string;
      locale: Locale;
    }>;
  }>
> = ({ path, language, localizations, children }) => {
  const mappedLocalizations = new Map<Locale, string>();
  localizations?.forEach((value) => {
    mappedLocalizations.set(value.locale, value.path);
  });
  return (
    <PageTranslationsContext.Provider
      value={
        mappedLocalizations ||
        translationPaths(path, language, Object.keys(locales))
      }
    >
      {children}
    </PageTranslationsContext.Provider>
  );
};

export const usePageTranslations = (): Partial<Map<Locale, string>> => {
  return useContext(PageTranslationsContext);
};

export type Linkable = {
  drupalId?: string;
  path?: string;
  langcode?: string;
};

export type WithTranslationLinks<T> = T & {
  translations: {
    [key: string]: string;
  };
};

/**
 * @deprecated
 */
export function addPageTranslations<T extends Linkable>(
  items: T[],
): WithTranslationLinks<T>[] {
  return items.map((item) => ({
    ...item,
    translations: items
      .filter((trans) => trans.drupalId === item.drupalId)
      .sort((a, b) => (a.langcode! > b.langcode! ? 1 : -1))
      .map((item) => ({
        [item.langcode || 'de']: item?.path || `/${item.drupalId}`,
      }))
      .reduce((acc, val) => Object.assign({}, acc, val), {}),
  }));
}

export function translationPaths(
  path: string | undefined,
  currentLanguage: string,
  languages: string[],
) {
  return path
    ? languages
        .map((lang) => ({
          [lang]: path.replace(`/${currentLanguage}`, `/${lang}`),
        }))
        .reduce((acc, val) => Object.assign({}, acc, val), {})
    : {};
}
