import queryString from 'query-string';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { DoctorsFiltersProvider } from '../../hooks';
import { DoctorsView } from './DoctorsView';

export type ParagraphViewsDoctorProps = {
  className?: string;
};

export const ParagraphViewsDoctor: React.FC<ParagraphViewsDoctorProps> = ({
  className,
}) => {
  const { useLocation } = useFrameworkDependencies();
  const { search } = useLocation();
  const parsedSearch = queryString.parse(search) as {
    field?: string;
    doctorFunction?: string;
    glossary?: string;
  };
  const { field, doctorFunction, glossary } = parsedSearch;
  // @todo simplify the filters by using the ones
  //   from ParagraphViewsDoctor instead of
  //   a standalone query.
  return (
    <DoctorsFiltersProvider
      field={field}
      doctorFunction={doctorFunction}
      glossary={glossary}
    >
      <DoctorsView className={className} />
    </DoctorsFiltersProvider>
  );
};

export default ParagraphViewsDoctor;
