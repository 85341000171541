import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePager } from '../../hooks';

export type RedesignedPagerProps = {
  itemsCount?: number;
  itemsPerPage?: number;
  displayPages?: number;
  className?: string;
};

export const RedesignedPager: React.FC<RedesignedPagerProps> = ({
  itemsCount,
  itemsPerPage = 10,
  displayPages = 4,
  className,
}) => {
  const { t } = useTranslation();
  const {
    setItemsCount,
    setItemsPerPage,
    setDisplayPages,
    pages,
    hasPreviousPage,
    hasNextPage,
    hasHiddenNextPages,
    hasHiddenPreviousPages,
    currentPage,
    firstPage,
    lastPage,
    setCurrentPage,
    previousPage,
    nextPage,
  } = usePager();

  useEffect(() => {
    if (itemsCount) {
      setItemsCount(itemsCount);
    }

    if (itemsPerPage) {
      setItemsPerPage(itemsPerPage);
    }

    if (displayPages) {
      setDisplayPages(displayPages);
    }
  }, [
    itemsCount,
    itemsPerPage,
    displayPages,
    setItemsCount,
    setItemsPerPage,
    setDisplayPages,
  ]);

  return itemsCount && itemsCount > itemsPerPage ? (
    <ul className={classNames('RedesignedPagination', className)}>
      <li>
        <a
          href="/"
          rel="prev"
          className={'RedesignedPagination--switch prev'}
          onClick={(event) => {
            event.preventDefault();
            previousPage();
          }}
        >
          <div
            className={classNames('inner prev', {
              isDisabled: !hasPreviousPage,
            })}
          >
            <span className="visuallyhidden">{t('Previous page')}</span>
            <i className="ico ico-arrow-left" />
            <span className="label">{t('Back')}</span>
          </div>
        </a>
      </li>

      {hasHiddenPreviousPages && (
        <>
          <li>
            <a
              href="/"
              className={classNames('RedesignedPagination--link', {
                'is-active': firstPage === currentPage,
              })}
              onClick={(event) => {
                event.preventDefault();
                setCurrentPage(firstPage);
              }}
            >
              <span className="visuallyhidden">{t('Page')}</span>
              {firstPage}
            </a>
          </li>
          {pages[0] - firstPage > 1 && (
            <li className={'RedesignedPagination--link dots'}>...</li>
          )}
        </>
      )}

      {pages.map((page) => (
        <div className="RedesignedPagination--list" key={page}>
          <li>
            <a
              href="/"
              className={classNames('RedesignedPagination--link', {
                'is-active': page === currentPage,
              })}
              onClick={(event) => {
                event.preventDefault();
                setCurrentPage(page);
              }}
            >
              <span className="visuallyhidden">{t('Page')}</span>
              {page}
            </a>
          </li>
        </div>
      ))}
      {hasHiddenNextPages && (
        <>
          {lastPage - pages[pages.length - 1] > 1 && (
            <li className={'RedesignedPagination--link dots'}>...</li>
          )}
          <li>
            <a
              href="/"
              className={classNames('RedesignedPagination--link', {
                'is-active': lastPage === currentPage,
              })}
              onClick={(event) => {
                event.preventDefault();
                setCurrentPage(lastPage);
              }}
            >
              <span className="visuallyhidden">{t('Page')}</span>
              {lastPage}
            </a>
          </li>
        </>
      )}
      <li>
        <a
          href="/"
          rel="next"
          className={'RedesignedPagination--switch next'}
          onClick={(event) => {
            event.preventDefault();
            nextPage();
          }}
        >
          <div
            className={classNames('inner next', {
              isDisabled: !hasNextPage,
            })}
          >
            <span className="visuallyhidden">{t('Next page')}</span>
            <span className="label">{t('Next')}</span>
            <i className="ico ico-arrow-right" />
          </div>
        </a>
      </li>
    </ul>
  ) : null;
};
