import classNames from 'classnames';
import React from 'react';

export type TextBoxProps = {
  title?: string;
  subtitle?: string;
  className?: string;
};

export const TextBox: React.FC<TextBoxProps> = ({
  title = '',
  subtitle = '',
  className,
}) => (
  <div className={classNames('TextBox', className)}>
    <div className="TextBox--content">
      <h3
        className="TextBox--title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <em
        className="TextBox--subtitle"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
    </div>
  </div>
);
