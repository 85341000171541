import { ParagraphCallToActionFragment } from '@custom/schema';

export const transformParagraphCallToActionToProps = (
  paragraph: ParagraphCallToActionFragment,
) => ({
  url: paragraph.link?.url,
  title: paragraph.link?.title,
  target: paragraph.link?.options?.attributes?.target,
  buttonStyle: paragraph.buttonStyle,
});
