import { GatsbyLinkProps, Link } from 'gatsby';
import React from 'react';

import { Locale } from '../../../i18n';
import { homepagePaths } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';
import {
  getLocalizedUrl,
  hasAnchorFragment,
  hasQueryString,
  isInternalUrl,
  isProtectedUrl,
  isRelativeUrl,
} from '../../utils';

export const LocalizedLink: React.FC<
  Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> & {
    language?: Locale;
    domain?: string;
  }
> = ({ to, target, language, domain: domainId, ...props }) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { domain: currentDomain, getDomain } = useDomain();
  const domain = domainId ? getDomain(domainId) : currentDomain;
  const linkLocale = language || locale;
  const rawUrl = homepagePaths.includes(to) ? '/' : to;

  const { url, gatsby } = getLocalizedUrl({
    to: rawUrl,
    locale: linkLocale,
    domain,
  });

  const isInternalTarget =
    typeof target === 'undefined' || target === '' || target === '_self';

  return gatsby &&
    isRelativeUrl(to) &&
    !isInternalUrl(to) &&
    !isProtectedUrl(to) &&
    !hasQueryString(to) &&
    !hasAnchorFragment(to) &&
    isInternalTarget ? (
    <Link {...props} to={url} />
  ) : (
    <a {...props} target={target} href={url} data-external={true} />
  );
};
