import React, { PropsWithChildren } from 'react';

import { useFrameworkDependencies } from '../../dependencies';

export type ButtonProps = PropsWithChildren<{
  url: string;
  label: string;
}>;

export const Button: React.FC<ButtonProps> = ({ url, label, children }) => {
  const { Link } = useFrameworkDependencies();

  return (
    <Link className="Button cta-white" type="button" to={url}>
      <span className="Button--text">{label}</span>
      {children}
    </Link>
  );
};
