import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export type AccordionToggleProps = PropsWithChildren<{
  id: string;
  isActive: boolean;
  className?: string;
  isCollapsible?: boolean;
  toggleAccordion?: () => void;
}>;

export const AccordionToggle: React.FC<AccordionToggleProps> = ({
  id,
  isActive,
  className,
  isCollapsible = true,
  toggleAccordion,
  children,
}) => (
  <div
    id={`AccordionItem--trigger-${id}`}
    className={classNames('AccordionItem--toggle', className, {
      'is-opened': isActive || !isCollapsible,
    })}
    onClick={() => isCollapsible && toggleAccordion && toggleAccordion()}
    aria-controls={`AccordionItem--content-${id}`}
    aria-expanded="false"
    role="tab"
  >
    {children}
  </div>
);
