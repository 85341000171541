export const getTranslatedUrl = ({
  urlObject,
  langcode,
}: {
  urlObject: Array<object>;
  langcode?: string;
}) => {
  let output = urlObject.filter(function (el) {
    // @ts-ignore
    return el?.langcode === langcode;
  });

  // First will try and from the filtered object, if not found will try and get the first one as fallback.
  if (output.length > 0) {
    // @ts-ignore
    output = output[0].link.url;
  } else if (urlObject.length > 1) {
    // @ts-ignore
    output = urlObject[0].link.url;
  }
  return output ?? '';
};
