import { GatsbyBrowser, WrapRootElementBrowserArgs } from 'gatsby';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ActiveCampaignTracking } from '../components/0-atoms';

const client = new QueryClient();

export const WrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}: WrapRootElementBrowserArgs) => (
  <>
    <QueryClientProvider client={client}>{element}</QueryClientProvider>
    <ActiveCampaignTracking />
  </>
);
