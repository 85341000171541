import classNames from 'classnames';
import { decode } from 'he';
import React from 'react';

import { ConditionalWrapper } from '../../utils/conditionalContainer';
import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphArticleTitleProps = {
  title?: string;
  tag?: string;
  tagClassName?: string;
  className?: string;
  hasBackground?: boolean;
};

export const ParagraphArticleTitle: React.FC<ParagraphArticleTitleProps> = ({
  title,
  tag = 'h3',
  tagClassName,
  className,
  hasBackground,
}) => {
  const element = tag ? tag.replace(/^<|>$/g, '') : 'h3';

  return title ? (
    <div
      className={classNames('ArticleTitle', className, {
        'has-background': hasBackground,
      })}
    >
      <FadeUp yGap={20}>
        <ConditionalWrapper
          condition={hasBackground}
          wrapper={(children: any) => (
            <div className="hasBackground--wrapper">{children}</div>
          )}
        >
          <>
            {React.createElement(
              element,
              {
                className: classNames(
                  'ArticleTitle--title',
                  tagClassName || element,
                ),
              },
              decode(title),
            )}
          </>
        </ConditionalWrapper>
      </FadeUp>
    </div>
  ) : null;
};

export default ParagraphArticleTitle;
