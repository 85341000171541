import React from 'react';

export type IconFooterPinterestProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterPinterest: React.FC<IconFooterPinterestProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    viewBox="0 0 512 512"
  >
    <path
      fill={fill}
      d="M256 7C119 7 8 118 8 255c0 105.1 65.4 194.8 157.6 230.9-2.2-19.6-4.1-49.7.9-71.1 4.5-19.4 29.1-123.3 29.1-123.3s-7.4-14.9-7.4-36.8c0-34.5 20-60.2 44.9-60.2 21.1 0 31.4 15.9 31.4 34.9 0 21.3-13.5 53.1-20.5 82.6-5.8 24.7 12.4 44.8 36.7 44.8 44.1 0 78-46.5 78-113.6 0-59.4-42.7-100.9-103.6-100.9-70.6 0-112 52.9-112 107.6 0 21.3 8.2 44.2 18.5 56.6 2 2.5 2.3 4.6 1.7 7.1l-6.9 28.1c-1.1 4.5-3.6 5.5-8.3 3.3-31-14.4-50.3-59.7-50.3-96.1 0-78.2 56.8-150.1 163.9-150.1 86 0 152.9 61.3 152.9 143.2 0 85.5-53.9 154.3-128.7 154.3-25.1 0-48.8-13-56.8-28.5l-15.5 59c-5.6 21.6-20.7 48.6-30.8 65 23.2 7.2 47.9 11.1 73.4 11.1 137 0 248-111 248-248C504 118 393 7 256 7z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
