import { ParagraphTwoColumnTextImageFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { transformImageFragmentToProps } from './MediaImage';

export const transformParagraphTwoColumnTextImgToProps = (
  paragraph: ParagraphTwoColumnTextImageFragment,
) => {
  const rightParagraphImage = paragraph?.image;
  const image = rightParagraphImage ? (
    <Image
      {...transformImageFragmentToProps(rightParagraphImage, [
        'small',
        'medium',
        'large',
      ])}
      size="large"
      className="ArticleFigure--img"
      fit
    />
  ) : undefined;

  return {
    title: paragraph?.textImageTitle,
    tag: paragraph?.headingLevel,
    text: paragraph?.textHtml,
    image,
  };
};
