import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphArticleFigureProps = {
  image: string | ReactNode;
  title?: string;
  description?: string;
  className?: string;
};

export const ParagraphArticleFigure: React.FC<ParagraphArticleFigureProps> = ({
  image,
  title,
  description = '',
  className,
}) => {
  return (
    <FadeUp yGap={20}>
      <figure className={classNames('ArticleFigure', className)}>
        {typeof image === 'string' ? (
          <img
            className="ArticleFigure--img"
            alt={title}
            title={title}
            src={image}
            loading="lazy"
          />
        ) : (
          image
        )}
        <figcaption
          className="ArticleFigure--caption"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </figure>
    </FadeUp>
  );
};

export default ParagraphArticleFigure;
