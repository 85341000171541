import React, { PropsWithChildren, useContext } from 'react';

import { DOMAINS } from '../constants';
import { Domain } from '../types';

export type DomainContext = {
  domain: Domain;
  getDomain: (domain: string) => Domain | undefined;
};
const DomainContext = React.createContext<DomainContext | undefined>(undefined);

export const DomainProvider: React.FC<
  PropsWithChildren<{ domain: string }>
> = ({ domain, children }) => {
  const getDomain = (domain: string) => DOMAINS[domain];

  return (
    <DomainContext.Provider
      value={{
        domain: getDomain(domain),
        getDomain,
      }}
    >
      {children}
    </DomainContext.Provider>
  );
};

export const useDomain = () => {
  const context = useContext(DomainContext);
  if (context === undefined) {
    throw new Error('useDomain must be used within a DomainProvider');
  }
  return context;
};
