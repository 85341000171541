import { ParagraphWebformFragment } from '@custom/schema';

export const transformParagraphWebformToProps = (
  paragraph: ParagraphWebformFragment,
) => {
  const drupalUrl =
    process.env.GATSBY_BACKEND_BASE_URL || 'http://127.0.0.1:8888';
  const absoluteUrl = drupalUrl + paragraph.webformUrl;
  return {
    url: absoluteUrl,
    status: paragraph.status,
  };
};
