import classNames from 'classnames';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';

export type LoadMoreButtonProps = {
  url: string;
  label: string;
  className?: string;
};

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  url,
  label,
  className,
}) => {
  const { Link } = useFrameworkDependencies();

  return (
    <div className={classNames('LoadMoreButton', className)}>
      <Link className="LoadMoreButton--button" to={url}>
        {label}
      </Link>
    </div>
  );
};
