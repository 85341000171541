import classNames from 'classnames';
import merge from 'lodash/fp/merge';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useScript } from '../../hooks';
import { FadeUp } from '../1-molecules/FadeIn';
import { Loader } from './Loader';

export type RaiseNowWidgetProps = {
  id?: string;
  type?: string;
  requestType?: string;
  amounts?: Array<number>;
  thankYouUrl?: string;
  backUrl?: string;
  campaign?: number;
  className?: string;
};

export const RaiseNowWidget: React.FC<RaiseNowWidgetProps> = ({
  id = 'raisenow-widget',
  type,
  requestType,
  amounts: predefinedAmounts,
  thankYouUrl,
  backUrl,
  campaign,
  className,
}) => {
  const isTestMode = APP_CONFIG.raisenowWidget.test === '1';
  const scriptStatus = useScript(
    isTestMode
      ? 'https://custom.raisenow.io/sps/stage/widget.js'
      : 'https://custom.raisenow.io/sps/widget.js',
  );
  const widget = useRef<HTMLDivElement>(null);
  const { navigate, useLocation, useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const defaultLanguage = 'de';

  const parsedQueryString = queryString.parse(search) as {
    amount_custom?: string;
    // Chosen predefined amount.
    amountu1?: string;
  };

  const { amount_custom, amountu1 } = parsedQueryString;
  const selectedAmount = amount_custom
    ? parseInt(amount_custom)
    : amountu1
    ? parseInt(amountu1)
    : undefined;
  const amounts = [
    ...(predefinedAmounts || []),
    ...(selectedAmount ? [selectedAmount] : []),
  ].filter((value, index, self): value is number =>
    Boolean(self.indexOf(value) === index && value),
  );

  /**
   * Helper to build a default values (initial values) object for a raisenow
   * widget based on the parsed query string.
   *
   * The parsed query string object might contain keys like
   * Person.Children.0.Firstname which will be transformed into an object with
   * nested properties.
   * @param qsObject
   */
  const buildDefaultValues = (qsObject: any) => {
    let result = {};
    for (const [key, value] of Object.entries(qsObject)) {
      // Dot notation to object parser from
      // https://tutorial.eyehunts.com/js/javascript-dot-notation-to-object-example-code/
      const tmpObject: any = {};
      let destination: any = tmpObject;
      key.split('.').map((k, i, values) => {
        destination = destination[k] = i == values.length - 1 ? value : {};
      });
      result = merge(result, tmpObject);
    }
    return result;
  };

  useEffect(() => {
    if (scriptStatus !== 'ready') {
      return;
    }

    const redirectOptions: any = {};
    const tamaroOptions: any = {};
    const accountOptions: any = {};
    const customerRequestOptions: any = {};

    if (thankYouUrl) {
      redirectOptions.success_url = () => {
        let state = {};
        if (window.rnw?.tamaro?.instance) {
          const transactionInfo = window.rnw.tamaro.instance.transactionInfo;
          state = {
            transactionInfo,
          };
        }
        // Redirect to Success page.
        setIsLoading(true);
        widget.current?.classList.add('is-hidden');
        navigate(thankYouUrl, {
          state,
          replace: true,
        });
      };
    }
    if (backUrl) {
      redirectOptions.back_url = () => {
        navigate(backUrl);
      };
    }

    if (type === 'donation') {
      tamaroOptions.tamaro_after_create = (tamaro: any) => {
        if (amounts.length > 0) {
          tamaro.config.amounts = amounts;
        }
        if (selectedAmount) {
          tamaro.paymentForm.setAmount(selectedAmount);
        }
        tamaro.paymentForm.data.stored_campaign_id = campaign || 6015;

        // English is not supported by the widget, so set the customer
        // language to the defaultLanguage in case the locale is 'en'.
        if (locale === 'en') {
          tamaro.paymentForm.data.stored_customer_language = defaultLanguage;
        }
      };
    }

    if (isTestMode) {
      accountOptions.account_uuid = APP_CONFIG.raisenowWidget.accountUuid;
      accountOptions.engagement_service_url =
        APP_CONFIG.raisenowWidget.engagementUrl;
    }

    if (requestType) {
      customerRequestOptions.initial_values = {
        'membership-service/customer-request': {
          Tmp: {
            RequestType: requestType,
          },
        },
      };
    }

    // If we have a query string, then we build the default (initial) values
    // based on it.
    if (!!parsedQueryString && type) {
      const defaultValues = buildDefaultValues(parsedQueryString);
      if (!customerRequestOptions.initial_values) {
        customerRequestOptions.initial_values = {};
      }
      customerRequestOptions.initial_values[type] = defaultValues;
    }

    // For English, we need to initialise the language field of users to German.
    // The language field is available in different places, for different
    // widgets, so we have this a bit ugly piece of code here that checks the
    // type of each widget and initialises the language only if there is no
    // value yet for it.
    if (locale === 'en') {
      switch (type) {
        case 'membership-service/contact-change':
          if (!customerRequestOptions.initial_values[type].ContactChange) {
            customerRequestOptions.initial_values[type].ContactChange = {};
          }
          if (
            !customerRequestOptions.initial_values[type].ContactChange
              .PersonUpdate
          ) {
            customerRequestOptions.initial_values[
              type
            ].ContactChange.PersonUpdate = {};
          }
          if (
            !customerRequestOptions.initial_values[type].ContactChange
              .PersonUpdate.Language
          ) {
            customerRequestOptions.initial_values[
              type
            ].ContactChange.PersonUpdate = {
              ...customerRequestOptions.initial_values[type].ContactChange
                .PersonUpdate,
              Language: defaultLanguage,
            };
          }
          break;

        case 'membership-service/couple-unification':
          if (!customerRequestOptions.initial_values[type].CoupleUnification) {
            customerRequestOptions.initial_values[type].CoupleUnification = {};
          }
          if (
            !customerRequestOptions.initial_values[type].CoupleUnification
              .PersonUpdate
          ) {
            customerRequestOptions.initial_values[
              type
            ].CoupleUnification.PersonUpdate = {};
          }
          if (
            !customerRequestOptions.initial_values[type].CoupleUnification
              .PersonUpdate.Language
          ) {
            customerRequestOptions.initial_values[
              type
            ].CoupleUnification.PersonUpdate = {
              ...customerRequestOptions.initial_values[type].CoupleUnification
                .PersonUpdate,
              Language: defaultLanguage,
            };
          }
          break;

        case 'membership-service/couple-separation':
          if (!customerRequestOptions.initial_values[type].CoupleSeparation) {
            customerRequestOptions.initial_values[type].CoupleSeparation = {};
          }
          if (
            !customerRequestOptions.initial_values[type].CoupleSeparation
              .PersonUpdate
          ) {
            customerRequestOptions.initial_values[
              type
            ].CoupleSeparation.PersonUpdate = {};
          }
          if (
            !customerRequestOptions.initial_values[type].CoupleSeparation
              .PersonUpdate.Language
          ) {
            customerRequestOptions.initial_values[
              type
            ].CoupleSeparation.PersonUpdate = {
              ...customerRequestOptions.initial_values[type].CoupleSeparation
                .PersonUpdate,
              Language: defaultLanguage,
            };
          }
          break;

        case 'membership-service/child-addition':
        case 'membership-service/document-order':
        case 'membership-service/magazine-change':
        case 'membership-service/bereavement-notification':
        case 'membership-service/membership-deregistration':
        case 'membership-service/customer-request':
        case 'membership-registration/permanent':
        case 'membership-registration/single-person':
        case 'membership-registration/couple':
        case 'membership-registration/small-family':
        case 'membership-registration/family':
        case 'membership-renewal':
          if (!customerRequestOptions.initial_values[type].Person) {
            customerRequestOptions.initial_values[type].Person = {};
          }
          if (!customerRequestOptions.initial_values[type].Person.Language) {
            customerRequestOptions.initial_values[type].Person = {
              ...customerRequestOptions.initial_values[type].Person,
              Language: defaultLanguage,
            };
          }
          break;

        case 'membership-registration/gift/permanent':
        case 'membership-registration/gift/couple':
        case 'membership-registration/gift/single-person':
        case 'membership-registration/gift/small-family':
        case 'membership-registration/gift/family': {
          if (!customerRequestOptions.initial_values[type].Person) {
            customerRequestOptions.initial_values[type].Person = {};
          }
          if (!customerRequestOptions.initial_values[type].Person.Language) {
            customerRequestOptions.initial_values[type].Person = {
              ...customerRequestOptions.initial_values[type].Person,
              Language: defaultLanguage,
            };
          }

          if (!customerRequestOptions.initial_values[type].Membership) {
            customerRequestOptions.initial_values[type].Membership = {};
          }
          if (
            !customerRequestOptions.initial_values[type].Membership
              .BillingPerson
          ) {
            customerRequestOptions.initial_values[
              type
            ].Membership.BillingPerson = {};
          }
          if (
            !customerRequestOptions.initial_values[type].Membership
              .BillingPerson.Language
          ) {
            customerRequestOptions.initial_values[
              type
            ].Membership.BillingPerson = {
              ...customerRequestOptions.initial_values[type].Membership
                .BillingPerson,
              Language: defaultLanguage,
            };
          }
          break;
        }
      }
    }

    window.rnw.sps
      .runWidget(`#${id}`, {
        language: locale,
        widget: type,
        debug: isTestMode,
        test_mode: isTestMode,
        ...redirectOptions,
        ...tamaroOptions,
        ...accountOptions,
        ...customerRequestOptions,
      })
      .catch((params: any) => {
        if (console && console.error) {
          console.error('Error while trying to initialize RaiseNow widget');
          console.error(params);
        }
      });

    return () => {
      window.rnw?.sps && window.rnw.sps.destroy();
    };
    // Exhaustive dependencies causes redirect issues.
    // @see https://amazeelabs.slack.com/archives/C02ELKC0LC9/p1694608909075829
    // @see https://react.dev/reference/react/useEffect#caveats
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptStatus]);

  return (
    <FadeUp yGap={20} className={className}>
      {isLoading && <Loader />}
      <div
        id={id}
        ref={widget}
        className={classNames({
          'is-donation': !!campaign,
        })}
      />
    </FadeUp>
  );
};

export default RaiseNowWidget;
