import { useLocation } from '@reach/router';
import classNames from 'classnames';
import queryString from 'query-string';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DOMAINS } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useSearchResultsFilters } from '../../hooks';
import { FormWideSelect } from '../0-atoms';
import { SubpageHeaderHero } from '../2-organisms';

export type SearchResultFormProps = {
  title?: string;
  resultsCount?: number;
  className?: string;
};

export const SearchResultForm: React.FC<SearchResultFormProps> = ({
  title,
  resultsCount,
  className,
}) => {
  const { t } = useTranslation();
  const { navigate } = useFrameworkDependencies();
  const { domain, search, setDomain, setSearch } = useSearchResultsFilters();
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const location = useLocation();

  const updateUrl = (query?: { domain?: string; search?: string }) =>
    navigate(
      `${location.pathname}?${queryString.stringify(
        {
          domain,
          search,
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      )}`,
    );

  const updateSearch = (searchTerm: string) => {
    if (searchTerm === search) {
      return;
    }
    setSearch(searchTerm);
    updateUrl({
      search: searchTerm,
    });
  };

  return (
    <>
      <SubpageHeaderHero title={' '} className={'third-level'} />
      <div className={classNames('SearchField', className)}>
        <div className="SearchField--content">
          {title && (
            <h1
              className="SearchField--title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          <form
            className="SearchField--form"
            onSubmit={(event) => {
              event.preventDefault();
              searchFieldRef.current &&
                updateSearch(searchFieldRef.current.value);
            }}
          >
            <label className="visuallyhidden" htmlFor="SearchField--input">
              {t('Search term')}
            </label>
            <div className="SearchField--form--inner">
              <div className="SearchField--inputWrapper">
                <input
                  ref={searchFieldRef}
                  className="SearchField--input"
                  id="SearchField--input"
                  name="search"
                  type="text"
                  defaultValue={search}
                />
              </div>
              <div className="SearchField--buttonWrapper">
                <button className="SearchField--submit" type="submit">
                  <span className="visuallyhidden">{t('Search')}</span>
                  <i className="ico ico-inpage-search" />
                </button>
              </div>
            </div>
            {search && (
              <FormWideSelect
                label={t('Filter by organisation')}
                id="u3"
                name="domain"
                defaultValue={domain || ''}
                options={Object.values(DOMAINS)
                  .filter(({ id }) => id !== 'paraplegie_ch')
                  .map(({ id, name }) => ({
                    value: id,
                    label: t(name),
                  }))}
                onChange={(event) => {
                  setDomain(event.target.value);
                  updateUrl({
                    domain: event.target.value,
                  });
                }}
              />
            )}
          </form>
          {search && resultsCount === 0 ? (
            <p
              className="SearchField--results"
              dangerouslySetInnerHTML={{
                __html: t('Please use other keywords for searching.'),
              }}
            />
          ) : null}
          {!search && (
            <p
              className="SearchField--results"
              dangerouslySetInnerHTML={{
                __html: t('Please input some keywords'),
              }}
            />
          )}
          {search && resultsCount && resultsCount > 0 ? (
            <p
              className="SearchField--results"
              dangerouslySetInnerHTML={{
                __html: t(
                  'Your search returned <strong>{{results}} results</strong>.',
                  { results: resultsCount },
                ),
              }}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
