const internalPrefixes = [
  'css',
  'domainPictures',
  'img',
  'page-data',
  'static',
  'widgets',
];

export const isInternalUrl = (url: string) =>
  url.match(new RegExp(`^/${internalPrefixes.join('|^/')}`, 'i'));
