import React from 'react';
import { useTranslation } from 'react-i18next';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { Icon } from '../../types';
import { CTAButton, CTAButtonProps } from '../0-atoms';

export type CTAMemberButtonProps = Omit<
  CTAButtonProps,
  'icon' | 'label' | 'url'
>;

export const CTAMemberButton: React.FC<CTAMemberButtonProps> = ({
  ...props
}) => {
  const { t } = useTranslation();
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const memberPath = APP_CONFIG.memberPagePath[locale];

  return (
    <CTAButton
      icon={Icon.ProfileWhiteSmall}
      className="font-agenda font-semibold"
      label={t('Become a member')}
      url={memberPath}
      {...props}
    />
  );
};
