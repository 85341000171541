import { useTranslation } from 'react-i18next';

export const useDoctorFunctionTerms = () => {
  const { t } = useTranslation();

  return [
    {
      id: '1',
      label: t('Head of Departments'),
      terms: ['25', '47', '141'],
    },
    {
      id: '2',
      label: t('Senior Consultants'),
      terms: ['26', '48', '64', '65', '138'],
    },
    {
      id: '3',
      label: t('Specialty Registrars'),
      terms: ['27', '49'],
    },
    {
      id: '4',
      label: t('Specialist'),
      terms: ['116', '119', '125', '122'],
    },
  ];
};
