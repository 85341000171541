import { decode } from 'he';
import React from 'react';

export type AccordionTitleProps = {
  title?: string;
  subtitle?: string;
};

export const AccordionTitle: React.FC<AccordionTitleProps> = ({
  title = '',
  subtitle = '',
}) => (
  <div className="AccordionItem--toggle--text">
    {title && (
      <h3
        className="AccordionItem--toggle--title"
        dangerouslySetInnerHTML={{ __html: decode(title) }}
      />
    )}
    <strong
      className="AccordionItem--toggle--subtitle"
      dangerouslySetInnerHTML={{ __html: subtitle }}
    />
  </div>
);
