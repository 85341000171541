import queryString from 'query-string';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { CompaniesFiltersProvider, PagerProvider } from '../../hooks';
import { CompaniesView } from '../2-organisms';

export type ParagraphViewsCompaniesProps = {
  className?: string;
};

export const ParagraphViewsCompanies: React.FC<
  ParagraphViewsCompaniesProps
> = ({ className }) => {
  const { useLocation } = useFrameworkDependencies();
  const { search } = useLocation();
  const parsedSearch = queryString.parse(search) as {
    category?: string;
    glossary?: string;
    searchTerm?: string;
  };
  const { category, glossary, searchTerm } = parsedSearch;
  // @todo simplify the filters by using the ones
  //   from ParagraphListingCompanySearch instead of
  //   a standalone query.
  return (
    <CompaniesFiltersProvider
      category={category}
      glossary={glossary}
      searchTerm={searchTerm}
    >
      <PagerProvider>
        <CompaniesView className={className} />
      </PagerProvider>
    </CompaniesFiltersProvider>
  );
};

export default ParagraphViewsCompanies;
