import { ParagraphRaiseNowFormFragment } from '@custom/schema';

export const transformParagraphRaisenowNotificationFormToProps = (
  paragraph: ParagraphRaiseNowFormFragment,
) => {
  let type;
  switch (paragraph.formType) {
    case 'address-mutation':
      type = 'membership-service/contact-change';
      break;
    case 'document-order':
      type = 'membership-service/document-order';
      break;
    case 'membership-mutation':
      type = 'membership-service/contact-change';
      break;
    default:
      type = paragraph.formType || 'membership-service';
  }

  // Extract the success/back url and add the langcode if not already present.
  let successUrl = undefined;
  let backUrl = undefined;
  let langcode = undefined;

  if (paragraph?.thankYouPage?.path) {
    langcode = paragraph?.thankYouPage?.langcode;
    successUrl = `${paragraph?.thankYouPage?.path}`;

    if (langcode && !successUrl.includes(`/${langcode}/`)) {
      successUrl = `/${langcode}/${successUrl}`;
    }
  }

  if (paragraph?.backPage?.path) {
    langcode = paragraph?.backPage?.langcode;
    backUrl = `${paragraph?.backPage?.path}`;

    if (langcode && !backUrl.includes(`/${langcode}/`)) {
      backUrl = `/${langcode}/${backUrl}`;
    }
  }

  return {
    id: paragraph.id,
    type: type,
    requestType: paragraph.requestType,
    successUrl: successUrl,
    backUrl: backUrl,
  };
};
