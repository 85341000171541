import {
  ParagraphAccordionDownloadItemFragment,
  ParagraphAccordionFragment,
  ParagraphAccordionLinkItemFragment,
  ParagraphAccordionSimpleItemFragment,
} from '@custom/schema';
import React from 'react';

import { isPublished } from '../../utils';
import { isTruthy } from '../../utils/isTruthy';
import { getImageStyleUrl } from './MediaImage';

export const transformParagraphAccordionToProps = (
  paragraph: ParagraphAccordionFragment,
) => {
  const getImageFromItem = (
    item:
      | ParagraphAccordionSimpleItemFragment
      | ParagraphAccordionLinkItemFragment
      | ParagraphAccordionDownloadItemFragment,
  ) => {
    const paragraphImage = item?.image;

    return paragraphImage ? (
      <div
        className="AccordionItem--toggle--img"
        style={{
          backgroundImage: `url(${getImageStyleUrl(
            paragraphImage,
            'accordion_item',
          )})`,
        }}
      />
    ) : undefined;
  };

  return {
    isCollapsible: paragraph.collapsible || true,
    items:
      paragraph?.items
        ?.filter(isPublished)
        .filter(isTruthy)
        .map((item) => {
          if (item.__typename === 'ParagraphAccordionSimpleItem') {
            return {
              type: 'item' as 'item',
              title: item?.itemTitle,
              subtitle: item.subTitle,
              image: getImageFromItem(item),
              content: item?.content?.filter(isPublished).filter(isTruthy),
              hasLogoImage: item.hasLogoImage,
              iconClass: item?.iconClass,
            };
          } else if (item.__typename === 'ParagraphAccordionLinkItem') {
            return {
              type: 'link' as 'link',
              title: item.itemTitle,
              subtitle: item.subTitle,
              image: getImageFromItem(item),
              url: item.link?.url,
              target: item.link?.options?.attributes?.target,
              linkLabel: item.link?.title,
              content: item?.content?.filter(isPublished).filter(isTruthy),
              hasLogoImage: item.hasLogoImage,
              iconClass: item?.iconClass,
            };
          } else if (item.__typename === 'ParagraphAccordionDownloadItem') {
            return {
              type: 'download' as 'download',
              title: item.itemTitle,
              subtitle: item.subTitle,
              image: getImageFromItem(item),
              url: item?.file?.url,
              linkLabel: item?.file?.name,
              content: item?.content?.filter(isPublished).filter(isTruthy),
              hasLogoImage: item.hasLogoImage,
              iconClass: item?.iconClass,
            };
          } else {
            return {
              type: 'item' as 'item',
            };
          }
        }) || [],
  };
};
