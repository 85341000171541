import React from 'react';

export type IconWheelChairProps = {
  className?: string;
  fill?: string | '#FFF';
  position?: 'left' | 'center';
};

export const IconWheelChair: React.FC<IconWheelChairProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <>
        <path
          fill={fill}
          d="m48.28,50.78c1.71-.96,3.69-1.19,5.58-.65.86.24,1.67.64,2.39,1.18.4.3.9.42,1.39.35.5-.07.93-.33,1.23-.73.62-.83.45-2.01-.38-2.62-1.08-.81-2.3-1.41-3.61-1.78-2.85-.8-5.84-.45-8.43.99-2.59,1.45-4.45,3.81-5.26,6.66-.81,2.85-.45,5.84.99,8.43,1.45,2.59,3.81,4.45,6.66,5.26.99.28,2,.42,3.01.42,4.94,0,9.33-3.32,10.67-8.07.28-1-.3-2.03-1.29-2.32-.48-.13-.99-.08-1.43.17-.44.24-.75.64-.89,1.13-1.1,3.89-5.17,6.16-9.06,5.06-3.89-1.1-6.16-5.17-5.06-9.06.53-1.89,1.77-3.45,3.48-4.41Z"
        />
        <path
          fill={fill}
          d="m33.92,16.21l-.84,2.19c-.81,2.13-.9,4.52-.25,6.72l4.2,13.65c.89,3.02,3.71,5.12,6.85,5.12h16.83c2.93,0,5.31-2.38,5.31-5.31s-2.38-5.31-5.31-5.31h-13.48l-3.69-12.21c-.14-.48-.47-.87-.91-1.11-.44-.24-.95-.29-1.43-.14-.48.14-.87.47-1.11.91s-.29.95-.14,1.43l4.1,13.55c.24.8.96,1.33,1.8,1.33h14.87c.86,0,1.56.7,1.56,1.56s-.7,1.56-1.56,1.56h-16.83c-1.49,0-2.84-1.01-3.26-2.45l-4.2-13.65c-.42-1.4-.36-2.93.17-4.3l.64-1.68c.9.28,1.82.42,2.75.42,5.09,0,9.24-4.15,9.24-9.24S45.07,0,39.97,0s-9.24,4.15-9.24,9.24c0,2.67,1.16,5.2,3.18,6.97Zm.57-6.97c0-3.03,2.46-5.49,5.49-5.49s5.49,2.46,5.49,5.49-2.46,5.49-5.49,5.49-5.49-2.46-5.49-5.49Z"
        />
        <path
          fill={fill}
          d="m48.72,18.27c-.44-.24-.95-.29-1.42-.15-.99.3-1.56,1.34-1.26,2.33l3.07,10.3c.23.79.97,1.34,1.8,1.34.18,0,.36-.03.54-.08.99-.3,1.56-1.34,1.26-2.33l-3.07-10.3c-.14-.48-.47-.88-.91-1.11Z"
        />
        <path
          fill={fill}
          d="m98.52,76.25h-36.63c1.42-.75,2.74-1.63,3.93-2.64.96-.82,1.88-1.76,2.74-2.81h10.26s.03,0,.05,0h4.24c1.03,0,1.88-.84,1.88-1.88,0-.58-.27-1.13-.73-1.48.69-1.36.85-2.91.45-4.37l-4.72-17.15c-1.56-5.66-5.66-9.04-10.97-9.04-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88c3.57,0,6.25,2.29,7.35,6.28l4.72,17.15c.17.61.09,1.24-.22,1.79-.31.55-.81.94-1.42,1.11-.58.16-1.23.07-1.78-.24-.56-.32-.96-.84-1.13-1.44l-4.03-14.48c-.05-.16-.12-.33-.23-.5-.88-2.62-2.22-5.01-3.99-7.09-.32-.38-.78-.61-1.28-.65-.5-.04-.99.12-1.37.44-.38.32-.61.78-.65,1.28-.04.5.12.98.44,1.37,6.36,7.48,5.45,18.75-2.04,25.11-7.48,6.36-18.75,5.45-25.11-2.03-5.85-6.88-5.62-17.06.53-23.67.7-.76.66-1.95-.1-2.65-.76-.7-1.95-.66-2.65.1-7.45,8.01-7.72,20.32-.64,28.65,1.78,2.1,3.94,3.81,6.41,5.1H6.48c-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88h92.05c1.03,0,1.88-.84,1.88-1.88s-.84-1.88-1.88-1.88Zm-27.51-9.2c.5-.97.93-1.99,1.28-3.04l.63,2.26c.07.26.16.52.27.78h-2.17Z"
        />
      </>
    ) : (
      <>
        <path
          fill={fill}
          d="m43.68,50.78c1.71-.96,3.69-1.19,5.58-.65.86.24,1.67.64,2.39,1.18.4.3.9.42,1.39.35.5-.07.93-.33,1.23-.73.62-.83.45-2.01-.38-2.62-1.08-.81-2.3-1.41-3.61-1.78-2.85-.8-5.84-.45-8.43.99-2.59,1.45-4.45,3.81-5.26,6.66-.81,2.85-.45,5.84.99,8.43,1.45,2.59,3.81,4.45,6.66,5.26.99.28,2,.42,3.01.42,4.94,0,9.33-3.32,10.67-8.07.28-1-.3-2.03-1.29-2.32-.48-.13-.99-.08-1.43.17-.44.24-.75.64-.89,1.13-1.1,3.89-5.17,6.16-9.06,5.06-3.89-1.1-6.16-5.17-5.06-9.06.53-1.89,1.77-3.45,3.48-4.41Z"
        />
        <path
          fill={fill}
          d="m29.32,16.21l-.84,2.19c-.81,2.13-.9,4.52-.25,6.72l4.2,13.65c.89,3.02,3.71,5.12,6.85,5.12h16.83c2.93,0,5.31-2.38,5.31-5.31s-2.38-5.31-5.31-5.31h-13.48l-3.69-12.21c-.14-.48-.47-.87-.91-1.11-.44-.24-.95-.29-1.43-.14-.48.14-.87.47-1.11.91s-.29.95-.14,1.43l4.1,13.55c.24.8.96,1.33,1.8,1.33h14.87c.86,0,1.56.7,1.56,1.56s-.7,1.56-1.56,1.56h-16.83c-1.49,0-2.84-1.01-3.26-2.45l-4.2-13.65c-.42-1.4-.36-2.93.17-4.3l.64-1.68c.9.28,1.82.42,2.75.42,5.09,0,9.24-4.15,9.24-9.24S40.47,0,35.37,0s-9.24,4.15-9.24,9.24c0,2.67,1.16,5.2,3.18,6.97Zm.57-6.97c0-3.03,2.46-5.49,5.49-5.49s5.49,2.46,5.49,5.49-2.46,5.49-5.49,5.49-5.49-2.46-5.49-5.49Z"
        />
        <path
          fill={fill}
          d="m44.12,18.27c-.44-.24-.95-.29-1.42-.15-.99.3-1.56,1.34-1.26,2.33l3.07,10.3c.23.79.97,1.34,1.8,1.34.18,0,.36-.03.54-.08.99-.3,1.56-1.34,1.26-2.33l-3.07-10.3c-.14-.48-.47-.88-.91-1.11Z"
        />
        <path
          fill={fill}
          d="m93.92,76.25h-36.63c1.42-.75,2.74-1.63,3.93-2.64.96-.82,1.88-1.76,2.74-2.81h10.26s.03,0,.05,0h4.24c1.03,0,1.88-.84,1.88-1.88,0-.58-.27-1.13-.73-1.48.69-1.36.85-2.91.45-4.37l-4.72-17.15c-1.56-5.66-5.66-9.04-10.97-9.04-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88c3.57,0,6.25,2.29,7.35,6.28l4.72,17.15c.17.61.09,1.24-.22,1.79-.31.55-.81.94-1.42,1.11-.58.16-1.23.07-1.78-.24-.56-.32-.96-.84-1.13-1.44l-4.03-14.48c-.05-.16-.12-.33-.23-.5-.88-2.62-2.22-5.01-3.99-7.09-.32-.38-.78-.61-1.28-.65-.5-.04-.99.12-1.37.44-.38.32-.61.78-.65,1.28-.04.5.12.98.44,1.37,6.36,7.48,5.45,18.75-2.04,25.11-7.48,6.36-18.75,5.45-25.11-2.03-5.85-6.88-5.62-17.06.53-23.67.7-.76.66-1.95-.1-2.65-.76-.7-1.95-.66-2.65.1-7.45,8.01-7.72,20.32-.64,28.65,1.78,2.1,3.94,3.81,6.41,5.1H1.88c-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88h92.05c1.03,0,1.88-.84,1.88-1.88s-.84-1.88-1.88-1.88Zm-27.51-9.2c.5-.97.93-1.99,1.28-3.04l.63,2.26c.07.26.16.52.27.78h-2.17Z"
        />
      </>
    )}
  </svg>
);
