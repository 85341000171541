import { ParagraphImageGridFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { transformImageFragmentToProps } from './MediaImage';

export const transformParagraphImageGridToProps = (
  paragraph: ParagraphImageGridFragment,
) => {
  const leftParagraphImage = paragraph?.leftImage;
  const imageLeft = leftParagraphImage ? (
    <Image
      {...transformImageFragmentToProps(leftParagraphImage, [
        'small',
        'medium',
        'large',
      ])}
      size="small"
      className="ArticleFigure--img"
      fit
    />
  ) : undefined;

  const rightParagraphImage = paragraph?.rightImage;
  const imageRight = rightParagraphImage ? (
    <Image
      {...transformImageFragmentToProps(rightParagraphImage, [
        'small',
        'medium',
        'large',
      ])}
      size="large"
      className="ArticleFigure--img"
      fit
    />
  ) : undefined;

  return {
    imageLeft,
    imageRight,
  };
};
