import classNames from 'classnames';
import React from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphCustomHtmlProps = {
  html?: string;
  className?: string;
};

export const ParagraphCustomHtml: React.FC<ParagraphCustomHtmlProps> = ({
  html,
  className,
}) => {
  html = html || '';
  className = className || 'paragraph-custom-html';
  return (
    <FadeUp yGap={20}>
      <div
        className={classNames('ArticleTitle', className)}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </FadeUp>
  );
};

export default ParagraphCustomHtml;
