import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

export type HeartAttackProps = {
  className?: string;
};

export const HeartAttack: React.FC<HeartAttackProps> = ({ className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const heartAttack = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!heartAttack.current) {
      return;
    }
    for (const heart of heartAttack.current.children) {
      const transitionDelay = Math.round(Math.random() * 2000) + 1000;
      const transitionDuration = Math.round(Math.random() * 1500);

      (heart as HTMLSpanElement).style.top = Math.random() * 100 + '%';
      (heart as HTMLSpanElement).style.transitionDelay = transitionDelay + 'ms';
      (heart as HTMLSpanElement).style.transitionDuration =
        transitionDuration + 'ms';

      setTimeout(() => {
        heart.classList.add('move');
      }, transitionDelay + transitionDuration);
    }
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 8000);
  }, [heartAttack]);

  return (
    <div
      className={classNames('HeartAttack', className, {
        'is-visible': isVisible,
      })}
      ref={heartAttack}
    >
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
      <span className="HeartAttack--heart" />
    </div>
  );
};
