import React from 'react';
import { useTranslation } from 'react-i18next';

import { Company } from '../../types';
import { ParagraphCopyText } from '../0-atoms';
import { ParagraphAccordion } from '../2-organisms';
import { FadeUp } from './FadeIn';

export type CompanyTeaserListProps = {
  companies: Company[];
  title?: string;
  className?: string;
};

export const CompanyTeaserList: React.FC<CompanyTeaserListProps> = ({
  companies,
  title,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <FadeUp yGap={20}>
      <div className="ArticleTitle">
        <h2 className="ArticleTitle--title h3">{title}</h2>
      </div>
      <ParagraphAccordion
        className={className}
        items={companies.map((company) => ({
          type: 'link',
          title: company.name,
          subtitle: [company.plz, company.place].filter((i) => i).join(' '),
          image: company.logo,
          content: [
            <ParagraphCopyText key={`company-${company.name}`}>
              {company.details && (
                <div
                  className={`company-details`}
                  dangerouslySetInnerHTML={{ __html: company.details }}
                />
              )}
              <p>
                {!!company.paraworkJobCompany && !!company.paraworkJobType && (
                  <>
                    {t('We offer')}:&nbsp;
                    {company.paraworkJobType.map((data, index) => (
                      <>
                        {data.title}
                        {index + 1 !== company.paraworkJobType?.length && ', '}
                      </>
                    ))}
                  </>
                )}
              </p>
              <p>
                {company.name}
                <br />
                {company.address}
                <br />
                {[company.plz, company.place].filter((i) => i).join(' ')}
              </p>
              <p>
                {company.email && (
                  <>
                    {t('Mail')}&nbsp;
                    <a href={`mailto:${company.email}`}>{company.email}</a>
                    <br />
                  </>
                )}
                {company.telephone && (
                  <>
                    {t('Telephone')}&nbsp;
                    <a href={`tel:${company.telephone}`}>{company.telephone}</a>
                    <br />
                  </>
                )}
                {company.website && (
                  <>
                    {t('Website')}&nbsp;
                    <a href={company.website} target="_blank" rel="noreferrer">
                      {company.website}
                    </a>
                  </>
                )}
              </p>
            </ParagraphCopyText>,
          ],
          hasLogoImage: true,
          ...(company.hasContent && {
            url: company.url,
            linkLabel: t('Details'),
          }),
        }))}
      />
    </FadeUp>
  );
};
