import React from 'react';

export type IconHeartProps = {
  className?: string;
  fill?: string | '#fff';
  position?: 'left' | 'center';
};

export const IconHeart: React.FC<IconHeartProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <path
        fill={fill}
        d="m74.45,0C66.95,0,60.43,3.57,55.6,10.33c-.37.52-.72,1.04-1.04,1.55l-2.06,3.25-2.05-3.26c-.32-.51-.67-1.03-1.04-1.55C44.56,3.57,38.05,0,30.57,0c-.06,0-.12,0-.17,0C23.83,0,17.59,2.81,13.25,7.72c-4.34,4.99-6.69,11.41-6.61,18.04,0,7.53,2.96,14.51,9.33,22.01,5.77,6.79,14.15,13.86,23.85,22.06,3.94,3.32,7.5,6.34,11.37,9.68.74.64,1.86.64,2.6,0,4.01-3.48,7.75-6.63,11.36-9.68,6.3-5.31,11.68-9.86,16.33-14.25,8.75-8.26,16.85-17.59,16.85-29.8.08-6.66-2.27-13.07-6.62-18.08C87.36,2.76,81-.05,74.45,0Zm6.12,50.96l.02.02-1.82,1.72c-4.58,4.32-9.93,8.84-16.14,14.08l-.26.22c-2.68,2.26-5.42,4.57-8.28,7.03l-1.58,1.36-1.58-1.36c-2.6-2.23-5.11-4.35-7.55-6.41l-.96-.81C22.59,50.08,10.63,39.98,10.63,25.79,10.63,13.36,19.2,3.98,30.57,3.98c6.16,0,11.35,2.85,15.45,8.46,1.98,2.75,3.51,5.78,4.54,8.99.17.61.5,1.03.96,1.29.46.26,1,.33,1.51.19.67-.19,1.2-.72,1.39-1.39.05-.17,4.96-17.55,20.01-17.55,11.37,0,19.94,9.38,19.94,21.82,0,8.28-3.9,15.46-13.8,25.17Z"
      />
    ) : (
      <path
        fill={fill}
        d="m67.8,0C60.3,0,53.79,3.57,48.95,10.33c-.37.52-.72,1.04-1.04,1.55l-2.06,3.25-2.05-3.26c-.32-.51-.67-1.03-1.04-1.55C37.92,3.57,31.4,0,23.92,0c-.06,0-.12,0-.17,0C17.18,0,10.95,2.81,6.61,7.72,2.27,12.72-.08,19.13,0,25.76c0,7.53,2.96,14.51,9.33,22.01,5.77,6.79,14.15,13.86,23.85,22.06,3.94,3.32,7.5,6.34,11.37,9.68.74.64,1.86.64,2.6,0,4.01-3.48,7.75-6.63,11.36-9.68,6.3-5.31,11.68-9.86,16.33-14.25,8.75-8.26,16.85-17.59,16.85-29.8.08-6.66-2.27-13.07-6.62-18.08C80.71,2.76,74.36-.05,67.8,0Zm6.12,50.96l.02.02-1.82,1.72c-4.58,4.32-9.93,8.84-16.14,14.08l-.26.22c-2.68,2.26-5.42,4.57-8.28,7.03l-1.58,1.36-1.58-1.36c-2.6-2.23-5.11-4.35-7.55-6.41l-.96-.81C15.94,50.08,3.98,39.98,3.98,25.79,3.98,13.36,12.56,3.98,23.92,3.98c6.16,0,11.35,2.85,15.45,8.46,1.98,2.75,3.51,5.78,4.54,8.99.17.61.5,1.03.96,1.29.46.26,1,.33,1.51.19.67-.19,1.2-.72,1.39-1.39.05-.17,4.96-17.55,20.01-17.55,11.37,0,19.94,9.38,19.94,21.82,0,8.28-3.9,15.46-13.8,25.17Z"
      />
    )}
  </svg>
);
