import React from 'react';

import { History } from '../../types';
import { HistoryNav } from '../0-atoms';
import { Content } from './Content';

export type HistoryViewProps = {
  historyData: History[];
  className?: string;
};

export const HistoryView: React.FC<HistoryViewProps> = ({
  historyData,
  className,
}) => {
  return (
    <div className={className}>
      <HistoryNav
        years={historyData
          .map(({ title }) => (title ? parseInt(title) : undefined))
          .filter((i): i is number => Boolean(i))}
      />
      {historyData.map((history: History, index: number) => (
        <div
          key={`history-${index}`}
          data-history={history.title}
          id={history.title}
        >
          <div className="ArticleTitle">
            <h2 className="ArticleTitle--title h1">{history.title}</h2>
          </div>
          <Content content={history?.content} />
        </div>
      ))}
    </div>
  );
};
