import classNames from 'classnames';
import { decode } from 'he';
import React, { PropsWithChildren, ReactNode } from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphTwoColumnTextImgProps = PropsWithChildren<{
  text?: string;
  className?: string;
  image?: string | ReactNode;
  title?: string;
  tag?: string;
}>;

export const ParagraphTwoColumnTextImg: React.FC<
  ParagraphTwoColumnTextImgProps
> = ({
  title = '',
  tag = 'h3',
  text = '',
  image = '',
  className,
  children,
}) => {
  const element = tag ? tag.replace(/^<|>$/g, '') : 'h3';
  return (
    <FadeUp yGap={20}>
      <section className="TwoColumnTextImg TwoColumnTextImg--wrapper">
        <div
          className={classNames(
            'TwoColumnTextImg--item TwoColumnTextImg--first',
            className,
          )}
        >
          {title && (
            <div
              className={classNames(
                'TwoColumnTextImg--title-wrapper',
                className,
              )}
            >
              {React.createElement(
                element,
                {
                  className: classNames(
                    `TwoColumnTextImg--title ${element}` || element,
                  ),
                },
                decode(title),
              )}
            </div>
          )}

          {text && (
            <div
              className="TwoColumnTextImg--text-first"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
        {image && (
          <div
            className={classNames(
              'TwoColumnTextImg--item TwoColumnTextImg--last TwoColumnTextImg--image',
              className,
            )}
          >
            {typeof image === 'string' ? (
              <div
                className="TwoColumnTextImg--image-cover"
                style={{ backgroundImage: `url(${image})` }}
              />
            ) : (
              image
            )}
          </div>
        )}
        {!text && !image && (
          <div className={classNames('ArticleText', className)}>{children}</div>
        )}
      </section>
    </FadeUp>
  );
};

export default ParagraphTwoColumnTextImg;
