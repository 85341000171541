import { ListingMedicalOfferSearchQuery } from '@custom/schema';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useLocalization,
  useMedicalDomainTerms,
  useMedicalOffersFilters,
} from '../../hooks';
import { isTruthy } from '../../utils/isTruthy';
import { useOperation } from '../../utils/operation';
import { Loader } from '../0-atoms';
import {
  MedicalOffersFilterForm,
  MedicalOffersTeaserList,
} from '../1-molecules';

export type MedicalOffersViewProps = {
  className?: string;
};

export const MedicalOffersView: React.FC<MedicalOffersViewProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const medicalDomainTerms = useMedicalDomainTerms();
  // Domain stands for medical domain, not domain access.
  const { domain, glossary } = useMedicalOffersFilters();
  const { locale: currentLocale } = useLocalization();
  const { data, isLoading } = useOperation(ListingMedicalOfferSearchQuery, {
    medicalDomain: domain ? [domain] : [],
    glossary,
    language: currentLocale,
    limit: 100, // No pagination yet.
    offset: 0,
  });
  const medicalOffers = data?.listingMedicalOfferSearch?.items || [];

  return (
    <div
      className={classNames(className, {
        'Pane is-loading relative': isLoading,
      })}
    >
      {isLoading && <Loader />}
      {medicalDomainTerms && (
        <MedicalOffersFilterForm
          domains={medicalDomainTerms.map(({ id, title }) => ({
            id: id,
            label: title || '',
          }))}
        />
      )}

      {medicalOffers?.length ? (
        <MedicalOffersTeaserList
          // @todo set ContentSecondLevelPage type.
          offers={medicalOffers?.filter(isTruthy).map((medicalOffer) => ({
            label: medicalOffer.title,
            url: medicalOffer.path,
            domain:
              [...medicalOffer.domainAccess]
                // @todo
                //.sort((a, b) => a.weight - b.weight)
                .shift()?.id || 'paraplegie_ch',
          }))}
        />
      ) : (
        <p className="ArticleText">
          {t('There are no medical offers that match your filters.')}
        </p>
      )}
    </div>
  );
};
