import React from 'react';

export type IconDoctorProps = {
  className?: string;
  fill?: string | '#FFF';
  position?: 'left' | 'center';
};

export const IconDoctor: React.FC<IconDoctorProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <>
        <path
          fill={fill}
          d="m35.36,41.47s.03,0,.05,0c.48,0,.93-.18,1.27-.51.36-.34.56-.8.58-1.38v-20.63c0-8.41,6.84-15.25,15.25-15.25s15.25,6.84,15.25,15.25v20.62c0,1.02.83,1.85,1.85,1.85s1.85-.83,1.85-1.85v-20.62c0-10.45-8.49-18.95-18.96-18.96h-1l.02.03c-4.69.24-9.06,2.18-12.41,5.52-3.58,3.58-5.55,8.33-5.55,13.41v20.61c-.01.49.17.96.51,1.32.34.36.8.56,1.29.58Z"
        />
        <path
          fill={fill}
          d="m76.82,47.27c-3.33-3.33-7.75-5.17-12.47-5.17-1.02-.02-1.87.78-1.9,1.8-.02.84.52,1.58,1.35,1.82v8.47c-3.06.84-5.24,3.65-5.25,6.86v1.32c.03,1.02.86,1.82,1.9,1.8.98-.03,1.77-.82,1.8-1.82v-1.3c.01-.91.38-1.76,1.04-2.39.65-.63,1.51-.96,2.42-.96,1.85.03,3.33,1.5,3.35,3.35v1.29c-.01.49.17.96.51,1.32s.8.56,1.29.58c.5.01.96-.17,1.32-.51s.56-.8.58-1.38v-1.31c0-3.21-2.2-6.03-5.26-6.86v-8.02c6.31,1.43,10.8,7.04,10.77,13.57v8.68h-5.51v-.77c.01-.49-.17-.96-.51-1.32-.34-.36-.8-.56-1.29-.58-.49-.01-.96.17-1.32.51-.36.34-.56.8-.58,1.38v8.68h-33.13v-8.66c.01-.49-.17-.96-.51-1.32-.34-.36-.8-.56-1.29-.58-.98-.03-1.87.78-1.9,1.89v.79h-5.5v-8.68c-.03-6.52,4.46-12.13,10.77-13.56v9.5c-.96.43-1.73,1.19-2.19,2.15-.52,1.08-.58,2.29-.18,3.42.4,1.13,1.21,2.03,2.29,2.55,1.08.52,2.29.58,3.42.18s2.03-1.21,2.55-2.29c.52-1.08.58-2.29.18-3.42-.41-1.16-1.26-2.08-2.37-2.58v-9.94c.76-.21,1.33-.9,1.35-1.73.03-1.02-.78-1.87-1.79-1.9h-.09s-.04,0-.04,0c-4.7,0-9.11,1.84-12.43,5.16-3.33,3.33-5.16,7.75-5.17,12.48v18.41c0,1.02.83,1.85,1.85,1.85h55.24c1.02,0,1.85-.83,1.85-1.85v-18.41c0-4.71-1.83-9.14-5.15-12.47Zm1.45,24.84v4.19h-5.51v-4.19h5.51Zm-46.04,0v4.19h-5.5v-4.19h5.5Zm7.12-13.69v.53c.19,0,.39.09.53.24.14.15.22.35.21.56-.01.41-.35.75-.76.76-.21,0-.41-.07-.56-.21-.15-.14-.24-.34-.24-.55-.01-.43.33-.79.76-.8l.06-.53h0Z"
        />
        <path
          fill={fill}
          d="m44.42,45.03l6.57,9.21c.36.5.93.77,1.51.77.37,0,.75-.11,1.08-.35.16-.12.31-.26.42-.42l6.58-9.2c.23-.32.34-.69.34-1.08v-6.74c2.51-2.33,3.94-5.61,3.94-9.04v-7.89c0-.49-.19-.96-.54-1.31-.35-.35-.81-.55-1.31-.55h-2.63c-2.51,0-3.48-2.58-3.51-2.67-.26-.79-.99-1.3-1.84-1.27-.76.04-1.43.55-1.66,1.26,0,.03-.97,2.68-3.5,2.68h-7.9c-1.02,0-1.85.84-1.84,1.86v7.89c0,3.43,1.43,6.71,3.94,9.03v6.75c0,.39.13.76.35,1.07Zm-.6-16.86v-6.05h6.03c2,.04,3.94-.81,5.27-2.3,1.33,1.49,3.26,2.35,5.26,2.3h.77v6.05c0,2.32-.9,4.49-2.54,6.13-1.64,1.64-3.81,2.54-6.13,2.54h0c-4.78,0-8.67-3.89-8.67-8.67Zm3.94,11.43c3.02,1.26,6.44,1.26,9.46,0v3.76l-4.73,6.62-4.73-6.62v-3.76Z"
        />
      </>
    ) : (
      <>
        <path
          fill={fill}
          d="m12.33,41.47s.03,0,.05,0c.48,0,.93-.18,1.27-.51.36-.34.56-.8.58-1.38v-20.63c0-8.41,6.84-15.25,15.25-15.25s15.25,6.84,15.25,15.25v20.62c0,1.02.83,1.85,1.85,1.85s1.85-.83,1.85-1.85v-20.62C48.43,8.51,39.94,0,29.47,0h-1l.02.03c-4.69.24-9.06,2.18-12.41,5.52-3.58,3.58-5.55,8.33-5.55,13.41v20.61c-.01.49.17.96.51,1.32.34.36.8.56,1.29.58Z"
        />
        <path
          fill={fill}
          d="m53.79,47.27c-3.33-3.33-7.75-5.17-12.47-5.17-1.02-.02-1.87.78-1.9,1.8-.02.84.52,1.58,1.35,1.82v8.47c-3.06.84-5.24,3.65-5.25,6.86v1.32c.03,1.02.86,1.82,1.9,1.8.98-.03,1.77-.82,1.8-1.82v-1.3c.01-.91.38-1.76,1.04-2.39.65-.63,1.51-.96,2.42-.96,1.85.03,3.33,1.5,3.35,3.35v1.29c-.01.49.17.96.51,1.32s.8.56,1.29.58c.5.01.96-.17,1.32-.51s.56-.8.58-1.38v-1.31c0-3.21-2.2-6.03-5.26-6.86v-8.02c6.31,1.43,10.8,7.04,10.77,13.57v8.68h-5.51v-.77c.01-.49-.17-.96-.51-1.32-.34-.36-.8-.56-1.29-.58-.49-.01-.96.17-1.32.51-.36.34-.56.8-.58,1.38v8.68H12.9v-8.66c.01-.49-.17-.96-.51-1.32-.34-.36-.8-.56-1.29-.58-.98-.03-1.87.78-1.9,1.89v.79H3.7v-8.68c-.03-6.52,4.46-12.13,10.77-13.56v9.5c-.96.43-1.73,1.19-2.19,2.15-.52,1.08-.58,2.29-.18,3.42.4,1.13,1.21,2.03,2.29,2.55,1.08.52,2.29.58,3.42.18s2.03-1.21,2.55-2.29c.52-1.08.58-2.29.18-3.42-.41-1.16-1.26-2.08-2.37-2.58v-9.94c.76-.21,1.33-.9,1.35-1.73.03-1.02-.78-1.87-1.79-1.9h-.09s-.04,0-.04,0c-4.7,0-9.11,1.84-12.43,5.16C1.84,50.58,0,55.01,0,59.73v18.41C0,79.17.83,80,1.85,80h55.24c1.02,0,1.85-.83,1.85-1.85v-18.41c0-4.71-1.83-9.14-5.15-12.47Zm1.45,24.84v4.19h-5.51v-4.19h5.51Zm-46.04,0v4.19H3.7v-4.19h5.5Zm7.12-13.69v.53c.19,0,.39.09.53.24.14.15.22.35.21.56-.01.41-.35.75-.76.76-.21,0-.41-.07-.56-.21-.15-.14-.24-.34-.24-.55-.01-.43.33-.79.76-.8l.06-.53h0Z"
        />
        <path
          fill={fill}
          d="m21.39,45.03l6.57,9.21c.36.5.93.77,1.51.77.37,0,.75-.11,1.08-.35.16-.12.31-.26.42-.42l6.58-9.2c.23-.32.34-.69.34-1.08v-6.74c2.51-2.33,3.94-5.61,3.94-9.04v-7.89c0-.49-.19-.96-.54-1.31-.35-.35-.81-.55-1.31-.55h-2.63c-2.51,0-3.48-2.58-3.51-2.67-.26-.79-.99-1.3-1.84-1.27-.76.04-1.43.55-1.66,1.26,0,.03-.97,2.68-3.5,2.68h-7.9c-1.02,0-1.85.84-1.84,1.86v7.89c0,3.43,1.43,6.71,3.94,9.03v6.75c0,.39.13.76.35,1.07Zm-.6-16.86v-6.05h6.03c2,.04,3.94-.81,5.27-2.3,1.33,1.49,3.26,2.35,5.26,2.3h.77v6.05c0,2.32-.9,4.49-2.54,6.13-1.64,1.64-3.81,2.54-6.13,2.54h0c-4.78,0-8.67-3.89-8.67-8.67Zm3.94,11.43c3.02,1.26,6.44,1.26,9.46,0v3.76l-4.73,6.62-4.73-6.62v-3.76Z"
        />
      </>
    )}
  </svg>
);
