import { ParagraphConversionWidgetFragment } from '@custom/schema';

export const transformParagraphConversionWidgetToProps = (
  paragraph: ParagraphConversionWidgetFragment,
) => ({
  donationPaymentUrl: paragraph.donationPaymentLink?.url,
  donationPaymentPage: paragraph.donationPaymentPage,
  widgetType: paragraph.widgetType,
  displayMode: paragraph.displayMode,
});
