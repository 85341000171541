import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphFloatingQuoteProps = {
  text?: string;
  overlap?: string;
  className?: string;
  author?: string;
};

export const ParagraphFloatingQuote: React.FC<ParagraphFloatingQuoteProps> = ({
  text = '',
  overlap = 'none',
  author = '',
  className,
}) => {
  const waypoint = useRef<HTMLDivElement>(null);
  const quote = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!['above', 'below'].includes(overlap)) {
      return;
    }

    if (!quote.current || !waypoint.current) {
      return;
    }

    typeof IntersectionObserver !== 'undefined'
      ? new IntersectionObserver((entries, observer) =>
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              quote.current!.classList.add('is-visible');
              observer.disconnect();
            }
          }),
        ).observe(waypoint.current!)
      : quote.current!.classList.add('is-visible');
  }, [overlap]);

  return (
    <FadeUp yGap={20}>
      <div ref={waypoint} />
      <figure
        ref={quote}
        className={classNames('FloatingQuote', className, {
          indentedTop: overlap === 'above',
          indentedBottom: overlap === 'below',
        })}
      >
        <blockquote
          className="FloatingQuote--quote"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        {author ? <figcaption>– {author}</figcaption> : ''}
      </figure>
    </FadeUp>
  );
};

export default ParagraphFloatingQuote;
