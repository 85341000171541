import { ParagraphOrthotecGalleryFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { isPublished } from '../../utils';
import { getImageStyleUrl, transformImageFragmentToProps } from './MediaImage';

export const transformParagraphOrthotecGalleryToProps = (
  paragraph: ParagraphOrthotecGalleryFragment,
) => ({
  items:
    paragraph?.images?.filter(isPublished).map((paragraphImage) => {
      const thumbnailImage = paragraphImage ? (
        <Image
          {...transformImageFragmentToProps(paragraphImage, [
            'small',
            'medium',
            'large',
          ])}
          width={80}
          height={80}
          size="small"
          className="ImageSlider--thumbSlide--img"
          fit
        />
      ) : undefined;
      const image = paragraphImage ? (
        <Image
          {...transformImageFragmentToProps(paragraphImage, [
            'small',
            'medium',
            'large',
          ])}
          size="medium"
          className="ImageSlider--slide--img"
        />
      ) : undefined;
      const fullImage = paragraphImage ? (
        <Image
          {...transformImageFragmentToProps(paragraphImage, [
            'small',
            'medium',
            'large',
          ])}
          size="large"
          className="ImageSlider--slide--img"
        />
      ) : undefined;

      return {
        image,
        fullImage,
        thumbnailImage,
        url: getImageStyleUrl(paragraphImage, 'large'),
        description: paragraphImage?.alt,
      };
    }) || [],
});
