import { ContentKitchenSinkFragment } from '@custom/schema';

export const transformKitchenSinkFragmentToKitchenSinkPage = (
  kitchenSink: ContentKitchenSinkFragment,
) => {
  return {
    title: kitchenSink.title,
    lead: '',
    hideFundingBanner: true,
    date: undefined,
  };
};
