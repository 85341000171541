import React from 'react';
import { useTranslation } from 'react-i18next';

export type FormWideSelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label?: string;
  options: {
    label?: string;
    value?: string;
  }[];
  emptyOption?: {
    label?: string;
    value: string;
  };
};

export const FormWideSelect: React.FC<FormWideSelectProps> = ({
  id,
  label,
  options,
  emptyOption = {
    value: '',
  },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="FormRow">
      {label && (
        <label className="FormLabel" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="BasicInput select">
        <div className="Select">
          <select id={id} {...props}>
            <option value={emptyOption.value}>
              {emptyOption.label || t('--Show all--')}
            </option>
            {options.map((option, index) => (
              <option key={`options-${index}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
