import React from 'react';

export type IconCalendarProps = {
  className?: string;
  fill?: string | '#fff';
  position?: 'left' | 'center';
};

export const IconCalendar: React.FC<IconCalendarProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <g fill={fill}>
        <path d="m95.14,73.61V10.87c0-3.49-2.84-6.33-6.34-6.34h-12.4v3.79h12.4c1.41,0,2.55,1.14,2.55,2.55v15.51H13.64v-15.57c0-1.38,1.12-2.5,2.5-2.5h11.95v-3.78h-11.94c-3.46,0-6.28,2.82-6.28,6.29v62.84c0,3.49,2.85,6.33,6.34,6.34h72.57c3.51,0,6.38-2.87,6.38-6.39Zm-3.78,0c-.03,1.44-1.16,2.57-2.58,2.6H16.19c-1.41,0-2.55-1.15-2.55-2.55V30.58h77.72v43.04Z" />
        <rect x="40.01" y="4.37" width="25.29" height="4.19" />
        <rect x="22.08" y="41.52" width="13.28" height="4.19" />
        <rect x="46.02" y="41.52" width="13.28" height="4.19" />
        <rect x="69.95" y="41.52" width="13.28" height="4.19" />
        <rect x="22.08" y="51.55" width="13.28" height="4.19" />
        <rect x="46.02" y="51.55" width="13.28" height="4.19" />
        <rect x="69.95" y="51.55" width="13.28" height="4.19" />
        <rect x="22.08" y="61.09" width="13.28" height="4.19" />
        <rect x="46.02" y="61.09" width="13.28" height="4.19" />
        <rect x="69.95" y="61.09" width="13.28" height="4.19" />
        <polygon points="28.72 17.29 39.46 17.29 39.46 14.19 36.19 14.19 36.19 0 32 0 32 14.19 28.72 14.19 28.72 17.29" />
        <polygon points="64.75 17.29 76.59 17.29 76.59 14.19 72.22 14.19 72.22 0 69.12 0 69.12 14.19 64.75 14.19 64.75 17.29" />
      </g>
    ) : (
      <g fill={fill}>
        <path d="m85.29,73.61V10.87c0-3.49-2.84-6.33-6.34-6.34h-12.4v3.79h12.4c1.41,0,2.55,1.14,2.55,2.55v15.51H3.78v-15.57c0-1.38,1.12-2.5,2.5-2.5h11.95v-3.78H6.28C2.82,4.54,0,7.36,0,10.82v62.84c0,3.49,2.85,6.33,6.34,6.34h72.57c3.51,0,6.38-2.87,6.38-6.39Zm-3.78,0c-.03,1.44-1.16,2.57-2.58,2.6H6.34c-1.41,0-2.55-1.15-2.55-2.55V30.58h77.72v43.04Z" />
        <rect x="30.15" y="4.37" width="25.29" height="4.19" />
        <rect x="12.23" y="41.52" width="13.28" height="4.19" />
        <rect x="36.16" y="41.52" width="13.28" height="4.19" />
        <rect x="60.09" y="41.52" width="13.28" height="4.19" />
        <rect x="12.23" y="51.55" width="13.28" height="4.19" />
        <rect x="36.16" y="51.55" width="13.28" height="4.19" />
        <rect x="60.09" y="51.55" width="13.28" height="4.19" />
        <rect x="12.23" y="61.09" width="13.28" height="4.19" />
        <rect x="36.16" y="61.09" width="13.28" height="4.19" />
        <rect x="60.09" y="61.09" width="13.28" height="4.19" />
        <polygon points="18.87 17.29 29.61 17.29 29.61 14.19 26.33 14.19 26.33 0 22.14 0 22.14 14.19 18.87 14.19 18.87 17.29" />
        <polygon points="54.9 17.29 66.73 17.29 66.73 14.19 62.36 14.19 62.36 0 59.27 0 59.27 14.19 54.9 14.19 54.9 17.29" />
      </g>
    )}
  </svg>
);
