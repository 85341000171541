import React from 'react';

export type IconFooterXProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterX: React.FC<IconFooterXProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="22.669"
    height="23.167"
    viewBox="0 0 22.669 23.167"
  >
    <path
      id="logo"
      d="M13.491,9.81,21.93,0h-2L12.6,8.518,6.75,0H0L8.85,12.88,0,23.167H2l7.738-8.995,6.181,8.995h6.75L13.491,9.81Zm-2.739,3.184-.9-1.283L2.721,1.506H5.792L11.55,9.742l.9,1.283L19.932,21.73H16.86l-6.108-8.736Z"
      fill={fill}
    />
  </svg>
);
