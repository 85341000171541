import { useLocation } from '@reach/router';
import { stringify } from 'qs';

import { DOMAINS } from '../constants';

// @see https://stackoverflow.com/a/34773221
const hasExtension = (url: string) =>
  url.match(/\[\.#]([0-9a-z]+)(?=[?#])|([.#])(?:[\w]+)$/gi);

const addTrailingSlash = (url: string) => url.replace(/\/$|$/, '/');

const isTruthy = (i: string | null | undefined): i is string => Boolean(i);

const isNotHomepage = (segment: string) => {
  const domainHomepages = Object.values(DOMAINS).map((domain) =>
    domain.homepagePath?.replace('/', ''),
  );
  return !domainHomepages.includes(segment);
};

const stripSlashes = (segment: string, index: number) => {
  if (index === 0) {
    return segment.replace(/^\/{2,}/g, '/').replace(/\/+$/g, '');
  }

  return segment.replace(/^\/+|\/+$/g, '');
};

/**
 * This function is for building URLs with query parameters.
 *
 * @param segments
 *  The segments of the URL to build.
 * @param query
 *  The query parameters to add to the URL.
 * @param queryOptions
 *  The options to pass to `qs.stringify`.
 */
export const buildUrl = (
  segments: Array<string | null | undefined>,
  query?: Parameters<typeof stringify>[0],
  queryOptions?: Parameters<typeof stringify>[1],
) => {
  const sanitizedUrl = segments
    .filter(isTruthy)
    .map(stripSlashes)
    .filter(isNotHomepage)
    .join('/');

  const [url, queryFromUrl] = sanitizedUrl.split('?');

  const queryString = stringify(query, {
    skipNulls: true,
    ...queryOptions,
  });

  return [
    hasExtension(url) ? url : addTrailingSlash(url),
    queryString || queryFromUrl,
  ]
    .filter(isTruthy)
    .join('?');
};

/**
 * This function is a wrapper around `buildUrl` that adds the current URL as a query parameter.
 *
 * @param segments
 *  The segments of the URL to build.
 * @param query
 *  The query parameters to add to the URL.
 * @param queryOptions
 *  The options to pass to `qs.stringify`.
 * @param onlyContinueTracking
 *  Whether to only continue tracking the current URL.
 */
export const useRaisenowTrackingWithBuildUrl = (
  segments: Array<string | null | undefined>,
  query?: Parameters<typeof stringify>[0],
  queryOptions?: Parameters<typeof stringify>[1],
  onlyContinueTracking?: boolean,
) => {
  const parameterKey = 'rnw-stored_campaign_id';
  const location = useLocation();
  const { pathname, search } = location;
  let pathName = pathname + search;

  // If we only want to continue tracking we don't want to add the current URL as a query parameter,
  // but we still want to keep the current query parameters.
  if (onlyContinueTracking) {
    pathName = '';
    // get query parameters from the current URL
    const urlParams = new URLSearchParams(search);
    if (urlParams.has(parameterKey)) {
      pathName = urlParams.get(parameterKey) ?? '';
    }
  }

  // If we have a pathName we want to add it as a query parameter
  if (pathName) {
    query = {
      ...query,
      [parameterKey]: pathName,
    };
  }

  // Build the URL
  return buildUrl(segments, query, queryOptions);
};

/**
 * Formats a relative url to be compatible as segments.
 * It returns false if the link is an external link.
 *
 * @param link
 *  The link to format.
 */
export const formatLinkForSegments = (link: string) => {
  const url = link;
  // 1. check if the link is an external link
  // if it is, return false to prevent further processing.
  if (link.startsWith('http')) {
    return false;
  }

  // 2. split the link into segments.
  const urlParts = url.split('/').filter(Boolean);

  // 3. check if the first segment is '/' if not, add it.
  if (urlParts[0] !== '/') {
    urlParts.unshift('/');
  }

  // 4. return the link segments.
  return urlParts;
};
