import classNames from 'classnames';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphCallToActionProps = {
  url?: string;
  title?: string;
  target?: string;
  buttonStyle: string;
  className?: string;
};

export const ParagraphCallToAction: React.FC<ParagraphCallToActionProps> = ({
  url,
  title,
  target,
  buttonStyle,
  className,
}) => {
  const { Link } = useFrameworkDependencies();
  return (
    <FadeUp yGap={20} className={classNames('ArticleText', className)}>
      <Link
        to={url || ''}
        target={target}
        className={classNames(
          'Button button button-shadow arrow',
          `content-${buttonStyle}`,
        )}
      >
        <span className="Button--text">{title}</span>
        <i className="IconSvg ico-arrow-right-svg transition inline white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.423"
            height="15.667"
            viewBox="0 0 19.423 15.667"
          >
            <g
              id="Group_53"
              data-name="Group 53"
              transform="translate(-206.077 -27.22)"
            >
              <g
                id="Group_2"
                data-name="Group 2"
                transform="translate(209.077 34.53)"
              >
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M353.961,296.046h-18.5a.527.527,0,0,1,0-1.046h18.5a.527.527,0,0,1,0,1.046Z"
                  transform="translate(-338 -295)"
                  fill="#fff"
                />
              </g>
              <path
                id="Path_6"
                data-name="Path 6"
                d="M455.523,230.668a.559.559,0,0,1-.37-.135.423.423,0,0,1,0-.652l8-7.047-8-7.047a.423.423,0,0,1,0-.652.574.574,0,0,1,.739,0l8.367,7.373a.423.423,0,0,1,0,.652l-8.367,7.373A.559.559,0,0,1,455.523,230.668Z"
                transform="translate(-238.913 -187.781)"
                fill="#fff"
              />
            </g>
          </svg>
        </i>
      </Link>
    </FadeUp>
  );
};

export default ParagraphCallToAction;
