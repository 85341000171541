import loadable from '@loadable/component';

export * from './BlockTeaser';
export * from './BlogSubpageHeader';
export * from './CompaniesFilterForm';
export * from './CompaniesParaworkFilterForm';
export * from './CompanyTeaserList';
export * from './CTAFlyoutButton';
export * from './DoctorsFilterForm';
export * from './DoctorTeaserList';
export * from './EventsFilterForm';
export * from './FlyoutBlock';
export * from './FlyoutCategory';
export * from './FlyoutDates';
export * from './MedicalOffersFilterForm';
export * from './MedicalOffersTeaserList';
export * from './MenuItem';
export * from './NewsroomFilterForm';
export * from './OverlayFloatingBox';
export * from './OverlayFull';
export * from './OverlayTransparent';
export * from './OverlayCrisis';
export * from './ParagraphStorifyMe';
export const ParagraphBlockTeaserList = loadable(
  () => import('./ParagraphBlockTeaserList'),
);
export const ParagraphInfoBoxList = loadable(
  () => import('./ParagraphInfoBoxList'),
);
export const ParagraphConversionWidget = loadable(
  () => import('./ParagraphConversionWidget'),
);
export const ParagraphDonationWidget = loadable(
  () => import('./ParagraphDonationWidget'),
);
export const ParagraphFloatingBox = loadable(
  () => import('./ParagraphFloatingBox'),
);
export const ParagraphFromLibrary = loadable(
  () => import('./ParagraphFromLibrary'),
);
export const ParagraphTeaserGrid = loadable(
  () => import('./ParagraphTeaserGrid'),
);
export const ParagraphContentBoxList = loadable(
  () => import('./ParagraphContentBoxList'),
);
export type { ParagraphTeaserGridProps } from './ParagraphTeaserGrid';
export * from './SearchResultForm';
export * from './SearchResultList';
