import React from 'react';
import { useTranslation } from 'react-i18next';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { Button } from '../0-atoms';

export type FundingBannerProps = {};

const IconArrow: React.FC = () => (
  <span className="icon-arrow">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.423"
      height="15.667"
      viewBox="0 0 19.423 15.667"
    >
      <g
        id="Group_53"
        data-name="Group 53"
        transform="translate(-206.077 -27.22)"
      >
        <g
          id="Group_2"
          data-name="Group 2"
          transform="translate(209.077 34.53)"
        >
          <path
            id="Path_5"
            data-name="Path 5"
            d="M353.961,296.046h-18.5a.527.527,0,0,1,0-1.046h18.5a.527.527,0,0,1,0,1.046Z"
            transform="translate(-338 -295)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_6"
          data-name="Path 6"
          d="M455.523,230.668a.559.559,0,0,1-.37-.135.423.423,0,0,1,0-.652l8-7.047-8-7.047a.423.423,0,0,1,0-.652.574.574,0,0,1,.739,0l8.367,7.373a.423.423,0,0,1,0,.652l-8.367,7.373A.559.559,0,0,1,455.523,230.668Z"
          transform="translate(-238.913 -187.781)"
          fill="#fff"
        />
      </g>
    </svg>
  </span>
);

const IconMemberPlus: React.FC = () => (
  <svg viewBox="0 0 16.482 18.472" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.775 17.372A.675.675 0 01.1 16.7a6.147 6.147 0 016.14-6.14h1.012a6.127 6.127 0 012.022.341.675.675 0 11-.444 1.274 4.782 4.782 0 00-1.578-.266H6.24a4.8 4.8 0 00-4.79 4.79.675.675 0 01-.675.673zM11.232 4.654a4.554 4.554 0 10-4.554 4.554 4.559 4.559 0 004.554-4.554zm-1.349 0a3.2 3.2 0 11-3.2-3.2 3.208 3.208 0 013.2 3.2zm5.824 9.67h-2.024V12.3a.675.675 0 00-1.349 0v2.024H10.31a.675.675 0 000 1.349h2.024V17.7a.675.675 0 001.349 0v-2.027h2.024a.675.675 0 100-1.349z"
      fill="#fff"
      stroke="#fff"
      strokeWidth=".2"
    />
  </svg>
);

export const FundingBanner: React.FC<FundingBannerProps> = () => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();

  return (
    <div className="FundingBanner">
      <div className="FundingBanner--inner-side FundingBanner--inner-side-left">
        <div className="FundingBanner--inner-side-content">
          <div className="FundingBanner--ctaContainer">
            <div className="FundingBanner--cta">
              <span className="FundingBanner--cta--iconWrapper">
                <IconMemberPlus />
              </span>
              <div className="FundingBanner--cta--text">
                {t('Become a member')}
              </div>
              <Button
                url={APP_CONFIG.memberPagePath[locale]}
                label={t('Become a member')}
              >
                <IconArrow />
              </Button>
            </div>
          </div>
          <div className="FundingBanner--text">
            <div
              dangerouslySetInnerHTML={{
                __html: t('blocks.content_footer.become_member'),
              }}
            />
            <Button
              url={APP_CONFIG.memberPagePath[locale]}
              label={t('Become a member')}
            >
              <IconArrow />
            </Button>
          </div>
        </div>
      </div>
      <div className="FundingBanner--inner-side FundingBanner--inner-side-right">
        <div className="FundingBanner--inner-side-content">
          <div className="FundingBanner--ctaContainer">
            <div className="FundingBanner--cta">
              <span className="FundingBanner--cta--iconWrapper">
                <img src="/img/svgs/heart-outline.svg" alt="" />
              </span>
              <div className="FundingBanner--cta--text">{t('Donate')}</div>
              <Button
                url={APP_CONFIG.donatePagePath[locale]}
                label={t('Donate')}
              >
                <IconArrow />
              </Button>
            </div>
          </div>
          <div className="FundingBanner--text">
            <div
              dangerouslySetInnerHTML={{
                __html: t('blocks.content_footer.donate'),
              }}
            />
            <Button url={APP_CONFIG.donatePagePath[locale]} label={t('Donate')}>
              <IconArrow />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
