import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { PropsWithChildren } from 'react';

import { LocalizedLink, SEO } from './components/0-atoms';
import {
  DataDependencyProvider,
  FrameworkDependencyProvider,
} from './dependencies';
import {
  useEntityUrlsQuery,
  useLocalization,
  usePageTranslations,
} from './hooks';

// TODO: Move tho common theme.
export const GatsbyDependencyWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <FrameworkDependencyProvider
    dependencies={{
      Link: LocalizedLink,
      navigate,
      SEO,
      useLocation,
      useLocalization,
      usePageTranslations,
    }}
  >
    {children}
  </FrameworkDependencyProvider>
);

export const DataDependencyWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <DataDependencyProvider
    dependencies={{
      useEntityUrlsQuery,
    }}
  >
    {children}
  </DataDependencyProvider>
);
