import { BlogPaginationQuery, ContentBlogFragment } from '@custom/schema';
import { fromUnixTime } from 'date-fns';
import React from 'react';

import { Locale } from '../../../i18n';
import { Image } from '../../components/0-atoms';
import { HeroButton } from '../../components/2-organisms';
import { formatLocalizedDate, getImageSrc, isPublished } from '../../utils';
import { isTruthy } from '../../utils/isTruthy';
import { transformRelatedBlogPostFragmentToRelatedBlogPost } from './ContentBlogRelated';
import { getImageStyleUrl } from './MediaImage';

export const transformBlogPostFragmentToBlogPost = (
  blogPost: ContentBlogFragment,
  previousPost?: BlogPaginationQuery['allBlogEntries'][number],
  nextPost?: BlogPaginationQuery['allBlogEntries'][number],
) => {
  const headerImage = blogPost?.headerImage;

  const desktopWideStyleUrl = getImageStyleUrl(headerImage, 'desktop_wide');
  const desktopStdStyleUrl = getImageStyleUrl(headerImage, 'desktop_standard');
  const isCropped = desktopWideStyleUrl !== null || desktopStdStyleUrl !== null;

  const image = headerImage ? (
    <Image
      className="HeaderHero--background--img"
      sizes="(max-width: 750px) 50vw, 100vw"
      filemime={headerImage?.mimeType}
      src={
        isCropped
          ? getImageSrc(
              headerImage,
              `${desktopWideStyleUrl} 2000w,
            ${getImageStyleUrl(headerImage, 'desktop_standard')} 1440w,
            ${getImageStyleUrl(headerImage, 'mobile')} 750w`,
            )
          : getImageSrc(
              headerImage,
              `${getImageStyleUrl(headerImage, 'header_slider')} 2000w,
            ${getImageStyleUrl(
              headerImage,
              'header_slider_image_responsive_',
            )} 750w`,
            )
      }
      alt={headerImage?.alt || blogPost.title}
      fit
      loading="eager"
    />
  ) : undefined;

  return {
    id: blogPost.id || '0',
    title: blogPost.title || 'Missing title',
    subtitle: blogPost.subTitle,
    image,
    date: blogPost.created
      ? formatLocalizedDate((blogPost.langcode as Locale) || 'en')(
          fromUnixTime(blogPost.created),
        )
      : 'N/A',
    url: blogPost?.path || '',
    domain: blogPost?.domainAccess?.[0]?.id,
    heroButton: (
      <HeroButton
        type={blogPost.heroButtonType}
        url={blogPost.heroButtonLink?.url}
      />
    ),
    hideFundingBanner: blogPost.hideFundingBanner,
    relatedPosts: blogPost?.blogReference
      ?.filter((relatedBlogPostFragment) => Boolean(relatedBlogPostFragment))
      .filter(isPublished)
      .filter(isTruthy)
      .map((relatedBlogPostFragment) =>
        transformRelatedBlogPostFragmentToRelatedBlogPost(
          relatedBlogPostFragment,
        ),
      ),
    previousPost: {
      id: previousPost?.id || '0',
      title: previousPost?.title || 'Missing title',
      url: previousPost?.path || '',
      domain: previousPost?.domainAccess?.[0]?.id,
    },
    nextPost: {
      id: nextPost?.id || '0',
      title: nextPost?.title || 'Missing title',
      url: nextPost?.path || '',
      domain: nextPost?.domainAccess?.[0]?.id,
    },
  };
};
