import React from 'react';

export type ParagraphAnchorTagProps = {
  anchor?: string;
};

export const ParagraphAnchorTag: React.FC<ParagraphAnchorTagProps> = ({
  anchor,
}) => <div className="anchor" id={anchor} />;

export default ParagraphAnchorTag;
