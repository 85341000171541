import React, { PropsWithChildren, useContext, useState } from 'react';

export type CompaniesFiltersContext = {
  category?: string;
  glossary?: string;
  searchTerm?: string;
  setCategory: (category: string) => void;
  setGlossary: (glossary: string) => void;
  setSearchTerm: (searchTerm: string) => void;
};
const CompaniesFiltersContext = React.createContext<
  CompaniesFiltersContext | undefined
>(undefined);

export const CompaniesFiltersProvider: React.FC<
  PropsWithChildren<{
    category?: string;
    glossary?: string;
    searchTerm?: string;
  }>
> = ({
  category: initialCategory,
  glossary: initialGlossary,
  searchTerm: initialSearchTerm,
  children,
}) => {
  const [category, setCategory] = useState(initialCategory);
  const [glossary, setGlossary] = useState(initialGlossary);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  return (
    <CompaniesFiltersContext.Provider
      value={{
        category,
        glossary,
        searchTerm,
        setCategory: (category: string) => setCategory(category),
        setGlossary: (glossary: string) => setGlossary(glossary),
        setSearchTerm: (searchTerm: string) => setSearchTerm(searchTerm),
      }}
    >
      {children}
    </CompaniesFiltersContext.Provider>
  );
};

export const useCompaniesFilters = () => {
  const context = useContext(CompaniesFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useCompaniesFilters must be used within a CompaniesFiltersProvider',
    );
  }
  return context;
};
