import {
  ContentFirstLevelFragment,
  ParagraphDonationWidgetFragment,
} from '@custom/schema';

import { DOMAINS } from '../constants';
import { getLocalizedUrl } from './getLocalizedUrl';

export const getFundingWidgetPropsFromFragment = (
  page: ContentFirstLevelFragment['donationPaymentPage'],
) => {
  const fundingWidgetPageContent = page?.content?.find(
    (content) => content?.__typename === 'ParagraphDonationWidget',
  ) as ParagraphDonationWidgetFragment | undefined;

  return fundingWidgetPageContent
    ? {
        amounts: fundingWidgetPageContent?.amounts as number[],
        paymentUrl: getLocalizedUrl({
          to: page?.path || '',
          locale: page?.langcode as any,
          domain: DOMAINS[page?.domainAccess?.[0]?.id || 'paraplegie_ch'],
        }).url,
      }
    : undefined;
};
