import { useLocation } from '@reach/router';
import classNames from 'classnames';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useMedicalOffersFilters } from '../../hooks';
import { FormSelect } from '../0-atoms';
import { FadeUp } from './FadeIn';

export type MedicalOffersFilterFormProps = {
  domains: {
    id: string;
    label: string;
  }[];
  className?: string;
};

export const MedicalOffersFilterForm: React.FC<
  MedicalOffersFilterFormProps
> = ({ domains, className }) => {
  const glossaries = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const { t } = useTranslation();
  const { navigate } = useFrameworkDependencies();
  const location = useLocation();
  const { domain, glossary, setDomain, setGlossary } =
    useMedicalOffersFilters();

  const updateUrl = (query?: { domain?: string; glossary?: string }) =>
    navigate(
      `${location.pathname}?${queryString.stringify(
        {
          domain,
          glossary,
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      )}`,
    );

  return (
    <FadeUp yGap={20} className={classNames('FilterBox', className)}>
      <div className="FilterBox--inner">
        <form action="" className="Form" method="GET">
          <FormSelect
            label={t('Medical domain')}
            id="u1"
            name="medical_domain"
            defaultValue={domain || ''}
            options={domains.map(({ id, label }) => ({
              value: id,
              label,
            }))}
            onChange={(event) => {
              setDomain(event.target.value);
              updateUrl({
                domain: event.target.value,
              });
            }}
          />
          <FormSelect
            label={t('Glossary')}
            id="u2"
            name="glossary"
            defaultValue={glossary || ''}
            options={glossaries.map((glossary) => ({
              value: glossary,
              label: glossary,
            }))}
            onChange={(event) => {
              setGlossary(event.target.value);
              updateUrl({
                glossary: event.target.value,
              });
            }}
          />
        </form>
      </div>
    </FadeUp>
  );
};
