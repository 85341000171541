import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../types';
import { CTAButton, CTAButtonProps } from '../0-atoms';

export type CTAContactUsButtonProps = Omit<CTAButtonProps, 'icon' | 'label'>;

export const CTAContactUsButton: React.FC<CTAContactUsButtonProps> = ({
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <CTAButton
      icon={Icon.Phone}
      className="arrow-right"
      label={t('Contact us')}
      {...props}
    />
  );
};
