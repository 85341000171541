import classNames from 'classnames';
import React from 'react';

export type LoaderProps = {
  size?: 'medium' | 'large';
  position?: 'top' | 'bottom';
};

export const Loader: React.FC<LoaderProps> = ({ size = 'large', position }) => (
  <div className={classNames('Loader', size, position)} />
);
