import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import {
  FieldError,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { SubmitErrorHandler } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useCountries } from '../../hooks';
import { getTranslatedUrl } from '../../utils/getTranslatedUrl';
import { Loader } from '../0-atoms';
import { ParagraphEventModule } from './ParagraphEventModule';
import {
  ModuleProps,
  RaisenowPaymentWidget,
  RaisenowPaymentWidgetProps,
} from './RaisenowPaymentWidget';

export type ZSMFormProps = {
  lead?: string;
  modules?: Array<{
    id: string;
    title?: string;
    description?: string;
    prices: Array<{
      label: string;
      amount: number;
    }>;
  }>;
  className?: string;
};

export type ZSMFormInputs = {
  fieldSalutation: string;
  fieldTitle?: string;
  fieldFirstName: string;
  fieldSurname: string;
  fieldEmail: string;
  fieldCompany: string;
  fieldStreet: string;
  fieldStreetNumber: string;
  fieldPOBox: string;
  fieldPostcode: string;
  fieldTown: string;
  fieldCountry: string;
  fieldAcceptTerms: boolean;
  selectedModules?: Record<string, ModuleProps | undefined>;
};

const isFieldError = (error: any): error is FieldError =>
  !!error && 'type' in error;

const getSelectedModules = (
  formSelectedModules: ZSMFormInputs['selectedModules'],
) =>
  formSelectedModules
    ? Object.entries(formSelectedModules)
        .filter(([, value]) => value !== undefined)
        .reduce((selectedModules, [id, value]) => {
          selectedModules.push({
            id,
            ...(value as Omit<ModuleProps, 'id'>),
          });

          return selectedModules;
        }, [] as Array<ModuleProps>)
    : [];

export const ZSMForm: React.FC<ZSMFormProps> = ({
  lead,
  modules = [],
  className,
}) => {
  const { useLocalization, useLocation } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const errorsRef = useRef<HTMLAnchorElement>(null);
  const countries = useCountries();
  const { t } = useTranslation();
  // react-hook-form was rolled back to ^6 as ^7 requires
  // to handle errors and ref={register} differently
  // https://stackoverflow.com/questions/66927051/getting-uncaught-typeerror-path-split-is-not-a-function-in-react
  const { register, handleSubmit, formState, setError, watch, ...formMethods } =
    useForm<ZSMFormInputs>({
      shouldFocusError: false,
    });
  const [total, setTotal] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [showPaymentWidget, setShowPaymentWidget] = useState(false);
  const [paymentProcessed, setPaymentProcessed] = useState(false);
  const [paymentCustomer, setPaymentCustomer] = useState<
    RaisenowPaymentWidgetProps['customer'] | undefined
  >(undefined);
  const formValues = watch();

  const settings = useStaticQuery(graphql`
    query ZsmFormSettings {
      settingsZsmForm {
        thankYouPageLink {
          translations {
            langcode
            link {
              url
            }
          }
        }
      }
    }
  `);

  const thankYouUrl = getTranslatedUrl({
    urlObject: settings.settingsZsmForm?.thankYouPageLink?.translations || [],
    langcode: locale,
  }) as unknown as string;

  const { search } = useLocation();
  useEffect(() => {
    const parsedQueryString = queryString.parse(search) as {
      'rnw-view'?: string;
      epp_transaction_id?: string;
    };
    if (
      parsedQueryString['rnw-view'] === 'payment_result' &&
      parsedQueryString.epp_transaction_id
    ) {
      setShowPaymentWidget(true);
      setPaymentProcessed(true);
    } else {
      setShowForm(true);
    }
  }, [search]);

  useEffect(() => {
    if (!modules || modules?.length === 0 || !formValues.selectedModules) {
      return;
    }
    setTotal(
      getSelectedModules(formValues.selectedModules).reduce(
        (total, currentValue) => {
          if (!currentValue) {
            return total;
          }
          return total + currentValue.amount;
        },
        0,
      ),
    );
  }, [formValues, modules]);

  const onSubmit: SubmitHandler<ZSMFormInputs> = (data) => {
    if (
      modules?.length &&
      (!data.selectedModules ||
        getSelectedModules(data.selectedModules).length === 0)
    ) {
      setError('selectedModules', {
        type: 'manual',
        message: t('At least one price must be selected.'),
      });
      errorsRef.current?.scrollIntoView({
        behavior: 'smooth',
      });

      return false;
    }
    setPaymentCustomer({
      salutation: data.fieldSalutation,
      title: data.fieldTitle,
      name: data.fieldFirstName,
      surname: data.fieldSurname,
      email: data.fieldEmail,
      company: data.fieldCompany,
      street: data.fieldStreet,
      streetNumber: data.fieldStreetNumber,
      poBox: data.fieldPOBox,
      postcode: data.fieldPostcode,
      town: data.fieldTown,
      country: data.fieldCountry,
      acceptTerms: data.fieldAcceptTerms,
    });
    setShowPaymentWidget(true);
  };
  const onError: SubmitErrorHandler<ZSMFormInputs> = (errors) =>
    !!Object.values(errors).length &&
    errorsRef.current?.scrollIntoView({
      behavior: 'smooth',
    });

  return (
    <FormProvider
      {...{
        register,
        handleSubmit,
        formState,
        setError,
        watch,
        ...formMethods,
      }}
    >
      {showPaymentWidget ? (
        <>
          {paymentProcessed ? (
            <RaisenowPaymentWidget
              paymentProcessed={paymentProcessed}
              thankYouUrl={thankYouUrl}
            />
          ) : (
            <>
              {getSelectedModules(formValues.selectedModules).length > 0 &&
                paymentCustomer && (
                  <RaisenowPaymentWidget
                    total={total}
                    customer={paymentCustomer}
                    selectedModules={getSelectedModules(
                      formValues.selectedModules,
                    )}
                    thankYouUrl={thankYouUrl}
                    onEditBooking={() => {
                      setPaymentCustomer(undefined);
                      setShowPaymentWidget(false);
                    }}
                  />
                )}
            </>
          )}
        </>
      ) : (
        <>
          {!showForm ? (
            <div className="ArticleText ZSMForm">
              <Loader />
            </div>
          ) : (
            <>
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className={className}
              >
                {lead && (
                  <div
                    className="ArticleText"
                    dangerouslySetInnerHTML={{ __html: lead }}
                  />
                )}
                <a ref={errorsRef} className="scroll-margin" />
                {!!Object.values(formState.errors).length && (
                  <div className="ArticleText FormAlert">
                    {Object.entries(formState.errors).map(
                      ([name, error]) =>
                        isFieldError(error) &&
                        error?.message && (
                          <h3
                            className="FormAlert--message"
                            key={`error-${name}`}
                          >
                            {t(error.message)}
                          </h3>
                        ),
                    )}
                  </div>
                )}
                {!!modules.length &&
                  modules.map((module, index) => (
                    <ParagraphEventModule
                      key={`event-module-${index}`}
                      value={formValues?.selectedModules?.[module.id]?.amount}
                      {...module}
                    />
                  ))}
                {!!modules.length && (
                  <div className="ArticleText">
                    <h3 className="ArticleTitle--title h3">
                      {t('Total cost')} = {t('CHF')} {total}
                    </h3>
                    <br />
                  </div>
                )}
                <div className="ArticleText">
                  <h3 className="ArticleTitle--title h3">
                    {t('Personal data')}
                  </h3>
                  <div className="OptionInputGroup inline">
                    <div className="OptionInputGroup--inputs">
                      <div className="OptionInput radio">
                        <input
                          id="fieldSalutationMS"
                          type="radio"
                          value="ms"
                          {...register('fieldSalutation', {
                            required: 'Salutation can not be blank.',
                          })}
                        />
                        <label htmlFor="fieldSalutationMS">{t('Ms.')}</label>
                      </div>
                      <div className="OptionInput radio">
                        <input
                          id="fieldSalutationMR"
                          type="radio"
                          value="mr"
                          {...register('fieldSalutation', {
                            required: 'Salutation can not be blank.',
                          })}
                        />
                        <label htmlFor="fieldSalutationMR">{t('Mr.')}</label>
                      </div>
                    </div>
                  </div>
                  <div className="FormRow">
                    <label className="FormLabel" htmlFor="fieldTitle">
                      {t('Title')}
                    </label>
                    <div className="BasicInput">
                      <input
                        id="fieldTitle"
                        type="text"
                        {...register('fieldTitle')}
                      />
                    </div>
                  </div>
                  <div className="InputGroup--row">
                    <div className="FormRow">
                      <label className="FormLabel" htmlFor="fieldFirstName">
                        {t('First name')}*
                      </label>
                      <div className="BasicInput">
                        <input
                          id="fieldFirstName"
                          type="text"
                          {...register('fieldFirstName', {
                            required: 'First name can not be blank.',
                          })}
                        />
                      </div>
                    </div>
                    <div className="FormRow">
                      <label className="FormLabel" htmlFor="fieldSurname">
                        {t('Surname')}*
                      </label>
                      <div className="BasicInput">
                        <input
                          id="fieldSurname"
                          type="text"
                          {...register('fieldSurname', {
                            required: 'Last name can not be blank.',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="FormRow">
                    <label className="FormLabel" htmlFor="fieldEmail">
                      {t('Email')}*
                    </label>
                    <div className="BasicInput">
                      <input
                        id="fieldEmail"
                        type="email"
                        {...register('fieldEmail', {
                          required: 'Email can not be blank.',
                          pattern: {
                            value:
                              // eslint-disable-next-line no-control-regex
                              /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/iu,
                            message: 'Email address is not valid.',
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="ArticleText">
                  <h3 className="ArticleTitle--title h3">
                    {t('Your address')}
                  </h3>
                  <div className="FormRow">
                    <label className="FormLabel" htmlFor="fieldCompany">
                      {t('Clinic and department')}*
                    </label>
                    <div className="BasicInput">
                      <input
                        id="fieldCompany"
                        type="text"
                        {...register('fieldCompany', {
                          required: 'Clinic and department can not be blank.',
                        })}
                      />
                    </div>
                  </div>
                  <div className="InputGroup--row">
                    <div className="FormRow _--xl">
                      <label className="FormLabel" htmlFor="fieldStreet">
                        {t('Street')}*
                      </label>
                      <div className="BasicInput">
                        <input
                          id="fieldStreet"
                          type="text"
                          {...register('fieldStreet', {
                            required: 'Street can not be blank.',
                          })}
                        />
                      </div>
                    </div>
                    <div className="FormRow">
                      <label className="FormLabel" htmlFor="fieldStreetNumber">
                        {t('Street number')}
                      </label>
                      <div className="BasicInput">
                        <input
                          id="fieldStreetNumber"
                          type="text"
                          {...register('fieldStreetNumber')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="FormRow">
                    <label className="FormLabel" htmlFor="fieldPOBox">
                      {t('P.O. Box')}
                    </label>
                    <div className="BasicInput">
                      <input
                        id="fieldPOBox"
                        type="text"
                        {...register('fieldPOBox')}
                      />
                    </div>
                  </div>
                  <div className="InputGroup--row">
                    <div className="FormRow">
                      <label className="FormLabel" htmlFor="fieldPostcode">
                        {t('Postcode')}*
                      </label>
                      <div className="BasicInput">
                        <input
                          id="fieldPostcode"
                          type="text"
                          {...register('fieldPostcode', {
                            required: 'Postcode can not be blank.',
                            minLength: {
                              value: 4,
                              message: 'Postcode must greater than 4 digits.',
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="FormRow _--l">
                      <label className="FormLabel" htmlFor="fieldTown">
                        {t('Town/City')}*
                      </label>
                      <div className="BasicInput">
                        <input
                          id="fieldTown"
                          type="text"
                          {...register('fieldTown', {
                            required: 'Town/City can not be blank.',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="FormRow">
                    <label className="FormLabel" htmlFor="fieldCountry">
                      {t('Country')}*
                    </label>
                    <div className="BasicInput select">
                      <div className="Select">
                        <select
                          id="fieldCountry"
                          {...register('fieldCountry', {
                            required: 'Country can not be blank.',
                          })}
                        >
                          {countries.map(({ id, name }, index) => (
                            <option key={`country-${index}`} value={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="OptionInput checkbox">
                    <input
                      id="fieldAcceptTerms"
                      type="checkbox"
                      {...register('fieldAcceptTerms', {
                        required:
                          'You must accept the General Terms and Conditions',
                      })}
                    />
                    <label htmlFor="fieldAcceptTerms">
                      {t('I accept the General Terms and Conditions')}
                    </label>
                    <a
                      href={
                        locale === 'en'
                          ? 'https://www.paraplegie.ch/sites/default/files/2018-11/General%20Terms%20and%20Conditions%20Centre%20for%20Pain%20Medicine.pdf'
                          : 'https://www.paraplegie.ch/sites/default/files/2018-11/agbs_kurse_und_kongresse_zsm.pdf'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('You will find our General Terms and Conditions here')}
                    </a>
                  </div>
                </div>
                <div className="ArticleText">
                  <button className="Button icon-after block" type="submit">
                    <span className="Button--text">
                      {t('Proceed to payment')}
                    </span>
                    <i className="absolute after ico-bolt-right-white icon" />
                  </button>
                </div>
              </form>
            </>
          )}
        </>
      )}
    </FormProvider>
  );
};
