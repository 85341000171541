import { locales } from '../../../i18n';
import { BreadcrumbLink } from '../../types';

export const transformBreadcrumbsFragmentToBreadcrumbs = (
  breadcrumbs?: Array<BreadcrumbLink | undefined>,
): Array<BreadcrumbLink> => {
  // Menu link set as '#' throws, so wrap in try catch.
  try {
    const breadcrumbLinks =
      breadcrumbs
        ?.filter((breadcrumb): breadcrumb is Required<BreadcrumbLink> =>
          Boolean(breadcrumb),
        )
        .map((breadcrumb) => ({
          text: breadcrumb.text,
          url: breadcrumb?.url.replace(/https?:\/\/[^/]+/i, ''),
          domain: breadcrumb?.domain || undefined,
        })) || [];
    // if we find a link in the form of [locale]/node it means that the entity has no breadcrumbs
    // or it's not linked to any menu item, so we return an empty array
    const hasInvalidBreadcrumbs = breadcrumbLinks.find((breadcrumb) =>
      breadcrumb.url.match(
        new RegExp(`^/(${Object.keys(locales).join('|')})/node`),
      ),
    );
    return hasInvalidBreadcrumbs ? [] : breadcrumbLinks;
  } catch (exception) {
    console.error(exception);
  }

  return [];
};
