import React, { PropsWithChildren, useState } from 'react';

export type MapMarkerProps = PropsWithChildren<{
  title: string;
  lat?: number;
  lng?: number;
}>;

export const MapMarker: React.FC<MapMarkerProps> = ({ title, children }) => {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div>
      <img
        src="/img/graphics/marker.png"
        onClick={() => setShowPopup(!showPopup)}
        alt={title}
        loading="lazy"
      />
      {showPopup && (
        <div className="si-wrapper-top si-has-border">
          <div className="si-frame si-content-wrapper">
            <button
              className="si-close-button"
              type="button"
              onClick={() => setShowPopup(false)}
            >
              <span className="visuallyhidden">{title}</span>
              <span className="MainNavFlyout--closeIcon">
                <span
                  className="MainNavFlyout--closeIcon--line"
                  style={{ backgroundColor: '#FFF' }}
                />
                <span
                  className="MainNavFlyout--closeIcon--line"
                  style={{ backgroundColor: '#FFF' }}
                />
              </span>
            </button>
            <div className="si-content">
              <div className="ArticleTitle inline">
                <h3 className="ArticleTitle--title h4">{title}</h3>
              </div>
              {children && (
                <div
                  className="ArticleText inline"
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {children}
                </div>
              )}
            </div>
          </div>

          <div className="si-pointer-top si-pointer-border-top" />
          <div
            className="si-pointer-top si-pointer-bg-top"
            style={{ borderWidth: 15, marginBottom: 0, top: 0 }}
          />
        </div>
      )}
    </div>
  );
};
