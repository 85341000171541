import React from 'react';

export type IconGiftProps = {
  className?: string;
  fill?: string | '#fff';
  position?: 'left' | 'center';
};

export const IconGift: React.FC<IconGiftProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <path
        fill={fill}
        d="m22.05,80h60.89c1.07,0,1.94-.87,1.94-1.94v-43.73h.6c1.07,0,1.94-.87,1.94-1.94v-15.22c0-1.07-.87-1.94-1.94-1.94h-17.64l.52-.9c2.65-4.54,1.1-10.39-3.44-13.04-3.94-2.29-8.98-1.47-11.98,1.97l-.45.52-.45-.52c-1.68-1.92-4-3.07-6.54-3.24-2.54-.17-5,.66-6.91,2.33-3.43,3-4.26,8.04-1.97,11.98l.52.9h-17.64c-1.07,0-1.94.87-1.94,1.94v15.22c0,1.07.87,1.94,1.94,1.94h.6v43.73c0,1.07.87,1.94,1.94,1.94Zm20.9-3.87h-18.96v-41.8h18.96v41.8Zm38.06,0h-18.96v-41.8h18.96v41.8Zm2.54-57.02v11.35h-21.5v-11.35h21.5Zm-29.11-9.55c0-3.12,2.55-5.67,5.67-5.67.03,0,.05,0,.08,0,1.49,0,2.89.57,3.96,1.61,1.09,1.06,1.7,2.47,1.72,3.99.02,1.52-.55,2.95-1.61,4.03-1.06,1.09-2.47,1.7-3.99,1.72h-5.82v-5.67Zm3.74,9.55v57.02h-11.35V19.11h11.35Zm-18.96-9.56c.04-3.13,2.65-5.6,5.75-5.6,3.1.04,5.55,2.5,5.6,5.6v5.68h-5.67c-3.13,0-5.67-2.55-5.67-5.67h0Zm-17.76,9.56h21.5v11.35h-21.5v-11.35Z"
      />
    ) : (
      <>
        <path
          fill={fill}
          d="m4.47,80h60.89c1.07,0,1.94-.87,1.94-1.94v-43.73h.6c1.07,0,1.94-.87,1.94-1.94v-15.22c0-1.07-.87-1.94-1.94-1.94h-17.64l.52-.9c2.65-4.54,1.1-10.39-3.44-13.04-3.94-2.29-8.98-1.47-11.98,1.97l-.45.52-.45-.52C32.79,1.34,30.47.19,27.93.02c-2.54-.17-5,.66-6.91,2.33-3.43,3-4.26,8.04-1.97,11.98l.52.9H1.94c-1.07,0-1.94.87-1.94,1.94v15.22c0,1.07.87,1.94,1.94,1.94h.6v43.73c0,1.07.87,1.94,1.94,1.94Zm20.9-3.87H6.41v-41.8h18.96v41.8Zm38.06,0h-18.96v-41.8h18.96v41.8Zm2.54-57.02v11.35h-21.5v-11.35h21.5Zm-29.11-9.55c0-3.12,2.55-5.67,5.67-5.67.03,0,.05,0,.08,0,1.49,0,2.89.57,3.96,1.61,1.09,1.06,1.7,2.47,1.72,3.99.02,1.52-.55,2.95-1.61,4.03-1.06,1.09-2.47,1.7-3.99,1.72h-5.82v-5.67Zm3.74,9.55v57.02h-11.35V19.11h11.35Zm-18.96-9.56c.04-3.13,2.65-5.6,5.75-5.6,3.1.04,5.55,2.5,5.6,5.6v5.68h-5.67c-3.13,0-5.67-2.55-5.67-5.67h0ZM3.87,19.11h21.5v11.35H3.87v-11.35Z"
        />
      </>
    )}
  </svg>
);
