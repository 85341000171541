import React from 'react';

export type IconSignPostProps = {
  className?: string;
  fill?: string | '#fff';
  position?: 'left' | 'center';
};

export const IconSignPost: React.FC<IconSignPostProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <path
        fill={fill}
        d="m78.25,25.52c.47,0,.92-.17,1.27-.48l9.7-8.61c.43-.38.66-.92.65-1.49-.02-.57-.28-1.1-.72-1.45l-9.7-7.71c-.34-.27-.76-.42-1.2-.42h-25.14V1.92c0-1.06-.86-1.92-1.92-1.92s-1.92.86-1.92,1.92v3.44h-11.81c-1.06,0-1.92.86-1.92,1.92v16.32c0,1.06.86,1.92,1.92,1.92h11.81v5.45h-22.52c-.47,0-.92.17-1.27.48l-9.71,8.6c-.43.38-.66.92-.65,1.49.02.57.28,1.1.72,1.45l9.71,7.71c.34.27.76.42,1.19.42h22.52v25.03h-12.79c-1.06,0-1.92.86-1.92,1.92s.86,1.92,1.92,1.92h14.7s.04,0,.06,0h14.64c1.06,0,1.92-.86,1.92-1.92s-.86-1.92-1.92-1.92h-12.77v-25.03h14.42c1.06,0,1.92-.86,1.92-1.92v-16.31c0-1.06-.86-1.92-1.92-1.92h-14.42v-5.45h25.14Zm-12.64,9.29v12.47H27.43l-.05-.04-7.34-5.84,7.45-6.6h23.71s.04,0,.06,0h14.37Zm-26.22-13.13v-12.48h38.19l.05.04,7.34,5.84-7.44,6.6h-38.13Z"
      />
    ) : (
      <path
        fill={fill}
        d="m63.12,25.52c.47,0,.92-.17,1.27-.48l9.7-8.61c.43-.38.66-.92.65-1.49-.02-.57-.28-1.1-.72-1.45l-9.7-7.71c-.34-.27-.76-.42-1.2-.42h-25.14V1.92c0-1.06-.86-1.92-1.92-1.92s-1.92.86-1.92,1.92v3.44h-11.81c-1.06,0-1.92.86-1.92,1.92v16.32c0,1.06.86,1.92,1.92,1.92h11.81v5.45H11.63c-.47,0-.92.17-1.27.48L.65,40.05c-.43.38-.66.92-.65,1.49.02.57.28,1.1.72,1.45l9.71,7.71c.34.27.76.42,1.19.42h22.52v25.03h-12.79c-1.06,0-1.92.86-1.92,1.92s.86,1.92,1.92,1.92h14.7s.04,0,.06,0h14.64c1.06,0,1.92-.86,1.92-1.92s-.86-1.92-1.92-1.92h-12.77v-25.03h14.42c1.06,0,1.92-.86,1.92-1.92v-16.31c0-1.06-.86-1.92-1.92-1.92h-14.42v-5.45h25.14Zm-12.64,9.29v12.47H12.3l-.05-.04-7.34-5.84,7.45-6.6h23.71s.04,0,.06,0h14.37Zm-26.22-13.13v-12.48h38.19l.05.04,7.34,5.84-7.44,6.6H24.26Z"
      />
    )}
  </svg>
);
