// @ts-ignore
import { SwiperComponent } from 'swiper/types';
import SwiperClass from 'swiper/types/swiper-class';

export const SwiperResizeObserverPlugin: SwiperComponent = {
  name: 'resizeObserver',
  create: (swiper: SwiperClass) => {
    if (typeof ResizeObserver === undefined) {
      return;
    }
    Object.assign(swiper, {
      resizeObserver: null,
    });
  },
  on: {
    init: (swiper: SwiperClass) => {
      if (typeof ResizeObserver === undefined) {
        return;
      }
      const container = swiper.$el[0];
      const observer = new ResizeObserver(() => {
        swiper.emit('observerUpdate');
      });
      observer.observe(container, { box: 'border-box' });
      // @ts-ignore
      swiper.resizeObserver = observer;
    },
  },
  destroy: (swiper: SwiperClass) => {
    // @ts-ignore
    if (swiper.resizeObserver) {
      // @ts-ignore
      swiper.resizeObserver.disconnect();
    }
  },
};
