import { globalHistory } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Unity, { UnityContext } from 'react-unity-webgl';

const buildUrl = '/game/Build';

const unityContext = new UnityContext({
  loaderUrl: buildUrl + '/WebGL.loader.js',
  dataUrl: buildUrl + '/WebGL.data',
  frameworkUrl: buildUrl + '/WebGL.framework.js',
  codeUrl: buildUrl + '/WebGL.wasm',
  streamingAssetsUrl: 'StreamingAssets',
  companyName: 'Paraplegie',
  productName: 'fussball-spiel-paraplegie',
  productVersion: '0.1',
});

export const OnlineGame: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setGameFinished: (value: boolean) => void;
}> = ({ setGameFinished }) => {
  const { t } = useTranslation();

  useEffect(function () {
    unityContext.on('GameFinished', function () {
      return unityContext.quitUnityInstance().then(() => setGameFinished(true));
    });
  });

  const [progression, setProgression] = useState(0);
  useEffect(function () {
    unityContext.on('progress', function (progression) {
      setProgression(progression);
    });
  });

  useEffect(function () {
    unityContext.on('Minimize', function () {
      unityContext.setFullscreen(false);
    });
  }, []);

  const [, setDidError] = useState(false);

  useEffect(() => {
    // If User navigates away
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        return unityContext.quitUnityInstance().then(() => setDidError(true));
      }
    });
  }, []);

  const handleOnClickFullscreen = () => {
    unityContext.setFullscreen(true);
  };

  let winWidth =
    typeof window !== 'undefined' ? window.innerWidth + 'px' : '100%';
  let winHeight =
    typeof window !== 'undefined' ? window.innerHeight + 'px' : '100%';

  let isDevice = false;

  if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      isDevice = true;
      winWidth = window.innerWidth + 'px';
      winHeight = window.innerHeight + 'px';
    }
  }

  return (
    <div className={'OnlineGame OnlineGame--inner'}>
      <div className="unity-container unity-desktop">
        {progression * 100 <= 98 && (
          <p className="text-center">
            {t('Loading')} {Math.round(progression * 100)} {t('%')}...
          </p>
        )}
        <button
          className="unity-fullscreen-button"
          onClick={handleOnClickFullscreen}
          aria-label="Go fullscreen"
        />

        {isDevice ? (
          <Unity
            className="unity-canvas"
            unityContext={unityContext}
            tabIndex={1}
            style={{
              height: winHeight,
              width: winWidth,
            }}
          />
        ) : (
          <Unity
            className="unity-canvas"
            unityContext={unityContext}
            tabIndex={1}
          />
        )}

        <div className="unity-loading-bar">
          <div className="unity-logo" />
          <div className="unity-progress-bar-empty">
            <div className="unity-progress-bar-full" />
          </div>
        </div>
        <div className="unity-warning" />
        <div className="unity-footer">
          <div className="unity-webgl-logo" />
          <div className="unity-build-title">Fussball Spiel Paraplegie</div>
        </div>
      </div>
    </div>
  );
};

export default OnlineGame;
