import { ParagraphLinkListFragment } from '@custom/schema';

import { isPublished } from '../../utils';

export const transformParagraphLinkListToProps = (
  paragraph: ParagraphLinkListFragment,
) => ({
  links:
    paragraph.links?.filter(isPublished).map((link) => ({
      url: link?.url || '',
      label: link?.title || '',
      target: link?.options?.attributes?.target,
    })) || [],
});
