import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain, useFlyout, useMenu } from '../../hooks';
import { FlyoutClose, SecondaryTitle } from '../0-atoms';

export const HeaderFlyoutOrganisation: React.FC = () => {
  const { Link } = useFrameworkDependencies();
  const { t } = useTranslation();
  const { activeFlyout } = useFlyout();
  const { domain } = useDomain();
  const menuItems = useMenu(domain.sidebarMenu || 'main');

  return (
    <>
      {menuItems
        .filter((menuItem) => menuItem.attributes?.ariaControls)
        .map((item, index) => {
          const key = `${item.attributes!.id}--${index}`;

          return (
            item.attributes?.enabled && (
              <div
                aria-expanded={activeFlyout === item.attributes?.ariaControls}
                aria-labelledby={item.attributes!.id}
                className={classNames('MainNavFlyout', {
                  'is-visible': activeFlyout === item.attributes?.ariaControls,
                })}
                role={item.attributes!.id}
                key={key}
              >
                <div className="MainNavFlyout--content">
                  <SecondaryTitle className="visuallyhidden">
                    {t('{{section}} Subnavigation', {
                      section: t(item.attributes!.id!),
                    })}
                  </SecondaryTitle>
                  <div className="MainNavFlyout--nav" role="list">
                    {item.childItems && (
                      <ul className="MainNavFlyout--categories">
                        {item.childItems.map(
                          (secondLevelItem) =>
                            secondLevelItem.attributes?.enabled && (
                              <li
                                key={secondLevelItem.id}
                                className="MainNavFlyout--category"
                              >
                                <Link
                                  className="MainNavFlyout--category--titleLink"
                                  to={secondLevelItem.url}
                                  target={secondLevelItem.attributes?.target}
                                >
                                  <div className="MainNavFlyout--title">
                                    {secondLevelItem.title}
                                  </div>
                                </Link>
                                {secondLevelItem.childItems && (
                                  <ul className="MainNavFlyout--category--list">
                                    {secondLevelItem.childItems.map(
                                      (thirdLevelItem) =>
                                        thirdLevelItem.attributes?.enabled && (
                                          <li key={`${thirdLevelItem.id}`}>
                                            <Link
                                              className="MainNavFlyout--category--link"
                                              to={thirdLevelItem.url}
                                              target={
                                                thirdLevelItem.attributes
                                                  ?.target
                                              }
                                            >
                                              {thirdLevelItem.title}
                                            </Link>
                                          </li>
                                        ),
                                    )}
                                  </ul>
                                )}
                              </li>
                            ),
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <FlyoutClose title={t('Close "Group" Flyout')} />
              </div>
            )
          );
        })}
    </>
  );
};
