import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphTwoColumnTextProps = PropsWithChildren<{
  text?: string;
  className?: string;
  textLast?: string;
}>;

export const ParagraphTwoColumnText: React.FC<ParagraphTwoColumnTextProps> = ({
  text = '',
  textLast = '',
  className,
  children,
}) => {
  return (
    <FadeUp yGap={20} className="TwoColumnText TwoColumnText--wrapper">
      {text && (
        <div
          className={classNames(
            'TwoColumnText--item TwoColumnText--first',
            className,
          )}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {textLast && (
        <div
          className={classNames(
            'TwoColumnText--item TwoColumnText--last',
            className,
          )}
          dangerouslySetInnerHTML={{ __html: textLast }}
        />
      )}
      {!text && !textLast && (
        <div className={classNames('ArticleText', className)}>{children}</div>
      )}
    </FadeUp>
  );
};

export default ParagraphTwoColumnText;
