import { stringify } from 'qs';
import { useQuery } from 'react-query';

import { Locale } from '../../i18n';
import { buildUrl } from '../utils';

export const endpoint = (
  language: Locale = 'en',
  path?: string,
  query?: Parameters<typeof stringify>[0],
  queryOptions?: Parameters<typeof stringify>[1],
) => {
  const drupalUrl = process.env.GATSBY_BACKEND_BASE_URL;
  return buildUrl([drupalUrl, language, path], query, queryOptions);
};

export type SearchResultsQueryData = {
  rows: Array<{
    title_1: string;
    search_api_excerpt?: string;
    url: string;
  }>;
  pager: {
    current_page: number;
    total_items: number;
    total_pages: number;
    items_per_page: number;
  };
};

export const useSearchResultsQuery = ({
  language,
  search,
  domain,
  page,
}: {
  language: Locale;
  search?: string;
  domain?: string;
  page?: number;
}) =>
  useQuery<SearchResultsQueryData>(
    ['search-results', language, search, domain, page],
    async () => {
      if (!search) {
        return;
      }
      const result = await fetch(
        endpoint(language, 'rest/site_search', {
          search_api_fulltext: search,
          field_domain_access: domain ? domain : undefined,
          page: page ? page - 1 : undefined,
        }),
      );
      const searchResult = await result.json();

      return {
        ...searchResult,
        pager: {
          current_page: parseInt(searchResult.pager.current_page),
          total_items: parseInt(searchResult.pager.total_items),
          total_pages: parseInt(searchResult.pager.total_pages),
          items_per_page: parseInt(searchResult.pager.items_per_page),
        },
      };
    },
    {
      retry: false,
    },
  );
