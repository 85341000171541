import { Locale } from '../../i18n';
import { useFrameworkDependencies } from '../dependencies';
import { isTruthy } from '../utils/isTruthy';
import { useMedicalDomainTermsQuery } from './useMedicalDomainTermsQuery';

export const useMedicalDomainTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const terms = useMedicalDomainTermsQuery();
  return (
    terms.fetchAllTermMedicalDomain
      ?.filter(isTruthy)
      .filter((term) => term.locale === (locale || currentLocale)) || []
  );
};
