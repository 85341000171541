import React from 'react';

export type IconDeskWheelChairProps = {
  className?: string;
  fill?: string | '#FFF';
  position?: 'left' | 'center';
};

export const IconDeskWheelChair: React.FC<IconDeskWheelChairProps> = ({
  className,
  fill,
  position = 'center',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 80"
  >
    {position === 'center' ? (
      <>
        <path
          fill={fill}
          d="m44.54,50.78c1.71-.96,3.69-1.19,5.58-.65.87.24,1.67.64,2.39,1.18.4.3.9.42,1.39.35.5-.07.93-.33,1.23-.74.3-.4.42-.9.35-1.39-.07-.5-.33-.93-.74-1.23-1.09-.81-2.3-1.41-3.61-1.78-2.85-.81-5.85-.46-8.43.99-2.59,1.44-4.45,3.81-5.26,6.66-1.66,5.88,1.77,12.02,7.65,13.69,1,.28,2.02.42,3.04.42,1.87,0,3.74-.49,5.39-1.41,2.58-1.45,4.45-3.81,5.26-6.66.28-1-.3-2.03-1.29-2.32-.48-.14-.99-.08-1.42.17-.44.25-.75.64-.89,1.13-.53,1.89-1.77,3.45-3.48,4.41-1.71.96-3.69,1.19-5.58.66-3.89-1.1-6.16-5.16-5.06-9.06.53-1.89,1.77-3.45,3.48-4.41Z"
        />
        <path
          fill={fill}
          d="m44.99,18.27c-.44-.24-.95-.29-1.43-.15-.99.3-1.56,1.34-1.26,2.33l3.07,10.3c.24.79.97,1.34,1.8,1.34.18,0,.36-.03.54-.08.99-.3,1.56-1.34,1.26-2.33l-3.07-10.3c-.14-.48-.46-.88-.91-1.12Z"
        />
        <path
          fill={fill}
          d="m68.11,31.08h13.81c.15,0,.31-.02.48-.07.04-.01.07-.02.11-.03.15-.05.28-.11.41-.19l.03-.02c.12-.08.24-.19.36-.32.03-.03.05-.06.08-.1.11-.15.19-.28.25-.42l5.76-13.82c.19-.46.19-.97,0-1.44-.19-.46-.55-.83-1.01-1.02-.96-.4-2.06.06-2.45,1.01l-5.27,12.65h-12.56c-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88Z"
        />
        <path
          fill={fill}
          d="m101.69,31.07h-40.27c-1.35,0-2.45,1.1-2.45,2.45,0,.05,0,.11,0,.16-.65-.27-1.33-.41-2.01-.41h-13.48l-3.69-12.22c-.15-.48-.47-.88-.91-1.11-.44-.23-.95-.28-1.42-.14-.48.14-.87.47-1.11.91-.24.44-.29.95-.14,1.43l4.1,13.55c.24.8.96,1.33,1.79,1.33h14.87c.86,0,1.56.7,1.56,1.56s-.7,1.56-1.56,1.56h-16.83c-1.49,0-2.84-1.01-3.26-2.45l-4.19-13.65c-.42-1.4-.36-2.93.17-4.29l.64-1.68c.9.28,1.82.42,2.75.42,5.1,0,9.24-4.15,9.24-9.24S41.33,0,36.24,0s-9.24,4.15-9.24,9.24c0,2.67,1.16,5.2,3.18,6.97l-.84,2.19c-.82,2.13-.91,4.52-.25,6.72l4.19,13.65c.89,3.02,3.71,5.12,6.85,5.12h16.83c2.93,0,5.31-2.38,5.31-5.31,0-.91-.24-1.81-.7-2.61h16.37v16.16l-1.71-6.21c-1.56-5.66-5.66-9.04-10.97-9.04-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88c3.57,0,6.25,2.29,7.35,6.28l4.72,17.15c.34,1.25-.39,2.55-1.64,2.89-.19.05-.39.08-.6.08h-.06c-1.05-.02-1.97-.74-2.24-1.76l-4.03-14.48c-.05-.16-.12-.33-.23-.49-.88-2.62-2.22-5.01-3.99-7.1-.67-.79-1.86-.88-2.65-.21s-.88,1.86-.21,2.64c6.36,7.48,5.45,18.75-2.03,25.11-3.62,3.08-8.23,4.56-12.97,4.17-4.75-.38-9.06-2.59-12.13-6.21-5.85-6.88-5.62-17.06.53-23.67.34-.37.52-.84.5-1.35-.02-.5-.23-.96-.6-1.31-.76-.7-1.95-.66-2.65.1-7.45,8.01-7.72,20.32-.64,28.65,1.78,2.1,3.94,3.81,6.41,5.1H2.74c-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88h92.05c1.03,0,1.88-.84,1.88-1.88s-.84-1.88-1.88-1.88h-13.08v-40.28h19.99c1.35,0,2.45-1.1,2.45-2.45s-1.1-2.45-2.45-2.45ZM30.75,9.24c0-3.03,2.46-5.49,5.49-5.49s5.49,2.46,5.49,5.49-2.46,5.49-5.49,5.49-5.49-2.46-5.49-5.49Zm36.53,57.8c.5-.97.92-1.99,1.27-3.04l.63,2.26c.07.26.16.52.27.78h-2.17Zm7.8,3.76h2.88s0,5.45,0,5.45h-19.8c1.42-.75,2.74-1.63,3.93-2.64.96-.81,1.88-1.76,2.74-2.81h10.21l.02-.15h0s0,0,0,0h0s.02.15.02.15Z"
        />
      </>
    ) : (
      <>
        <path
          fill={fill}
          d="m43.68,50.78c1.71-.96,3.69-1.19,5.58-.65.87.24,1.67.64,2.39,1.18.4.3.9.42,1.39.35.5-.07.93-.33,1.23-.74.3-.4.42-.9.35-1.39-.07-.5-.33-.93-.74-1.23-1.09-.81-2.3-1.41-3.61-1.78-2.85-.81-5.85-.46-8.43.99-2.59,1.44-4.45,3.81-5.26,6.66-1.66,5.88,1.77,12.02,7.65,13.69,1,.28,2.02.42,3.04.42,1.87,0,3.74-.49,5.39-1.41,2.58-1.45,4.45-3.81,5.26-6.66.28-1-.3-2.03-1.29-2.32-.48-.14-.99-.08-1.42.17-.44.25-.75.64-.89,1.13-.53,1.89-1.77,3.45-3.48,4.41-1.71.96-3.69,1.19-5.58.66-3.89-1.1-6.16-5.16-5.06-9.06.53-1.89,1.77-3.45,3.48-4.41Z"
        />
        <path
          fill={fill}
          d="m44.12,18.27c-.44-.24-.95-.29-1.43-.15-.99.3-1.56,1.34-1.26,2.33l3.07,10.3c.24.79.97,1.34,1.8,1.34.18,0,.36-.03.54-.08.99-.3,1.56-1.34,1.26-2.33l-3.07-10.3c-.14-.48-.46-.88-.91-1.12Z"
        />
        <path
          fill={fill}
          d="m67.25,31.08h13.81c.15,0,.31-.02.48-.07.04-.01.07-.02.11-.03.15-.05.28-.11.41-.19l.03-.02c.12-.08.24-.19.36-.32.03-.03.05-.06.08-.1.11-.15.19-.28.25-.42l5.76-13.82c.19-.46.19-.97,0-1.44-.19-.46-.55-.83-1.01-1.02-.96-.4-2.06.06-2.45,1.01l-5.27,12.65h-12.56c-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88Z"
        />
        <path
          fill={fill}
          d="m100.83,31.07h-40.27c-1.35,0-2.45,1.1-2.45,2.45,0,.05,0,.11,0,.16-.65-.27-1.33-.41-2.01-.41h-13.48l-3.69-12.22c-.15-.48-.47-.88-.91-1.11-.44-.23-.95-.28-1.42-.14-.48.14-.87.47-1.11.91-.24.44-.29.95-.14,1.43l4.1,13.55c.24.8.96,1.33,1.79,1.33h14.87c.86,0,1.56.7,1.56,1.56s-.7,1.56-1.56,1.56h-16.83c-1.49,0-2.84-1.01-3.26-2.45l-4.19-13.65c-.42-1.4-.36-2.93.17-4.29l.64-1.68c.9.28,1.82.42,2.75.42,5.1,0,9.24-4.15,9.24-9.24S40.47,0,35.37,0s-9.24,4.15-9.24,9.24c0,2.67,1.16,5.2,3.18,6.97l-.84,2.19c-.82,2.13-.91,4.52-.25,6.72l4.19,13.65c.89,3.02,3.71,5.12,6.85,5.12h16.83c2.93,0,5.31-2.38,5.31-5.31,0-.91-.24-1.81-.7-2.61h16.37v16.16l-1.71-6.21c-1.56-5.66-5.66-9.04-10.97-9.04-1.03,0-1.88.84-1.88,1.88s.84,1.88,1.88,1.88c3.57,0,6.25,2.29,7.35,6.28l4.72,17.15c.34,1.25-.39,2.55-1.64,2.89-.19.05-.39.08-.6.08h-.06c-1.05-.02-1.97-.74-2.24-1.76l-4.03-14.48c-.05-.16-.12-.33-.23-.49-.88-2.62-2.22-5.01-3.99-7.1-.67-.79-1.86-.88-2.65-.21s-.88,1.86-.21,2.64c6.36,7.48,5.45,18.75-2.03,25.11-3.62,3.08-8.23,4.56-12.97,4.17-4.75-.38-9.06-2.59-12.13-6.21-5.85-6.88-5.62-17.06.53-23.67.34-.37.52-.84.5-1.35-.02-.5-.23-.96-.6-1.31-.76-.7-1.95-.66-2.65.1-7.45,8.01-7.72,20.32-.64,28.65,1.78,2.1,3.94,3.81,6.41,5.1H1.88C.84,76.25,0,77.09,0,78.12s.84,1.88,1.88,1.88h92.05c1.03,0,1.88-.84,1.88-1.88s-.84-1.88-1.88-1.88h-13.08v-40.28h19.99c1.35,0,2.45-1.1,2.45-2.45s-1.1-2.45-2.45-2.45ZM29.88,9.24c0-3.03,2.46-5.49,5.49-5.49s5.49,2.46,5.49,5.49-2.46,5.49-5.49,5.49-5.49-2.46-5.49-5.49Zm36.53,57.8c.5-.97.92-1.99,1.27-3.04l.63,2.26c.07.26.16.52.27.78h-2.17Zm7.8,3.76h2.88s0,5.45,0,5.45h-19.8c1.42-.75,2.74-1.63,3.93-2.64.96-.81,1.88-1.76,2.74-2.81h10.21l.02-.15h0s0,0,0,0h0s.02.15.02.15Z"
        />
      </>
    )}
  </svg>
);
