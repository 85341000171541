import { ParagraphTestimonialImageFragment } from '@custom/schema';
import React from 'react';

import { Image, Teaser, TextBox } from '../../components/0-atoms';
import { transformImageFragmentToProps } from './MediaImage';

// @todo potential candidate for removal
//   as this does not seem to be used in the backend
//   ParagraphTestimonialGallery (machine name: full_width_gallery)
//     - References ParagraphFullWidthGalleryImage items (machine name: full_width_gallery_image)
//     - Does not reference ParagraphTestimonialImage (machine name: floating_box)
export const transformParagraphTestimonialImageToProps = (
  paragraph: ParagraphTestimonialImageFragment,
) => {
  const paragraphImage = paragraph?.image;
  const image = paragraphImage ? (
    <Image
      {...transformImageFragmentToProps(paragraphImage, [
        'small',
        'medium',
        'large',
      ])}
      size="large"
      className="FloatingBox--img"
      fit
    />
  ) : undefined;

  let children;
  switch (paragraph?.textItem?.__typename) {
    case 'ParagraphTextTeaser':
      children = (
        <Teaser
          title={paragraph?.textItem?.link?.title}
          url={paragraph?.textItem?.link?.url}
          target={paragraph?.textItem?.link?.options?.attributes?.target}
          linkLabel={paragraph?.textItem?.link?.title}
          text={paragraph?.textItem?.textHtml}
        />
      );
      break;
    case 'ParagraphTextBox':
      children = (
        <TextBox
          title={paragraph?.textItem?.titleHtml}
          subtitle={paragraph?.textItem?.subTitleHtml}
        />
      );
      break;
  }

  return {
    children,
    image,
  };
};
