import React from 'react';

export type IconFooterBlogProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterBlog: React.FC<IconFooterBlogProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="25"
    viewBox="0 0 31 25"
  >
    <path
      fill={fill}
      d="M14.319 8.477q0 2.417-1.062 4.431-1.062 1.978-2.49 3.443-1.465 1.501-3.223 2.71-1.758 1.171-2.82 1.72l-1.245-2.16q1.502-.879 2.857-1.794 1.391-.952 2.124-1.795.988-1.098 1.574-2.27.586-1.209.733-3.15H9.009q-2.307 0-3.589-1.171T4.138 5.18q0-1.501 1.172-2.82 1.172-1.354 3.186-1.354 3.113 0 4.468 2.087 1.355 2.05 1.355 5.383zm13.55 0q0 2.417-1.062 4.431-1.062 1.978-2.49 3.443-1.465 1.501-3.223 2.71-1.758 1.171-2.82 1.72l-1.245-2.16q1.502-.879 2.857-1.794 1.391-.952 2.124-1.795.988-1.098 1.574-2.27.586-1.209.733-3.15h-1.758q-2.307 0-3.589-1.171-1.282-1.172-1.282-3.26 0-1.501 1.172-2.82 1.172-1.354 3.186-1.354 3.113 0 4.468 2.087 1.355 2.05 1.355 5.383z"
    />
  </svg>
);
