import classNames from 'classnames';
import React from 'react';

export type AccordionContentProps = {
  id: string;
  isActive: boolean;
  className?: string;
  isCollapsible?: boolean;
  children: React.ReactNode;
};

export const AccordionContent: React.FC<AccordionContentProps> = ({
  id,
  isActive,
  className,
  isCollapsible = true,
  children,
}) => {
  return (
    <div
      id={`AccordionItem--content-${id}`}
      className={classNames('AccordionItem--content', className, {
        'is-opened': isActive || !isCollapsible,
      })}
      aria-labelledby={`AccordionItem--trigger-${id}`}
      role="tabpanel"
    >
      {children}
    </div>
  );
};
