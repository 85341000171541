type TypeMap = {
  types: Array<string>;
  label: string;
};

export const formatType = (type: string) => {
  const [, subtype] = type.split('/', 2);
  const typesMap: Array<TypeMap> = [
    {
      types: ['ms-excel', 'spreadsheetml'],
      label: 'Excel',
    },
    {
      types: ['ms-powerpoint', 'presentationml'],
      label: 'Powerpoint',
    },
    {
      types: ['ms-word', 'wordprocessingml', 'msword'],
      label: 'Word',
    },
    {
      types: ['opendocument.text'],
      label: 'OpenDocument text',
    },
    {
      types: ['opendocument.spreadsheet'],
      label: 'OpenDocument spreadsheet',
    },
    {
      types: ['opendocument.presentation'],
      label: 'OpenDocument presentation',
    },
    {
      types: ['jpeg', 'jpg'],
      label: 'jpeg/jpg',
    },
    {
      types: ['png'],
      label: 'png',
    },
    {
      types: ['gif'],
      label: 'gif',
    },
    {
      types: ['svg'],
      label: 'svg',
    },
    {
      types: ['html'],
      label: 'html',
    },
    {
      types: ['pdf'],
      label: 'pdf',
    },
    {
      types: [
        'aac',
        'midi',
        'x-midi',
        'wav',
        'opus',
        'audio/ogg',
        'audio/mpeg',
        'audio/webm',
        'audio/3gpp',
        'audio/3gpp2',
      ],
      label: 'audio',
    },
    {
      types: [
        'x-msvideo',
        'mp2t',
        'video/mpeg',
        'video/ogg',
        'video/webm',
        'video/3gpp',
        'video/3gpp2',
      ],
      label: 'video',
    },
    {
      types: ['plain'],
      label: 'text',
    },
    {
      types: ['woff', 'woff2', 'ttf', 'otf', 'ms-fontobject'],
      label: 'font',
    },
  ];

  return typesMap
    .filter(
      ({ types }) =>
        types.includes(type.toLowerCase()) ||
        types.includes(subtype.toLowerCase()) ||
        types.find((mappedType) => {
          return subtype.toLowerCase().match(mappedType);
        }),
    )
    .map(({ label }) => label.toUpperCase())
    .join(', ');
};
