import { ParagraphDownloadListFragment } from '@custom/schema';

import { formatSize, isPublished } from '../../utils';

export const transformParagraphDownloadListToProps = (
  paragraph: ParagraphDownloadListFragment,
) => ({
  downloads:
    paragraph?.files?.filter(isPublished).map((download) => ({
      url: download?.url,
      label: download?.name,
      size: download?.size ? formatSize(download?.size) : undefined,
      type: download?.mimeType,
    })) || [],
});
