import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export type AccordionWrapperProps = PropsWithChildren<{
  isActive: boolean;
  hasLogoImage?: boolean;
  isCollapsible?: boolean;
}>;

export const AccordionWrapper: React.FC<AccordionWrapperProps> = ({
  isActive,
  hasLogoImage = false,
  isCollapsible = true,
  children,
}) => (
  <div
    className={classNames('AccordionItem', {
      imgContain: hasLogoImage,
      'is-opened': isActive || !isCollapsible,
    })}
  >
    {children}
  </div>
);
