import React from 'react';

export type IconFooterLinkedInProps = {
  className?: string;
  fill?: string | '#FFF';
};

export const IconFooterLinkedIn: React.FC<IconFooterLinkedInProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    viewBox="0 0 512 512"
  >
    <path
      fill={fill}
      d="M109.7 79.4c0 28.2-22.7 51-50.6 51s-50.6-22.8-50.6-51 22.7-51 50.6-51 50.6 22.8 50.6 51zm.4 91.9H8V498h102.1V171.3zm162.9 0H171.6V498h101.5V326.5c0-95.3 123.1-103.1 123.1 0V498H498V291.1c0-160.9-182.2-155-225-75.8v-44z"
    />
  </svg>
);
