import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { useFrameworkDependencies } from '../../dependencies';
import { SwiperResizeObserverPlugin } from '../../plugins';
import { RelatedBlogPost } from '../../types';

SwiperCore.use([A11y, SwiperResizeObserverPlugin]);

export type BlogRelatedProps = {
  blogs: Array<RelatedBlogPost>;
};

export const BlogRelated: React.FC<BlogRelatedProps> = ({ blogs }) => {
  const { t } = useTranslation();
  const { Link } = useFrameworkDependencies();
  const swiperInstance = useRef<SwiperClass>();
  const [isPreviousActive, setIsPreviousActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(false);

  return (
    <div className="RelatedArticles" data-slides={blogs.length}>
      <div className="RelatedArticles--header">
        <div className="RelatedArticles--titleContainer">
          <h2 className="RelatedArticles--title">{t('Related posts')}</h2>
        </div>
        <div className="RelatedArticles--buttonContainer">
          <a
            className={classNames(
              'RelatedArticle--articleLink RelatedArticle--articleLink-prev',
              {
                'is-disabled': !isPreviousActive,
              },
            )}
            href=""
            rel="prev"
            onClick={(event) => {
              event.preventDefault();
              swiperInstance.current?.slidePrev();
            }}
          >
            <span className="visuallyhidden">{t('Previous')}</span>
            <i className="ico ico-bolt-left" />
          </a>
          <a
            className={classNames(
              'RelatedArticle--articleLink RelatedArticle--articleLink-next',
              {
                'is-disabled': !isNextActive,
              },
            )}
            href=""
            rel="next"
            onClick={(event) => {
              event.preventDefault();
              swiperInstance.current?.slideNext();
            }}
          >
            <span className="visuallyhidden">{t('Next')}</span>
            <i className="ico ico-bolt-right" />
          </a>
        </div>
      </div>
      <Swiper
        className="RelatedArticles--list"
        wrapperTag="ul"
        breakpoints={{
          320: {
            slidesPerView: 1.1,
          },
          480: {
            slidesPerView: 1.5,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
        onSwiper={(instance) => {
          swiperInstance.current = instance;
          setIsPreviousActive(false);
          setIsNextActive(
            instance.slides.length >
              ((instance.params.slidesPerView as number) || 2),
          );
        }}
        onSlideChange={(instance) => {
          setIsPreviousActive(instance.realIndex > 0);
          setIsNextActive(
            instance.slides.length >
              instance.realIndex +
                (typeof instance.params.slidesPerView === 'number'
                  ? instance.params.slidesPerView || 2
                  : 2),
          );
        }}
        updateOnWindowResize={typeof ResizeObserver !== undefined}
      >
        {blogs.map(({ id, image, url, title, domain }) => (
          <SwiperSlide key={id} tag="li" className="RelatedArticles--teaser">
            <Link className="RelatedArticleTeaser" to={url} domain={domain}>
              {image && (
                <div className="RelatedArticleTeaserContainer--background">
                  <div
                    className="RelatedArticleTeaser--background"
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                  />
                </div>
              )}
              <div className="RelatedArticleTeaser--footer mt-2">
                <h3 className="RelatedArticleTeaser--title">{title}</h3>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="RelatedArticles--titleContainerMobile">
        <h2 className="RelatedArticles--title">{t('Related posts')}</h2>
      </div>
    </div>
  );
};
