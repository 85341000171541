import { ContentThankYouFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import { ThankYouPageProps } from '../../components/3-layouts';
import { getImageSrc } from '../../utils';
import { getImageStyleUrl } from './MediaImage';

export const transformThankYouPageFragmentToThankYouPageProps = (
  page?: ContentThankYouFragment,
): ThankYouPageProps => {
  if (!page) {
    return {};
  }

  const thankYouImage = page?.image;

  const desktopWideStyleUrl = getImageStyleUrl(thankYouImage, 'desktop_wide');
  const desktopStdStyleUrl = getImageStyleUrl(
    thankYouImage,
    'desktop_standard',
  );
  const isCropped = desktopWideStyleUrl !== null || desktopStdStyleUrl !== null;

  const image = thankYouImage ? (
    <Image
      alt={thankYouImage?.alt || page.title}
      filemime={thankYouImage?.mimeType}
      src={
        isCropped
          ? getImageSrc(
              thankYouImage,
              `${desktopWideStyleUrl} 2000w,
            ${getImageStyleUrl(thankYouImage, 'desktop_standard')} 1440w,
            ${getImageStyleUrl(thankYouImage, 'mobile')} 750w`,
            )
          : getImageSrc(
              thankYouImage,
              `${getImageStyleUrl(thankYouImage, 'header_image')} 2000w, 
                 ${getImageStyleUrl(
                   thankYouImage,
                   'header_slider_image_responsive_',
                 )} 750w`,
            )
      }
      sizes="(max-width: 750px) 50vw, 100vw"
      className="SubpageHeader--background--img"
      fit
      loading="eager"
    />
  ) : undefined;

  return {
    title: page.title,
    subtitle: page.subTitle,
    body: page?.body,
    url: page.backLink?.url,
    linkLabel: page.backLink?.title,
    image,
    hideCtasHeader: page.hideCallToActionInHeader,
  };
};
