import { ListingDoctorSearchQuery } from '@custom/schema';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDoctorFunctionTerms,
  useDoctorsFilters,
  useFieldOfExpertiseTerms,
  useLocalization,
} from '../../hooks';
import { useOperation } from '../../utils/operation';
import { Loader } from '../0-atoms';
import { DoctorsFilterForm, DoctorTeaserList } from '../1-molecules';

export type DoctorsViewProps = {
  className?: string;
};

export const DoctorsView: React.FC<DoctorsViewProps> = ({ className }) => {
  const { t } = useTranslation();
  const fieldOfExpertiseTerms = useFieldOfExpertiseTerms();
  const doctorFunctionsTerms = useDoctorFunctionTerms();
  const { doctorFunction, field, glossary } = useDoctorsFilters();
  const { locale: currentLocale } = useLocalization();
  // This aggregate is a mapping between a Gatsby speficic id and a collection of terms.
  const doctorFunctionAggregate = doctorFunctionsTerms.find(
    (doctorFunctionsTerm) => doctorFunctionsTerm.id === doctorFunction,
  );
  const doctorFuctionTerms = doctorFunctionAggregate
    ? doctorFunctionAggregate.terms
    : [];

  const { data, isLoading } = useOperation(ListingDoctorSearchQuery, {
    field: field ? [field] : [],
    doctorFunction: doctorFuctionTerms,
    glossary,
    language: currentLocale,
    limit: 100, // No pagination yet.
    offset: 0,
  });
  const doctors = data?.listingDoctorSearch?.items || [];

  return (
    <div
      className={classNames(className, {
        'Pane is-loading relative': isLoading,
      })}
    >
      {isLoading && <Loader />}
      {fieldOfExpertiseTerms && doctorFunctionsTerms && (
        <DoctorsFilterForm
          fields={fieldOfExpertiseTerms.map(({ id, title }) => ({
            id: id.toString(),
            label: title || '',
          }))}
          doctorFunctions={doctorFunctionsTerms.map(({ id, label }) => ({
            id: id,
            label,
          }))}
        />
      )}

      {doctors?.length ? (
        <DoctorTeaserList
          // @ts-ignore
          doctors={doctors
            // Glossary filter.
            // This should be offloaded to the backend
            // but there is no Glossary for last name (just the title).
            // So we just don't pass it to the backend for now.
            // This will be an issue if we have pagination.
            .filter(
              (doctor) =>
                !glossary ||
                glossary.toLowerCase() ===
                  // @ts-ignore
                  doctor.lastName?.charAt(0).toLowerCase(),
            )}
        />
      ) : (
        <p className="ArticleText">
          {t('There are no doctors that match your filters.')}
        </p>
      )}
    </div>
  );
};
