import React from 'react';

export type IconScrollArrowProps = {
  className?: string;
};

export const IconScrollArrow: React.FC<IconScrollArrowProps> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="13.502"
    viewBox="0 0 24 13.502"
    className={className}
  >
    <path
      id="icon_scroll_arrow"
      d="M7.866,4.365a1.221,1.221,0,0,0,0,1.746l7.3,7.148.129.11a1.271,1.271,0,0,0,1.646-.116,1.22,1.22,0,0,0,0-1.745L9.642,4.36l-.129-.111a1.271,1.271,0,0,0-1.647.116Zm-.11,21.653a1.221,1.221,0,0,0,.11,1.62,1.271,1.271,0,0,0,1.775,0L20.63,16.875l.114-.126a1.215,1.215,0,0,0-.11-1.62,1.269,1.269,0,0,0-1.776,0L7.869,25.892Z"
      transform="translate(28 -7.5) rotate(90)"
      fill="#00417a"
      fillRule="evenodd"
    />
  </svg>
);
