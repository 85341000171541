import React from 'react';
import { useTranslation } from 'react-i18next';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { Icon } from '../../types';
import { CTAButton, CTAButtonProps } from '../0-atoms';

export type CTADonateButtonProps = Omit<
  CTAButtonProps,
  'icon' | 'label' | 'url'
> & {
  url?: string;
};

export const CTADonateButton: React.FC<CTADonateButtonProps> = ({
  url,
  ...props
}) => {
  const { t } = useTranslation();
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const donatePath = APP_CONFIG.donatePagePath[locale];

  return (
    <CTAButton
      icon={Icon.DonateRed}
      className="arrow-right"
      label={t('Donate')}
      url={url || donatePath}
      {...props}
    />
  );
};
