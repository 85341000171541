import classNames from 'classnames';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { FadeUp } from '../1-molecules/FadeIn';
import { IconKey, IconSvg } from './IconSvg';

export type ParagraphLinkListProps = {
  links: {
    label: string;
    url: string;
    target?: string;
    domain?: string;
  }[];
  className?: string;
};

export const ParagraphLinkList: React.FC<ParagraphLinkListProps> = ({
  links,
  className,
}) => {
  const { Link } = useFrameworkDependencies();

  return (
    <FadeUp yGap={20} className={classNames('LinkList', className)}>
      <ul className="LinkList--list">
        {links.map(({ label, url, target, domain }, index) => (
          <li key={`link-${index}`}>
            <Link
              to={url}
              target={target}
              domain={domain}
              className="LinkList--link"
            >
              {label}
              <IconSvg
                position={'after'}
                wrapClass={'LinkList--link--icon'}
                icon={`ico-bolt-right` as IconKey}
                transition
                fill="theme"
              />
            </Link>
          </li>
        ))}
      </ul>
    </FadeUp>
  );
};

export default ParagraphLinkList;
