import { ParagraphTitleFragment } from '@custom/schema';

export const transformParagraphTitleToProps = (
  paragraph: ParagraphTitleFragment,
) => ({
  tag: paragraph?.headingLevel || 'h3',
  title: paragraph.title,
  tagClassName: paragraph.headingClass,
  hasBackground: paragraph?.hasBackground,
});
