import { useTranslation } from 'react-i18next';

export const useCountries = () => {
  const { t } = useTranslation();

  return [
    { id: 'CH', name: t('Switzerland') },
    { id: 'DE', name: t('Germany') },
    { id: 'FR', name: t('France') },
    { id: 'IT', name: t('Italy') },
    { id: 'AF', name: t('Afghanistan') },
    { id: 'AL', name: t('Albania') },
    { id: 'DZ', name: t('Algeria') },
    { id: 'AS', name: t('American Samoa') },
    { id: 'AD', name: t('Andorra') },
    { id: 'AO', name: t('Angola') },
    { id: 'AI', name: t('Anguilla') },
    { id: 'AQ', name: t('Antarctica') },
    { id: 'AG', name: t('Antigua and Barbuda') },
    { id: 'AR', name: t('Argentina') },
    { id: 'AM', name: t('Armenia') },
    { id: 'AW', name: t('Aruba') },
    { id: 'AU', name: t('Australia') },
    { id: 'AT', name: t('Austria') },
    { id: 'AZ', name: t('Azerbaijan') },
    { id: 'BS', name: t('Bahamas') },
    { id: 'BH', name: t('Bahrain') },
    { id: 'BD', name: t('Bangladesh') },
    { id: 'BB', name: t('Barbados') },
    { id: 'BY', name: t('Belarus') },
    { id: 'BE', name: t('Belgium') },
    { id: 'BZ', name: t('Belize') },
    { id: 'BJ', name: t('Benin') },
    { id: 'BM', name: t('Bermuda') },
    { id: 'BT', name: t('Bhutan') },
    { id: 'BO', name: t('Bolivia') },
    { id: 'BA', name: t('Bosnia and Herzegovina') },
    { id: 'BW', name: t('Botswana') },
    { id: 'BV', name: t('Bouvet Island') },
    { id: 'BR', name: t('Brazil') },
    { id: 'IO', name: t('British Indian Ocean Territory') },
    { id: 'BN', name: t('Brunei Darussalam') },
    { id: 'BG', name: t('Bulgaria') },
    { id: 'BF', name: t('Burkina Faso') },
    { id: 'BI', name: t('Burundi') },
    { id: 'KH', name: t('Cambodia') },
    { id: 'CM', name: t('Cameroon') },
    { id: 'CA', name: t('Canada') },
    { id: 'CV', name: t('Cape Verde') },
    { id: 'KY', name: t('Cayman Islands') },
    { id: 'CF', name: t('Central African Republic') },
    { id: 'TD', name: t('Chad') },
    { id: 'CL', name: t('Chile') },
    { id: 'CN', name: t('China') },
    { id: 'CX', name: t('Christmas Island') },
    { id: 'CC', name: t('Cocos (Keeling) Islands') },
    { id: 'CO', name: t('Colombia') },
    { id: 'KM', name: t('Comoros') },
    { id: 'CG', name: t('Congo (Brazzaville)') },
    { id: 'CD', name: t('Congo (Kinshasa)') },
    { id: 'CK', name: t('Cook Islands') },
    { id: 'CR', name: t('Costa Rica') },
    { id: 'HR', name: t('Croatia') },
    { id: 'CU', name: t('Cuba') },
    { id: 'CY', name: t('Cyprus') },
    { id: 'CZ', name: t('Czech Republic') },
    { id: 'CI', name: t('Côte dIvoire') },
    { id: 'DK', name: t('Denmark') },
    { id: 'DJ', name: t('Djibouti') },
    { id: 'DM', name: t('Dominica') },
    { id: 'DO', name: t('Dominican Republic') },
    { id: 'EC', name: t('Ecuador') },
    { id: 'EG', name: t('Egypt') },
    { id: 'SV', name: t('El Salvador') },
    { id: 'CQ', name: t('Equatorial Guinea') },
    { id: 'ER', name: t('Eritrea') },
    { id: 'EE', name: t('Estonia') },
    { id: 'ET', name: t('Ethiopia') },
    { id: 'FK', name: t('Falkland Islands') },
    { id: 'FO', name: t('Faroe Islands') },
    { id: 'FJ', name: t('Fiji') },
    { id: 'FI', name: t('Finland') },
    { id: 'GF', name: t('French Guiana') },
    { id: 'PF', name: t('French Polynesia') },
    { id: 'TF', name: t('French Southern Lands') },
    { id: 'GA', name: t('Gabon') },
    { id: 'GM', name: t('Gambia') },
    { id: 'GE', name: t('Georgia') },
    { id: 'GH', name: t('Ghana') },
    { id: 'GI', name: t('Gibraltar') },
    { id: 'GR', name: t('Greece') },
    { id: 'GL', name: t('Greenland') },
    { id: 'GD', name: t('Grenada') },
    { id: 'GP', name: t('Guadeloupe') },
    { id: 'GU', name: t('Guam') },
    { id: 'GT', name: t('Guatemala') },
    { id: 'GG', name: t('Guernsey') },
    { id: 'GN', name: t('Guinea') },
    { id: 'GW', name: t('Guinea-Bissau') },
    { id: 'GY', name: t('Guyana') },
    { id: 'HT', name: t('Haiti') },
    { id: 'HM', name: t('Heard and McDonald Islands') },
    { id: 'HN', name: t('Honduras') },
    { id: 'HK', name: t('Hong Kong') },
    { id: 'HU', name: t('Hungary') },
    { id: 'IS', name: t('Iceland') },
    { id: 'IN', name: t('India') },
    { id: 'ID', name: t('Indonesia') },
    { id: 'IR', name: t('Iran') },
    { id: 'IQ', name: t('Iraq') },
    { id: 'IE', name: t('Ireland') },
    { id: 'IM', name: t('Isle of Man') },
    { id: 'IL', name: t('Israel') },
    { id: 'JM', name: t('Jamaica') },
    { id: 'JP', name: t('Japan') },
    { id: 'JE', name: t('Jersey') },
    { id: 'JO', name: t('Jordan') },
    { id: 'KZ', name: t('Kazakhstan') },
    { id: 'KE', name: t('Kenya') },
    { id: 'KI', name: t('Kiribati') },
    { id: 'KP', name: t('Korea, North') },
    { id: 'KR', name: t('Korea, South') },
    { id: 'XK', name: t('Kosovo') },
    { id: 'KW', name: t('Kuwait') },
    { id: 'KG', name: t('Kyrgyzstan') },
    { id: 'LA', name: t('Laos') },
    { id: 'LV', name: t('Latvia') },
    { id: 'LB', name: t('Lebanon') },
    { id: 'LS', name: t('Lesotho') },
    { id: 'LR', name: t('Liberia') },
    { id: 'LY', name: t('Libya') },
    { id: 'LI', name: t('Liechtenstein') },
    { id: 'LT', name: t('Lithuania') },
    { id: 'LU', name: t('Luxembourg') },
    { id: 'MO', name: t('Macau') },
    { id: 'MK', name: t('Macedonia') },
    { id: 'MG', name: t('Madagascar') },
    { id: 'MW', name: t('Malawi') },
    { id: 'MY', name: t('Malaysia') },
    { id: 'MV', name: t('Maldives') },
    { id: 'ML', name: t('Mali') },
    { id: 'MT', name: t('Malta') },
    { id: 'MH', name: t('Marshall Islands') },
    { id: 'MQ', name: t('Martinique') },
    { id: 'MR', name: t('Mauritania') },
    { id: 'MU', name: t('Mauritius') },
    { id: 'YT', name: t('Mayotte') },
    { id: 'MX', name: t('Mexico') },
    { id: 'FM', name: t('Micronesia') },
    { id: 'MD', name: t('Moldova') },
    { id: 'MC', name: t('Monaco') },
    { id: 'MN', name: t('Mongolia') },
    { id: 'ME', name: t('Montenegro') },
    { id: 'MS', name: t('Montserrat') },
    { id: 'MA', name: t('Morocco') },
    { id: 'MZ', name: t('Mozambique') },
    { id: 'MM', name: t('Myanmar') },
    { id: 'NA', name: t('Namibia') },
    { id: 'NR', name: t('Nauru') },
    { id: 'NP', name: t('Nepal') },
    { id: 'NL', name: t('Netherlands') },
    { id: 'AN', name: t('Netherlands Antilles') },
    { id: 'NC', name: t('New Caledonia') },
    { id: 'NZ', name: t('New Zealand') },
    { id: 'NI', name: t('Nicaragua') },
    { id: 'NE', name: t('Niger') },
    { id: 'NG', name: t('Nigeria') },
    { id: 'NU', name: t('Niue') },
    { id: 'NF', name: t('Norfolk Island') },
    { id: 'MP', name: t('Northern Mariana Islands') },
    { id: 'NO', name: t('Norway') },
    { id: 'OM', name: t('Oman') },
    { id: 'PK', name: t('Pakistan') },
    { id: 'PW', name: t('Palau') },
    { id: 'PS', name: t('Palestine') },
    { id: 'PA', name: t('Panama') },
    { id: 'PG', name: t('Papua New Guinea') },
    { id: 'PY', name: t('Paraguay') },
    { id: 'PE', name: t('Peru') },
    { id: 'PH', name: t('Philippines') },
    { id: 'PN', name: t('Pitcairn') },
    { id: 'PL', name: t('Poland') },
    { id: 'PT', name: t('Portugal') },
    { id: 'PR', name: t('Puerto Rico') },
    { id: 'QA', name: t('Qatar') },
    { id: 'RE', name: t('Reunion') },
    { id: 'RO', name: t('Romania') },
    { id: 'RU', name: t('Russian Federation') },
    { id: 'RW', name: t('Rwanda') },
    { id: 'SH', name: t('Saint Helena') },
    { id: 'KN', name: t('Saint Kitts and Nevis') },
    { id: 'LC', name: t('Saint Lucia') },
    { id: 'PM', name: t('Saint Pierre and Miquelon') },
    { id: 'VC', name: t('Saint Vincent and the Grenadines') },
    { id: 'BL', name: t('Saint-Barthélemy') },
    { id: 'MF', name: t('Saint-Martin (French part)') },
    { id: 'WS', name: t('Samoa') },
    { id: 'SM', name: t('San Marino') },
    { id: 'ST', name: t('Sao Tome and Principe') },
    { id: 'SA', name: t('Saudi Arabia') },
    { id: 'SN', name: t('Senegal') },
    { id: 'RS', name: t('Serbia') },
    { id: 'SC', name: t('Seychelles') },
    { id: 'SL', name: t('Sierra Leone') },
    { id: 'SG', name: t('Singapore') },
    { id: 'SK', name: t('Slovakia') },
    { id: 'SI', name: t('Slovenia') },
    { id: 'SB', name: t('Solomon Islands') },
    { id: 'SO', name: t('Somalia') },
    { id: 'ZA', name: t('South Africa') },
    { id: 'GS', name: t('South Georgia and South Sandwich Islands') },
    { id: 'ES', name: t('Spain') },
    { id: 'LK', name: t('Sri Lanka') },
    { id: 'SD', name: t('Sudan') },
    { id: 'SR', name: t('Suriname') },
    { id: 'SJ', name: t('Svalbard and Jan Mayen Islands') },
    { id: 'SZ', name: t('Swaziland') },
    { id: 'SE', name: t('Sweden') },
    { id: 'SY', name: t('Syria') },
    { id: 'TW', name: t('Taiwan') },
    { id: 'TJ', name: t('Tajikistan') },
    { id: 'TX', name: t('Tanzania') },
    { id: 'TH', name: t('Thailand') },
    { id: 'TL', name: t('Timor-Leste') },
    { id: 'TG', name: t('Togo') },
    { id: 'TK', name: t('Tokelau') },
    { id: 'TO', name: t('Tonga') },
    { id: 'TT', name: t('Trinidad and Tobago') },
    { id: 'TN', name: t('Tunisia') },
    { id: 'TR', name: t('Turkey') },
    { id: 'TM', name: t('Turkmenistan') },
    { id: 'TC', name: t('Turks and Caicos Islands') },
    { id: 'TV', name: t('Tuvalu') },
    { id: 'UG', name: t('Uganda') },
    { id: 'UA', name: t('Ukraine') },
    { id: 'AE', name: t('United Arab Emirates') },
    { id: 'GB', name: t('United Kingdom') },
    { id: 'UM', name: t('United States Minor Outlying Islands') },
    { id: 'US', name: t('United States of America') },
    { id: 'UY', name: t('Uruguay') },
    { id: 'UZ', name: t('Uzbekistan') },
    { id: 'VU', name: t('Vanuatu') },
    { id: 'VA', name: t('Vatican City') },
    { id: 'VE', name: t('Venezuela') },
    { id: 'VN', name: t('Vietnam') },
    { id: 'VG', name: t('Virgin Islands, British') },
    { id: 'VI', name: t('Virgin Islands, U.S.') },
    { id: 'WF', name: t('Wallis and Futuna Islands') },
    { id: 'EH', name: t('Western Sahara') },
    { id: 'YE', name: t('Yemen') },
    { id: 'ZM', name: t('Zambia') },
    { id: 'ZW', name: t('Zimbabwe') },
    { id: 'AX', name: t('Åland') },
  ];
};
