import { useLocation } from '@reach/router';
// import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useNewsroomFilters, usePager } from '../../hooks';
import { FormVerticalSelect } from '../0-atoms';
import { FormTabs } from '../0-atoms/FormTabs';

export type NewsroomFilterFormProps = {
  organisations?: {
    id: string;
    label: string;
  }[];
  types?: {
    id: string;
    label: string;
  }[];
  className?: string;
};

export const NewsroomFilterForm: React.FC<NewsroomFilterFormProps> = ({
  organisations,
  types,
}) => {
  const { t } = useTranslation();
  const { navigate } = useFrameworkDependencies();
  const location = useLocation();
  const { organisation, setOrganisation, type, setType } = useNewsroomFilters();

  const { setCurrentPage } = usePager();

  const resetPage = () => setCurrentPage(1);

  const updateUrl = (query?: { organisation?: string; type?: string }) =>
    navigate(
      `${location.pathname}?${queryString.stringify(
        {
          organisation,
          type,
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      )}`,
    );

  return (
    <form className="NewsroomFilters">
      {types && (
        <FormTabs
          id="u1"
          defaultValue={type ? type : ''}
          name="type"
          options={sortBy(types, 'weight').map(({ id, label }) => ({
            value: id,
            label,
          }))}
          onClick={(type) => {
            setType(type);
            updateUrl({
              type,
            });
            resetPage();
          }}
        />
      )}

      {organisations && (
        <FormVerticalSelect
          id="u2"
          name="organisation"
          className="NewsroomFilters--Organization"
          defaultValue={organisation || ''}
          emptyOption={{
            value: '',
            label: t('Organisation'),
          }}
          options={sortBy(organisations, 'label').map(({ id, label }) => ({
            value: id,
            label,
          }))}
          onChange={(event) => {
            setOrganisation(event.target.value);
            updateUrl({
              organisation: event.target.value,
            });
            resetPage();
          }}
        />
      )}
    </form>
  );
};
