import { decode } from 'he';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';

export type SearchResultProps = {
  url: string;
  title: string;
  excerpt?: string;
};

export const SearchResult: React.FC<SearchResultProps> = ({
  url,
  title,
  excerpt,
}) => {
  const { Link } = useFrameworkDependencies();
  const { domain } = useDomain();

  return (
    <article className="SearchResult">
      <Link to={url} className="SearchResult--titleLink">
        {title && (
          <h2
            className="SearchResult--title"
            dangerouslySetInnerHTML={{ __html: decode(title) }}
          />
        )}
      </Link>
      {excerpt && (
        <p
          className="SearchResult--text"
          dangerouslySetInnerHTML={{ __html: decode(excerpt) }}
        />
      )}
      <p>
        <Link to={url} className="SearchResult--link">
          {url.includes('http') ? url : `${domain.formattedHost}${url}`}
        </Link>
      </p>
    </article>
  );
};
