// import { BlogPager } from '../0-atoms';
import classNames from 'classnames';
import React from 'react';

import { BlogPostPointer } from '../../types';

export type BlogSubpageHeaderProps = {
  image: string | React.ReactNode;
  previous?: BlogPostPointer;
  next?: BlogPostPointer;
  title?: string;
  subtitle?: string;
  date?: string;
  heroButton?: React.ReactNode;
};

export const BlogSubpageHeader: React.FC<BlogSubpageHeaderProps> = ({
  image,
  // previous,
  // next,
  title,
  subtitle,
  // date,
  heroButton,
}) => (
  <div
    className={classNames(
      'SubpageHeader--witText SubpageHeader first-level SubpageHeader--blog',
      {
        'SubpageHeader-img': !!image,
      },
    )}
  >
    <div className="SubpageHeader--body">
      {image && (
        <div className="SubpageHeader--background is-initialized">
          {typeof image === 'string' ? (
            <img
              className="SubpageHeader--background--img"
              alt={title}
              title={title}
              src={image}
            />
          ) : (
            image
          )}
        </div>
      )}
      {title && (
        <div className="SubpageHeader--content">
          <h1
            className="SubpageHeader--title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {subtitle && (
            <p className="SubpageHeader--witText--text">{subtitle}</p>
          )}
        </div>
      )}
      {heroButton && (
        <div className="HeaderHero--footer">
          <div className="HeaderHero--footer--left BlogCTA">
            {/* Was asked to make this temporary. PARD-34 */}
            {/* <div>
            <div className="BlogCTA--item BlogCTA--item-date">
              {date && (
                <time className="BlogCTA--date" dateTime={date}>
                  {date}
                </time>
              )}
            </div>
            <BlogPager previous={previous} next={next} />
          </div> */}
          </div>
          <div className="HeaderHero--footer--right SubpageHeader--cta SubpageHeader--cta-donate">
            {heroButton}
          </div>
        </div>
      )}
    </div>
  </div>
);
