import React, { useEffect } from 'react';

import { APP_CONFIG } from '../../constants';

export const ActiveCampaignTracking: React.FC = () => {
  useEffect(() => {
    if (typeof window === 'undefined' || !APP_CONFIG.activeCampaign.enabled) {
      return;
    }

    // 1 month
    const expiration = new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 30,
    );
    document.cookie =
      'ac_enable_tracking=1; expires= ' + expiration + '; path=/';

    if (/(^|; )ac_enable_tracking=([^;]+)/.test(document.cookie)) {
      // is this required?
      const trackcmp_email = '';
      const script = document.createElement('script');
      script.async = true;
      script.type = 'text/javascript';
      script.src =
        '//trackcmp.net/visit?actid=251965609&e=' +
        encodeURIComponent(trackcmp_email) +
        '&r=' +
        encodeURIComponent(document.referrer) +
        '&u=' +
        encodeURIComponent(window.location.href);
      document.head.appendChild(script);
    }
  }, []);

  return null;
};
