import React from 'react';

export type IconLockProps = {
  className?: string;
};

export const IconLock: React.FC<IconLockProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 25"
    focusable="false"
  >
    <g fillRule="evenodd">
      <g fill="none" fillRule="evenodd">
        <path
          strokeWidth="1.7"
          d="M15.76 8.85H4.21c-1.845 0-3.36 1.524-3.36 3.385v8.53c0 1.861 1.515 3.385 3.36 3.385h11.58c1.845 0 3.36-1.524 3.36-3.385v-8.52c-.024-1.882-1.529-3.395-3.39-3.395z"
        ></path>
        <path
          fillRule="nonzero"
          d="M15.3 7.412c0-3.02-2.353-5.512-5.3-5.512-2.947 0-5.3 2.491-5.3 5.512 0 .262.021.482.08.736l10.48.01c.028-.224.04-.464.04-.746zM16.5 9.5L3.617 9.488C3.26 8.598 3 8.441 3 7.412 3 3.318 6.134 0 10 0s7 3.318 7 7.412c0 1.034-.139 1.194-.5 2.088z"
        ></path>
        <path strokeLinecap="round" strokeWidth="1.7" d="M10 16v2.583"></path>
      </g>
    </g>
  </svg>
);
