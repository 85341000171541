import { SilverbackIframe } from '@amazeelabs/silverback-iframe';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';
import { getDomainUrl } from '../../utils/getDomainUrl';
import { FadeUp } from '../1-molecules/FadeIn';
import OnlineGame from './OnlineGame';

export type ParagraphWebformProps = {
  url?: string;
  status?: string;
  className?: string;
};

export const ParagraphWebform: React.FC<ParagraphWebformProps> = ({
  url,
  status = 'open',
  className,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const isFirstMessage = useRef(true);
  const { domain } = useDomain();

  const { navigate } = useFrameworkDependencies();

  const [urlSearch, setUrlSearch] = useState('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUrlSearch(window.location.search);
    }
  }, [url]);

  // GAME
  const [deviceOrientationPortait, setDeviceOrientationPortait] =
    useState(false);

  const [gameFinished, setGameFinished] = useState(false);

  const gameWebformUrl = '/fcl-wettbewerb';

  const [dimensions, setDimensions] = useState({
    height: typeof window !== 'undefined' && window.innerHeight,
    width: typeof window !== 'undefined' && window.innerWidth,
  });

  const jumpToTop = (to: string, iframeWrapper: HTMLElement) => {
    // For now, we have only implemented the scroll to top feature.
    switch (to) {
      case 'top':
      default:
        iframeWrapper.scrollIntoView({ behavior: 'instant' as ScrollBehavior });
    }
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: typeof window !== 'undefined' && window.innerHeight,
        width: typeof window !== 'undefined' && window.innerWidth,
      });
    }, 1000);

    typeof window !== 'undefined' &&
      window.addEventListener('resize', debouncedHandleResize);

    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    if (dimensions.height > dimensions.width) {
      setDeviceOrientationPortait(true);
    } else {
      setDeviceOrientationPortait(false);
    }
  }, [dimensions]);

  if (status !== 'open' || !url) {
    return null;
  }

  if (url.includes(gameWebformUrl) && status === 'open' && !gameFinished) {
    return (
      <FadeUp yGap={20}>
        <div
          className={classNames(
            'OnlineGame game--device-instructions ArticleText',
            {
              visible: deviceOrientationPortait,
              hidden: !deviceOrientationPortait,
            },
          )}
        >
          <p>{t('Bitte drehen Sie Ihr Gerät ins Querformat.')}</p>
        </div>

        <div
          className={classNames('OnlineGame OnlineGame--outer', className, {
            visible: !deviceOrientationPortait,
            hidden: deviceOrientationPortait,
          })}
        >
          <OnlineGame setGameFinished={setGameFinished} />
        </div>
      </FadeUp>
    );
  } else {
    const domainIframeUrl = getDomainUrl({
      absoluteUrl: url,
      domain,
    });

    if (domainIframeUrl === '') {
      return null;
    }

    let src = domainIframeUrl;
    // Webform prepopulate: Pass query params from parent to the webform iframe.
    if (urlSearch !== '') {
      try {
        const url = new URL(domainIframeUrl);
        url.search = url.search ? `${url.search}&${urlSearch}` : urlSearch;
        src = url.toString();
      } catch {
        console.error('Failed to parse URL', domainIframeUrl);
      } // eslint-disable-line no-empty
    }

    return (
      <FadeUp yGap={20}>
        <div ref={ref} className={classNames('Webform', className)}>
          <SilverbackIframe
            src={src}
            checkOrigin={false}
            redirect={(
              url,
              // Messages support is not implemented at the moment.
              // See par_webform_form_webform_settings_confirmation_form_alter()
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              htmlMessages,
            ) => {
              navigate(url);
            }}
            scroll={jumpToTop}
            style={{ width: '100%', minWidth: '100%' }}
            heightCalculationMethod="lowestElement"
            onMessage={(data) => {
              if (data.message === 'iframe_loaded') {
                if (isFirstMessage.current) {
                  isFirstMessage.current = false;
                } else {
                  ref.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }
            }}
            buildMessages={(htmlMessages) => {
              return (
                <div className="messages ArticleText">
                  {htmlMessages.map((htmlMessage, index) => (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{ __html: htmlMessage }}
                    />
                  ))}
                </div>
              );
            }}
          />
        </div>
      </FadeUp>
    );
  }
};

export default ParagraphWebform;
