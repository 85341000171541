import { initAppConfig, Providers } from '@custom/ui/routes/Providers';
import { GatsbyBrowser, WrapPageElementBrowserArgs } from 'gatsby';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';

import { i18n, Locale } from '../../i18n';
import { APP_CONFIG } from '../constants';
import { DataDependencyWrapper, GatsbyDependencyWrapper } from '../gatsby-api';
import {
  DomainProvider,
  LocalizationProvider,
  PageTranslationsProvider,
} from '../hooks';
import { PageContext } from '../types';

initAppConfig(APP_CONFIG);

export const WrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}: WrapPageElementBrowserArgs<any, PageContext>) => {
  return (
    <Providers
      domain={(props.pageContext.domain?.id || 'paraplegie_ch') as any}
      i18n={i18n}
      path={props.path}
      locale={props.pageContext.locale as Locale}
      localizations={
        props.pageContext.localizations as Array<{
          path: string;
          locale: Locale;
        }>
      }
    >
      <LocalizationProvider
        defaultLocale="de"
        locale={props.pageContext.locale as Locale}
      >
        <PageTranslationsProvider
          path={props.path}
          language={props.pageContext.locale as Locale}
          localizations={
            props.pageContext.localizations as Array<{
              path: string;
              locale: Locale;
            }>
          }
        >
          <GatsbyDependencyWrapper>
            <DataDependencyWrapper>
              <ParallaxProvider>
                <DomainProvider
                  domain={props.pageContext.domain?.id || 'paraplegie_ch'}
                >
                  <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
                </DomainProvider>
              </ParallaxProvider>
            </DataDependencyWrapper>
          </GatsbyDependencyWrapper>
        </PageTranslationsProvider>
      </LocalizationProvider>
    </Providers>
  );
};
